import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';

import { EMAIL_PATTERN, PASSWORD_PATTERN, keyPressNoHTML } from '@util/common';
import { LoaderService, AuthService, LanguageService } from '@services/public';

@Component({
  selector: 'app-public-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class PublicRegisterComponent implements OnInit {
  keyPressNoHTML: typeof keyPressNoHTML = keyPressNoHTML; // Expose this function to the template.
  @ViewChild('captchaElem', { static: false }) captchaElem: PublicRegisterComponent;
  public aFormGroup: FormGroup;
  form: FormGroup;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  pageLanguage: any
  labels: any;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private loaderSvc: LoaderService,  private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _languageSvc: LanguageService) { }

  ngOnInit() {
    this._languageSvc.get([`labels`]).then(value => {
      this.labels = value[`labels`]
    });
    this._languageSvc.get([`pages.public.register`]).then((value) => {
      this.pageLanguage = value[`pages.public.register`];
      this.form = new FormGroup({
        firstName: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
        lastName: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
        email: new FormControl(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
        organizationCode: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
        confirmPassword: new FormControl(null, Validators.required),
        recaptcha:new FormControl(null, [Validators.required]),
        check:new FormControl(false, [Validators.requiredTrue]),
      }, this.matchPasswords);
    });
  }

  handleSuccess(e) {
  }

  get f(): any {
    return this.form.controls;
  }

  private matchPasswords(form: FormGroup): any {
    if (form.value.password === form.value.confirmPassword) return null;
    return { 'mismatch': true };
  }

  register() {
    // Validate form.
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    // Register user.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.authSvc.register(this.form.value).pipe(map(
      (response: any) => {
        if (!!response.success) {
          this.form.reset();
          this.loaderSvc.removeLoader(loader);
          Swal.fire({
            title: this.pageLanguage?.resterSuccess,
            icon: 'success',
            confirmButtonColor: '#456790',
            confirmButtonText: this.pageLanguage?.ok,
            didDestroy: () => this.router.navigate(['/login'])
          });
        }
      }).bind(this)).subscribe();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }
  reloadCaptcha() {
    throw new Error('Method not implemented.');
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }
  resetCaptcha() {
    throw new Error('Method not implemented.');
  }
}
