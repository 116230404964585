import { Component, Input, OnInit } from '@angular/core';
import { REFLECTION_PAGE, ReflectionData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';
import {
  Domain2Service,
  LanguageService,
  Skill2,
  StorageService,
  TEMP_KEYS
} from '@services/public';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.strengths';

@Component({
  selector: 'app-member-skill-builder-reflection-strengths',
  templateUrl: './strengths.component.html',
  styleUrls: ['./strengths.component.scss']
})
export class MemberSkillBuilderReflectionStrengthsComponent implements OnInit {

  @Input() data: ReflectionData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: '',
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        for (const key in value[PAGE_PATH]) {
          switch (key) {
            case 'subTitle': {
              if (this.data.domainUID === 0) break;
              this._languageSvc.template(
                value[PAGE_PATH][key],
                { domain: this._domainSvc.getDomain(
                  this.data.domainUID).name.toLowerCase() }
              ).then(value => this.page.subTitle = value);
              break;
            }
            case 'subTitleOnboarding': {
              if (this.data.domainUID !== 0) break;
              this._languageSvc.template(value[PAGE_PATH][key]).then(
                value => this.page.subTitle = value);
              break;
            }
            default: {
              this._languageSvc.template(value[PAGE_PATH][key]).then(
                value => this.page[key] = value);
            }
          }
        }
      });

    // Get options list.
    if (this.data.domainUID === 0) {
      // Get onboarding skills.
      this.options.next(this.data.onboardingSkillUIDs.map(
        skillUID => {
          const skill: Skill2 = this._domainSvc.getSkill(skillUID);
          const option: SelectListOption = {
            display: skill.name,
            value: skill.uid
          }
          if (this.data.strengths?.includes(skill.uid))
            option.selected = true;
          return option;
        }));
    } else {
      // Get domain skills.
      this.options.next(this._domainSvc.getSkills(this.data.domainUID).map(
        skill => {
          const option: SelectListOption = {
            display: skill.name,
            value: skill.uid
          }
          if (this.data.strengths?.includes(skill.uid))
            option.selected = true;
          return option;
        }));
    }
  }

  nextOnClick(options: Array<SelectListOption>): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION, {
      ...this.data,
      page: REFLECTION_PAGE.WEAKNESSES,
      strengths: options.map(option => option.value)
    });
  }

}
