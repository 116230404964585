<div class="modal-header justify-content-between">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <div *ngIf="filters?.length > 0">
    <select *ngFor="let filter of filters" class="select-w"
      [(ngModel)]="filter.value" (change)="updateFilters()">
      <option *ngFor="let option of filter.options" [ngValue]="option">
        <a class="{{ filter.value === option ? 'active' : '' }}">
          {{ option.name }}
        </a>
      </option>
    </select>
    <button type="button" class="close pull-right d-none d-md-block"
      [attr.aria-label]="labels?.close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div id="scrollContainer">
    <div #graphContainer class="chartContainer">
      <canvas #graph></canvas>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center modal-footer bottomBtnsModal footer-inner-action pb-3">
  <button type="button" class="cancel mr-0  d-block d-md-none"
  data-dismiss="modal" [attr.aria-label]="labels?.close" (click)="bsModalRef.hide()">
    {{labels?.close}}
  </button>
</div>
