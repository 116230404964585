import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LABELS, LanguageService } from '@services/public';
import { NO_HTML_PATTERN } from '@util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject } from 'rxjs';

export interface SelectListOption {
  display: string; // The display text for the option.
  value?: any // Used to assign a value different than the display.
  info?: SelectListOptionInfo; // Used for info popups.
  link?: string; // Used to redirect to a different URI.
  custom?: boolean; // Used to allow custom options to be deleted.
  selected?: boolean; // Used to select an option.
}

export interface SelectListOptionInfo {
  display: string; // The display text for the info popup.
  tooltip?: string; // Used for a condensed hover tooltip.
}

export interface SelectListCustom {
  display?: string; // The display text for adding custom options.
  onClick?: Function; // An override function for the add custom button.
  add?: Function; // The function to call to add custom options.
  delete?: Function; // The function to call to delete custom options.
  form?: FormGroup; // Used internally.
}

export interface SelectListNext {
  display?: string; // The display text for the Next button.
  onClick: Function; // The function to call to submit the selected options.
}

const PAGE_PATH: string = 'skillBuilders.components.selectList';

@Component({
  selector: 'app-skill-builder-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SkillBuilderSelectListComponent implements OnInit {

  static ID: number = 1;

  @ViewChild('addCustomTemplate') addCustomTemplate: TemplateRef<any>;

  @Input() mainTitle: string = '';
  @Input() subTitle: string = '';
  @Input() options:
    ReplaySubject<Array<SelectListOption>> |
    (() => ReplaySubject<Array<SelectListOption>>) = null;
  @Input() custom: SelectListCustom = null;
  @Input() change: Function = () => {};
  @Input() next: SelectListNext = null;
  @Input() max: number = 1; // Maximum number of allowed selections.
  @Input() submitted: boolean = false; // Controllable via the parent.

  form: FormGroup;
  cachedOptions: Array<SelectListOption> = [];
  id: number = 0;
  private _modal: BsModalRef;

  // Page langauge.
  page: {[key: string]: string} = {
    addCustom: '',
    error1: '',
    error2: '',
    error3: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.CANCEL]: '',
    [LABELS.ERROR_NO_HTML]: '',
    [LABELS.NEXT]: '',
    [LABELS.SAVE]: '',
    [LABELS.TYPE_HERE]: ''
  }

  constructor(
    private _modalSvc: BsModalService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(
            this.page[key],
            (key === 'error2' ? { max: this.max?.toString() } : {})
          ).then(value => this.page[key] = value);
      }
    );
    this._languageSvc.getLabels(this.labels);

    // Set component id.
    this.id = SkillBuilderSelectListComponent.ID++;

    // Setup form.
    this.form = new FormGroup({
      [`optionList${this.id}`]: new FormArray([])
    });
    if (typeof this.options === 'function') this.options = this.options();
    this.options.subscribe(options => {
      if (this.max === 1) {
        // Handle single select lists.
        const selectedOption = options.find(
          option => this.optionList.value[0]?.display === option.display ||
            !!option.selected);
        while (this.optionList.length !== 0)
          this.optionList.removeAt(0);
        this.cachedOptions = options;
        for (let i = 0; i < options.length; i++)
          this.optionList.push(new FormControl(null));
        if (this.optionList.controls.length > 0)
          this.optionList.controls[0].setValue(
            selectedOption || { display: null });
      } else {
        // Handle multiple select lists.
        for (const option of options) {
          const cachedIndex = this.cachedOptions.findIndex(
            cachedOption => cachedOption.display === option.display);
          if (cachedIndex === -1) {
            if (!!option.selected) {
              this.cachedOptions.push(option);
            } else option.selected = false;
          } else option.selected = this.optionList.value[cachedIndex];
        }
        while (this.optionList.length !== 0)
          this.optionList.removeAt(0);
        this.cachedOptions = options;
        for (let i = 0; i < options.length; i++) {
          this.optionList.push(new FormControl(options[i].selected));
          delete options[i].selected;
        }
      }
    });
    if (!!this.custom)
      this.custom.form = new FormGroup({
        [`option${this.id}`]: new FormControl(null,
          [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
      });
  }

  get optionList(): FormArray {
    return <FormArray>this.form.controls[`optionList${this.id}`];
  }

  get selectedCount(): number {
    if (this.max === 1) {
      if (!this.optionList.value[0]?.display) return 0;
      return 1;
    }
    let count = 0;
    this.optionList.value.forEach(
      (selected: boolean) => { if (selected) count++; });
    return count;
  }

  onChange(): void {
    if (this.max === 1) this.change(this.optionList.value[0]);
    else this.change(this.cachedOptions.filter(
      (_value, index) => this.optionList.value[index]));
  }

  addCustom(): void {
    this.custom.form.reset();
    this._modal = this._modalSvc.show(this.addCustomTemplate, {
      class: 'modal-sm modal-dialog-centered',
      backdrop: 'static',
      keyboard: true,
    });
  }

  cancelCustom(): void {
    this._modal.hide();
  }

  saveCustom(): void {
    this.custom.form.controls[`option${this.id}`].setValue(
      (this.custom.form.controls[`option${this.id}`].value || '').trim());
    this.custom.form.markAllAsTouched();
    if (!this.custom.form.valid) return;
    this.custom.add(this.custom.form.controls[`option${this.id}`].value);
    this._modal.hide();
  }

  displayInfo(option: SelectListOption): void {

  }

  displayLink(option: SelectListOption): void {

  }

  nextOnClick(): void {
    this.submitted = true;
    if (this.selectedCount === 0 || this.selectedCount > this.max) return;
    if (this.max === 1) this.next.onClick(this.optionList.value[0]);
    else this.next.onClick(this.cachedOptions.filter(
      (_value, index) => this.optionList.value[index]));
  }

}
