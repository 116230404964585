<div class="tabHeadMain px-5">
  <div class="container">
    <div class="tabHead">
      <span routerLink="/leader/assessment/list" class="back-arrow"><i class="fas fa-arrow-left"></i>
      </span>
      {{page?.updateAssessment}}
    </div>
  </div>
</div>

<div *ngIf="assessment.status === AssessmentStatus.INACTIVE || assessment.status === AssessmentStatus.SCHEDULED"
  class="container">
  <div class="pageTopCard">
    <div class="topCardLbl btn-c">
      <h5>{{page?.assessmentDetails}}</h5>
      <button (click)="editAssessment()" type="button" class="btn btn-primary">
        <i class="fas fa-edit"></i>
        {{page?.edit}}
      </button>
    </div>

    <div class="topCardCntr">
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.assessmentName}}</label>
          <span>{{assessment.name}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.AssessmentType}}</label>
          <span class="text-capitalize">{{!!assessment.type ? assessment.type : '-'}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.groupName}}</label>
          <span>{{assessment.group.name}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.startDate}}</label>
          <span>{{assessment.startDate | date: 'MM-dd-yyyy'}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.endDate}}</label>
          <span>{{assessment.endDate | date: 'MM-dd-yyyy'}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 topCardCol">
          <label>{{page?.status}}</label>
          <span>{{assessment.status}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="cardDsplMain">
    <ng-container *ngFor="let domain of domains; let i = index;">
      <div class="cardDsplRow">
        <div class="cardDsplLft" data-toggle="collapse" [attr.href]="'#collapse' + i" role="button"
          aria-expanded="false" [attr.aria-controls]="'collapse' + i">
          <div class="cardDsplLftInner">
            {{domain.name}}
          </div>
        </div>
        <div class="cardDsplRyt collapse" [id]="'collapse' + i">
          <ul>
            <ng-container *ngFor="let skill of domain.skills">
              <li (click)="toggleSkill(skill)">
                <div class="cardDsplRytItems cursor-pointer {{ assessment.skills.includes(skill) ? 'selected' : '' }}">
                  <span>{{skill.name}}</span>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="timesheetMain">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain">
        <div class="timesheetCol">
          <h5>{{page?.numOfCompetencies}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain">
        <div class="timesheetCol">
          <h5>{{page?.numOfQuestions}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain">
        <div class="timesheetCol">
          <h5>{{page?.completionTime}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 btn-c d-flex align-items-center justify-content-center">
    <button routerLink="/leader/dashboard" type="button" class="btn btn-md mt-2 mt-md-0">{{page?.cancel}}</button>

    <button [disabled]="!assessment.skills.length" (click)="onSubmit()" type="button" class="btn btn-primary btn-primary-sm">
      {{assessment.type === AssessmentType.PEER ? page?.saveAndContinue  : page?.submit}}
    </button>
  </div>
</div>

<div *ngIf="assessment.status === AssessmentStatus.COMPLETED">
  <div class="emptyAssmntMain">
    <div class="emptyAssmntInner">
      <span><i class="fa fa-check-circle done" aria-hidden="true"></i></span>
      <p>{{page?.alreadyComplete}}</p>
    </div>
  </div>
</div>
<div *ngIf="assessment.status === AssessmentStatus.ACTIVE">
  <div class="emptyAssmntMain">
    <div class="emptyAssmntInner">
      <span><i class="fa fa-check-circle done" aria-hidden="true"></i></span>
      <p>{{page?.alreadyActive}}</p>
    </div>
  </div>
</div>
