import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UserAgentScreenService {

  constructor(private platform: Platform) { }

  // Check user agent for specific conditions
  isMobile(): boolean {
    return !this.platform.isBrowser;
  }

  // Check screen size for specific conditions
  // Adjust this value according to your requirements
  isSmallScreen(screenSize: number=768): boolean {
    const screenWidth = window.innerWidth;
    return screenWidth <= screenSize;
  }
}
