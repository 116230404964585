import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Chart } from "chart.js/auto";
import { Domain, LoaderService, LanguageService } from "@services/public";
import {
  AssessmentService,
  Assessment,
  User,
  AssessmentStatus,
  AssessmentScoresService,
  AssessmentScores,
} from "@services/leader";
import { GroupService, Group } from "@services/member";
import { LeaderGroupCreateComponent } from "@pages/leader/group";
import { OrganizationService } from "@services/member";
import { LeaderAssessmentCreateComponent } from "@pages/leader/assessment";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SelfAwareness } from "@services/member/report.service";
const PAGE_PATH = `pages.researcher.dashboard.trackDashboard`;
@Component({
  selector: "app-track-dashboard",
  templateUrl: "./track-dashboard.component.html",
  styleUrls: ["./track-dashboard.component.scss"],
})
export class TrackDashboardComponent implements OnInit, OnDestroy {
  @ViewChild("selfVSPeerCanvas", { read: ElementRef })
  selfVSPeerCanvas: ElementRef;
  @ViewChild("assesmentgraph", { read: ElementRef }) assesmentgraph: ElementRef;

  //view all
  modalRef: BsModalRef;
  title: string;
  message: string;
  selfData: any;
  selfAssesment: Array<Array<string | number>> = null;
  recentSelfAssessment: Array<Array<string | number>> = [];
  p: number = 1;
  public ascNumberSort = true;
  days_difference: number;
  finalDate: number;
  selfAssesmentData: BehaviorSubject<any> = new BehaviorSubject(null);
  recentSelfAssesmentData: BehaviorSubject<any> = new BehaviorSubject(null);
  isPieChartVisible: boolean = true;
  viewMore: boolean;
  maxGridLength: any;
  recentSelfAweness: any = [];
  recentSelfData: any;
  peerData: any[];
  viewAllRecentSelf: boolean;
  viewAllSelfPeer: boolean;
  table_header_highlight: any = "";
  endDate: Boolean = false;
  status: Boolean = false;

  // Page subscriptions.
  private _subscriptions: Subscription = new Subscription();

  // Page filters.
  selectedDomain: Domain | null = null;
  selectedTimespan: string = "all time";
  selectedGroup: Group | null = null;
  selectedUser: User | null = null;
  selectedGrowthDomain: Domain | null = null;
  selectedRecentSelfDomain: Domain | null = null;
  selectedSelfVsPeerDomain: Domain | null = null;
  allGroup: any = [];

  // Local data storage from API calls.
  domains: Array<Domain> = [];
  groups: Array<Group> = [];
  users: Array<User> | null = null;
  assessmentScores: AssessmentScores | null = null;
  allDomainsAndSkills: any = {};
  allAssessments: any = null;

  // Loading flags.
  groupsLoaded = false;

  // Filtered data storage.
  usersInSelectedGroup: Array<User> = [];
  assessments: Array<Assessment> = [];
  assessmentsCompleted: Assessment[];
  assessmentsPending: Assessment[];
  assessmentsActive: Assessment[];
  filteredAssessmentScoresByTimespan: AssessmentScores | null = null;
  filteredAssessmentScoresByGroup: AssessmentScores | null = null;
  filteredAssessmentScoresByUser: AssessmentScores | null = null;

  // Report data.
  domainOverallScoresGraph: any = null;
  growthChart: Chart = null;
  strengthsAndWeaknesses: any = null;
  selfAwareness: Array<SelfAwareness> = [];
  selfVSPeer: any = null;
  selfVSPeerChart: Chart = null;
  showbutton: string = "View More";
  organizations: any;
  selectedOrg: any = null;
  deleteAss: any;

  // Page language.
  page: {[key: string]: string} = {
  }
  constructor(
    private _languageSvc: LanguageService,
    private router: Router,
    private groupSvc: GroupService,
    private assessmentSvc: AssessmentService,
    private assessmentScoresSvc: AssessmentScoresService,
    private orgSVC: OrganizationService,
    private modalSvc: BsModalService,
    private loaderSvc: LoaderService
  ) {}

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page)
        this._languageSvc.template(this.page[key]).then(
          value => this.page[key] = value);
      }
    );
    // Load assessment scores
    this._subscriptions.add(
      this.assessmentScoresSvc.scores.subscribe((next) => {
        this.assessmentScores = next;
      })
    );
    this.loadOrganization();
    // Load groups.
    this._subscriptions.add(
      this.groupSvc.groups.subscribe((next) => {
        this.allGroup = next;
        this.groups = next.filter(
          (group) => group.organizationUUID === this.selectedOrg
        );
        if (this.groups.length === 0) {
          this.selectedGroup = null;
          this.usersInSelectedGroup = [];
          this.selectedUser = null;
        } else {
          if (!!this.selectedGroup)
            this.selectedGroup = this.groups.find(
              (group) => this.selectedGroup.uuid === group.uuid
            );
          if (!this.selectedGroup) this.selectedGroup = this.groups[0];
          if (!!this.users) {
            this.usersInSelectedGroup = this.users.filter(
              (user) =>
                this.selectedGroup.memberUUIDs.findIndex(
                  (groupUser) => groupUser === user.uuid
                ) !== -1
            );
            if (!!this.selectedUser)
              this.selectedUser = this.usersInSelectedGroup.find(
                (user) => this.selectedUser.uuid === user.uuid
              );
            if (!this.selectedUser) this.selectedUser = null;
          }
        }
        this.groupsLoaded = true;
        this.loadAssessments();
        // Load assessments.
        this.loadAllAssessments();
      })
    );
    this.groupSvc.loadGroups();
  }

  loadOrganization() {
    const orgUUID = localStorage.getItem("organizationUUID");
    this.orgSVC.organizations.subscribe((organization) => {
      this.organizations = organization;
      if (orgUUID !== null) {
        const org = this.organizations.find(
          (element) => element.uuid === orgUUID
        );
        this.selectedOrg = org.uuid;
      }
    });
  }
  onSelectOrganization(event) {
    if (event[0] !== null) {
      localStorage.setItem("organizationUUID", event);
      const org = this.organizations.find((element) => element.uuid === event);
      this.groups = this.allGroup.filter(
        (group) => group.organizationUUID === org.uuid
      );
      this.selectedGroup = this.groups[0];
      this.loadAssessments();
    }
  }
  loadAllAssessments(): void {
    // Load all assessments.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(
      loader,
      "pages/leader/dashboard/track:loadAllAssessments"
    );
    this.assessmentSvc
      .getAssessments()
      .pipe(
        map((response: any) => {
          if (!!response) {
            response.map((assessment) => {
              const group = this.groups.find(
                (group) => group.uuid === assessment.groupUUID
              );
              assessment.group = group;
            });
            this.allAssessments = response;
            this.allAssessments.sort((a, b) => {
              if (a.status === b.status) return 0;
              if (a.status === AssessmentStatus.INACTIVE) return -1;
              if (a.status === AssessmentStatus.COMPLETED) return 1;
              if (a.status === AssessmentStatus.SCHEDULED) {
                if (b.status === AssessmentStatus.COMPLETED) return -1;
                return 1;
              }
              if (b.status === AssessmentStatus.INACTIVE) return 1;
              return -1;
            });
            this.loadAssessments();
            this.loaderSvc.removeLoader(loader);
          }
        }).bind(this)
      )
      .subscribe();
  }

  diffDates(curr, memory) {
    var date1 = new Date(memory);
    var date2 = new Date(curr);
    //calculate time difference
    var time_difference = date2.getTime() - date1.getTime();

    //calculate days difference by dividing total milliseconds in a day
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return days_difference;
  }

  loadAssessments(): void {
    // Skip if groups or assessments aren't loaded yet.
    this.p = 1;
    if (this.selectedGroup === null) return;
    if (this.allAssessments === null) return;

    // Load assessments.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(
      loader,
      "pages/leader/dashboard/track:loadAssessments"
    );
    this.assessments = this.allAssessments.filter(
      (assessment) => assessment.groupUUID === this.selectedGroup?.uuid
    );

    let activeAssessments = this.assessments.filter(
      (assessment) => assessment.status != "SCHEDULED"
    );

    if (activeAssessments.length > 0) {
      const memory = activeAssessments.reduce((a, b) =>
        a.startDate > b.startDate ? a : b
      );

      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      let currentDate = `${month}/${day}/${year}`;

      this.finalDate = Math.round(
        this.diffDates(currentDate, memory.startDate)
      );
    } else {
      this.finalDate = 0;
    }

    this.assessmentsCompleted = this.assessments.filter(
      (x) => x.status === "COMPLETED"
    );
    this.assessmentsActive = this.assessments.filter(
      (x) => x.status === "ACTIVE"
    );
    this.assessmentsPending = this.assessments.filter(
      (x) => x.status !== "COMPLETED" && x.status !== "ACTIVE"
    );
    this.loaderSvc.removeLoader(loader);
  }

  sortNumberColumn(type) {
    this.table_header_highlight = type;
    if (type == "startdate") {
      this.endDate = false;
      this.status = false;
      this.ascNumberSort = !this.ascNumberSort;
      if (this.ascNumberSort) {
        this.assessments.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
      } else {
        this.assessments.sort((a, b) => (b.startDate > a.startDate ? 1 : -1));
      }
    } else if (type == "enddate") {
      this.endDate = !this.endDate;
      this.ascNumberSort = true;
      this.status = false;
      if (this.endDate) {
        this.assessments.sort((a, b) => (a.endDate > b.endDate ? 1 : -1));
      } else {
        this.assessments.sort((a, b) => (b.endDate > a.endDate ? 1 : -1));
      }
    } else {
      this.ascNumberSort = true;
      this.endDate = false;
      this.status = !this.status;
      if (this.status) {
        this.assessments.sort((a, b) => (a.status > b.status ? 1 : -1));
      } else {
        this.assessments.sort((a, b) => (b.status > a.status ? 1 : -1));
      }
    }
  }

  pageRenderTo() {
    this.router.navigate(["/"]);
  }

  endDateColumn() {
    this.ascNumberSort = !this.ascNumberSort;
    if (this.ascNumberSort) {
      this.assessments.sort((a, b) => (a.endDate > b.endDate ? 1 : -1));
    } else {
      this.assessments.sort((a, b) => (b.endDate > a.endDate ? 1 : -1));
    }
  }

  createGroup(): void {
    this.modalSvc.show(LeaderGroupCreateComponent, {
      class: "modal-md modal-dialog-centered",
      backdrop: "static",
      keyboard: true,
    });
  }

  createAssessment(): void {
    this.modalSvc.show(LeaderAssessmentCreateComponent, {
      class: "modal-dialog-centered",
    });
  }

  modified_date(objectDate) {
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    return this.getMonthName(month + 1).slice(0, 3) + " " + day;
  }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString("en-US", { month: "long" });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  //pie chart

  changeTab($event) {
    this.selectedGroup = this.groups[0];
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

  viewAssessment(assessment: Assessment): void {
    if (
      assessment.status === AssessmentStatus.INACTIVE ||
      assessment.status === AssessmentStatus.SCHEDULED
    ) {
      localStorage.setItem(
        "createAssessment",
        JSON.stringify({ formValue: assessment, from: "view" })
      );
      this.router.navigate(["/leader/assessment/skills"]);
    } else this.router.navigate(["/leader/assessment/view", assessment.uuid]);
  }

  openModal(template: TemplateRef<any>, assessment: any) {
    this.deleteAss = assessment;
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  deleteAssessment(assessment): void {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .deleteAssessment(assessment.uuid)
      .pipe(
        map((response: any) => {
          this.loadAssessments();
          this.loaderSvc.removeLoader(loader);
        }).bind(this)
      )
      .subscribe();
    this.modalRef?.hide();
  }

  decline(): void {
    this.message = this.page?.declined;
    this.modalRef?.hide();
  }

}
