<div
  class="main-container d-flex align-items-center justify-content-center">
  <div
    class="container-fluid px-0 w-sm-100 w-50 d-flex align-items-center justify-content-center pb-5-md-c">
    <div class="col-sm-12 col-md-12 col-lg-6  mb-4 mb-lg-0">
      <h4 class="w-auto text-center mb-3 px-3 font-weight-bold">
        {{ page.title }}
      </h4>
      <div class="card card-white">
        <div class="card-body justify-content-center">
          <div class="success-checkmark"><i class="fas fa-check"></i></div>
          <p class="mt-3 text-center success-msg">
            {{ page.text1 }} 
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-inner-action">
    <button
      type="button"
      (click)="nextOnClick()"
      class="btn btn-primary btn-lg px-4">
      {{ page.submit }}
    </button>
  </div>
</div>
