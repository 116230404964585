<div class="main-container pb-lg">
  <tabset class="tab-c">
    <tab heading="{{page?.measure}}" (selectTab)="changeTab($event)">
      <div class="dashContArea dashContAreaStudent px-5 mt-4">
        <app-measure-dashboard></app-measure-dashboard>
      </div>
    </tab>
    <tab heading="{{page?.train}}" (selectTab)="changeTab($event)">
      <div class="dashContArea dashContAreaStudent px-5 mt-2">
        <app-train-dashboard></app-train-dashboard>
      </div>
    </tab>
    <tab heading="{{page?.track}}" (selectTab)="changeTab('Track')">
      <div *ngIf="IsTrack"  class="dashContArea dashContAreaStudent px-5 mt-2">
       <app-track-dashboard></app-track-dashboard>
      </div>
    </tab>
    <tab heading="{{page?.userManage}}" (selectTab)="changeTab($event)">
      <div  class="dashContArea dashContAreaStudent px-5 mt-2">
        <app-user-management></app-user-management>
      </div>
    </tab>
  </tabset>
</div>