import { Component, Input, OnInit } from '@angular/core';
import { STRESS_PAGE, StressData } from "../common";
import {
  SelectListCustom,
  SelectListNext,
  SelectListOption
} from '@components';
import {
  LanguageService,
  MAIN_KEYS,
  MAIN_KEY_PREFIX,
  StorageService,
  TEMP_KEYS
} from '@services/public';
import { ReplaySubject, Subscription } from 'rxjs';
import { APIErrors, APISuccess, ExposedPromise, isAPISuccess } from '@util';
import { StressService } from '@services/member';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.stress.pages.brain';

@Component({
  selector: 'app-member-skill-builder-stress-brain',
  templateUrl: './brain.component.html',
  styleUrls: ['./brain.component.scss']
})
export class MemberSkillBuilderStressBrainComponent implements OnInit {

  @Input() data: StressData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  customOptions: Array<SelectListOption> = [];
  custom: SelectListCustom = {
    add: this.addCustom.bind(this),
    delete: this.deleteCustom.bind(this)
  }
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }
  max: number = 2;
  defaultLoaded: ExposedPromise<void> = new ExposedPromise<void>();
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: ''
  };

  constructor(
    private _stressSvc: StressService,
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Get options list.
    const path: string = 'skillBuilders.stress.brain';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = Object.values(value[path]);
        this.defaultLoaded.resolve();
      });
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
        this.customOptions = [];
        if (Array.isArray(update.value[MAIN_KEYS.STRESS_DESTRESS])) {
          for (const option of update.value[MAIN_KEYS.STRESS_DESTRESS]) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
        this.customOptions = [];
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.STRESS_DESTRESS) {
        this.customOptions = [];
        if (Array.isArray(update.value)) {
          for (const option of update.value) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
    }));
    let options: Array<string> = this._storageSvc.getMainStorage(
      MAIN_KEYS.STRESS_DESTRESS);
    if (Array.isArray(options)) {
      for (const option of options) {
        if (typeof option !== 'string') continue;
        this.customOptions.push({ display: option, custom: true });
      }
    }

    this._mergeAndSelectOptions();
  }

  private async _mergeAndSelectOptions(): Promise<void> {
    await this.defaultLoaded;
    this.options.next([...this.customOptions, ...this.defaultOptions].map(
      (value: SelectListOption) => {
        if (this.data?.brain?.includes(value.display))
          return { ...value, selected: true };
        return value;
      }));
    this._updateMaxOptions();
  }

  private _updateMaxOptions(): void {
    this.max =
      (this.customOptions.length || 0) + (this.defaultOptions.length || 0);
  }

  addCustom(customOption: string): void {
    if (this.customOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    if (this.defaultOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    this.customOptions.push({ display: customOption, custom: true });
    this._storageSvc.setMainStorage(MAIN_KEYS.STRESS_DESTRESS,
      this.customOptions.map(option => option.display));
  }

  deleteCustom(option: SelectListOption): void {
    this.customOptions = this.customOptions.filter(
      customOption => customOption.display !== option.display);
    if (this.customOptions.length === 0)
      this._storageSvc.setMainStorage(MAIN_KEYS.STRESS_DESTRESS, null);
    else this._storageSvc.setMainStorage(MAIN_KEYS.STRESS_DESTRESS,
        this.customOptions.map(option => option.display));
  }

  nextOnClick(options: Array<SelectListOption>): void {
    if (this.data.tryDifferentReliever) {
      this.data.brain = options.map(option => option.display);
      this._stressSvc.createPlan(this.data).subscribe(
        (res: APISuccess | APIErrors) => {
          if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
        }
      );
      return;
    }
    this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
      {
        ...this.data,
        page: STRESS_PAGE.DESTRESS,
        brain: options.map(option => option.display)
      });
  }

}
