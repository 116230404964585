import { Injectable } from '@angular/core';
import { ExposedPromise, JClone } from '@util';
import {
  arLocale,
  bgLocale,
  caLocale,
  csLocale,
  daLocale,
  deLocale,
  defineLocale,
  enGbLocale,
  esLocale,
  etLocale,
  fiLocale,
  frLocale,
  glLocale,
  heLocale,
  hiLocale,
  hrLocale,
  huLocale,
  idLocale,
  itLocale,
  jaLocale,
  kaLocale,
  kkLocale,
  koLocale,
  ltLocale,
  lvLocale,
  mnLocale,
  nbLocale,
  nlLocale,
  plLocale,
  ptBrLocale,
  roLocale,
  ruLocale,
  skLocale,
  slLocale,
  sqLocale,
  svLocale,
  thLocale,
  trLocale,
  ukLocale,
  viLocale,
  zhCnLocale
} from 'ngx-bootstrap/chronos';

export enum LANGUAGE_CODES {
  ARA = 'ara', // ar: Arabic
  BUL = 'bul', // bg: Bulgarian
  CAT = 'cat', // ca: Catalan
  CES = 'ces', // cs: Czech
  DAN = 'dan', // da: Danish
  DEU = 'deu', // de: German
  ENG = 'eng', // en/en-gb: English/UK English
  EST = 'est', // et: Estonian
  FIN = 'fin', // fi: Finnish
  FRA = 'fra', // fr: French
  GLG = 'glg', // gl: Galician
  HEB = 'heb', // he: Hebrew
  HIN = 'hin', // hi: Hindi
  HRV = 'hrv', // hr: Croatian
  HUN = 'hun', // hu: Hungarian
  IND = 'ind', // id: Indonesian
  ITA = 'ita', // it: Italian
  JPN = 'jpn', // ja: Japanese
  KAT = 'kat', // ka: Georgian
  KAZ = 'kaz', // kk: Kazakh
  KOR = 'kor', // ko: Korean
  LAV = 'lav', // lv: Latvian
  LIT = 'lit', // lt: Lithuanian
  MON = 'mon', // mn: Mongolian
  NLD = 'nld', // nl: Dutch
  NOR = 'nor', // no/nb: Norwegian
  POL = 'pol', // pl: Polish
  POR = 'por', // pt/pt-br: Portugese/Brazillian Portugese
  RON = 'ron', // ro: Romanian
  RUS = 'rus', // ru: Russian
  SLK = 'slk', // sk: Slovak
  SLV = 'slv', // sl: Slovenian
  SPA = 'spa', // es: Spanish
  SQI = 'sqi', // sq: Albanian
  SWE = 'swe', // sv: Swedish
  THA = 'tha', // th: Thai
  TUR = 'tur', // tr: Turkish
  UKR = 'ukr', // uk: Ukrainian
  VIE = 'vie', // vi: Vietnamese
  ZHO = 'zho', // zh/zh-cn: Chinese/PRC Chinese
}

// Define locales for calendars.
defineLocale(LANGUAGE_CODES.ARA, arLocale);
defineLocale(LANGUAGE_CODES.BUL, bgLocale);
defineLocale(LANGUAGE_CODES.CAT, caLocale);
defineLocale(LANGUAGE_CODES.CES, csLocale);
defineLocale(LANGUAGE_CODES.DAN, daLocale);
defineLocale(LANGUAGE_CODES.DEU, deLocale);
defineLocale(LANGUAGE_CODES.ENG, enGbLocale);
defineLocale(LANGUAGE_CODES.EST, etLocale);
defineLocale(LANGUAGE_CODES.FIN, fiLocale);
defineLocale(LANGUAGE_CODES.FRA, frLocale);
defineLocale(LANGUAGE_CODES.GLG, glLocale);
defineLocale(LANGUAGE_CODES.HEB, heLocale);
defineLocale(LANGUAGE_CODES.HIN, hiLocale);
defineLocale(LANGUAGE_CODES.HRV, hrLocale);
defineLocale(LANGUAGE_CODES.HUN, huLocale);
defineLocale(LANGUAGE_CODES.IND, idLocale);
defineLocale(LANGUAGE_CODES.ITA, itLocale);
defineLocale(LANGUAGE_CODES.JPN, jaLocale);
defineLocale(LANGUAGE_CODES.KAT, kaLocale);
defineLocale(LANGUAGE_CODES.KAZ, kkLocale);
defineLocale(LANGUAGE_CODES.KOR, koLocale);
defineLocale(LANGUAGE_CODES.LAV, lvLocale);
defineLocale(LANGUAGE_CODES.LIT, ltLocale);
defineLocale(LANGUAGE_CODES.MON, mnLocale);
defineLocale(LANGUAGE_CODES.NLD, nlLocale);
defineLocale(LANGUAGE_CODES.NOR, nbLocale);
defineLocale(LANGUAGE_CODES.POL, plLocale);
defineLocale(LANGUAGE_CODES.POR, ptBrLocale);
defineLocale(LANGUAGE_CODES.RON, roLocale);
defineLocale(LANGUAGE_CODES.RUS, ruLocale);
defineLocale(LANGUAGE_CODES.SLK, skLocale);
defineLocale(LANGUAGE_CODES.SLV, slLocale);
defineLocale(LANGUAGE_CODES.SPA, esLocale);
defineLocale(LANGUAGE_CODES.SQI, sqLocale);
defineLocale(LANGUAGE_CODES.SWE, svLocale);
defineLocale(LANGUAGE_CODES.THA, thLocale);
defineLocale(LANGUAGE_CODES.TUR, trLocale);
defineLocale(LANGUAGE_CODES.UKR, ukLocale);
defineLocale(LANGUAGE_CODES.VIE, viLocale);
defineLocale(LANGUAGE_CODES.ZHO, zhCnLocale);

export enum LABELS {
  ALL_DOMAINS = 'allDomains',
  ALL_GROUPS = 'allGroups',
  ALL_TIME = 'allTime',
  CANCEL = 'cancel',
  CLOSE = 'close',
  COMPLETED = 'completed',
  DAYS = 'days',
  DECLINED = 'declined',
  ERROR_DISCLAIMER = 'errorDisclaimer',
  ERROR_NO_HTML = 'errorNoHTML',
  ERROR_SELECT_OPTION = 'errorSelectOption',
  GOOD = 'good',
  GROUP = 'group',
  IMPROVEMENT = 'improvement',
  INSUFFICIENT_DATA = 'insufficientData',
  LEVEL = 'level',
  NAME = 'name',
  NEXT = 'next',
  OK = 'ok',
  ONBOARDING = 'onboarding',
  SAVE = 'save',
  SELECT_DATE = 'selectDate',
  STATUS = 'status',
  SUBMIT = 'submit',
  TO = 'to',
  TYPE_HERE = 'typeHere',
  VIEW_ALL = 'viewAll',
  VIEW_LESS = 'viewLess',
  VIEW_MORE = 'viewMore',
  YEAR = 'year',
  YEARS = 'years'
}

export interface Domain {
  uid: number;
  name: string;
  skills: Skill[];
}

export interface Skill {
  uid: number;
  name: string;
  definition: string;
  description: string;
  questions: Array<string>;
}

export interface Reading {
  intro: Array<string>;
  inventory: Array<string>;
  cycle: Array<object>;
  header: string;
  body: string;
}

const LABELS_PATH: string = 'labels';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: LANGUAGE_CODES;
  private _labels: { [key: string]: string } = {};
  private _initialize: ExposedPromise<void> = new ExposedPromise<void>();
  private _loadingLabels: ExposedPromise<void> = new ExposedPromise<void>();
  private _loading: Set<Promise<any>> =
    new Set<Promise<any>>([this._initialize]);
  private _compiled: { [key: string]: any } = {};
  private _loadPaths: (paths: Array<string>) => Promise<void>;

  constructor() { }

  initialize(): void {
    this._initialize.resolve();
    this._loading.delete(this._initialize);
    this.get([LABELS_PATH]).then(
      value => {
        if (
          typeof value[LABELS_PATH] !== 'object' ||
          value[LABELS_PATH] === null
        ) return;
        this._labels = value[LABELS_PATH];
        this._loadingLabels.resolve();
      }
    );
  }

  addLoader(loader: Promise<any>): void {
    this._loading.add(loader);
    loader.then(() => { this._loading.delete(loader) });
  }

  set compiled(compiled: any) {
    this._compiled = compiled;
  }

  set loadPaths(loadPath: (paths: Array<string>) => Promise<void>) {
    this._loadPaths = loadPath;
  }

  // Used in app.component.ts.
  get loadingLabels(): Promise<void> {
    return this._loadingLabels.toPromise();
  }

  async get(
    paths: Array<string>,
    data?: { [key: string]: { [key: string]: string } }
  ): Promise<{ [key: string]: any }> {
    // Wait for the language loader.
    await Promise.all(this._loading);

    // Attempt to locate values.
    const results: { [key: string]: any } = {};
    for (const path of paths) {
      try {
        const splitPath = path.split('.');
        let value = this._compiled;
        for (let i = 0; i < splitPath.length; i++) {
          value = value[splitPath[i]];
          if (typeof value === 'undefined') {
            value = null;
            break;
          }
        }
        results[path] =
          ((typeof value === 'object' && !value._loaded) ? null : value);
      } catch (e) {
        results[path] = null;
      }
    }

    // Attempt to load null values.
    const badPaths = paths.filter(path => results[path] === null);
    if (badPaths.length > 0) {
      const loader = this._loadPaths(badPaths);
      this.addLoader(loader);
      await loader;

      // Second attempt to locate values.
      for (const path of badPaths) {
        try {
          const splitPath = path.split('.');
          let value = this._compiled;
          for (let i = 0; i < splitPath.length; i++) {
            value = value[splitPath[i]];
            if (typeof value === 'undefined') {
              results[path] = null;
              break;
            }
          }
          results[path] =
            ((typeof value === 'object' && !value._loaded) ? null : value);
        } catch (e) {
          results[path] = null;
        }
      }
    }

    // Resolve template strings.
    if (!!data)
      for (const path in results)
        if (typeof results[path] === 'string')
          results[path] = await this.template(results[path], data[path]);

    // Return a deep copy.
    const resultsClone = JClone(results);
    this._deletePrivateFlags(resultsClone);
    return resultsClone;
  }

  async template(
    template: string,
    data?: { [key: string]: string }
  ): Promise<string> {
    await Promise.all(this._loading);

    // Resolve template strings.
    data = data || {};
    while (/\$\{language\:[^\}]+\}/.test(template)) {
      const variables = template.match(/\$\{language\:[^\}]+\}/g);
      for (const variable of variables) {
        const path = variable.slice(11, -1);
        const results = await this.get([path], {});
        if (typeof results[path] === 'string')
          template = template.replace(variable, results[path]);
      }
    }
    for (const key in data)
      template = template.replace('${data:' + key + '}', data[key]);

    return template;
  }

  getLabels(labels: { [key: string]: string }): void {
    for (const key in labels) labels[key] = this._labels[key];
  }

  objectToOrderedArray(obj: object): Array<any> {
    return Object.entries(obj).sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    }).map(value => value[1]);
  }

  private _deletePrivateFlags(value: { [key: string]: any }) {
    if (typeof value !== 'object' || value === null) return;
    delete value._loaded;
    Object.values(value).forEach(value => this._deletePrivateFlags(value));
  }

}
