import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData } from '../common';
import { APIErrors, APISuccess, ExposedPromise, isAPISuccess } from '@util';
import { Router } from '@angular/router';
import { WwydService } from '@services/member';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.finalChoice';

@Component({
  selector: 'app-member-skill-builder-wwyd-final-choice',
  templateUrl: './final-choice.component.html',
  styleUrls: ['./final-choice.component.scss']
})
export class MemberSkillBuilderWwydFinalChoiceComponent implements OnInit {

  BUTTON_CLASS: { [key: string]: string } = {
    SELECTED: 'btn-secondary',
    NOT_SELECTED: 'btn-outline btn-outline-danger'
  }
  @Input() data: WWYDData;
  choice: boolean = null;
  submitted: boolean = false;
  private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

  // Page langauge.
  page: {[key: string]: string} = {
    description: '',
    customDescription: '',
    no: '',
    yes: '',
    error: '',
    submit: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _router: Router,
    private _storageSvc: StorageService,
    private _wwydSvc: WwydService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        const promises: Array<Promise<void>> = [];
        for (const key in this.page) {
          switch (key) {
            case 'description':
            case 'customDescription':
              break;
            default: {
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => { this.page[key] = value }));
            }
          }
        }
        Promise.all(promises).then(_value => this._languageLoaded.resolve());
      }
    );

    await this._languageLoaded;

    // Set description.
    if (!this.data.dilemma.custom) {
      this._languageSvc.template(this.page.description, {
        firstChoice: (this.data.firstChoice ? this.page.yes : this.page.no),
        description: this.data.dilemma.description
      }).then(value => this.page.description = value);
    } else {
      this._languageSvc.template(this.page.customDescription, {
        firstChoice: (this.data.firstChoice ? this.page.yes : this.page.no),
        description: this.data.dilemma.description
      }).then(value => this.page.description = value);
    }
  }

  submitOnClick(): void {
    this.submitted = true;
    if (this.choice === null) return;
    this.data.finalChoice = this.choice;
    this._wwydSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, null);
          this._router.navigate(['member/dashboard']);
        }
      }
    );
  }

}
