<div class="col-12">
  <div class="row align-items-center border-btm-wht pb-3 pt-3 pt-lg-0">
    <div class="col-sm-12 col-md-6 mb-3 mb-md-0">
      <select  [(ngModel)]="selectedOrg" (change)="onSelectOrganization($event.target.value)">
        <option *ngFor="let organization of organizations" [value]="organization.uuid">{{organization.name}}</option>
      </select>
    </div>
    <div *ngIf="isAddUser" class="col-sm-12 col-md-6 d-flex justify-content-start justify-content-md-end">
      <button type="button" (click)="addUser()" class="btn btn-primary btn-sm">
        <span class="add-icon-white"><svg xmlns="http://www.w3.org/2000/svg" id="Add-icon" width="14" height="14" viewBox="0 0 14 14">
          <path id="add" d="M16.875,15.125V9h-1.75v6.125H9v1.75h6.125V23h1.75V16.875H23v-1.75Z" transform="translate(-9 -9)" />
        </svg> <span class="pl-2">{{page?.user}}</span></span>
      </button>
    </div>
  </div>
  <div></div>
  <div class="boxMain card-white card-white-sm border-radius-md">
    <div class="col-md-12 col-lg-8 filters mt-3 mt-lg-0 px-2">
      <div class="row w-100 user-mngmt align-items-center">
        <div class="col-xs-12 col-md-4 mb-2 mb-lg-0">
          <div class="input-group search-bar">
            <input  type="text" name="search" [ngModel]="searchText" (ngModelChange)="searchKey($event)"
              class="form-control" placeholder="{{page?.searchUser}}">
           <img src="../../../../../assets/img/search-icon.svg" class="search-icon" alt="{{page?.search}}" />
        </div>
        </div>

        <div class="col-xs-12 col-md-4 mb-2 mb-lg-0">
          <select
            (change)="onSelectCollection($event)"
            [(ngModel)]="selectedTag"
            class="select-w">
            <option value="null">{{page?.selectTag}}</option>
            <option *ngFor="let tag of tagslist" [value]="tag.uuid">{{tag.name}}</option>
          </select>
        </div>
        <div class="col-xs-12 col-md-4 mb-2 mb-lg-0">
          <select
            (change)="onSelectGroup($event)"
            [(ngModel)]="selectedGroup"
            class="select-w">
            (ngModel)
            <option value="null">{{page?.selectGroup}}</option>
            <option *ngFor="let group of groupslist" [value]="group.uuid">
              {{group.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <app-jkp-grid [tableData]="data" (onpaginationClicked)="onPaginationEventClick($event)" [totalItems]="totalCount" (sortByColumn)="sortByColumn($event)" [p]="currentPage" [headerData]="cols" (onRowClicked)="rowClicked($event)"></app-jkp-grid>

    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center modal-confirm">
    <div class="modal-body">
      <div class="msg-wrapper">
        <div class="delete-ico">
          <img src="assets/img/new_delete.svg" alt="new_delete.svg" />
        </div>
        <p class="mt-3 text-center delete-msg">
          {{page?.confirmRemove}}
        </p>
      </div>
    </div>
    <div class="pt-4 border-top">
      <button (click)="decline()" type="button" class="btn btn-md ml-3">
        {{page?.cancel}}
      </button>
      <button
        (click)="deleteUser()"
        type="button"
        class="btn btn-md btn-primary">
        {{page?.delete}}
      </button>
    </div>
  </div>
</ng-template>
