import { Injectable } from '@angular/core';
import { OverallScoresColumn } from './common';
import { AssessmentScores } from '../assessment-scores.service';
import { User } from '@services/viewer';
interface labels {
  users: string;
}
@Injectable({
  providedIn: 'root'
})
export class DomainOverallScoresService {

  constructor() { }

  private get EMPTY_REPORT(): Array<OverallScoresColumn> {
    return [
      {
        name: '1-2',
        value: 0,
        label: '',
        users: []
      },
      {
        name: '2-3',
        value: 0,
        label: '',
        users: []
      },
      {
        name: '3-4',
        value: 0,
        label: '',
        users: []
      },
      {
        name: '4-5',
        value: 0,
        label: '',
        users: []
      },
    ];
  }

  generateReport(scores: AssessmentScores, users: Array<User>, labels:labels):
      Array<OverallScoresColumn> {
    // Filter to the most recent self data per user.
    const compiledScores = {}
    scores.self.forEach(
      ({userUUID, genericName, skillUID, date, score}) => {
        if (!compiledScores[userUUID])
          compiledScores[userUUID] = {genericName, skills: {}};
        if (!compiledScores[userUUID].skills[skillUID])
          compiledScores[userUUID].skills[skillUID] = {};
        if (!compiledScores[userUUID].skills[skillUID].self)
          compiledScores[userUUID].skills[skillUID].self = { date, score };
        else if (new Date(date) >
          new Date(compiledScores[userUUID].skills[skillUID].self.date))
          compiledScores[userUUID].skills[skillUID].self = { date, score };
      });

    // Sort the historical peer data per user.
    scores.peer.forEach(
      ({userUUID, genericName, skillUID, score}) => {
        if (!compiledScores[userUUID])
          compiledScores[userUUID] = {genericName, skills: {}};
        if (!compiledScores[userUUID].skills[skillUID])
          compiledScores[userUUID].skills[skillUID] = {};
        if (!compiledScores[userUUID].skills[skillUID].peer)
          compiledScores[userUUID].skills[skillUID].peer = [score];
        else compiledScores[userUUID].skills[skillUID].peer.push(score);
      });

    // Calculate composite skill scores
    let report: Array<OverallScoresColumn> = this.EMPTY_REPORT;
    for (const userUUID in compiledScores) {
      compiledScores[userUUID].scores = [];
      for (const skillUID in compiledScores[userUUID].skills) {
        if (!compiledScores[userUUID].skills[skillUID].peer)
          compiledScores[userUUID].scores.push(
            compiledScores[userUUID].skills[skillUID].self.score);
        else {
          const peerScore =
            compiledScores[userUUID].skills[skillUID].peer.reduce(
              (a, b) => a + b, 0) /
            compiledScores[userUUID].skills[skillUID].peer.length;
          if (!compiledScores[userUUID].skills[skillUID].self)
            compiledScores[userUUID].scores.push(peerScore);
          else compiledScores[userUUID].scores.push(
            (compiledScores[userUUID].skills[skillUID].self.score * 0.4)
            + (peerScore * 0.6));
        }
      }
      const score =
        compiledScores[userUUID].scores.reduce((a, b) => a + b, 0) /
        compiledScores[userUUID].scores.length;
      let column = 0;
      if (score > 4) column = 3;
      else if (score > 3) column = 2;
      else if (score > 2) column = 1;
      report[column].value++;
      const user: User = users.find(user => user.uuid === userUUID);
      report[column].users.push(
        !user ? compiledScores[userUUID].genericName : user.name);
      report.forEach(
        column => {
          if (column.value > 0) column.label = `${column.value} ${labels.users}`;
        });
    }
    return report;
  }
}
