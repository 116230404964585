import { Component, Input, OnInit } from '@angular/core';
import {
  LABELS,
  LanguageService,
  StorageService,
  TEMP_KEYS
} from '@services/public';
import { ABSTRACT_PAGE, AbstractData } from '../common';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.rating';

@Component({
  selector: 'app-member-skill-builder-abstract-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class MemberSkillBuilderAbstractRatingComponent implements OnInit {

  @Input() data: AbstractData;
  submitted = false;

  // Page langauge.
  page: {[key: string]: string} = {
    howRate: '',
    left: '',
    middle: '',
    right: '',
    errorRequired: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.NEXT]: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    this._languageSvc.getLabels(this.labels);
  }

  nextOnClick(): void {
    this.submitted = true;
    if (!this.data.userRating) return;
    this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, {
      ...this.data,
      page: ABSTRACT_PAGE.SUMMARY
    });
  }

}
