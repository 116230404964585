<div class="modal-header px-0 pr-4 pt-md-3 pb-3">
  <h4 class="modal-title d-flex align-items-center justify-content-center">
    <label class="lbl-md pl-4 mb-0">{{ dialogTitle }}</label>
  </h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body pt-1 px-0">
  <div class="col-12 mt-2 px-4 wrapper">
    <div class="main-container p-0">
      <tabset class="tab-c" *ngIf="isAdduser; else formadd">
        <tab heading="{{ dialogTitle }}" id="tab1">
          <div *ngIf="isAdduser; then tab1"></div>
        </tab>
        <tab heading="Bulk Upload" id="tab2">
          <div *ngIf="isAdduser; then tab2"></div>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<ng-template #formadd>
  <form class="modal-form modal-scroll pt-3" [formGroup]="form">
    <div class="col-12 form-group">
      <div class="row">
        <div class="col-md-12 col-lg-6 d-flex flex-column">
          <label for="fname">{{page?.firstName}}<span class="required-asterisk">*</span></label>
          <input id="fname" type="text" formControlName="firstName" placeholder="{{page?.firstName}}"
            class="form-control {{ isFieldDisabled ? 'select-disable' : '' }}" [readonly]="isFieldDisabled"/>
          <div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required || f.firstName.errors?.['whitespace']">
              {{ validationMessage.firstName.required }}
            </div>
            <div *ngIf="f.firstName.errors.firstName">
              {{ validationMessage.firstName.firstName }}
            </div>
            <div *ngIf="
                !f.firstName.errors.required &&
                f.firstName.errors.maxlength?.actualLength > 40
              ">
              {{ validationMessage.firstName.maxLength }}
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 d-flex flex-column">
          <label for="lname">{{page?.lastName}}<span class="required-asterisk">*</span>
          </label>
          <input id="lname" formControlName="lastName" type="text" placeholder="{{page?.lastName}}"
            class="form-control {{ isFieldDisabled ? 'select-disable' : '' }}" [readonly]="isFieldDisabled" />
          <div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required || f.lastName.errors?.['whitespace']">
              {{ validationMessage.lastName.required }}
            </div>
            <div *ngIf="f.lastName.errors.lastName">
              {{ validationMessage.lastName.lastName }}
            </div>
            <div *ngIf="
                !f.lastName.errors.required &&
                f.lastName.errors.maxlength?.actualLength > 40
              ">
              {{ validationMessage.lastName.maxLength }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 form-group">
      <div class="row">
        <div class="col-md-12 col-lg-6 d-flex flex-column">
          <label for="fname">{{page?.email}}<span class="required-asterisk">*</span></label>
          <input id="fname" formControlName="email" type="email" placeholder="{{page?.email}}"
            class="form-control {{ isFieldDisabled ? 'select-disable' : '' }}" [readonly]="isFieldDisabled"/>
          <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
            <div *ngIf="f.email.errors.required || f.email.errors?.['whitespace']">
              {{ validationMessage.email.required }}
            </div>
            <div *ngIf="f.email.errors.pattern">
              {{ validationMessage.email.email }}
            </div>
            <div *ngIf="
                !f.email.errors.required &&
                f.email.errors.maxlength?.actualLength > 200
              ">
              {{ validationMessage.email.maxLength }}
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 d-flex flex-column">
          <label for="lname">{{page?.org}}<span class="required-asterisk">*</span></label>
          <input *ngIf="isFieldDisabled" id="fname" formControlName="organizationUUID" type="Organization"
            placeholder="{{page?.org}}" class="form-control {{ isFieldDisabled ? 'select-disable' : '' }}"
            [readonly]="isFieldDisabled" />
          <select *ngIf="!isFieldDisabled" class="w-100 {{ isFieldDisabled ? 'select-disable' : '' }}"
            formControlName="organizationUUID" (change)="onSelectOrganization()">
            <option [ngValue]="null" selected>{{page?.selectOrg}}</option>
            <option *ngFor="let organization of organizations" [value]="organization.uuid">{{organization.name}}
            </option>
          </select>
          <div *ngIf="f.organizationUUID.errors && f.organizationUUID.touched" class="invalid-feedback">
            <div *ngIf="f.organizationUUID.errors.required">
              {{ validationMessage.organization.required }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="accordion" class="accordion-c">
      <details>
        <summary>
          <div class="d-flex align-items-center">
           {{page?.assignGroup}}
            <small class="font-weight-normal pl-1">{{page?.optional}}</small>
            <span [ngbTooltip]="
                page?.assignGroupTip
              " triggers="click" tooltipClass="my-custom-class" class="pl-2" placement="bottom">
              <span class="info-circle">
                <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
              </span>
            </span>
          </div>
        </summary>
        <div class="summary-open px-0">
          <div class="d-flex justify-content-start align-items-center dotted-border-btm pl-3">
            <div class="col-sm-12 col-md-5 pb-3 pl-0 pr-3">
              <div class="input-group search-bar">
                <input type="text" name="search" [formControl]="searchText" class="form-control"
                  placeholder="{{page?.searchGroup}}" />
                <img _ngcontent-pwv-c314="" src="../../../../../assets/img/search-icon.svg" alt="{{page?.search}}"
                  class="search-icon">
              </div>
            </div>
          </div>
          <div class="col-md-12 px-3" formArrayName="groups">
            <ul class="list-bordered-btm list-bordered-btm-solid">
              <li class="d-flex justify-content-between" *ngFor="
                  let group of groupData | searchFilterPipe : searchText.value;
                  let index = index
                ">
                <div class="row w-100" [formGroupName]="index">
                  <div class="col-sm-12 col-md-7 d-flex align-items-center py-2 py-md-0 px-0 px-md-2">
                    {{ group.name }}
                  </div>
                  <div class="col-sm-12 col-md-5 d-flex c-form c-form-modal align-items-center justify-content-between pr-0">
                    <div *ngIf="!isAdduser">
                      <input class="form-check-input"  [checked]="group.memberChecked" type="checkbox"  (change)="groupCheckUnchecked($event,group);"  id="checkMarak" />
                      <label class="form-check-label">
                        {{page?.member}}
                      </label>
                    </div>
                    <select class="w-75" style="margin-left: 5px;" formControlName="role" (change)='onselectGroup(group, $event.target.value)'>
                      <option value="null" [selected]="group.assignRole == ''">{{page?.noRole}}</option>
                      <option value="admin"  [disabled]="group.isLeader" [selected]="group.assignRole =='admin'">{{page?.admin}}</option>
                      <option value="leader" [disabled]="group.isMember"[selected]="group.assignRole =='leader'">{{page?.leader}</option>
                      <option *ngIf="isAdduser" value="member" [selected]="group.assignRole =='member'">{{page?.member}}</option>
                    </select>
                    <div class="delete-ico">
                      <img style="margin-left: 10px;" src="assets/img/delete-gray.svg" alt="{{page?.delete}}" (click)="deleteGroup(group)" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </details>
      <details>
        <summary>
          <div class="d-flex align-items-center">
            {{page?.assignTag}} <small class="font-weight-normal pl-1">{{page?.optional}}</small>
            <span [ngbTooltip]="
                page?.assignTagTip
              " triggers="click" tooltipClass="my-custom-class" class="pl-2" placement="bottom">
              <span class="info-circle">
                <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
              </span>
            </span>
          </div>
        </summary>
        <div class="summary-open px-0">
          <div class="d-flex justify-content-start align-items-center dotted-border-btm pl-3">
            <div class="col-sm-12 col-md-5 pb-3 pl-0 pr-3">
              <div class="input-group search-bar">
                <input type="text" name="search" [formControl]="searchCollection" class="form-control"
                  placeholder="{{page?.tagSearch}}" />
                <img src="../../../../../assets/img/search-icon.svg" class="search-icon" alt="{{page?.search}}" />
              </div>
            </div>
          </div>
          <div class="col-md-12 px-3" formArrayName="tags">
            <ul class="list-bordered-btm list-bordered-btm-solid">
              <li class="d-flex justify-content-between" *ngFor="
                  let collection of collectionData
                    | searchFilterPipe : searchCollection.value;
                  let index = index
                ">
                <div class="row w-100" [formGroupName]="index">
                  <div class="col-sm-12 col-md-7 d-flex align-items-center py-2 py-md-0 px-0 px-md-2">
                    {{ collection.name }}
                  </div>
                  <div class="col-sm-12 col-md-5 d-flex c-form c-form-modal align-items-center justify-content-between pr-0">
                    <div *ngIf="!isAdduser">
                      <input class="form-check-input" *ngIf="collection.isLeaderTag" [disabled]="collection.isDisable" [checked]="collection.memberChecked" type="checkbox"  (change)="tagCheckUnchecked($event,collection);"  id="checkMarak">
                      <input class="form-check-input" *ngIf="!collection.isLeaderTag" [disabled]="collection.isDisable" [checked]="collection.memberChecked" type="checkbox" (change)="tagCheckUnchecked($event,collection);" id="checkMarak">
                      <label class="form-check-label">
                        {{page?.member}}
                      </label>
                    </div>
                    <select   class="w-75" style="margin-left: 5px;" formControlName="role" (change)='onselectTag(collection, $event.target.value)'>
                      <option value="null" [selected]="collection.assignRole ==''">{{page?.noRole}}</option>
                      <option value="admin" [disabled]="collection.isLeaderTag" [selected]="collection.assignRole =='admin'">{{page?.admin}}</option>
                      <option value="leader" [disabled]="collection.isMember"   [selected]="collection.assignRole =='leader'">{{page?.leader}}</option>
                      <option  *ngIf="isAdduser" value="member" [disabled]="collection.isDisable" [selected]="collection.assignRole =='member'">
                        {{page?.member}}</option>
                    </select>
                    <div class="delete-ico">
                      <img style="margin-left: 5px;" src="assets/img/delete-gray.svg" (click)="deleteCollection(collection)" alt="{{page?.delete}}" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </details>
    </div>
  </form>
  <div class="d-flex justify-content-center align-items-center border-top pt-3">
    <button (click)="decline()" type="button" class="btn btn-md">
    {{page?.cancel}}
    </button>
    <button type="button" [disabled]="!form.valid" class="btn btn-md btn-primary" (click)="onSubmit()">
      {{ saveButton }}
    </button>
  </div>
</ng-template>

<ng-template #tab1>
  <ng-container *ngTemplateOutlet="formadd"></ng-container>
</ng-template>

<ng-template #tab2>
  <div *ngIf="!isBulkdata" class="d-flex p-4 align-items-center justify-content-center modal-body-height flex-column">
    <div class="col-md-12 d-flex align-items-center justify-content-center pb-5">
      <img src="assets/img/upload-data.svg" alt="{{page?.uploadCSV}}" />
    </div>
    <div class="d-flex">
      <div class="file file--upload divider-rgt mr-3 flex-column">
        <label for="input-file" class="link-purple mb-0">
          <button class="circle-ico-purple d-flex align-items-center justify-content-center mr-1">
            <img src="assets/img/upload-CSV-icon.svg" alt="{{page?.uploadCSV}}" /></button>{{page?.uploadCSV}}
        </label>
        <input id="input-file" (change)="handleFileSelect($event)" type="file" />
        <div *ngIf="isFileType" class="invalid-feedback pr-2">
          {{validateMessage}}
        </div>
      </div>
      <div class="file file--upload  mr-3">
      <label class="px-1 link-purple mb-0">  <button class="circle-ico-purple d-flex align-items-center justify-content-center  mr-1" (click)="downloadFile()">
        <img src="assets/img/sample-CSV-icon.svg" alt="{{page?.csv}}" />
      </button>{{page?.csv}}</label>
    </div>
    </div>
  </div>

  <div *ngIf="isBulkdata" class="d-flex px-0 pt-3 pb-3 modal-body-height flex-column">
    <div class="col-md-12">
      <div class="row">
        <div class="col-sm-12 col-md-6 d-flex align-items-center">
          <select class="w-100" (change)="onChangeOrganization($event.target.value)">
            <option [ngValue]="null" selected>{{page?.selectOrg}}</option>
            <option *ngFor="let organization of organizations" [value]="organization.uuid">{{organization.name}}
            </option>
          </select>
          <div *ngIf="isSubmitted" class="invalid-feedback col-12">
            <div>
              {{ validationMessage.organization.required }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="d-flex p-2 align-items-center justify-content-end w-100">
            <div class="file file--upload divider-rgt mr-3 flex-column">
              <label for="input-file" class="link-purple mb-0">
                <button class="circle-ico-purple d-flex align-items-center justify-content-center mr-1">
                  <img src="assets/img/upload-CSV-icon.svg" alt="{{page?.uploadCSV}}" /></button>{{page?.uploadCSV}}
              </label>
              <input id="input-file" (change)="handleFileSelect($event)" type="file" accept=".csv" />
              <div *ngIf="isFileType" class="invalid-feedback pr-2">
                {{validateMessage}}
              </div>
            </div>
            <div class="file file--upload  mr-3">
              <label class="px-1 mt-2 link-purple mb-0">  <button class="circle-ico-purple d-flex align-items-center justify-content-center  mr-1" (click)="downloadFile()">
                <img src="assets/img/sample-CSV-icon.svg" alt="{{page?.csv}}" />
              </button>Sample CSV</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-view w-100">
      <div class="userGroups">
        <div class="row">
          <div class="col-md-6 {{ index % 2 == 0 ? 'divider-rgt' : '' }}"
            *ngFor="let user of csvfiledata; let index = index">
            <label class="container-chk d-flex ml-2">
              <div>
                {{ user.firstName + " " + user.lastName }}
                <p class="mb-0">{{ user.email }}</p>
              </div>
              <div class="delete-ico pl-2 img-sm mr-1">
                <img src="assets/img/delete-gray.svg" (click)="deleteUser(user)" alt="delete" />
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center border-top pt-3">
    <button (click)="decline()" type="button" class="btn btn-md">
      Cancel
    </button>
    <button *ngIf="isBulkdata" type="button" class="btn btn-md btn-primary" (click)="bulkUploadUser()">
      Send
    </button>
  </div>
</ng-template>