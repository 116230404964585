import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData, WWYD_PAGE } from '../common';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.firstChoice';

@Component({
  selector: 'app-member-skill-builder-wwyd-first-choice',
  templateUrl: './first-choice.component.html',
  styleUrls: ['./first-choice.component.scss']
})
export class MemberSkillBuilderWwydFirstChoiceComponent implements OnInit {

  BUTTON_CLASS: { [key: string]: string } = {
    SELECTED: 'btn-secondary',
    NOT_SELECTED: 'btn-outline btn-outline-danger'
  }
  @Input() data: WWYDData;
  choice: boolean = null;
  submitted: boolean = false;

  // Page langauge.
  page: {[key: string]: string} = {
    description: '',
    no: '',
    yes: '',
    error: '',
    next: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'description': {
              if (!this.data.dilemma.custom)
                this.page.description = this.data.dilemma.description;
              else this._languageSvc.template(
                this.page[key],
                { description: this.data.dilemma.description }
              ).then(value => this.page[key] = value);
              break;
            }
            default: {
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value);
            }
          }
        }
      }
    );
  }

  nextOnClick(): void {
    this.submitted = true;
    if (this.choice === null) return;
    this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
      ...this.data,
      page: WWYD_PAGE.LIST_ACTIONS,
      firstChoice: this.choice
    });
  }

}
