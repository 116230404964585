import { Component, Input, OnInit } from '@angular/core';
import { JOURNAL_PAGE, JournalData } from "../common";
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.journal.pages.generatedSummary';

@Component({
  selector: 'app-member-skill-builder-journal-generated-summary',
  templateUrl: './generated-summary.component.html',
  styleUrls: ['./generated-summary.component.scss']
})
export class MemberSkillBuilderJournalGeneratedSummaryComponent implements OnInit {

  @Input() data: JournalData;
  submitted: boolean = false;

  // Page language.
  page: { [key: string]: string } = {
    title: '',
    generatedSummary: '',
    next: ''
  };

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          if (key === 'generatedSummary') {
            this._languageSvc.template(
              this.page[key], { 
                highlight: this.data?.highlight,
                frequency: this.data?.frequency,
                enjoyment: this.data?.enjoyment,
                social: this.data?.social,
                confidence: this.data?.confidence,
                emotions: this.data?.emotions.join(', '),
                symptoms: this.data?.symptoms.join(', '),
                thoughts: this.data?.thoughts
              }
            ).then(value => this.page[key] = value);
          } else {
            this._languageSvc.template(this.page[key]).then(
              value => this.page[key] = value);
          }
        }
      });
  }

  nextOnClick(): void {
    const data: JournalData = Object.assign({}, this.data, {
      page: JOURNAL_PAGE.ACCURACY,
      generatedSummary: this.page.generatedSummary
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, data);
  }

}
