import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService, LogoutService } from '@services/public';
import { config } from 'environment';
import { Observable, ReplaySubject, map } from 'rxjs';

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

// Tag metadata
export interface Tag {
  uuid: string;
  name: string;
  type: string;
  main: boolean;
  organizationUUID: string;
  member: boolean;
  role: string|null;
}

@Injectable({
  providedIn: 'root'
})
export class TagService {

  private _tags: ReplaySubject<Array<Tag>> =
    new ReplaySubject<Array<Tag>>(1);
  private _updated: Date | null = null;

  constructor(
    public http: HttpClient,
    private logoutSvc: LogoutService,
    private loaderSvc: LoaderService
  ) {
    // Bind the logout function to clear data.
    this.logoutSvc.subscribe(this.logout.bind(this));
  }

  // Reloads all tag metadata.
  loadTags(): void {
    const loader: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated) this.loaderSvc.addLoader(loader,
      'services/member/tag:loadTags');
    this.http.get<any>(
      `${config.apiBase}member/tag/list`).pipe(map(
      (response: any) => {
        if (!response.errors) {
          this._tags.next(response);
          this._updated = new Date();
          this.loaderSvc.removeLoader(loader);
        }
      }).bind(this)).subscribe();
  }

  // Gets all tag metadata. Refreshes if data is outdated.
  get tags(): Observable<Array<Tag>> {
    if (!this._updated ||
      this._updated.valueOf() + UPDATE_INTERVAL < (new Date()).valueOf())
      this.loadTags();
    return this._tags.asObservable();
  }

  // Clears the tag data upon logout and resets the service.
  private logout(): void {
    this._tags.complete();
    this._tags = new ReplaySubject<Array<Tag>>(1);
    this._updated = null;
  }

  leaveTag(reqObj): Observable<any> {
    return this.http.delete<any>(
      `${config.apiBase}member/tag/leave`,{
        body: reqObj
      });
  }

  sendTagRequest(requredObj){
    return this.http.post<any>(
      `${config.apiBase}member/tag/join`,requredObj);
  }

}
