import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { config } from "environment";

@Injectable({
  providedIn: "root",
})
export class GroupService {

  constructor(
    private http: HttpClient
  ) { }

  // group management API
  getGroupList(query?: any): Observable<any> {
    if (query === undefined) {
      query = "";
    }
    return this.http.get<any>(
      `${config.apiBase}leader/management/group/list${query}`
    );
  }

  getGroupView(query?: any): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}leader/management/group/view?uuid=${query}`
    );
  }
  updateGroup(updatedReqBody) {
    return this.http.put<any>(
      `${config.apiBase}leader/management/group/edit`,
      updatedReqBody
    );
  }

  createGroup(reqBody) {
    return this.http.post<any>(
      `${config.apiBase}leader/management/group/create`,
      reqBody
    );
  }

  acceptUserRequest(requredObj) {
    return this.http.post<any>(
      `${config.apiBase}leader/management/group/request/accept`,
      requredObj
    );
  }

}
