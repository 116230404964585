import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService, LogoutService } from "@services/public";
import { config } from "environment";
import { firstValueFrom, Observable, ReplaySubject, map, Subject } from "rxjs";

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

// Group metadata
export interface Group {
  uuid: string;
  name: string;
  abbreviation: string;
  timezone: string | null;
  startDate: string;
  endDate: string | null;
  organizationUUID: string;
  member: boolean;
  users: Array<any>;
  role: string | null;
  memberUUIDs: Array<string>;
}

export const ALL_GROUPS: Group = {
  uuid: null,
  name: 'All Groups',
  abbreviation: null,
  timezone: null,
  startDate: null,
  endDate: null,
  organizationUUID: null,
  member: false,
  users: [],
  role: null,
  memberUUIDs: []
}

export const NO_GROUPS: Group = {
  uuid: null,
  name: 'No Groups',
  abbreviation: null,
  timezone: null,
  startDate: null,
  endDate: null,
  organizationUUID: null,
  member: false,
  users: [],
  role: null,
  memberUUIDs: []
}

@Injectable({
  providedIn: "root",
})
export class GroupService {
  private _groups: ReplaySubject<Array<Group>> =
    new ReplaySubject<Array<Group>>(1);
  private _updated: Date | null = null;
  _groupsSubject: Subject<Array<Group>> = new Subject<Array<Group>>();

  constructor(
    public http: HttpClient,
    private logoutSvc: LogoutService,
    private loaderSvc: LoaderService
  ) {
    // Bind the logout function to clear data.
    this.logoutSvc.subscribe(this.logout.bind(this));
  }

  // Use getGroupsSubject with loadGroups to get updated group data.
  getGroupsSubject(): Subject<Array<Group>> {
    return this._groupsSubject;
  }

  // Reloads all group metadata.
  loadGroups(): void {
    const loader: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated)
      this.loaderSvc.addLoader(loader, "services/member/group:loadGroups");
    this.http
      .get<any>(`${config.apiBase}member/group/list`)
      .pipe(
        map((response: any) => {
          if (!response.errors) {
            this._groups.next(response);
            this._updated = new Date();
            this.loaderSvc.removeLoader(loader);
          }
        }).bind(this)
      )
      .subscribe();
  }

  // Gets all group metadata. Refreshes if data is outdated.
  get groups(): Observable<Array<Group>> {
    if (
      !this._updated ||
      this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()
    )
      this.loadGroups();
    return this._groups.asObservable();
  }

  // Gets a group's name based on its UUID.
  async groupName(uuid: string): Promise<string> {
    const groups = await firstValueFrom(this.groups);
    const group = groups.find((group) => group.uuid === uuid);
    if (typeof group === "undefined") return "Unknown Group";
    return group.name;
  }

  registerGroup(groupCode: string): Observable<any> {
    return this.http.post<any>(`${config.apiBase}user/group/register`, {
      groupCode,
    });
  }

  // Clears the group data upon logout and resets the service.
  private logout(): void {
    this._groups.complete();
    this._groups = new ReplaySubject<Array<Group>>(1);
    this._updated = null;
  }

  leaveGroup(reqObj): Observable<any> {
    return this.http.delete<any>(`${config.apiBase}member/group/leave`, {
      body: reqObj,
    });
  }

  sendGroupRequest(requredObj) {
    return this.http.post<any>(
      `${config.apiBase}member/group/join`,
      requredObj
    );
  }

}
