import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@services/public';
import { AbstractData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';
import { AbstractService } from '@services/member';
import { Router } from '@angular/router';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.comfort';

@Component({
  selector: 'app-member-skill-builder-abstract-comfort',
  templateUrl: './comfort.component.html',
  styleUrls: ['./comfort.component.scss']
})
export class MemberSkillBuilderAbstractComfortComponent implements OnInit {

  @Input() data: AbstractData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: ''
  }

  constructor(
    private _abstractSvc: AbstractService,
    private _languageSvc: LanguageService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    // Get options list.
    const path: string = 'skillBuilders.abstract.comfort';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) {
          this.options.next(
            this._languageSvc.objectToOrderedArray(value[path])
          );
        } else this.options.next([]);
      }
    );
  }

  nextOnClick(option: SelectListOption): void {
    this.data.comfort = option.value;
    this._abstractSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
      }
    );
  }

}
