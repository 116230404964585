import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SKILL_BUILDER_TYPES } from '@services/member';
import {
  LABELS,
  LanguageService,
  PublicCalendarDateFormatterService
} from '@services/public';
import { JDate } from '@util';
import {
  CalendarDateFormatter,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay
} from 'angular-calendar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

/**
 * Must use the format 'YYYY-MM-DD'.
 */
export type CalendarDate = string;

export enum CalendarColors {
  VERY_BAD = 'very-bad',
  BAD = 'bad',
  OKAY = 'okay',
  GOOD = 'good'
}

export interface CalendarEvent {
  color: CalendarColors,
  summary?: string;
}

export interface CalendarLegend {
  color: CalendarColors,
  display: string;
}

@Component({
  selector: 'app-skill-builder-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: PublicCalendarDateFormatterService,
    },
  ]
})
export class SkillBuilderCalendarComponent implements OnInit {

  @ViewChild('journalSummary') journalSummary: TemplateRef<any>;
  @Input() events: Map<CalendarDate, CalendarEvent> =
    new Map<CalendarDate, CalendarEvent>();
  @Input() legend: Array<CalendarLegend> = [];
  @Input() title: string = '';
  @Input() subtext: string = '';
  @Input() skillBuilderType: SKILL_BUILDER_TYPES;
  viewDate: Date = new Date();
  refresh: Subject<void> = new Subject();
  summary : string;
  private _modalRef: BsModalRef;

  // Page langauge.
  labels: {[key: string]: string} = {
    [LABELS.OK]: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.getLabels(this.labels);
  }

  ngOnChanges(): void {
    this.refresh.next();
  }

  beforeMonthViewRender(
    beforeMonthViewRenderEvent: CalendarMonthViewBeforeRenderEvent
  ): void {
    beforeMonthViewRenderEvent.body.forEach((day) => {
      const event = this.events.get(new JDate(day.date).toString());
      if (event) day.cssClass = event.color;
    });
  }

  dayClicked(
    dayClickedEvent: {
      day: CalendarMonthViewDay,
      sourceEvent: MouseEvent | KeyboardEvent
    }
  ): void {
    const event = this.events.get(
      new JDate(dayClickedEvent.day.date).toString()
    );
    if (!event) return;

    switch (this.skillBuilderType) {
      case SKILL_BUILDER_TYPES.JOURNAL:
        this.summary = event.summary;
        this._modalRef = this._modalSvc.show(
          this.journalSummary,
          {
            class: 'modal-sm modal-dialog-centered',
            backdrop: 'static',
            keyboard: true
          }
        );
        break;
      default:
        break;
    }
  }

  okOnClick(): void {
    this._modalRef?.hide();
  }

}
