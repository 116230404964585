import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { AssessmentScores } from '../assessment-scores.service';

interface labels {
  self?: string;
  peer?: string;
  skillLevel?: string;
}
@Injectable({
  providedIn: 'root'
})
export class SkillGrowthService {

 

  constructor() { }

  private get EMPTY_REPORT(): ChartConfiguration {
    return {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Self',
            data: [],
            backgroundColor: '#3BE684',
            borderColor: '#3BE684'
          },
          {
            label: 'Peer',
            data: [],
            backgroundColor: '#74A2FF',
            borderColor: '#74A2FF'
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            align: 'end',
            labels: {
              useBorderRadius: true,
              borderRadius: 5.5,
              boxWidth: 10,
              boxHeight:10
            }
          }
        },
        elements: {
          line: {
            fill: false,
            spanGaps: true
          },
          point: {
            pointStyle: 'circle',
            radius: 5
          }
        }
      }
    };
  }

  // Page language.
  page: {[key: string]: string} = {
  }

  generateReport(scores: AssessmentScores, chartlabels: labels): ChartConfiguration {
    const labels: Array<string> = [];
    const selfData: Array<number> = [];
    const peerData: Array<number> = [];

    // Filter distinct self dates.
    const selfDatesSet: Set<string> = new Set();
    scores.self.forEach(score => selfDatesSet.add(score.date));
    const selfDates =
      Array.from(selfDatesSet)
        .sort((a, b) => new Date(a) < new Date(b) ? -1 : 1)
        .map(date => { return { date, score: 0, users: {} } });

    // Filter to the most recent self data per user per date.
    scores.self.forEach(
      ({ userUUID, date, score }) => {
        for (const selfDate of selfDates) {
          if (new Date(date) <= new Date(selfDate.date)) {
            if (!selfDate.users[userUUID])
              selfDate.users[userUUID] = { date, score };
            else if (new Date(date) >
              new Date(selfDate.users[userUUID].date))
              selfDate.users[userUUID] = { date, score };
          }
        }
      });

    // Calculate self scores per date.
    for (const date of selfDates) {
      const scores = Object.values(date.users).map((user: any) => user.score);
      date.score = scores.reduce((a, b) => a + b, 0) / scores.length;
    }

    // Filter distinct peer dates.
    const peerDatesSet: Set<string> = new Set();
    scores.peer.forEach(score => peerDatesSet.add(score.date));
    const peerDates =
      Array.from(peerDatesSet)
        .sort((a, b) => new Date(a) < new Date(b) ? -1 : 1)
        .map(date => { return { date, score: 0, users: {} } });

    // Filter to the most recent peer data per user per date.
    scores.peer.forEach(
      ({ userUUID, date, score }) => {
        for (const peerDate of peerDates) {
          if (new Date(date) <= new Date(peerDate.date)) {
            if (!peerDate.users[userUUID])
              peerDate.users[userUUID] = { date, score };
            else if (new Date(date) >
              new Date(peerDate.users[userUUID].date))
              peerDate.users[userUUID] = { date, score };
          }
        }
      });

    // Calculate peer scores per date.
    for (const date of peerDates) {
      const scores = Object.values(date.users).map((user: any) => user.score);
      date.score = scores.reduce((a, b) => a + b, 0) / scores.length;
    }

    // Filter distinct dates.
    const datesSet: Set<string> = new Set();
    selfDates.forEach(date => datesSet.add(date.date));
    peerDates.forEach(date => datesSet.add(date.date));
    const dates = Array.from(datesSet).sort((a, b) => a < b ? -1 : 1);

    if (dates.length > 0) {
      // Buffer arrays to reduce needed checks during iteration.
      selfDates.push({date: null, score: null, users: null});
      peerDates.push({date: null, score: null, users: null});

      // Iterate with separate indexes. All arrays are sorted.
      let a = 0;
      let b = 0;
      for (const date of dates) {
        labels.push(new Date(date).toLocaleDateString('en-US',
          {year: 'numeric', month: 'short', day: 'numeric'}));
        if (selfDates[a].date === date) {
          selfData.push(selfDates[a].score);
          a++
        } else selfData.push(null);
        if (peerDates[b].date === date) {
          peerData.push(peerDates[b].score);
          b++
        } else peerData.push(null);
      }
    }

    // Update chart configuration.
    let report: ChartConfiguration = this.EMPTY_REPORT;
    report.data.labels = labels;
    report.data.datasets[0].data = selfData;
    report.data.datasets[0].label = chartlabels.self;
    report.data.datasets[1].data = peerData;
    report.data.datasets[1].label = chartlabels.peer;
    return report;
  }
}
