import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TEMP_KEYS,
  StorageService,
  TEMP_KEY_PREFIX,
  LanguageService,
  Domain2Service
} from '@services/public';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AVATARS, getAvatarCoordinates } from '@util';
import { BLANK_USER, LeaderboardUser } from '@components';
import {
  RANK_DELTAS,
  RewardsService,
  SKILL_BUILDER_FILTERS,
  SkillBuilder,
  SkillBuilderService
} from '@services/member';
import { JourneyDomain } from './journey/journey.component';
import {
  SkillBuilderBadge
} from './skill-builder-badges/skill-builder-badges.component';
import { ChallengeBadge } from './challenge-badges/challenge-badges.component';
import { MemberModalSelectAvatarComponent } from '@pages/member/modal';

const PAGE_PATH: string = 'pages.member.dashboard.rewards.page';

enum TABS {
  LEADERBOARD = 'leaderboard',
  METRICS = 'metrics',
  BADGES = 'badges',
  ALL = 'all'
}

@Component({
  selector: 'app-member-dashboard-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
})
export class MemberDashboardRewardsComponent implements OnInit, OnDestroy{

  TABS: typeof TABS = TABS;
  RANK_DELTAS: typeof RANK_DELTAS = RANK_DELTAS;

  selectedTab: TABS = TABS.ALL;
  journeyDomains: Array<JourneyDomain> = [];
  user: LeaderboardUser = { ...BLANK_USER };
  dailySkillBuilderBadges: Array<SkillBuilderBadge> = [];
  repeatableSkillBuilderBadges: Array<SkillBuilderBadge> = [];
  challengeBadges: Array<ChallengeBadge> = [];

  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    welcome: '',
    instructions: '',
    dailySkillBuilderBadges: '',
    repeatableSkillBuilderBadges: '',
    badgesEarned: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _rewardSvc: RewardsService,
    private _skillBuilderSvc: SkillBuilderService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'welcome':
              this._languageSvc.template(
                this.page[key],
                { name: this._storageSvc.getTempStorage(TEMP_KEYS.NAME) }
              ).then(value => { this.page[key] = value; });
              break;
            default:
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value
              );
          }
        }
      }
    );

    // Get user data.
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL) {
        this.user.name = update.value;
        this.user.avatar = update.value;
      }
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) {
        this.user = { ...BLANK_USER };
      }
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.NAME) {
        this.user.name = update.value;
      }
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.AVATAR) {
        this.user.avatar = update.value;
      }
    }));
    this.user.uuid = this._storageSvc.userUUID;
    this.user.name = this._storageSvc.getTempStorage(TEMP_KEYS.NAME);
    this.user.avatar = this._storageSvc.getTempStorage(TEMP_KEYS.AVATAR);

    // Get reward data.
    this._rewardSvc.loadRewardData().subscribe((res) => {
      this.journeyDomains = res.journeyDomains;
      this.dailySkillBuilderBadges = this._skillBuilderSvc.getSkillBuilders(
        SKILL_BUILDER_FILTERS.DAILY
      ).map(skillBuilder => {
        return this._skillBuilderBadge(skillBuilder, res.badges.find(
          badge => badge.name === `${badge.type}-${skillBuilder.type}`
        ));
      });
      this.repeatableSkillBuilderBadges = this._skillBuilderSvc.getSkillBuilders(
        SKILL_BUILDER_FILTERS.REPEATABLE
      ).map(skillBuilder => {
        return this._skillBuilderBadge(skillBuilder, res.badges.find(
          badge => badge.name === `${badge.type}-${skillBuilder.type}`
        ));
      });
      this.challengeBadges = this._domainSvc.getDomains().map(domain => {
        const badge = res.badges.find(
          badge => badge.name === `${badge.type}-${domain.key}`
        );
        return {
          name: domain.name,
          gradient: badge?.gradient ?? '',
          level: badge?.level ?? 0,
          icon: domain.logo
        }
      });
    });
  }

  editAvatarOnClick() {
    this._modalSvc.show(MemberModalSelectAvatarComponent, {
      class: 'modal-sm modal-dialog-centered'
    });
  }

  getAvatarStyle(avatar: AVATARS, size?: number) {
    const { x, y } = getAvatarCoordinates(avatar, size);
    return {'background-position': `${-x}px ${-y}px`};
  }

  private _skillBuilderBadge(
    skillBuilder: SkillBuilder,
    badge: any
  ): SkillBuilderBadge {
    return {
      ...skillBuilder,
      class: `progress-container ${
        !badge || badge.progress === 0 ? '' : 'skill-builder-progress'
      }`,
      progress: `${
        !badge || badge.progress === 0 ? 0 :
        !badge.nextLevelProgress ? 100 :
        Math.round((badge.progress / badge.nextLevelProgress) * 100)
      },100`,
      level: badge?.level ?? 0
    };
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
