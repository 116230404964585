<div class="credHeader">
  <a href="#">
    <img src="assets/img/logo.svg" alt="">
  </a>
</div>

<div *ngIf="!verified" class="emptyAssmntMain">
  <div class="emptyAssmntInner">
    <span><img src="assets/img/empty-search.svg" alt="#"></span>
    <h5>{{pageLanguage?.noResults}}</h5>
  </div>
</div>

<div *ngIf="verified" class="emptyAssmntMain">
  <div class="emptyAssmntInner d-flex align-items-center justify-content-center flex-column">
    <div class="success-checkmark"><i class="fas fa-check"></i></div>
    <h3 class="black pt-3">{{pageLanguage?.emailVerified}}</h3>
    <div class="login-dont-have-text">
      <span><a routerLink="/" routerLinkActive="active" class="link">{{pageLanguage?.clickToLogin}}</a></span>
    </div>
  </div>
</div>
