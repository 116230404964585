import { Component, Input, OnInit } from '@angular/core';
import {
  Domain2Service,
  LanguageService,
  StorageService,
  TEMP_KEYS
} from '@services/public';
import { GOAL_PAGE, GoalData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';

const PAGE_PATH: string = 'skillBuilders.goal.pages.domain';

@Component({
  selector: 'app-member-skill-builder-goal-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class MemberSkillBuilderGoalDomainComponent implements OnInit {

  @Input() data: GoalData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Get options list.
    this.options.next(this._domainSvc.getDomains().map(
      domain => {
        const option: SelectListOption = {
          display: domain.name,
          value: domain.uid
        }
        if (this.data.domainUID === domain.uid)
          option.selected = true;
        return option;
      }));
  }

  nextOnClick(option: SelectListOption): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.GOAL, {
      ...this.data,
      page: GOAL_PAGE.SKILL,
      domainUID: option.value
    });
  }

}
