<div class="assessmentListMain">
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-12 rytPanalMain">
        <div class="rytPanal">
          <div class="rytHeadMain btn-c">
            <div class="tabHead">
              <span routerLink="/member/dashboard" class="back-arrow"><i class="fas fa-arrow-left"></i></span
              >{{pageLanguage?.list.title}}
            </div>
            <button
              class="btn btn-primary mt-2 mt-md-0"
              (click)="onCreateExternalAssessmentClick()">
              <span>{{pageLanguage?.createOrUpdate.modalTitle}}</span>
            </button>
          </div>
          <div class="emptyAssmntMain" *ngIf="!assessments.length">
            <div class="emptyAssmntInner">
              <span><img src="assets/img/empty-search.svg" alt="#" /></span>
              <h5>{{appLabels.noResultsFound}}</h5>
              <p>{{pageLanguage?.list.noExternalAssessments}}</p>
            </div>
          </div>
          <div
            *ngIf="!!assessments.length"
            id="no-more-tables">
            <table class="table-c">
              <thead>
                <tr>
                  <th width="16%">{{appLabels?.assessmentName}}</th>
                  <th width="15%">{{appLabels?.name}}</th>
                  <th width="22%">{{appLabels?.email}}</th>
                  <th width="15%">{{appLabels?.date}}</th>
                  <th width="12%">{{appLabels?.status}}</th>
                  <th width="20%" class="actionLast">{{appLabels?.actions}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr *ngFor="
                  let assessment of assessments
                    | paginate: { itemsPerPage: 10, currentPage: p }
                " class="cursor-pointer">
                    <td [attr.data-title]="appLabels?.assessmentName">
                      <span class="mobLabel">{{appLabels.assessmentName}}</span
                      >{{ assessment.assessmentName }}
                    </td>
                    <td [attr.data-title]="appLabels?.name">
                      <span class="mobLabel">{{appLabels?.name}}</span
                      >{{ assessment.firstName }} {{ assessment.lastName }}
                    </td>
                    <td [attr.data-title]="appLabels?.email">
                      <span class="mobLabel">{{appLabels.email}}</span>{{ assessment.email }}
                    </td>
                    <td [attr.data-title]="appLabels?.date">
                      <span class="mobLabel">{{appLabels.date}}</span
                      >{{ assessment.startDate }}
                    </td>
                    <td [attr.data-title]="appLabels?.status" *ngIf="assessment.status == 'ACTIVE'">
                      <span><img src="assets/img/pending_ass.svg" alt="pending_ass" class="img-status" /></span><span
                      class="pl-2">{{appLabels.active}}</span>
                    </td>
                    <td [attr.data-title]="appLabels?.status" *ngIf="assessment.status == 'SCHEDULED'">
                      <span><img src="assets/img/pro.svg" alt="pro.svg" class="img-status" /></span><span
                        class="pl-2">{{appLabels.inactive}}</span>
                    </td>
                    <td class="sucess-ico" [attr.data-title]="appLabels?.status" *ngIf="assessment.status == 'COMPLETED'">
                      <span><img src="assets/img/success.svg" alt="success.svg" class="img-status" /></span><span
                      class="pl-2">{{appLabels.completed}}</span>
                    </td>
                    <td [attr.data-title]="appLabels?.actions">
                      <div class="d-flex">
                        <div class="actionColumn mx-2" *ngIf="assessment.status === AssessmentStatus.ACTIVE" (click)="openModal(template)"><img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" class="pr-2" />{{appLabels.delete}}</div>
                      <div  class="actionColumn mx-2" *ngIf="assessment.status === AssessmentStatus.COMPLETED" (click)="viewAssessment(assessment)"><img src="./../../../../assets/img/view-report-icon.svg" alt="view.svg"  class="pr-2" />{{appLabels.viewReport}}</div>
                      <div class="actionColumn mx-2" *ngIf="assessment.status === AssessmentStatus.ACTIVE" (click)="resendAssessment(assessment)"><img src="./../../../../assets/img/send-reminder-icon.svg" alt="send" class="pr-2" />{{appLabels.sendReminder}}</div>
                      <div class="actionColumn mx-2" *ngIf="assessment.status === AssessmentStatus.INACTIVE"><img src="./../../../../assets/img/update-icon.svg" alt="send" class="pr-2" />{{appLabels.update}}</div>

                    </div>
                    </td>
                    <ng-template #template>
                      <div class="modal-body text-center modal-confirm">
                        <div class="modal-body">
                          <div class="msg-wrapper">
                            <div>
                              <img src="assets/img/new_delete.svg" alt="new_delete.svg" />
                            </div>
                            <p class="mt-3 text-center delete-msg">
                             {{pageLanguage?.list.deleteAssessment}}
                            </p>
                          </div>
                        </div>
                        <div class="pt-4 border-top">
                          <button
                            (click)="decline()"
                            type="button"
                            class="btn btn-md ml-3">
                           {{appLabels.cancel}}
                          </button>
                          <button
                            type="button"
                            (click)="deleteAssessment(assessment)"
                            class="btn btn-md btn-danger">
                            {{appLabels.delete}}
                          </button>
                        </div>
                      </div>
                    </ng-template>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div style="float: right" class="w-sm-100">
              <pagination-controls  previousLabel=""
              nextLabel="" class="my-pagination" (pageChange)="p = $event" directionLinks="true">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

