import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DECISION_PAGE, DecisionAction, DecisionData } from '../common';
import { NO_HTML_PATTERN } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.decision.pages.outcomes';

@Component({
  selector: 'app-member-skill-builder-decision-outcomes',
  templateUrl: './outcomes.component.html',
  styleUrls: ['./outcomes.component.scss']
})
export class MemberSkillBuilderDecisionOutcomesComponent implements OnInit {

  @Input() data: DecisionData;
  form: FormGroup;
  submitted: boolean = false;

  // Page langauge.
  page: {[key: string]: string} = {
    positiveOutcome: '',
    negativeOutcome: '',
    placeholder: '',
    next: '',
    error1: '',
    error2: '',
  }

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Setup form.
    this.form = new FormGroup({
      actions: new FormArray([]),
    });
    for (const action of <Array<DecisionAction>>this.data?.actions)
      this.actions.push(new FormGroup({
        positiveOutcome: new FormControl(action.positiveOutcome || '',
          [Validators.required, Validators.pattern(NO_HTML_PATTERN)]),
        negativeOutcome: new FormControl(action.negativeOutcome || '',
          [Validators.required, Validators.pattern(NO_HTML_PATTERN)]),
      }));
  }

  get actions(): FormArray {
    return <FormArray>this.form.controls['actions'];
  }

  nextOnClick(): void {
    (<Array<FormGroup>>this.actions.controls).forEach(group => {
      group.controls.positiveOutcome.setValue(
        (group.controls.positiveOutcome.value || '').trim());
      group.controls.negativeOutcome.setValue(
        (group.controls.negativeOutcome.value || '').trim());
    });
    this.submitted = true;
    if (!this.form.valid) return;
    for (let i = 0; i < this.data.actions.length; i++) {
      (<DecisionAction>this.data.actions[i]).positiveOutcome =
        this.actions.controls[i].value.positiveOutcome;
      (<DecisionAction>this.data.actions[i]).negativeOutcome =
        this.actions.controls[i].value.negativeOutcome;
    }
    const data: DecisionData = Object.assign({}, this.data, {
      page: DECISION_PAGE.IMPACTS
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, data);
  }

}
