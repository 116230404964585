import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  WWYDData,
  WWYDReportData
} from '@pages/member/skill-builder/wwyd/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WwydService {

  constructor(private _http: HttpClient) { }

  createLog(data: WWYDData): Observable<APISuccess | APIErrors> {
    return this._http.post<APISuccess | APIErrors>(
      `${config.apiBase}member/challenge/wwyd/log/create`,
      {
        dilemma: data.dilemma,
        firstChoice: data.firstChoice,
        actions: data.actions,
        chosenAction: data.chosenAction,
        finalChoice: data.finalChoice,
        goalPlanUUID: data.goalPlanUUID ?? null
      });
  }

  getReport(): Observable<WWYDReportData | APINoData> {
    return this._http.get<WWYDReportData | APINoData>(
      `${config.apiBase}member/challenge/wwyd/report`);
  }

}
