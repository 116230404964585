import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { LANGUAGE_CODES, LanguageService } from './language.service';
import { PUBLIC_HEADERS } from './auth.service';
import { config } from 'environment';
import { ExposedPromise } from '@util';
import { STORAGE_KEYS } from './storage.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

const LANGUAGE_DEFAULT: LANGUAGE_CODES = LANGUAGE_CODES.ENG;
const LANGUAGE_UPDATE_DEFAULT = '2000-01-01 00:00:00.0';

@Injectable({
  providedIn: 'root'
})
export class LanguageLoaderService {

  private _language: LANGUAGE_CODES = LANGUAGE_DEFAULT;
  private _update: string = LANGUAGE_UPDATE_DEFAULT;
  private _compiled: { [key: string]: any } = {};

  constructor(
    private _http: HttpClient,
    private _languageSvc: LanguageService,
    private _localeSvc: BsLocaleService
  ) {
    try {
      // Define the loadPath function.
      this._languageSvc.loadPaths = this.loadPaths.bind(this);

      // Attempt to pull stored language data.
      let language: {
        language: LANGUAGE_CODES,
        update: string,
        compiled: { [key: string]: any }
      };
      try {
        language = JSON.parse(localStorage.getItem(STORAGE_KEYS.LANGUAGE));
      } catch (_e) { }
      if (!!language && !!language.language && !!language.update
        && !!language.compiled
      ) {
        this._language = language.language;
        this._update = language.update;
        this._compiled = language.compiled;
        this._languageSvc.compiled = this._compiled;
      }

      // Set the current language.
      this._languageSvc.language = this._language;
      this._localeSvc.use(this._languageSvc.language);

      // Check if the language has been updated.
      this._http.get(
        `${config.apiBase}public/language?language=${
          encodeURIComponent(this._language)
        }&update=${encodeURIComponent(this._update)}`,
        { headers: PUBLIC_HEADERS }
      ).pipe(map(
        (response: any) => {
          if (this._update !== response.update) {
            if (!!response.errors) this._language = LANGUAGE_DEFAULT;
            this._update = response.update || LANGUAGE_UPDATE_DEFAULT;
            this._compiled = {};
            this._languageSvc.compiled = this._compiled;
            localStorage.setItem(STORAGE_KEYS.LANGUAGE, JSON.stringify({
              language: this._language,
              update: this._update,
              compiled: this._compiled
            }));
          }
          this._languageSvc.initialize();
        }).bind(this)).subscribe();
    } catch (e) {
      if (!config.production)
        console.error('Failed to load language module.');
    }
  }

  async loadPaths(paths: Array<string>): Promise<void> {
    const loading: ExposedPromise<void> = new ExposedPromise<void>();
    this._http.get(
      `${config.apiBase}public/language?language=${
        encodeURIComponent(this._language)
      }&update=${encodeURIComponent(this._update)}&paths=${
        encodeURIComponent(paths.join(','))}`,
      { headers: PUBLIC_HEADERS }).pipe(map(
        (response: any) => {
          if (this._update !== response.update) {
            if (!!response.errors) this._language = LANGUAGE_DEFAULT;
            this._update = response.update || LANGUAGE_UPDATE_DEFAULT;
            this._compiled = {};
            this._languageSvc.compiled = this._compiled;
            localStorage.setItem(STORAGE_KEYS.LANGUAGE, JSON.stringify({
              language: this._language,
              update: this._update,
              compiled: this._compiled
            }));
          }
          if (!!response.data) {
            for (const path in response.data) {
              if (response.data[path] === null) continue;
              const splitPath = path.split('.');
              let container = this._compiled;
              for (let i = 0; i < splitPath.length - 1; i++) {
                if (
                  typeof container[splitPath[i]] !== 'object' ||
                  container[splitPath[i]] === null
                ) container[splitPath[i]] = { _loaded: false };
                container = container[splitPath[i]];
              }
              container[splitPath[splitPath.length - 1]] =
                response.data[path];
            }
            localStorage.setItem(STORAGE_KEYS.LANGUAGE, JSON.stringify({
              language: this._language,
              update: this._update,
              compiled: this._compiled
            }));
          }
          loading.resolve();
        }).bind(this)).subscribe();
    return loading.toPromise();
  }
}
