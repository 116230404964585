<!-- Skill builder header -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="back()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
      </div>
    </div>
  </div>
</div>

<ng-container [ngSwitch]="data?.page">
  <!-- Start Flow -->
  <app-member-skill-builder-adventure-choose
    *ngSwitchCase="ADVENTURE_PAGE.CHOOSE"
    [data]="data"
  ></app-member-skill-builder-adventure-choose>

  <!-- Skill builder track flow -->
  <app-member-skill-builder-adventure-impact
    *ngSwitchCase="ADVENTURE_PAGE.IMPACT"
    [data]="data"
  ></app-member-skill-builder-adventure-impact>
</ng-container>
