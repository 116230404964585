<div class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center pb-5-sm-c pb-5-md-c">
  <div class="container-fluid px-0 w-sm-100 w-50 pb-5-sm-c pb-5-md-c">
    <span class="d-flex justify-content-center text-center mt-2 sm-center-txt pb-2 px-2">
      {{ page.title }}
    </span>
    <div class="positive-brain-list w-auto">
      <div class="col-12 d-flex justify-content-center mt-3">
        <div class="col-sm-12 col-lg-6 c-form">
            <div>
              <div class="col-12 d-flex justify-content-center align-items-center pb-3">
                <button
                  type="button"
                  class="d-flex btn-link info-modal-btn align-items-center"
                  (click)="openModal()">
                  <span class="circle-btn-bordered info-btn ml-0"><i class="fas fa-info"></i></span>
                  <label class="lbl-md pl-3 mb-0 cursor-pointer">{{ page.needExample }}</label>
                </button>
                <ng-template #exampleTemplate>
                  <div class="modal-body px-0">
                    <div class="modal-header px-0 pr-4">
                      <h4 class="modal-title d-flex align-items-center justify-content-center">
                        <span class="circle-btn-bordered info-btn"
                          ><i class="fas fa-info"></i></span>
                        <label class="lbl-md pl-3 mb-0 font-weight-bold">{{ page.needExample }}</label>
                      </h4>
                      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
                          <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </div>
              
                    <div class="col-12 my-4 px-4">
                      <p>
                        {{ page.example }}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <form [formGroup]="form">
                <div class="heading-md text-center txt-green font-lg">{{ data.name }}</div>            
                <div class="heading-md text-center pb-3">{{ page.values }}</div>
                <div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="message"
                      aria-describedby=""
                      placeholder="{{ page.placeholder }}"
                      formControlName="message" />
                    <div class="invalid-feedback">
                      <div *ngIf="submitted && form.controls.message.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.message.errors.required || form.controls.message.errors.pattern">
                          {{ page.error }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </form>
                    
            <div class="footer-inner-action">
              <button 
                type="button" 
                (click)="next()"
                class="btn btn-primary btn-lg px-4">
                {{ page.submit }}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
