import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData, WWYD_PAGE } from '../common';
import {
  TextListDisclaimer,
  TextListNext,
  TextListTextInput
} from '@components';
import { ExposedPromise } from '@util';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.listActions';

@Component({
  selector: 'app-member-skill-builder-wwyd-list-actions',
  templateUrl: './list-actions.component.html',
  styleUrls: ['./list-actions.component.scss']
})
export class MemberSkillBuilderWwydListActionsComponent implements OnInit {

  @Input() data: WWYDData;
  actions: Array<TextListTextInput> = [
    { placeholder: '' },
    { placeholder: '' },
    { placeholder: '' }
  ];
  disclaimer: TextListDisclaimer = { display: '', error: '' };
  next: TextListNext = { onClick: this.nextOnClick.bind(this) };
  loaded: ExposedPromise<void> = new ExposedPromise<void>();
  min: number = 1;
  private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    error1: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        const promises: Array<Promise<void>> = [];
        for (const key in this.page) {
          switch (key) {
            case 'placeholder':
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => {
                  this.page[key] = value;
                  for (const action of this.actions) action.placeholder = value;
                }));
              break;
            case 'disclaimer':
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => {
                  this.page[key] = value;
                  this.disclaimer.display = value;
                }));
              break;
            case 'error2':
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => {
                  this.page[key] = value;
                  this.disclaimer.error = value;
                }));
              break;
            default:
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => { this.page[key] = value }));
          }
        }
        Promise.all(promises).then(_value => this._languageLoaded.resolve());
      }
    );

    await this._languageLoaded;

    // Get actions list.
    if (!this.data.actions) {
      if (!this.data.dilemma.custom)
        this.actions[0].value = this.data.dilemma.action;
      else this.min = 2;
    } else {
      for (let i = 0; i < this.data.actions.length; i++)
        this.actions[i].value = this.data.actions[i].display;
    }
    if (!this.data.dilemma.custom) this.actions[0].disabled = true;

    this.loaded.resolve();
  }

  nextOnClick(actions: Array<string>): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
      ...this.data,
      page: WWYD_PAGE.ETHICS,
      actions: actions.map((value, index) => {
        if (
          !!this.data.actions &&
          value === this.data.actions[index]?.display
        ) {
          return this.data.actions[index];
        }
        return { display: value };
      })
    });
  }

}
