<div class="col-md-12 d-flex align-items-center filters">
  <div class="row mb-3 w-100">
    <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
      <select [(ngModel)]="organizationFilter.value"
        (change)="onOrganizationChange()">
        <option *ngFor="let organization of organizationFilter.options"
          [ngValue]="organization">
          <a class="{{ organizationFilter.value === organization ? 'active' : '' }}">
            {{organization.name}}
          </a>
        </option>
      </select>
    </div>
    <!-- <span class="divider-rgt pl-2 mr-1 d-none d-md-flex"></span>
    <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
      <select [(ngModel)]="timespanFilter.value" (change)="refreshCharts()">
        <option *ngFor="let timespan of timespanFilter.options"
          [ngValue]="timespan">
          <a class="{{ timespanFilter.value === timespan ? 'active' : '' }}">
            {{timespan.name}}
          </a>
        </option>
      </select>
    </div> -->
  </div>
</div>

<div class="card card-column p-3">
  <div class="d-flex justify-content-between name " style="margin-bottom: 9px;">
    <h5 class="font-weight-bold">{{page?.orgDataAvg}}</h5>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
      <div class="improvements start-box d-flex text-muted">
        <img src="assets/img/new/skill-builder.svg" alt="skill-builder"
          class="bd-placeholder-img me-2 flex-shrink-0 img" />
        <div class="pb-3 pb-lg-0 mb-0 mid content">
          <div class="d-flex justify-content-between name">
            <h6 class="font-weight-bold">{{page?.skillBuilder}}</h6>
          </div>
          <div class="retings-count">
            <span class="skill-builder-count">
              {{organizationStats.completed}}
            </span>
            <span class="progress-status">{{page?.completed}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
      <div class="improvements mid-box d-flex text-muted">
        <img src="assets/img/calendar-check.svg" alt="{{page?.dailyStreak}}"
          class="bd-placeholder-img me-2 flex-shrink-0 img" />
        <div class="pb-3 pb-lg-0 mb-0 mid content">
          <div class="d-flex justify-content-between name">
            <h6 class="font-weight-bold"> {{page?.dailyStreak}}</h6>
          </div>
          <div class="retings-count">
            <span class="mid-box-count">
              {{organizationStats.streak}}
            </span>
            <span class="progress-status">{{page?.dailyInRow}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
      <div class="improvements start-box d-flex text-muted">
        <img src="./../../../../assets/img/new/positive-impact.svg" alt="{{page?.positiveImpact}}"
          class="bd-placeholder-img me-2 flex-shrink-0 img" />
        <div class="pb-3 pb-lg-0 mb-0 mid content">
          <div class="d-flex justify-content-between name">
            <h6 class="font-weight-bold">{{page?.positiveImpact}}</h6>
          </div>
          <div class="retings-count">
            <span class="skill-builder-count">
              {{organizationStats.positive}}
            </span>
            <span class="progress-status"> {{page?.numInstances}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
      <div class="improvements end-box d-flex text-muted">
        <img src="./../../../../assets/img/new/daily-goal.svg" alt="daily-goal"
          class="bd-placeholder-img me-2 flex-shrink-0 img" />
        <div class="pb-3 pb-lg-0 mb-0 mid content">
          <div class="d-flex justify-content-between name">
            <h6 class="font-weight-bold">{{page?.dailyGoal}}</h6>
          </div>
          <div class="retings-count">
            <span class="end-box-count ">
              {{organizationStats.goal}}%
            </span>
            <span class="progress-status"> {{page?.howOften}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-lg-12">
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-6 mb-3 mb-xl-0">
        <app-bar-chart title="{{page?.skbCompleted}}" view="{{page?.viewAll}}"
          [filters]="filtersForSkill"
          [getChartConfiguration]="getChartConfigurationForSkill.bind(this)"
          [refresh]="refreshForSkill" [isViewAll]="viewAllForSkill">
        </app-bar-chart>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 mb-3 mb-xl-0">
        <app-bar-chart title="{{page?.activityStreak}}" view="{{page?.viewAll}}"
          [filters]="filtersForActivity"
          [getChartConfiguration]="getChartConfigurationForActivity.bind(this)"
          [refresh]="refreshForActivity" [isViewAll]="viewAllForActivity">
        </app-bar-chart>
      </div>
    </div>  
    <div class="row mb-4">
      <div class="col-xs-12 col-sm-12 col-md-6 mb-3 mb-xl-0">
        <app-bar-chart [title]="page?.dailyGoal" [view]="page?.viewAll"
          [filters]="filtersForGoal"
          [getChartConfiguration]="getChartConfigurationForGoal.bind(this)"
          [refresh]="refreshForGoal" [isViewAll]="viewAllForGoal">
        </app-bar-chart>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 mb-3 mb-xl-0">
        <app-bar-chart [title]="page?.positiveImpact" [view]="page?.viewAll"
          [filters]="filtersForImpact"
          [getChartConfiguration]="getChartConfigurationForImpact.bind(this)"
          [refresh]="refreshForImpact" [isViewAll]="viewAllForImpact">
        </app-bar-chart>
      </div>
    </div>
  </div>
</div>


<div class="col-md-12 d-flex align-items-center filters">
  <div class="row mb-3 w-100 justify-content-end">
    <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
      <select [(ngModel)]="skillBuilderFilter.value"
        (change)="refreshSkillBuilderEngagement()">
        <option *ngFor="let skillBuilder of skillBuilderFilter.options"
          [ngValue]="skillBuilder">
          <a class="{{ skillBuilderFilter.value === skillBuilder ? 'active' : '' }}">
            {{skillBuilder.name}}
          </a>
        </option>
      </select>
    </div>
    <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
      <select [(ngModel)]="groupFilter.value" (change)="onGroupChange()">
        <option *ngFor="let group of groupFilter.options" [ngValue]="group">
          <a class="{{ groupFilter.value === group ? 'active' : '' }}">
            {{group.name}}
          </a>
        </option>
      </select>
    </div>
  </div>
</div>

<div class="card-white card-white-sm border-radius-md">
  <div class="col-12 d-flex justify-content-start card-title-main border-0 px-3 pt-4">
    <h5>{{page?.sbEngagement}}</h5>
  </div>
  <div>
    <div class="card-white card-white-sm border-radius-md">
      <div>
        <div class="col-md-12 col-lg-12">
          <div class="row mb-4 mt-4">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="boxMain card-white card-white-sm border-radius-md">
                <div id="no-more-tables">
                  <table class="table table-c">
                    <thead class="thead-light">
                      <tr>
                        <th> {{page?.name}}</th>
                        <th> {{page?.skillBuilder}} </th>
                        <th>{{page?.numCompleted}}</th>                         
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngIf="skillBuilderEngagementData?.length > 0">
                        <tr *ngFor="
                          let data of skillBuilderEngagementData | paginate: {
                            itemsPerPage: 10,
                            currentPage: skillBuilderEngagementPage
                          }">
                          <td data-title="{{page?.name}}">
                            {{data.name }}
                          </td>
                          <td data-title="{{page?.skillBuilder}}">
                            {{ data.skillBuilder }}
                          </td>
                          <td data-title="{{page?.numCompleted}}">
                            {{ data.completed }}
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container
                        *ngIf="skillBuilderEngagementData?.length === 0">
                        <tr *ngFor="
                          let data of skillBuilderEngagementData
                            | paginate: { itemsPerPage: 5, currentPage: '1' }">
                        </tr>
                        <td class="text-center pl-0-c" [attr.colspan]="3">
                          {{page?.noData}}
                        </td>
                      </ng-container>
                    </tbody>
                  </table>
                  <div style="float: right" class="w-sm-100">
                    <pagination-controls  previousLabel="" nextLabel=""
                      class="my-pagination"
                      (pageChange)="skillBuilderEngagementPage = $event"
                      directionLinks="true">
                    </pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
