<div class="main-container w-100" *ngIf="display">
  <div class="container-fluid pb-5-sm-c pb-5-md-c">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
        <app-skill-builder-list
          [title]="page.titleTopPositiveThoughts"
          [data]="topPositiveThoughtsList"
          class="w-100 mb-3"
        ></app-skill-builder-list>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
        <app-skill-builder-list
          [title]="page.titlePositiveThoughts"
          [data]="positiveThoughtsList"
          class="w-100 mb-3"
        ></app-skill-builder-list>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
        <app-skill-builder-calendar
          [events]="calendarEvents"
          [legend]="calendarLegend"
          [title]="page.calendarTitle"
          skillBuilderType="brain"
          class="w-100 mb-3"
        ></app-skill-builder-calendar>
      </div>

      <div *ngIf="isPie" class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
        <app-skill-builder-pie-chart
          [title]="page.pieTitle"
          [chartTitle]="page.pieChartTitle"
          [data]="pieData"
          class="w-100 mb-3"
        ></app-skill-builder-pie-chart>
      </div>
    </div>
  </div>
</div>
