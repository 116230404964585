import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { STRESS_PAGE, StressData } from "../common";
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ReplaySubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { StressService } from '@services/member';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.stress.pages.impact';

@Component({
  selector: 'app-member-skill-builder-stress-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class MemberSkillBuilderStressImpactComponent implements OnInit {

  @Input() data: StressData;
  @ViewChild('NoHelpTemplate', { static: false })
    NoHelpTemplate: TemplateRef<void>;
  @ViewChild('LittleHelpTemplate', { static: false })
    LittleHelpTemplate: TemplateRef<void>;
  @ViewChild('LotOfHelpTemplate', { static: false })
    LotOfHelpTemplate: TemplateRef<void>;
  modalRef: BsModalRef;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    littleHelp: '',
    lotOfHelp: '',
    noHelp: '',
    tryDifferent: '',
    trySame: '',
    ok: ''
  };

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService,
    private _stressSvc: StressService,
    private _modalSvc: BsModalService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          if (key === 'title') {
            this._languageSvc.template(
              this.page[key], { destress: this.data?.destress }
            ).then(value => this.page[key] = value);
          } else {
            this._languageSvc.template(this.page[key]).then(
              value => this.page[key] = value);
          }
      });

    const path: string = 'skillBuilders.stress.impact';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = Object.values(value[path]);

        this.options.next(this.defaultOptions.map(
          (value: SelectListOption) => {
            if (value.value === this.data?.impact)
              return { ...value, selected: true };
            return value;
          }));
      });
  }

  nextOnClick(option: SelectListOption): void {
    this.data.impact = option.value;
    switch(option.value) {
      case 0:
        this.modalRef = this._modalSvc.show(this.NoHelpTemplate, {
          class: "modal-sm modal-dialog-centered",
          backdrop: "static",
          keyboard: true
        });
        break;
      case 1:
        this._stressSvc.createLog(this.data).subscribe(
          (res: APISuccess | APIErrors) => {
            if (isAPISuccess(res)) {
              this.modalRef = this._modalSvc.show(this.LittleHelpTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true
              });
            }
          }
        );
        break;
      case 2:
        this._stressSvc.createLog(this.data).subscribe(
          (res: APISuccess | APIErrors) => {
            if (isAPISuccess(res)) {
              this.modalRef = this._modalSvc.show(this.LotOfHelpTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true
              });
            }
          }
        );
        break;
      default:
        this._router.navigate(['member/dashboard']);
        break;
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
    this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
      {
        ...this.data,
        page: STRESS_PAGE.CHANGE_PLAN
      });
  }

  tryDifferent(): void {
    this._stressSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this.modalRef?.hide();
          this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
            {
              ...this.data,
              page: STRESS_PAGE.DESTRESS,
              tryDifferentReliever: true
            });
        }
      }
    );
  }

  trySame(): void {
    this._stressSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this.modalRef?.hide();
          this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
            {
              ...this.data,
              page: STRESS_PAGE.CHANGE_PLAN
            });
        }
      }
    );
  }

  closeOptionModal(): void {
    this.modalRef?.hide();
  }

}
