import { JCloneInterface, toJClone } from "./jclone";

const ONE_DAY = 864e5;

export class JDate implements JCloneInterface {
  static readonly PATTERN = /^[1-9][0-9]{3}-[0-1][0-9]-[0-3][0-9]$/;

  private _date: Date;

  constructor(date?: string | JDate | Date) {
    if (typeof date === 'undefined' || date === null || date instanceof Date) {
      this._date = (!date ? new Date() : new Date(<Date>date));
      this._date.setUTCFullYear(
        this._date.getFullYear(), this._date.getMonth(), this._date.getDate());
      this._date.setUTCHours(12, 0, 0, 0);
      return;
    }

    if (date instanceof JDate) {
      this._date = new Date(date._date);
      return;
    }

    if (typeof date === 'string' && JDate.PATTERN.test(date)) {
     this._date = new Date(`${date}T12:00:00Z`);
      if (isNaN(this._date.getTime()))
        throw new TypeError(
          'Invalid argument for JDate.constructor(date: string|JDate). ' +
          `The parameter 'date' is not a valid date string.`);
      return;
    }

    throw new TypeError(
      'Invalid argument for JDate.constructor(date: string|JDate).');
  }

  toString(): string {
    return this._date.toISOString().slice(0, 10);
  }

  valueOf(): number {
    return Math.floor(this._date.getTime() / ONE_DAY);
  }

  addDays(days: number): JDate {
    if (typeof days !== 'number')
      throw new TypeError(
        'Invalid argument for JDate.addDays(days: number).');
    if (days !== Math.floor(days))
      throw new RangeError(
        `Invalid argument for JDate.addDays(days: number). ` +
        `The parameter 'days' must be an integer.`);
   this._date.setUTCDate(this._date.getUTCDate() + days);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString()))
      throw new RangeError(
        `Invalid argument for JDate.addDays(days: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`);
    return this;
  }

  addMonths(months: number): JDate {
    if (typeof months !== 'number') {
      throw new TypeError('Invalid argument for JDate.addMonths(months: number).');
    }
    if (months !== Math.floor(months)) {
      throw new RangeError(
        `Invalid argument for JDate.addMonths(months: number). ` +
        `The parameter 'months' must be an integer.`
      );
    }
   this._date.setUTCMonth(this._date.getUTCMonth() + months);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString())) {
      throw new RangeError(
        `Invalid argument for JDate.addMonths(months: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`
      );
    }
    return this;
  }

  addYears(years: number): JDate {
    if (typeof years !== 'number') {
      throw new TypeError('Invalid argument for JDate.addYears(years: number).');
    }
    if (years !== Math.floor(years)) {
      throw new RangeError(
        `Invalid argument for JDate.addYears(years: number). ` +
        `The parameter 'years' must be an integer.`
      );
    }
   this._date.setUTCFullYear(this._date.getUTCFullYear() + years);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString())) {
      throw new RangeError(
        `Invalid argument for JDate.addYears(years: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`
      );
    }
    return this;
  }

  setDays(days: number): JDate {
    if (typeof days !== 'number') {
      throw new TypeError('Invalid argument for JDate.setDays(days: number).');
    }
    if (days !== Math.floor(days)) {
      throw new RangeError(
        `Invalid argument for JDate.setDays(days: number). ` +
        `The parameter 'days' must be an integer.`
      );
    }
   this._date.setUTCDate(days);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString())) {
      throw new RangeError(
        `Invalid argument for JDate.setDays(days: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`
      );
    }
    return this;
  }

  setMonths(months: number): JDate {
    if (typeof months !== 'number') {
      throw new TypeError('Invalid argument for JDate.setMonths(months: number).');
    }
    if (months !== Math.floor(months)) {
      throw new RangeError(
        `Invalid argument for JDate.setMonths(months: number). ` +
        `The parameter 'months' must be an integer.`
      );
    }
   this._date.setUTCMonth(months);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString())) {
      throw new RangeError(
        `Invalid argument for JDate.setMonths(months: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`
      );
    }
    return this;
  }

  setYears(years: number): JDate {
    if (typeof years !== 'number') {
      throw new TypeError('Invalid argument for JDate.setYears(years: number).');
    }
    if (years !== Math.floor(years)) {
      throw new RangeError(
        `Invalid argument for JDate.setYears(years: number). ` +
        `The parameter 'years' must be an integer.`
      );
    }
   this._date.setUTCFullYear(years);
    if (isNaN(this._date.getTime()) || !JDate.PATTERN.test(this.toString())) {
      throw new RangeError(
        `Invalid argument for JDate.setYears(years: number). ` +
        `The new date does not conform to the 'YYYY-MM-DD' format.`
      );
    }
    return this;
  }

  [toJClone](): this {
    return <this>new JDate(this);
  }

  get weekday(): number {
    return this._date.getUTCDate();
  }

  static compareDays(start: JDate, end: JDate): number {
    if (!(start instanceof JDate && end instanceof JDate)) {
      throw new TypeError(
        'Invalid argument for JDate.compareDays(start: JDate, end: JDate).'
      );
    }
    return Math.round((end._date.getTime() - start._date.getTime()) / ONE_DAY);
  }

  static compareMonths(start: JDate, end: JDate): number {
    if (!(start instanceof JDate && end instanceof JDate)) {
      throw new TypeError(
        'Invalid argument for JDate.compareMonths(start: JDate, end: JDate).'
      );
    }
    let startDate = start._date;
    let endDate = end._date;
    let fraction = 0;
    let lowerBound = new Date(endDate);
    if (startDate.getUTCDate() !== endDate.getUTCDate()) {

      // Calculate the fractional month.
      let upperBound: Date;
      if (startDate.getUTCDate() < endDate.getUTCDate()) {

        // Set the lower bound.
        lowerBound.setUTCDate(startDate.getUTCDate());

        // Calculate the upper bound.
        upperBound = new Date(lowerBound);
        let oldMonth = upperBound.getUTCMonth();
        upperBound.setUTCMonth(oldMonth + 1);
        if ((oldMonth + 1) % 12 !== upperBound.getUTCMonth())
          upperBound.setUTCDate(-1);
      }
      if (startDate.getUTCDate() > endDate.getUTCDate()) {

        // Calculate the lower bound.
        let oldMonth = lowerBound.getUTCMonth();
        lowerBound.setUTCDate(1);
        lowerBound.setUTCMonth(oldMonth - 1);
        lowerBound.setUTCDate(startDate.getUTCDate());
        if (oldMonth === lowerBound.getUTCMonth()) lowerBound.setUTCDate(-1);

        // Calculate the upper bound.
        upperBound = new Date(endDate);
        oldMonth = upperBound.getUTCMonth();
        upperBound.setUTCDate(startDate.getUTCDate());
        if (oldMonth !== upperBound.getUTCMonth()) upperBound.setUTCDate(-1);
      }

      fraction = (endDate.getTime() - lowerBound.getTime()) / (upperBound.getTime() - lowerBound.getTime());
    }

    // Calculate the number of months between the start date and the end date.
    return ((lowerBound.getUTCFullYear() - startDate.getUTCFullYear()) * 12) + (lowerBound.getUTCMonth() - startDate.getUTCMonth()) + fraction;
  }

  static compareYears(start, end) {
    if (!(start instanceof JDate && end instanceof JDate))
      throw new TypeError(
        'Invalid argument for JDate.compareYears(start: JDate, end: JDate).');
    let startDate = start._date;
    let endDate = end._date;
    let fraction = 0;
    let lowerBound = new Date(endDate);
    if (startDate.getUTCDate() !== endDate.getUTCDate()
      || startDate.getUTCMonth() !== endDate.getUTCMonth()) {

      // Calculate the lower bound month.
      if (startDate.getUTCMonth() < endDate.getUTCMonth()) {

        // Calculate the lower bound month.
        lowerBound.setUTCMonth(startDate.getUTCMonth());
        if (startDate.getUTCMonth() !== lowerBound.getUTCMonth())
          lowerBound.setUTCDate(-1);
      }
      if (startDate.getUTCMonth() > endDate.getUTCMonth()) {

        // Calculate the lower bound month.
        let oldMonth = lowerBound.getUTCMonth();
        lowerBound.setUTCFullYear(lowerBound.getUTCFullYear() - 1);
        if (oldMonth !== lowerBound.getUTCMonth()) lowerBound.setUTCDate(-1);
        lowerBound.setUTCMonth(startDate.getUTCMonth());
        if (startDate.getUTCMonth() !== lowerBound.getUTCMonth())
          lowerBound.setUTCDate(-1);
      }

      // Calculate the lower bound day.
      if (startDate.getUTCDate() < endDate.getUTCDate()) {

        // Calculate the lower bound day.
        lowerBound.setUTCDate(startDate.getUTCDate());
        if (startDate.getUTCMonth() !== lowerBound.getUTCMonth())
          lowerBound.setUTCDate(-1);
      }
      if (startDate.getUTCDate() > endDate.getUTCDate()) {
        // Calculate the lower bound day.
        if (startDate.getUTCMonth() === endDate.getUTCMonth()) {
          lowerBound.setUTCFullYear(lowerBound.getUTCFullYear() - 1);
          if (startDate.getUTCMonth() !== lowerBound.getUTCMonth())
            lowerBound.setUTCDate(-1);
        }
        lowerBound.setUTCDate(startDate.getUTCDate());
        if (startDate.getUTCMonth() !== lowerBound.getUTCMonth())
          lowerBound.setUTCDate(-1);
      }

      // Calculate the upper bound.
      let upperBound: Date = new Date();
      upperBound.setUTCFullYear(upperBound.getUTCFullYear() + 1);
      let oldMonth = upperBound.getUTCMonth();
      upperBound.setUTCFullYear(upperBound.getUTCFullYear() + 1);
      if (oldMonth !== upperBound.getUTCMonth()) upperBound.setUTCDate(-1);

      fraction = (endDate.getTime() - lowerBound.getTime()) / (upperBound.getTime() - lowerBound.getTime());
    }

    // Calculate the number of years between the start date and the end date.
    return (
        lowerBound.getUTCFullYear() - startDate.getUTCFullYear()
      ) + fraction;
  }

}
