<div class="credHeader">
    <img src="assets/img/logo.svg" alt="">
</div>

<div class="d-flex justify-content-center align-items-center plain-rounded-card px-4 pt-5">
  <div class="card card-primary col-sm-12 col-lg-6">
    <div class="card-body">
      <h3 class="title-main">{{this.pageLanguage?.title}}</h3>
      <p class="subHd">{{this.pageLanguage?.subTitle}}</p>
      <form [formGroup]="form" (ngSubmit)="resetPassword()">
        <div class="formInner">

          <div class="flex-column formInnerCol">
            <label>{{this.pageLanguage?.newPass}}</label>
            <span type="button" [ngbTooltip]="popTemplate" triggers="click" tooltipClass="my-custom-class" placement="bottom" class="px-2">
              <span class="info-circle">
                <img src="assets/img/info-ico-card.svg" alt="info" />
              </span>
            </span>
            <div class="input-group">
              <span class="credIcons">
                <input formControlName="password" type="{{hidePassword ? 'password' : 'text'}}" maxlength="32"
                  [ngClass]="{'danger-class': f.password.errors && f.password.touched}" name="password"
                  class="form-control" placeholder="{{this.pageLanguage?.newPass}}">
                <button type="button" (click)="hidePassword=!hidePassword"><i
                    class="fas {{hidePassword ? 'fa-eye-slash' : 'fa-eye'}}"></i>
                </button>
              </span>
              <div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
                <div *ngIf="f.password.errors.required"> {{this.pageLanguage?.passRequired}}</div>
                <div *ngIf="f.password.errors.pattern"> {{this.pageLanguage?.passMinimum}}</div>
              </div>
            </div>
          </div>

          <div class="flex-column formInnerCol">
            <label>{{this.pageLanguage?.confirmPass}}</label>
            <div class="input-group">
              <span class="credIcons">
                <input formControlName="confirmPassword" type="{{hideConfirmPassword ? 'password' : 'text'}}"
                  maxlength="32" [ngClass]="{'danger-class': f.confirmPassword.errors && f.confirmPassword.touched}"
                  name="confirmPassword" class="form-control" placeholder="{{this.pageLanguage?.confirmPass}}">
                <button type="button" (click)="hideConfirmPassword=!hideConfirmPassword"><i
                    class="fas {{hideConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}}"></i>
                </button>
              </span>
              <div *ngIf="f.confirmPassword.errors && f.confirmPassword.touched" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">{{this.pageLanguage?.confirmPassRequired}}
                </div>
              </div>
            </div>

            <div class="flex-column formInnerCol">
              <div *ngIf="!!f.confirmPassword.value && f.password.value !== f.confirmPassword.value">
                <small class="text-danger">{{this.pageLanguage?.passMismatch}}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-c">
          <button class="btn btn-primary btn-block">
           {{this.pageLanguage?.reset}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #popTemplate>
  <ul class="list-unstyled text-left">
    <li><strong>{{this.pageLanguage?.passRequirements}}</strong></li>
    <ul class="text-left">
      <li>{{this.pageLanguage?.passLength}}</li>
      <li> {{this.pageLanguage?.lowercase}}</li>
      <li>{{this.pageLanguage?.uppercase}}</li>
      <li>{{this.pageLanguage?.number}}</li>
      <li>{{this.pageLanguage?.special}}</li>
    </ul>
  </ul>
</ng-template>
