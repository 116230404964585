import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdventureData,
  AdventureReportData
} from '@pages/member/skill-builder/adventure/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdventureService {

  constructor(private _http: HttpClient) { }

  createLog(data: AdventureData): Observable<APISuccess | APIErrors> {
    return this._http.post<APISuccess | APIErrors>(
      `${config.apiBase}member/challenge/adventure/log/create`,
      {
        adventure: data.adventure,
        goalPlanUUID: data.goalPlanUUID ?? null
      });
  }

  updateLog(data: AdventureData): Observable<APISuccess | APIErrors> {
    return this._http.put<APISuccess | APIErrors>(
      `${config.apiBase}member/challenge/adventure/log/update`,
      {
        logUUID: data.logUUID,
        impact: data.impact
      });
  }

  getReport(): Observable<AdventureReportData | APINoData> {
    return this._http.get<AdventureReportData | APINoData>(
      `${config.apiBase}member/challenge/adventure/report`);
  }

}
