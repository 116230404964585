import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractData,
  AbstractReportData
} from '@pages/member/skill-builder/abstract/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AbstractService {

  constructor(
    private _http: HttpClient
  ) { }

  createLog(data: AbstractData): Observable<APISuccess | APIErrors> {
    return this._http.post<APISuccess | APIErrors>(
      `${config.apiBase}member/challenge/abstract/log/create`,
      {
        idea: data.idea,
        positiveReasons: data.positiveReasons,
        negativeReasons: data.negativeReasons,
        userRating: data.userRating,
        generatedRating: data.generatedRating,
        comfort: data.comfort,
        goalPlanUUID: data.goalPlanUUID ?? null
      }
    );
  }

  getReport(): Observable<AbstractReportData | APINoData> {
    return this._http.get<AbstractReportData | APINoData>(
      `${config.apiBase}member/challenge/abstract/report`
    );
  }

}
