import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData, WWYDDilemma, WWYD_PAGE } from '../common';
import { ReplaySubject } from 'rxjs';
import {
  SelectListCustom,
  SelectListNext,
  SelectListOption
} from '@components';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.dilemma';

@Component({
  selector: 'app-member-skill-builder-wwyd-dilemma',
  templateUrl: './dilemma.component.html',
  styleUrls: ['./dilemma.component.scss']
})
export class MemberSkillBuilderWwydDilemmaComponent implements OnInit {

  @Input() data: WWYDData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  custom: SelectListCustom = {
    onClick: this.customOnClick.bind(this)
  }
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    // Get options list.
    const path: string = 'skillBuilders.wwyd.dilemmas';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.options.next(
          Object.values(value[path]).map(
            (value: WWYDDilemma) => {
              return {
                display: value.display,
                value,
                selected: (this.data.dilemma?.display === value.display)
              };
            }
          )
        );
      }
    );
  }

  customOnClick(): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
      ...this.data,
      page: WWYD_PAGE.CUSTOM_DILEMMA,
      dilemma: null
    });
  }

  nextOnClick(option: SelectListOption): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
      ...this.data,
      page: WWYD_PAGE.FIRST_CHOICE,
      dilemma: option.value
    });
  }

}
