import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  DomainService,
  LanguageService,
} from "@services/public";
import { AssessmentService } from "@services/member";
import { map } from "rxjs";
import { WeeklyChallengeService } from "@pages/member/challenges/services/weekly-challenge.service";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit {
  questions: any;
  submitted = false;
  title: any;
  description: any;
  domain: any;
  letterOption = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
  };
  domainData: any;

  pageLanguage: any;
  labels: any;
  path: string = `pages.member.inventory`;


  constructor(
    private assessmentSvc: AssessmentService,
    private router: Router,
    private _languageSvc: LanguageService,
    private weeklyChallengeSvc: WeeklyChallengeService,
    private _domainSvc: DomainService
  ) {}

  ngOnInit(): void {
    this.domainData = JSON.parse(localStorage.getItem("weeklyActivities"));
    this._languageSvc.get([this.path]).then((value) => {
      this.pageLanguage = value[this.path];
      const letters = ['a', 'b', 'c', 'd', 'e', 'f' ,'g', 'h'];
      for (const [index, opp] of Object.entries(this.letterOption)) {
        
        this.letterOption[index] =  this.pageLanguage[`${letters[index]}`];
      };
    });
    this._languageSvc.get([`labels`]).then((value) => {
      this.labels = value[`labels`];
    });
    
    this._domainSvc.getDomain(this.domainData.domainUUID).then(
      domain => {
        this.domain = domain;
        localStorage.setItem("domains", JSON.stringify(this.domain));
        this.questions = this.domain.inventory.questions;
        this.questions.map((element) => {
          element.selected = null;
          if (element.display.includes("Check all"))
            element['input'] = "checkbox";
          else
            element['input'] = "radio";
        }); 
        this.description = this.domain.inventory.description;
        this._languageSvc
          .template(this.domain.inventory.title, {
            domain: this.domain.name.toLowerCase(),
          })
          .then((value) => {
            this.title = value;
          });
      }
    );
  }

  GetSelectedOptions() {
    this.submitted = false;
    const selectedOptoin = this.questions.filter(
      (element) => element.selected !== null ||
        element.answers.find(val => val.selected) !== undefined
      );
    if (selectedOptoin.length !== this.questions.length) {
      this.submitted = true;
      return;
    }
    let score = [];
    this.questions.forEach(val => {
      if (val.type === "list" 
        && val.selected !== null
        && val.input === "radio") {
        score.push(
          { uid: val.uid,
            score: val.answers[val.selected].value
          });
      } else 
      if (val.type === "list"
        && val.input === "checkbox") {
          val.answers.filter((answer) => answer.selected).map(selected =>
            score.push({ uid: val.uid, score: selected.value}));
      }else 
        if (val.selected !== null && val.type !== "list") {
          score.push({ uid: val.uid, score: val.selected });
        }
    });
    let reqObject = {
      timezone: "America/Chicago",
      scores: score,
    };
    this.assessmentSvc
      .inventorySubmit(reqObject)
      .pipe(
        map((response: any) => {
          if (!!response.success) {
            this.activityCompleted();
          }
        }).bind(this)
      )
      .subscribe();
  }
  activityCompleted() {
    this.weeklyChallengeSvc
      .completeWeeklyChallenge({ uuid: this.domainData.uuid })
      .subscribe((res) => {
        this.router.navigate(["/member/dashboard"]);
      });
  }
  backstep() {
    this.router.navigate(["member/dashboard"]);
  }
  closeTab() {
    this.router.navigate(["member/dashboard"]);
  }
}
