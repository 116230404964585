<section class="login-main">
  <div class="container">
    <div class="login-inner">
      <div class="row login-box" data-aos="fade-up">
        <div class="col-md-6 login-left">
          <div class="cred-logo">
            <img src="assets/img/cred-logo.png" alt="background" />
          </div>
        </div>
        <div class="col-md-6 login-right-cover">
          <div class="login-right">
            <h2 class="black d-none d-md-block">
              {{page.title}}
            </h2>
            <div class="mobi-banner-login d-block d-md-none">
              <div class="curve">
                <h2 class="white">
                  {{page.titleMobile}}
                </h2>
                <img src="assets/img/Jakapa-Logo.png" alt="Logo" />
              </div>
            </div>
            <p class="px-3 px-md-0 pt-3 pt-md-0">
              {{page.subtitle}}
            </p>
            <form
              [formGroup]="form"
              (ngSubmit)="onLoginSubmit()"
              id="login-form"
              class="loginFormMain px-3 px-md-0"
            >
              <div class="form-group">
                <label class="control-label">
                  {{page.email}}
                </label>
                <span class="credIcons">
                  <input
                    formControlName="email"
                    type="email"
                    maxlength="320"
                    name="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    [ngClass]="{
                      'danger-class': f.email.errors && f.email.touched
                    }"
                    [placeholder]="page.email"
                  />
                  <button disabled type="button">
                    <i class="fas fa-user"></i>
                  </button>
                </span>
                <div
                  *ngIf="f.email.errors && f.email.touched"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.email.errors.required">
                    {{page.emailRequired}}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">
                  {{page.password}}
                </label>
                <span class="credIcons">
                  <input
                    formControlName="password"
                    type="{{ hidePassword ? 'text' : 'password' }}"
                    maxlength="32"
                    [ngClass]="{
                      'danger-class': f.password.errors && f.password.touched
                    }"
                    name="password"
                    class="form-control"
                    [placeholder]="page.password"
                  />
                  <button type="button" (click)="hidePassword = !hidePassword">
                    <i
                      class="fas {{
                        hidePassword ? 'fa-eye' : 'fa-eye-slash'
                      }}"
                    ></i>
                  </button>
                </span>
                <div
                  *ngIf="f.password.errors && f.password.touched"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.password.errors.required">
                    {{page.passwordRequired}}
                  </div>
                </div>
              </div>

              <div class="form-group login-bottom-flex">
                <!-- <div class="remember">
                  <label style="cursor: not-allowed;" class="container-check">Remember Me
                    <input type="checkbox" disabled>
                    <span class="checkmark"></span>
                  </label>
                </div> -->
                <a routerLink="/password/forgot" class="Forgot-text link">
                  {{page.forgotPassword}}
                </a>
                <app-language-select></app-language-select>
              </div>

              <div class="form-group btn-c">
                <app-button type="submit" [block]="true">
                  {{page.login}}
                </app-button>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-center google-logo"
              >
                <div class="circle-md edlink">
                  <img
                    src="assets/img/edlink2.svg"
                    [alt]="page.loginEdLink"
                    (click)="onEdLinkClick()"
                  />
                </div>
                <!-- <div class="circle-md circle-border" >
                  <img
                    src="assets/img/apple-logo.svg"
                    [alt]="page.loginApple"
                    (click)="onAppleClick()"
                  />
                </div> -->
                <div class="circle-md circle-border">
                  <img
                    src="assets/img/google-logo.svg"
                    (click)="onGoogleClick()"
                    [alt]="page.loginGoogle"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
