/** Symbol for custom clone functions.
 */
export const toJClone: unique symbol = Symbol();

/** Interface to add a custom clone function to a class.
 */
export interface JCloneInterface extends Object {
  [toJClone](): this;
}

function isJCloneInterface(value: unknown): value is JCloneInterface {
  if (typeof value !== 'object' || value === null) return false;
  return toJClone in value;
}

/** Creates a deep clone of any variable. Non-enumerated object parameters are
 * not cloned. Use JCloneInterface to add cloning functionality to a custom
 * object class.
 * 
 * @param {T} value The variable to be cloned.
 * @returns The cloned variable.
 */
export function JClone<T>(
  value: T,
  clones: Map<Object, Object> = new Map<Object, Object>()
): typeof value {
  // Use toJClone function if available.
  if (isJCloneInterface(value)) {
    const clone: typeof value = value[toJClone]();
    clones.set(value, clone);
    return clone;
  }

  // Clone undefined.
  if (typeof value === 'undefined') return;

  // Clone non-objects.
  if (typeof value !== 'object') return value;

  // Clone null.
  if (value === null) return null;

  // Check if the object has already been cloned.
  if (clones.has(value)) return <T>clones.get(value);

  // Clone arrays.
  if (Array.isArray(value)) {
    const clone: typeof value = <typeof value>[];
    clones.set(value, clone);
    value.forEach(
      (value: unknown, index: number) => {
        clone[index] = JClone(value, clones);
      });
    return clone;
  }

  // Clone enumerable keys within the object.
  const clone: typeof value = <typeof value>{};
  clones.set(value, clone);
  for (const key in value) clone[key] = JClone(value[key], clones);
  return clone;
}
