import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { STRESS_PAGE, StressData } from "../common";
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ReplaySubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StressService } from '@services/member/skill-builder/stress.service';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.stress.pages.feelTrack';

@Component({
  selector: 'app-member-skill-builder-stress-feel-track',
  templateUrl: './feel-track.component.html',
  styleUrls: ['./feel-track.component.scss']
})
export class MemberSkillBuilderStressFeelTrackComponent implements OnInit {

  @Input() data: StressData;
  @ViewChild('noStress', { static: false }) noStress: TemplateRef<void>;
  modalRef: BsModalRef;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    noStress: '',
    ok: ''
  };

  constructor(
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _storageSvc: StorageService,
    private _stressSvc: StressService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    const path: string = 'skillBuilders.stress.feelTrack';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = Object.values(value[path]);

        this.options.next(this.defaultOptions.map(
          (value: SelectListOption) => {
            if (value.value === this.data?.stress)
              return { ...value, selected: true };
            return value;
          }));
      });
  }

  closeModal(): void {
    this.modalRef?.hide();
    this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
      {
        ...this.data,
        page: STRESS_PAGE.CHANGE_PLAN
      });
  }

  nextOnClick(option: SelectListOption): void {
    if (option.value === 0) {
      this.data.stress = 0;
      this.data.impact = 2;
      this._stressSvc.createLog(this.data).subscribe(
        (res: APISuccess | APIErrors) => {
          if (isAPISuccess(res)) {
            this.modalRef = this._modalSvc.show(this.noStress, {
              class: "modal-sm modal-dialog-centered",
              backdrop: "static",
              keyboard: true
            });
          }
        }
      );
      return;
    }
    this._storageSvc.setTempStorage(TEMP_KEYS.STRESS,
      {
        ...this.data,
        page: STRESS_PAGE.IMPACT,
        stress: option.value
      });
  }

}
