import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  LanguageService,
  StorageService,
  VOLATILE_KEYS
} from "@services/public";
import { SkillBuilderService } from "@services/member";

@Component({
  selector: "app-challenges",
  templateUrl: "./challenges.component.html",
  styleUrls: ["./challenges.component.scss"],
})
export class ChallengesComponent implements OnInit {
  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("starttemplate") starttemplate: TemplateRef<any>;
  @Input() navigationFrom: any;
  goals: Array<any> = [];
  challenges: Array<any> = [];
  modalRef?: BsModalRef;
  message?: string;
  isMobile: boolean;
  options: any;
  accExpanded: boolean = false;
  goalLevel: any;
  skillBuilderIcon = {
    brain: "assets/img/Build-Positive-Brain.svg",
    adventure: "assets/img/Go-Adventure.svg",
    wwyd: "assets/img/What-You-Do.svg",
    decision: "assets/img/Practice-Decision-Making.svg",
    journal: "assets/img/Did-Felt-Journal.svg",
    goal: "assets/img/Achieve-Goal.svg",
    stress: "assets/img/Make-Stress-Plan.svg",
    abstract: "assets/img/Abstract-Thinking.svg",
    message: "assets/img/Manage-Message.svg",
  };
  currentURL: any;
  cssprop = "circular-chart nill";
  strokes = "10 ,10";
  value = 99;

  pageLanguage: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalSvc: BsModalService,
    private _languageSvc: LanguageService,
    private _skillBuilderSvc: SkillBuilderService,
    private _storageSvc: StorageService
  ) {}

  ngOnInit(): void {
    this.cssprop = "circular-chart green";

    this.strokes = this.value + " ," + 100;

    if (this.navigationFrom === "dashboard") {
      window.screen.width === 360;
    }
    localStorage.setItem("trackGoalUUID", null);
    localStorage.setItem("fromGoal", null);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    this._skillBuilderSvc.getList().subscribe((res) => {
      this.challenges = res.skillBuilders.filter(
        (item) => item.type !== "goal"
      );
      this.goalLevel = res.skillBuilders.find((item) => item.type === "goal");
      if (this.goalLevel.progress === 0) {
        this.goalLevel.cssprop = "circular-chart nill";
        this.goalLevel.progressPercentage = this.goalLevel.progress;
      } else {
        this.goalLevel.cssprop = "circular-chart green";
        this.goalLevel.progressPercentage = Math.round(
          (this.goalLevel.progress / this.goalLevel.nextLevelProgress) * 100
        );
      }
      this.goalLevel.strokes = `${this.goalLevel.progressPercentage},${100}`;
      this.challenges.forEach((element) => {
        const progress = element.progress;
        const nextLevelProgress = element.nextLevelProgress;
        if (progress === 0) {
          element.cssprop = "circular-chart nill";
          element.progressPercentage = progress;
        } else {
          element.cssprop = "circular-chart green";
          element.progressPercentage = Math.round(
            (progress / nextLevelProgress) * 100
          );
        }
        element.strokes = `${element.progressPercentage},${100}`;
      });
      this._languageSvc.get(
        this.challenges.map(challenge => `skillBuilders.${challenge.type}.name`)
      ).then(
        value => {
          for (const challenge of this.challenges) {
            const name = value[`skillBuilders.${challenge.type}.name`];
            challenge.name = (typeof name === 'string' ? name : '');
          }
        });
      this._languageSvc.get([`pages.member.challenges.page`]).then(
        (value) => this.pageLanguage=value[`pages.member.challenges.page`]);
        
      this.goals = res.goals;
      this.generateGoalNames();
      localStorage.setItem("challengList", JSON.stringify(res.skillBuilders));
      localStorage.setItem(
        "challenguid",
        JSON.stringify(res.skillBuilder?.uuid)
      );
      localStorage.setItem("goalsList", JSON.stringify(res.goals));
      localStorage.setItem("challenguid", JSON.stringify(res.goal?.uuid));
    });
  }

  async generateGoalNames() {
    const paths: Array<string> =
      [...new Set<string>(
        this.goals.map(goal => `domains2.${goal.domainUID}.name`))];
    await this._languageSvc.get([...paths]).then(
      value => {
        for (const goal of this.goals) {
          let name = value[`domains2.${goal.domainUID}.name`];
          if (typeof name !== 'string') name = '';
          goal.name = `${name} - ${goal.skill} - ${goal.action}`
        }
      });
  }

  expandAcc() {
    if (this.accExpanded) {
      this.accExpanded = false;
    } else {
      this.accExpanded = true;
    }
  }

  startChalleng(type) {
    localStorage.removeItem("weeklyActivities");
    const startarrey = this.challenges.filter(
      (ele) => ele.type === type && ele.completed
    );
    if (startarrey.length !== 0) {
      this.openModalstart(this.starttemplate);
    } else {
      let curURL;
      this.route.url.subscribe((segments) => {
        curURL = segments.map((segment) => segment.path).join('/')
      });
      this.router.navigate(['member/skill-builder', type]);
    }
  }

  openModalstart(template1: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template1, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  confirm(): void {
    this.message = "Confirmed!";
    this.modalRef?.hide();
  }

  decline(): void {
    this.message = "Declined!";
    this.modalRef?.hide();
  }

  trackChalleng(type, completed) {
    localStorage.removeItem("weeklyActivities");
    if (completed) {
      this.openModal(this.template);
      return;
    }
    this.router.navigate(['member/skill-builder', type]);
  }

  end(challengeUUID, type) {
    this._skillBuilderSvc.end(type, challengeUUID).subscribe(() => {
      this._skillBuilderSvc.getList().subscribe(response => {
        this.challenges = response.skillBuilders.filter(
          (item) => item.type !== "goal"
        );
        this._languageSvc.get(
          this.challenges.map(
            challenge => `skillBuilders.${challenge.type}.name`)
        ).then(
          value => {
            for (const challenge of this.challenges) {
              const name = value[`skillBuilders.${challenge.type}.name`];
              challenge.name = (typeof name === 'string' ? name : '');
            }
          });
        this.goals = response.goals;
        this.generateGoalNames();
        localStorage.setItem("challengList", JSON.stringify(this.challenges));
      });
    });
  }

  achieveGoal() {
    localStorage.removeItem("weeklyActivities");
    this.router.navigate(['member/skill-builder/goal']);
  }

  achieveGoalChallenges(goal) {
    localStorage.removeItem("weeklyActivities");
    if (goal.completed) {
      this.openModalstart(this.starttemplate);
      return;
    }
    this._storageSvc.setVolatileStorage(
      VOLATILE_KEYS.GOAL_PLAN_UUID, goal.uuid);
    this.router.navigate(['member/skill-builder/goal']);
  }

  trackGoal(goal) {
    localStorage.removeItem("weeklyActivities");
    if (goal.completed) {
      this.openModalstart(this.starttemplate);
      return;
    }
    this._storageSvc.setVolatileStorage(
      VOLATILE_KEYS.GOAL_PLAN_UUID, goal.uuid);
    this.router.navigate(['member/skill-builder/goal']);
  }

  endAchieveGoal(goal) {
    this.end(goal.uuid, goal.type || 'goal');
  }
}
