<div
  class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center">
  <div class="container px-0">
    <div class="positive-brain-list w-auto">
      <h4 class="w-auto text-center mb-0 px-3 mb-4">
        {{ page.title }}
      </h4>
      <div
        class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="card card-white card-white-sm">
              <div class="card-body">
                <h5>{{ page.subTitle }}</h5>
                <div class="pt-4">
                  <button
                    type="button"
                    (click)="choice = false"
                    class="btn btn-md ml-3 {{ choice === false ? 'btn-secondary' : 'btn-outline btn-outline-danger' }}">
                    {{ page.no }}
                  </button>
                  <ng-template #keepTryingTemplate>
                    <div class="modal-body text-center p-4">
                      <div>
                        <h5>
                          {{ page.keepTrying }}
                        </h5>
                      </div>
                      <div class="pt-4">
                        <button
                          (click)="closeModal()"
                          type="button"
                          class="btn btn-md ml-3 btn-primary">
                          {{ page.ok }}
                        </button>
                      </div>
                    </div>
                  </ng-template>
                  <button
                    type="button"
                    (click)="choice = true"
                    class="btn btn-md ml-3 {{ choice === true ? 'btn-secondary' : 'btn-outline btn-outline-danger' }}">
                    {{ page.yes }}
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="submitted && choice === null"  class="invalid-feedback">
              {{ page.error }}
            </div>
          </div>
        </div>
      </div>
     
      <div
        class="main-container w-100 d-flex align-items-center justify-content-center">
        <div class="footer-inner-action">
          <button
            type="button"
            (click)="next()"
            class="btn btn-primary btn-lg px-4">
            {{ page.next }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
