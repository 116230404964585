import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  private _authLogout: Function = () => {};
  private _logoutFunctions: Array<Function> = [];

  constructor() { }

  set authLogout(authLogout: Function) {
    this._authLogout = authLogout;
  }

  subscribe(func: Function): void {
    this._logoutFunctions.push(func);
  }

  logout(): void {
    this._authLogout();
    this._logoutFunctions.forEach(func => func());
  }
}
