<!-- Skill builder header -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="back()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === STRESS_PAGE.FEEL">
  <app-member-skill-builder-stress-feel [data]="data">
  </app-member-skill-builder-stress-feel>
</ng-container>
<ng-container *ngIf="data?.page === STRESS_PAGE.LOOK">
  <app-member-skill-builder-stress-look [data]="data">
  </app-member-skill-builder-stress-look>
</ng-container>
<ng-container *ngIf="data?.page === STRESS_PAGE.BRAIN">
  <app-member-skill-builder-stress-brain [data]="data">
  </app-member-skill-builder-stress-brain>
</ng-container>
<ng-container *ngIf="data?.page === STRESS_PAGE.DESTRESS">
  <app-member-skill-builder-stress-destress [data]="data">
  </app-member-skill-builder-stress-destress>
</ng-container>

<!-- Skill builder track flow -->
<ng-container *ngIf="data?.page === STRESS_PAGE.FEEL_TRACK">
  <app-member-skill-builder-stress-feel-track [data]="data">
  </app-member-skill-builder-stress-feel-track>
</ng-container>
<ng-container *ngIf="data?.page === STRESS_PAGE.IMPACT">
  <app-member-skill-builder-stress-impact [data]="data">
  </app-member-skill-builder-stress-impact>
</ng-container>
<ng-container *ngIf="data?.page === STRESS_PAGE.CHANGE_PLAN">
  <app-member-skill-builder-stress-change-plan [data]="data">
  </app-member-skill-builder-stress-change-plan>
</ng-container>
