import { Component, Input, OnInit } from '@angular/core';
import { ReflectionData } from '../common';
import {
  Domain2Service,
  LanguageService,
  StorageService,
  TEMP_KEYS
} from '@services/public';
import { Router } from '@angular/router';
import {
  ReflectionService,
  ReflectionServiceCreateLogResponse
} from '@services/member';
import { APIErrors, isAPIErrors } from '@util';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.summary';

@Component({
  selector: 'app-member-skill-builder-reflection-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class MemberSkillBuilderReflectionSummaryComponent implements OnInit {

  @Input() data: ReflectionData

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    summary: '',
    submit: ''
  };

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _reflectionSvc: ReflectionService,
    private _router: Router,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        for (const key in value[PAGE_PATH]) {
          switch (key) {
            case 'summary': {
              if (this.data.domainUID === 0) break;
              console.debug(this.data);
              this._languageSvc.template(
                value[PAGE_PATH][key],
                {
                  domain: this._domainSvc.getDomain(
                    this.data.domainUID
                  ).name.toLowerCase(),
                  strengths: this._toList(this.data.strengths).toLowerCase(),
                  weaknesses: this._toList(this.data.weaknesses).toLowerCase(),
                  impacts: this._toList(this.data.impacts).toLowerCase(),
                  skill: this.data.skill.toLowerCase(),
                  action: this.data.action.toLowerCase()
                }
              ).then(value => this.page.summary = value);
              break;
            }
            case 'summaryOnboarding': {
              if (this.data.domainUID !== 0) break;
              this._languageSvc.template(
                value[PAGE_PATH][key],
                {
                  strengths: this._toList(this.data.strengths).toLowerCase(),
                  weaknesses: this._toList(this.data.weaknesses).toLowerCase(),
                  impacts: this._toList(this.data.impacts).toLowerCase(),
                  skill: this.data.skill.toLowerCase(),
                  action: this.data.action.toLowerCase()
                }
              ).then(value => this.page.summary = value);
              break;
            }
            default: {
              this._languageSvc.template(
                value[PAGE_PATH][key]
              ).then(value => this.page[key] = value);
            }
          }
        }
      });
  }

  submitOnClick(): void {
    this._reflectionSvc.createLog(this.data).subscribe(
      (res: ReflectionServiceCreateLogResponse | APIErrors) => {
        if (!isAPIErrors(res)) {
          if (this.data.domainUID === 0)
            this._storageSvc.setTempStorage(
              TEMP_KEYS.IS_REWARDS_ORIENTATION, true);
          this._router.navigate(['member/dashboard/rewards']);
        }
      }
    );
  }

  private _toList(value: Array<string | number>): string {
    // Copying array.
    value = [...value];

    // Convert skill UIDs to names.
    for (let i = 0; i < value.length; i++)
      if (typeof value[i] === 'number')
        value[i] = this._domainSvc.getSkill(value[i]).name;

    // Convert string array to a list.
    if (value.length === 0) return '';
    if (value.length === 1) return <string>value[0];
    if (value.length === 2) return value.join(' and ');
    return value.slice(0, -1).concat(`and ${value.slice(-1)}`).join(', ');
  }

}
