import { Component, Input, OnInit } from '@angular/core';
import {
  LABELS,
  LanguageService,
  StorageService,
  TEMP_KEYS
} from '@services/public';
import { ABSTRACT_PAGE, AbstractData } from '../common';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.positiveImpact';

@Component({
  selector: 'app-member-skill-builder-abstract-positive-impact',
  templateUrl: './positive-impact.component.html',
  styleUrls: ['./positive-impact.component.scss']
})
export class MemberSkillBuilderAbstractPositiveImpactComponent
  implements OnInit
{

  @Input() data: AbstractData;
  extremeTitles: Array<string> = [];
  bigTitles: Array<string> = [];
  submitted: boolean = false;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subtitle: '',
    howPositive: '',
    extremeTitle: '',
    neutral: '',
    veryPositive: '',
    howBig: '',
    bigTitle: '',
    small: '',
    large: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.NEXT]: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'extremeTitle':
              for (
                let i: number = 0; i < this.data.positiveReasons.length; i++
              ) {
                this._languageSvc.template(
                  this.page[key],
                  { reason: this.data.positiveReasons[i].display }
                ).then(value => { this.extremeTitles[i] = value; });
              }
              break;
            case 'bigTitle':
              for (
                let i: number = 0; i < this.data.positiveReasons.length; i++
              ) {
                this._languageSvc.template(
                  this.page[key],
                  { reason: this.data.positiveReasons[i].display }
                ).then(value => { this.bigTitles[i] = value; });
              }
              break;
            default:
              this._languageSvc.template(this.page[key]).then(
                value => { this.page[key] = value });
          }
        }
      }
    );
    this._languageSvc.getLabels(this.labels);
  }

  onChange(index: number, key: 'extreme' | 'big', value: number) {
    this.data.positiveReasons[index][key] = value;
  }

  nextOnClick(): void {
    this.submitted = true;
    for (const reason of this.data.positiveReasons) {
      if (!reason.extreme || !reason.big) return;
    }
    this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, {
      ...this.data,
      page: ABSTRACT_PAGE.NEGATIVE_IMPACT
    });
  }

}
