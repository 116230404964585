
<div #carouselWrapper (touchstart)="posIni = $event.changedTouches[0].pageX"
(touchend)="move($event.changedTouches[0].pageX)" class="carousel-container hide animate__animated "
  [class.animate__fadeInLeft]="isCarouselVisible" [class.animate__fadeOutLeft]="!isCarouselVisible">
  <ngb-carousel #carousel class="challenge-carousel" [interval]="0">
     <ng-template ngbSlide *ngFor="let slide of slides; let index=index">
      <app-info-card [header]="slide.header" [body]="slide.body" [domain]="domain" [type]="type"></app-info-card>
     </ng-template>
  </ngb-carousel>
  <div  *ngIf="isCarouselVisible && (slides?.length == 1 || !!last_slide)" class="carousel-footer animate__animated
    {{type == 'reading'? 'bg-reading': 'bg-skb'}} {{slides?.length == 1 || !!last_slide? 'animate__slideInRight': 'animate__slideInLeft'}}">
    <div class="carousel-close button-pushable" role="button">
      <button #carouselClose class="button-shadow" (click)="showCarousel()">
        <span class="button-inner {{type == 'reading'? 'icon reading': 'icon skb'}} "></span>
        <span class="button-front text"></span>
      </button>
    </div>
   
  </div>
  
</div>
