import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { AssessmentScores } from '../assessment-scores.service';
import { User } from '@services/viewer';
interface labels {
  self?: string;
  peer?: string;
  skillLevel?: string;
}
@Injectable({
  providedIn: 'root'
})
export class SelfVsPeerByUserService {

  constructor() { }

  private get EMPTY_REPORT(): ChartConfiguration {
    return {
      type: 'bar',
      data: {
        labels: [],
        datasets:[
          {
            label: 'Self',
            data: [],
            borderRadius: 5,
            backgroundColor: '#3CE986'
          },
          {
            label: 'Peer',
            data: [],
            borderRadius: 5,
            backgroundColor: '#74A2FF'
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'top',
            align: 'end',
            labels: {
              useBorderRadius: true,
              borderRadius: 5.5,
              boxWidth: 10,
              boxHeight:10
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          y: {
            grid: {
              display: false
            },
          },
          x: {
            min: 0,
            max: 5,
            ticks:{
              stepSize: 0.5
            },
            title:{
              display:true,
              text: "Skill Level",
              color: "#757575"
            }
          }
        }
      }
    };
  }

  // Page language.
  page: {[key: string]: string} = {
  }

  generateReport(scores: AssessmentScores, users: Array<User>, chartlabels: labels):
      ChartConfiguration {
    const labels: Array<string> = [];
    const selfData: Array<number> = [];
    const peerData: Array<number> = [];

    // Filter to the most recent self data per user.
    const compiledScores = {}
    scores.self.forEach(
      ({userUUID, genericName, skillUID, date, score}) => {
        if (!compiledScores[userUUID])
          compiledScores[userUUID] = {genericName, skills: {}};
        if (!compiledScores[userUUID].skills[skillUID])
          compiledScores[userUUID].skills[skillUID] = {};
        if (!compiledScores[userUUID].skills[skillUID].self)
          compiledScores[userUUID].skills[skillUID].self = { date, score };
        else if (new Date(date) >
          new Date(compiledScores[userUUID].skills[skillUID].self.date))
          compiledScores[userUUID].skills[skillUID].self = { date, score };
      });

    // Sort the historical peer data per user.
    scores.peer.forEach(
      ({userUUID, genericName, skillUID, score}) => {
        if (!compiledScores[userUUID])
          compiledScores[userUUID] = {genericName, skills: {}};
        if (!compiledScores[userUUID].skills[skillUID])
          compiledScores[userUUID].skills[skillUID] = {};
        if (!compiledScores[userUUID].skills[skillUID].peer)
          compiledScores[userUUID].skills[skillUID].peer = [score];
        else compiledScores[userUUID].skills[skillUID].peer.push(score);
      });

    // Calculate labels and scores.
    for (const userUUID in compiledScores) {
      const user: User = users.find(user => user.uuid === userUUID);
      labels.push(!user ? compiledScores[userUUID].genericName : user.name);
      const selfScores = Object.values(compiledScores[userUUID].skills).map(
        (skill: any) => !skill.self ? null : skill.self.score)
        .filter(score => score !== null);
      if (!selfScores.length) selfData.push(null);
      else selfData.push(+(selfScores.reduce((a, b) => a + b, 0) /
        selfScores.length).toFixed(1));
      const peerScores = Object.values(compiledScores[userUUID].skills).map(
        (skill: any) => !skill.peer ? null :
          skill.peer.reduce((a, b) => a + b, 0) / skill.peer.length)
        .filter(score => score !== null);
      if (!peerScores.length) peerData.push(null);
      else peerData.push(+(peerScores.reduce((a, b) => a + b, 0) /
        peerScores.length).toFixed(1));
    }

    // Update chart configuration.
    let report: ChartConfiguration = this.EMPTY_REPORT;
    report.data.labels = labels;
    report.data.datasets[0].data = selfData;
    report.data.datasets[0].label = chartlabels.self;
    report.data.datasets[1].data = peerData;
    report.data.datasets[1].label = chartlabels.peer;
    report.options = {
      ...report.options, scales: {...report.options.scales,
        x: {...report.options.scales.x,
          title: {
            display:true,
            text: chartlabels.skillLevel,
            color: "#757575"
          }
        }
      }as any
    }
    return report;
  }
}
