import { Component, Input, OnInit } from '@angular/core';
import { BRAIN_PAGE, BrainData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrainService } from '@services/member/skill-builder/brain.service';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.brain.pages.impact';

@Component({
  selector: 'app-member-skill-builder-brain-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class MemberSkillBuilderBrainImpactComponent implements OnInit {

  @Input() data: BrainData;
  choice: boolean | null = null;
  form: FormGroup;
  ratingValue: number;
  submitted = false;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    no: '',
    yes: '',
    error1: '',
    ratingTitle: '',
    noImpact: '',
    bigImpact: '',
    error2: '',
    next: ''
  }

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService,
    private _brainSvc: BrainService
  ) {}

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
        }
      });

      this.form = new FormGroup({
        rating: new FormControl(this.data?.impact || '', Validators.required)
      });
  }

  next() {
    this.submitted = true;
    if (this.choice === null || (this.choice && !this.form.valid)) return;

    const data: BrainData = Object.assign({}, this.data, {
      completed: this.choice,
      impact: this.form.controls.rating.value || null
    });
    this._brainSvc.updateLog(data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this._storageSvc.setTempStorage(TEMP_KEYS.BRAIN,
            {
              ...this.data,
              page: BRAIN_PAGE.AFFIRMATIONS,
              doTomorrow: null
            });
        }
      });
  }

}
