import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GoalData,
  GoalReportData
} from '@pages/member/skill-builder/goal/common';
import { APIErrors, APINoData, APISuccess } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

export interface GoalServiceCreatePlanResponse {
  goalPlanUUID: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoalService {

  constructor(private http: HttpClient) { }

  createPlan(
    data: GoalData
  ): Observable<GoalServiceCreatePlanResponse | APIErrors> {
    return this.http.post<GoalServiceCreatePlanResponse | APIErrors>(
      `${config.apiBase}member/challenge/goal/plan/create`,
      {
        domainUID: data.domainUID,
        skill: data.skill,
        action: data.action,
        skillBuilderType: data.skillBuilderType
      });
  }

  createLog(data: GoalData): Observable<APISuccess | APIErrors> {
    return this.http.post<APISuccess | APIErrors>(
      `${config.apiBase}member/challenge/goal/log/create`,
      {
        goalPlanUUID: data.goalPlanUUID,
        completed: data.completed,
        help: data.help
      });
  }

  getReport(): Observable<Array<GoalReportData> | APINoData> {
    return this.http.get<Array<GoalReportData> | APINoData>(
      `${config.apiBase}member/challenge/goal/report`);
  }

}
