<div class="modal-header px-0 pr-4 pt-md-3 pb-3">
  <h4 class="modal-title d-flex align-items-center justify-content-center">
    <label class="lbl-md pl-4 mb-0">{{ dialogTitle }}</label>
  </h4>
  <button type="button" class="btn-close close pull-right" [attr.aria-label]="labels?.close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="customStepper">
  <div class="row">
    <div class="col-md-4">
      <div class="input-group">
        <input type="text" name="search" [ngModel]="searchText" (ngModelChange)="searchKey($event)"
          class="form-control" [placeholder]="pageLanguage?.placeholder">
        <img src="../../../../../assets/img/search-icon.svg" class="search-icon" alt="{{labels?.search}}" />
      </div>
    </div>
  </div>
  <div
  class="d-flex justify-content-between align-items-center pt-3 py-2 w-100">
</div>
  <div class="userGroups mb-3">
    <div class="row">
      <div
        class="col-md-6 pl-4  {{ i % 2 == 0 ? 'divider-rgt' : '' }}"
        *ngFor="let record of records; let i = index" [formGroup]="formGroup">
        <label class="container-chk">
          {{ record.name }}
          <input
            type="checkbox"
            [(ngModel)]="record.checked"
            name="list_name"
            value="{{ record.uuid }}" 
            formControlName="recordList"
            (change)="isAllSelected()"
            />
          <span class="checkmark"></span>
        </label>
      </div>
      <ng-container *ngIf="records.length == 0">       
       {{pageLanguage?.noRecord}}
    </ng-container>
    </div>
  </div>

 
</div>
<div class="d-flex justify-content-center align-items-center border-top pt-3 mb-3">
  <button (click)="decline()" type="button" class="btn btn-md">
   {{labels?.cancels}}
  </button>
  <button type="button"  class="btn btn-md btn-primary"[disabled]="formGroup.invalid && checkedList.length == 0 " (click)="onSendRequest()">
    {{pageLanguage?.requestJoin}}
  </button>
</div>
