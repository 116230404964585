
<div class="d-flex align-items-center justify-content-end w-100">
    <small >{{pageLanguage?.assessmentInstructionPopup.modalTitle}}</small>
    <span>
      <button
        type="button"
        class="d-flex btn-link info-modal-btn align-items-center"
        (click)="openModal(template)"
      >
      <span  type="button"  triggers="click">
      <img src="./../../../../assets/img/Info-icon.svg" />
    </span>
      </button>
    </span>
      <ng-template #template>
        <div class="modal-body px-0">
            <div class="d-flex align-items-center justify-content-center pt-4">
              <span class="circle-btn-lg circle-btn-bordered info-btn d-flex align-items-center justify-content-center ml-0"
                ><img src="./../../../../assets/img/info-blue.svg" style="width: 24px; height: 24px;" /></span>
            </div>  
          <div class="col-12 mt-4 mb-1 px-4">
            <p class="text-center" *ngIf="!isSelf">
                {{pageLanguage?.assessmentInstructionPopup.instructions.notSelf}}
            </p>
            <p class="text-center"  *ngIf="isSelf">
              {{pageLanguage?.assessmentInstructionPopup.instructions.self}}
            </p>
            <div class="d-flex align-items-center justify-content-center border-top pt-3">
              <button
                type="button"
                class="btn btn-md btn-primary" (click)=" confirm()">
               {{labels?.ok}}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    
  