<div class="modal-header px-0 pr-4 pt-md-3 pb-3">
  <h1 class="modal-title d-flex align-items-center justify-content-center">
    <label class="lbl-md pl-3 mb-0">{{pageLanguage?.label}}</label>
  </h1>
</div>
<div class="modal-body modal-center px-0 pt-0 modal-scroll">
  <div class="d-flex align-items-center justify-content-center">
    {{pageLanguage?.description}}
  </div>
</div>
<div>
  <div class="d-flex align-items-center justify-content-center">
    {{pageLanguage?.instructions}}
  </div>
  <div class="d-flex justify-content-center" style="padding: 20px;">
    <a href="https://jakapa.com/gateway-to-mastering-essential-soft-skills/" target="_blank">
      <button class="btn btn-primary">{{pageLanguage?.renewButton}}</button>
    </a>
    <button class="btn btn-primary" style="margin-left: 10px;" (click)="logout()">{{labels?.logout}}</button>
  </div>
</div>
