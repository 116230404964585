import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services/public';

const PATH = `pages.member.footer`;

@Component({
  selector: 'app-member-main-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class MemberMainFooterComponent implements OnInit{
  pageLanguage: any;

  constructor( private _languageSvc: LanguageService) { }

  ngOnInit(): void {
    this._languageSvc.get([PATH]).then((value) => {
      this.pageLanguage = value[PATH];
    });
  }

}
