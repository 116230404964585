import { Injectable } from '@angular/core';
import { config } from 'environment';
import { Observable, Subject } from 'rxjs';

export enum STORAGE_KEYS {
  CLIENT_ID = 'client-id',
  DEVICE_TOKEN = 'device-token',
  LANGUAGE = 'language',
  REFRESH_TOKEN = 'refresh-token',
  TEST = 'test'
}

export const MAIN_KEY_PREFIX = 'main.';
export enum MAIN_KEYS {
  ALL = 'all',
  CLEAR = 'clear',
  ADVENTURE_CHOOSE = 'adventure-choose',
  BRAIN_AFFIRMATIONS = 'brain-affirmations',
  DECISION_AFFECTS = 'decision-affects',
  GOAL_ACTIONS = 'goal-actions',
  GOAL_SKILLS = 'goal-skills',
  JOURNAL_FREQUENCY = 'journal-frequency',
  JOURNAL_ENJOYMENT = 'journal-enjoyment',
  JOURNAL_SOCIAL = 'journal-social',
  JOURNAL_CONFIDENCE = 'journal-confidence',
  JOURNAL_EMOTIONS = 'journal-emotions',
  JOURNAL_SYMPTOMS = 'journal-symptoms',
  JOURNAL_THOUGHTS = 'journal-thoughts',
  REFLECTION_IMPACTS = 'reflection-impacts',
  STRESS_FEEL = 'stress-feel',
  STRESS_LOOK = 'stress-look',
  STRESS_IMPACT = 'stress-impact',
  STRESS_DESTRESS = 'stress-destress'
}

export const TEMP_KEY_PREFIX = 'temp.';
export enum TEMP_KEYS {
  ALL = 'all',
  CLEAR = 'clear',
  NAME = 'name',
  AVATAR = 'avatar',
  IS_GHOST = 'is-ghost',
  IS_FIRST_LOGIN = 'is-first-login',
  ABSTRACT = 'abstract',
  ADVENTURE = 'adventure',
  BRAIN = 'brain',
  DECISION = 'decision',
  GOAL = 'goal',
  JOURNAL = 'journal',
  MESSAGE = 'message',
  REFLECTION = 'reflection',
  STRESS = 'stress',
  WWYD = 'wwyd',
  LANGUAGE = 'language',
  IS_FIRST_SELF_ASSESSMENT = 'is-first-self-assessment',
  IS_REWARDS_ORIENTATION = 'is-rewards-orientation'
}

export enum VOLATILE_KEYS {
  DOMAIN_UID = 'domain-uid',
  GOAL_PLAN_UUID = 'goal-plan-uuid'
}

export interface Update {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _userUUID: string = null;
  private _mainStorage: Storage = localStorage;
  private _tempStorage: Storage = sessionStorage;
  private _updates: Subject<Update> = new Subject<Update>();
  private _volatileStorage: { [key: string]: any } = {};

  constructor() {
    // Use session storage for main storage if necessary.
    try {
      localStorage.setItem(STORAGE_KEYS.TEST, STORAGE_KEYS.TEST);
      localStorage.removeItem(STORAGE_KEYS.TEST);
    } catch (e) {
      this._mainStorage = sessionStorage;
    }

    // Event triggers only for main storage as long as no internal iframes are
    // used.
    addEventListener('storage', event => {
      if (!event.key)
        this._updates.next(
          {key: MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR, value: null});
      if (event.key !== MAIN_KEY_PREFIX + this._userUUID) return;
      const oldValue = event.oldValue || {};
      const newValue = event.newValue || {};
      for (const key in newValue)
        if (!this._deepEqual(oldValue[key], newValue[key]))
          this._updates.next(
            {key: MAIN_KEY_PREFIX + key, value: newValue[key]});
      for (const key in oldValue)
        if (typeof newValue[key] === 'undefined')
          this._updates.next(
            {key: MAIN_KEY_PREFIX + key, value: newValue[key]});
    });
  }

  private _deepEqual(a: any, b: any): boolean {
    if (typeof a !== 'object') return a === b;
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (const key in a) if (!this._deepEqual(a[key], b[key])) return false;
    return true;
  }

  get userUUID(): string {
    return this._userUUID;
  }

  set userUUID(uuid: string) {
    if (uuid === this._userUUID) return;
    if (!!this._userUUID) this.clearTempStorage();
    this._userUUID = uuid;
    this._updates.next({
      key: MAIN_KEY_PREFIX + MAIN_KEYS.ALL,
      value: this._getMainCache()
    });
    this._updates.next({
      key: TEMP_KEY_PREFIX + TEMP_KEYS.ALL,
      value: this._getTempCache()
    });
  }

  get updates(): Observable<Update> {
    return this._updates.asObservable();
  }

  private _getMainCache(): any {
    const mainJSON: string =
      this._mainStorage.getItem(MAIN_KEY_PREFIX + this._userUUID);
    if (!mainJSON) return {};
    return JSON.parse(mainJSON);
  }

  getMainStorage(path: MAIN_KEYS): any {
    try {
      const paths: Array<string> = path.split('.');
      let value: any = this._getMainCache();
      for (let i: number = 0; i < paths.length; ++i)
        value = value[paths[i]];
      return value;
    } catch (e) {
      if (!config.production)
        console.error(`Unable to get main storage path '${path}'.`);
      return null;
    }
  }

  setMainStorage(path: MAIN_KEYS, value: any): void {
    if (!this._userUUID) {
      if (!config.production)
        console.error(`Attempting to set main storage without a user.`);
      return;
    }
    try {
      const paths: Array<string> = path.split('.');
      let cache: any = this._getMainCache();
      let location: any = cache;
      for (let i: number = 0; i < paths.length - 1; ++i)
        location = location[paths[i]];
      if (value === null) delete location[paths[paths.length - 1]];
      else location[paths[paths.length - 1]] = value;
      if (Object.keys(cache).length === 0)
        this._mainStorage.removeItem(MAIN_KEY_PREFIX + this._userUUID);
      else this._mainStorage.setItem(MAIN_KEY_PREFIX + this._userUUID,
        JSON.stringify(cache));
      this._updates.next({key: MAIN_KEY_PREFIX + path, value});
    } catch (e) {
      if (!config.production)
        console.error(`Unable to set main storage path '${path}'.`);
    }
  }

  clearMainStorage(): void {
    this._mainStorage.removeItem(MAIN_KEY_PREFIX + this._userUUID);
    this._updates.next({key: MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR, value: null});
  }

  private _getTempCache(): any {
    const tempJSON: string =
      this._tempStorage.getItem(TEMP_KEY_PREFIX + this._userUUID);
    if (!tempJSON) return {};
    return JSON.parse(tempJSON);
  }

  getTempStorage(path: TEMP_KEYS): any {
    try {
      const paths: Array<string> = path.split('.');
      let value: any = this._getTempCache();
      for (let i: number = 0; i < paths.length; ++i)
        value = value[paths[i]];
      return value;
    } catch (e) {
      if (!config.production)
        console.error(`Unable to get temp storage path '${path}'.`);
      return null;
    }
  }

  setTempStorage(path: TEMP_KEYS, value: any): void {
    if (!this._userUUID) {
      if (!config.production)
        console.error(`Attempting to set temp storage without a user.`);
      return;
    }
    try {
      const paths: Array<string> = path.split('.');
      let cache: any = this._getTempCache();
      let location: any = cache;
      for (let i: number = 0; i < paths.length - 1; ++i)
        location = location[paths[i]];
      if (value === null) delete location[paths[paths.length - 1]];
      else location[paths[paths.length - 1]] = value;
      if (Object.keys(cache).length === 0)
        this._tempStorage.removeItem(TEMP_KEY_PREFIX + this._userUUID);
      else this._tempStorage.setItem(TEMP_KEY_PREFIX + this._userUUID,
        JSON.stringify(cache));
      this._updates.next({key: TEMP_KEY_PREFIX + path, value});
    } catch (e) {
      if (!config.production)
        console.error(`Unable to set temp storage path '${path}'.`);
    }
  }

  clearTempStorage(): void {
    this._tempStorage.removeItem(TEMP_KEY_PREFIX + this._userUUID);
    this._updates.next({key: TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR, value: null});
  }

  getVolatileStorage(path: VOLATILE_KEYS, clear: boolean = false): any {
    try {
      const paths: Array<string> = path.split('.');
      let location: any = this._volatileStorage;
      for (let i: number = 0; i < paths.length - 1; ++i)
        location = location[paths[i]];
      const value = location[paths[paths.length - 1]];
      if (clear) delete location[paths[paths.length - 1]];
      return value;
    } catch (e) {
      if (!config.production)
        console.error(`Unable to get volatile storage path '${path}'.`);
      return null;
    }
  }

  setVolatileStorage(path: VOLATILE_KEYS, value: any): void {
    try {
      const paths: Array<string> = path.split('.');
      let location: any = this._volatileStorage;
      for (let i: number = 0; i < paths.length - 1; ++i)
        location = location[paths[i]];
      if (value === null) delete location[paths[paths.length - 1]];
      else location[paths[paths.length - 1]] = value;
    } catch (e) {
      if (!config.production)
        console.error(`Unable to set volatile storage path '${path}'.`);
    }
  }

  clearVolatileStorage(): void {
    this._volatileStorage = {};
  }

}
