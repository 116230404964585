import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { map } from "rxjs/operators";

import { MemberAssessmentExternalCreateOrUpdateComponent } from "@pages/member";
import { LoaderService, LanguageService} from "@services/public";
import {
  AssessmentService,
  ExternalAssessment,
  AssessmentStatus,
} from "@services/member/assessment.service";
import { Router } from "@angular/router";


@Component({
  selector: "app-member-assessment-external-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class MemberAssessmentExternalListComponent implements OnInit {
  AssessmentStatus: typeof AssessmentStatus = AssessmentStatus; // Expose this enum to the template.
  assessment: ExternalAssessment = {
    uuid: null,
    assessmentName: "-",
    firstName: "-",
    lastName: "-",
    email: "-",
    relationship: "-",
    startDate: "",
    status: null,
    skills: [],
  };

  assessments: Array<ExternalAssessment> = [];
  modalRef?: BsModalRef;
  message?: string;
  p: number = 1;

  // Language Translation
  pageLanguage: any;
  appLabels: any;
  basePath = `pages.member.assessment`;

  constructor(
    private assessmentSvc: AssessmentService,
    private modalSvc: BsModalService,
    private loaderSvc: LoaderService,
    private router: Router,
    private _languageSvc: LanguageService
  ) {}

  ngOnInit(): void {
    this.loadExternalList();
    const pageLanguagePath = this.basePath+`.external.pages`;
    this._languageSvc.get([pageLanguagePath]).then((value) => {
      this.pageLanguage = value[pageLanguagePath];
    });
    this._languageSvc.get([`labels`]).then((value)=> {
      this.appLabels = value[`labels`];
    });
  }

  loadExternalList() {
    // Load assessments.
  this.p = 1;
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .getExternals()
      .pipe(
        map((response: any) => {
          if (!!response) this.assessments = response;
          this.assessments.forEach((assessment) => {
            const propertiesToCapitalize = [
              "assessmentName",
              "firstName",
              "lastName",
            ];
            propertiesToCapitalize.forEach((property) => {
              if (assessment[property]) {
                assessment[property] = this.capitalizeFirstLetter(
                  assessment[property]
                );
              }
            });
          });
          this.assessments.sort((a, b) => {
            if (a.status === b.status) return 0;
            if (a.status === AssessmentStatus.INACTIVE) return -1;
            if (a.status === AssessmentStatus.COMPLETED) return 1;
            if (b.status === AssessmentStatus.INACTIVE) return 1;
            return -1;
          });
          this.loaderSvc.removeLoader(loader);
        }).bind(this)
      )
      .subscribe();
  }

  capitalizeFirstLetter(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  onCreateExternalAssessmentClick(): void {
    this.modalRef = this.modalSvc.show(
      MemberAssessmentExternalCreateOrUpdateComponent,
      {
        class: "modal-md modal-dialog-centered",
        backdrop: "static",
        keyboard: true,
      }
    );
  }

  deleteAssessment(assessment): void {
    // Delete assessment and reload.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .deleteExternal(assessment.uuid)
      .pipe(
        map((response: any) => {
          this.loadExternalList();
          this.loaderSvc.removeLoader(loader);
        }).bind(this)
      )
      .subscribe();
    this.modalRef?.hide();
  }

  resendAssessment(assessment): void {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .resendExternal(assessment.uuid)
      .pipe(
        map((response: any) => {
          this.loadExternalList();
          this.loaderSvc.removeLoader(loader);
        }).bind(this)
      )
      .subscribe();
  }

  decline(): void {
    this.message = "Declined!";
    this.modalRef?.hide();
  }
  
  viewAssessment(assessment): void {
    this.router.navigate([
      "/member/assessment/external/report",
      assessment.uuid,
    ]);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }
}
