import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Group, GroupService, ReportService, SelfAwareness, StrengthsAndWeaknesses } from '@services/member';
import { Domain, DomainService, LABELS, LanguageService } from '@services/public';
import { Chart, ChartEvent } from 'chart.js';
import { Subscription } from 'rxjs';

const PAGE_PATH: string = 'pages.member.dashboard.reports.page';

@Component({
  selector: 'app-member-dashboard-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class MemberDashboardReportsComponent implements OnInit, OnDestroy {

  @ViewChild("myCanvas", { read: ElementRef }) myCanvas: ElementRef;
  @ViewChild("lineChart", { read: ElementRef }) lineChart: ElementRef;

  pageLanguage: any;
  topSkills: Array<any> = [];

  // Page filters.
  selectedDomain: Domain | null = null;
  selectedTimespan: string = "all time";
  selectedGroup: Group | null = null;
  selectedGrowthDomain: Domain | null = null;

  // Local data storage from API calls.
  domains: Array<Domain> = [];
  groups: Array<Group> = [];

  // Loading flags.
  groupsLoaded = false;

  // Report data.
  selfVsPeer: Chart = null;
  strengthsAndWeaknesses: Array<StrengthsAndWeaknesses> = [];
  selfAwareness: Array<SelfAwareness> = [];
  growth: Chart = null;
  isPeerDataAvailable: boolean = false;

  // Chart plugins.
  _clickDomainPlugin: any = null;

  // Page subscriptions.
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    assessments: '',
    topSkills: '',
    title: '',
    selfVsPeer: '',
    selfVsPeerInfo: '',
    compareYourSkills: '',
    showAllDomains: '',
    strengthsAndWeaknesses: '',
    strengthsAndWeaknessesInfo: '',
    recognizeStrengthsAndWeaknesses: '',
    selfAwareness: '',
    selfAwarenessInfo: '',
    seeYourRatings: '',
    aligned: '',
    partialAligned: '',
    notAligned: '',
    growth: '',
    growthInfo: '',
    seeYourGrowth: '',
    skillBuilders: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.ALL_DOMAINS]: '',
    [LABELS.ALL_GROUPS]: '',
    [LABELS.ALL_TIME]: '',
    [LABELS.DAYS]: '',
    [LABELS.GOOD]: '',
    [LABELS.IMPROVEMENT]: '',
    [LABELS.INSUFFICIENT_DATA]: '',
    [LABELS.OK]: '',
    [LABELS.YEAR]: '',
    [LABELS.YEARS]: ''
  }

  constructor(
    private _domainSvc: DomainService,
    private _groupSvc: GroupService,
    private _languageSvc: LanguageService,
    private _reportSvc: ReportService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value
          );
        }
      }
    );
    this._languageSvc.getLabels(this.labels);

    // Initialize click domain plugin.
    this._clickDomainPlugin = {
      id: "yAxisCustomClick",
      afterEvent: (
        chart: Chart<"bar">,
        event: {
          event: ChartEvent;
          replay: boolean;
          changed?: boolean | undefined;
          cancelable: false;
          inChartArea: false;
        }
      ) => {
        const evt = event.event;
        if (
          evt.type === "click" &&
          evt.x! <
            Object.values(chart.scales)
              .filter((s) => s.id === "x")[0]
              .getBasePixel()
        ) {
          const labelIndex = Object.values(chart.scales)
            .filter((s) => s.id === "y")[0]
            .getValueForPixel(evt.y!);
          const label = Object.values(chart.scales)
            .filter((s) => s.id === "y")[0]
            .getTicks()[labelIndex!]?.label;
          this.updateDashboardDomain(label);
        }
      },
    };

    // Load groups.
    this._subscriptions.add(
      this._groupSvc.groups.subscribe((next) => {
        this.groups = next.filter((group) => group.member);
        if (!this.groups.includes(this.selectedGroup) && !!this.selectedGroup)
          this.selectedGroup = null;
        this.groupsLoaded = true;
        if (!!this.selectedGrowthDomain) this.updateFilters();
      })
    );

    // Load domains.
    this._domainSvc.getDomains().then((response) => {
      this.domains = response;
      this.selectedGrowthDomain = this.domains[0];
      if (this.groupsLoaded) this.updateFilters();
    });

    // Load assessment charts.
    this._subscriptions.add(
      this._reportSvc.selfVsPeer.subscribe((next) => {
        if (!!this.selfVsPeer) this.selfVsPeer.destroy();
        if (this.myCanvas) {
          this.selfVsPeer = new Chart(
            this.myCanvas.nativeElement,
            Object.assign(next, { plugins: [this._clickDomainPlugin] })
          );
        }
        this.isPeerDataAvailable = this._reportSvc.hasPeerData;
      })
    );
    this._subscriptions.add(
      this._reportSvc.strengthsAndWeaknesses.subscribe(
        (next) => (this.strengthsAndWeaknesses = next)
      )
    );
    this._subscriptions.add(
      this._reportSvc.selfAwareness.subscribe(
        (next) => (this.selfAwareness = next)
      )
    );
    this._subscriptions.add(
      this._reportSvc.growth.subscribe((next) => {
        if (!!this.growth) this.growth.destroy();
        if (this.lineChart)
          this.growth = new Chart(this.lineChart.nativeElement, next);
      })
    );
    this._subscriptions.add(
      this._reportSvc.topSkills.subscribe((next) => (this.topSkills = next))
    );
  }

  // Filter charts with click on label
  updateDashboardDomain(newDomain: any) {
    this.selectedDomain = this.domains.find((ele) => ele.name == newDomain);
    if (!this.selectedDomain) this.selectedDomain = null;
    this.updateFilters();
  }

  updateFilters(): void {
    this.selectedGrowthDomain =
      this.selectedDomain === null
        ? this.selectedGrowthDomain
        : this.selectedDomain;
    this._reportSvc.updateFilters(
      this.selectedDomain,
      this.selectedGroup,
      this.selectedTimespan,
      this.selectedGrowthDomain
    );
  }

  updateGrowthFilters(): void {
    this._reportSvc.updateGrowthFilters(this.selectedGrowthDomain);
  }

  getLogo(uid) {
    if (uid) {
      return this._domainSvc.getLogo(parseInt(uid.toString().substring(0, 1)));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
