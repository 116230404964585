import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { LanguageService } from '@services/public';

@Component({
  selector: 'app-first-login-modal',
  templateUrl: './first-login-modal.component.html',
  styleUrls: ['./first-login-modal.component.scss']
})
export class FirstLoginModalComponent implements OnInit {
  message?: string;
  pageLanguage: any;
  labels: any;
  path: string = `pages.member.firstLoginModal`

  constructor(
    private _languageSvc: LanguageService,
    public modalRef?: BsModalRef
    ) { }

  ngOnInit(): void {
    this._languageSvc.get([this.path]).then((value) => {
      this.pageLanguage = value[this.path];
    });
    this._languageSvc.get([`labels`]).then((value) => {
      this.labels = value[`labels`];
    });
  }

}
