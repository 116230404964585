<div>
  <div *ngIf="modalInfo.title !== ''" class="modal-header px-0 pr-4 pt-md-3 pb-3 weekly-challenge text-center">
    <h1 class="modal-title d-flex align-items-center justify-content-center">
      <label class="lbl-md pl-3 mb-0">{{modalInfo.title}}</label>
    </h1>
    <button #modalCloseButton type="button" class="btn-close close pull-right d-none d-lg-flex" aria-label="Close" (click)="hideModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-center px-0 pt-0 {{modalInfo.pdfSrc ? '' : 'modal-scroll'}} text-center">
    <div *ngIf="modalInfo.subTitle || modalInfo.message || modalInfo.linkDestination && modalInfo.linkText" class="col-12 mt-4 mb-0 px-4">
      <div class="row pt-3">
        <div *ngIf="modalInfo.subTitle" class="col-12 headline"><h2>{{modalInfo.subTitle}}</h2></div>
        <div *ngIf="modalInfo.message"class="col-12"><p>{{modalInfo.message}}</p></div>
        <div *ngIf="modalInfo.linkDestination && modalInfo.linkText"class="col-12"><p><a href={{modalInfo.linkDestination}}>{{modalInfo.linkText}}</a></p></div>
      </div>
    </div>
    <div *ngIf="modalInfo.videoSrc">
      <app-video-viewer [challengeID]="challengeID" [videoSrc]="modalInfo.videoSrc" [transcript]="modalInfo.transcript"></app-video-viewer>
    </div>
    <div *ngIf="modalInfo.pdfSrc" class="pdf-viewer p-3">
      <div *ngIf="isLoading" class="loading"></div>

        <pdf-viewer [src]="modalInfo.pdfSrc"
        [render-text]="true"
        [original-size]="false"
        [(zoom)]="zoomLevel"
        (after-load-complete)="isLoading = false"
        style="width: 100%; height: 500px;"
      ></pdf-viewer>
    </div>
  </div>
</div>
<div *ngIf="modalInfo.pdfSrc" class="zoomButtons">
  <button type="button" class="zoomIn" (click)="zoomIn()"> 
    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#FFFFFF">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
      <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"/>
    </svg>
   </button>
  <button type="button" class="zoomOut" (click)="zoomOut()">
    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#FFFFFF">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>
  </button>
</div>
<div id="{{modalInfo.pdfSrc? 'modal-button': ''}}" class="pt-0 d-flex d-lg-none align-items-center justify-content-center">
  <button (click)="hideModal()" type="button" class="btn btn-md">
    Close
  </button>
</div>
