import { Component } from '@angular/core';

@Component({
  selector: 'app-member-dashboard-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class MemberDashboardActivitiesComponent {

  constructor() { }

}
