<div
  class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center">
  <div class="container px-0 w-sm-100 w-50">
    <div class="positive-brain-list w-auto">
      <div class="col-12 d-flex justify-content-center mt-3">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <span
            class="d-flex justify-content-center small text-center mt-2 sm-center-txt">
            {{ page.title }}
          </span>
          <div class="row justify-content-center mt-3">
            <div class="col-12">
              <div class="card card-white card-white-sm">
                <div class="card-body">
                  <div class="col-12 d-flex">
                    <div class="col-auto dotted-line-v position-relative">
                      <div class="dot-sm-v"></div>
                      <i class="fas fa-chevron-down down-arrow"></i>
                    </div>
                    <div class="col-8 ranking-wrapper">
                      <span class="d-flex small text-center sm-center-txt">
                        {{ page.highest }}
                      </span>
                      <div>
                        <ul class="pt-3" >
                          <li
                            class="font-weight-bold"
                            *ngFor="let action of actions">
                            {{action.action}}
                          </li>
                        </ul>
                      </div>
                      <span class="d-flex small text-center sm-center-txt">
                        {{ page.lowest }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-inner-action">
        <button
          type="button"
          (click)="nextOnClick()"
          class="btn btn-primary btn-lg px-4">
          {{ page.submit }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #reminder>
  <div class="modal-body modal-center">
    <p class="mt-3 text-center success-msg px-5 title-main">
      {{ page.reminder }}
    </p>
    <div class="pt-4 d-flex align-items-center justify-content-center">
      <button
        (click)="closeReminder()"
        type="button"
        class="btn btn-md btn-bordered-purple">
        {{ page.ok }}
      </button>
    </div>
  </div>
</ng-template>
