export const VALIDATIONS = {
  firstName: {
    required: "First Name is required",
    maxLength: "First Name must not exceed more that 40 characters.",
    firstName: "Name is invalid.",
  },
  lastName: {
    required: "Last Name is required",
    maxLength: "Last Name must not exceed more that 40 characters.",
    lastName: "Name is invalid.",
  },
  email: {
    required: "Email is required",
    maxLength: "Email must not exceed more that 200 characters.",
    email: "Email is invalid.",
  },
  organization: {
    required: "Organization is required.",
  },

  date: {
    required: "Date is required.",
  },
  groupName: {
    required: "Group Name is required.",
  },
  tagName: {
    required: "Tag Name is required.",
  },
  typeTag: {
    required: "Type is required.",
  },
  types: {
    required: "Only CSV file required",
  },
  fileSize: {
    required: "The file size should not be greater than 10KB.",
  },
  uploadOtherFile: {
    required: "Please upload a valid CSV file.",
  },
  emailVerify: {
    required: "Please verify email id in CSV file.",
  },
  fileEmpty: {
    required: "The CSV file is empty.",
  },
  missingFiled: {
    required: "Please upload a valid CSV file.",
  },
};

export const SUCCESSMESSAGE = {
  group: {
    deleteMessage:
      "Are you sure you want to delete this group? This will permanently remove the data from your organization. We highly recommend just archiving the group if the group is no longer active.",
    deleteSuccess: "Group deleted Successfully",
    unarchivedMessage: "Group activated Successfully",
    archivedMessage: "Group archived Successfully",
  },

  tag: {
    deleteMessage:
      "Are you sure you want to delete this tag? This will permanently remove the data from your organization. We highly recommend just archiving the tag if the tag is no longer active.",
    deleteSuccess: "Tag deleted Successfully",
    unarchivedMessage: "Tag activated Successfully",
    archivedMessage: "Tag archived Successfully",
  },

  user: {
    deleteMessage:
      "Are you sure you want to remove this user from the selected group/tag?",
    deleteSuccess: "User deleted Successfully",
  },

  joinRequest: {
    requestSentSuccess: "Request Sent Successfully",
  },
  joinGroup: {
    deleteSuccess: "User has left the Group Successfully",
  },
  joinTag: {
    deleteSuccess: "User has left the Tag Successfully",
  },
};
