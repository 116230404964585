import { Component, OnInit, OnDestroy, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { LeaderAssessmentCreateComponent } from "@pages/leader/assessment/create/create.component";
import { LoaderService, LanguageService } from "@services/public";

import {
  AssessmentService,
  Assessment,
  AssessmentStatus,
} from "@services/leader";
import { Group, GroupService } from "@services/member";

const PAGE_PATH = `pages.leader.assessment.list`;

@Component({
  selector: "app-leader-assessment-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class LeaderAssessmentListComponent implements OnInit, OnDestroy {
  groups: Array<Group> = [];
  assessment: any;
  modalRef?: BsModalRef;
  message?: string;
  rowData: any;
  deleteAss: any;
  private _subscriptions: Subscription = new Subscription();
  selectedGroup: BehaviorSubject<Group | null> =
    new BehaviorSubject<Group | null>(null);
  allAssessments: Array<Assessment> = null;
  assessments: Array<Assessment> = [];
  p: number = 1;
   // Page language.
   page: {[key: string]: string} = {
   }

  constructor(
    private groupSvc: GroupService,
    private assessmentSvc: AssessmentService,
    private router: Router,
    private modalSvc: BsModalService,
    private loaderSvc: LoaderService,
    private _languageSvc: LanguageService
  ) {}

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    // Load groups.
    this._subscriptions.add(
      this.groupSvc.groups.subscribe((next) => {
        this.groups = next;
        this.selectedGroup.next(null);
        this.loadAssessmentList();
      })
    );
    this.groupSvc.loadGroups();

    // Load all assessments.
    this.selectedGroup.subscribe((_next) => this.loadAssessments());
  }
  loadAssessmentList() {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .getAssessments()
      .pipe(
        map((response: any) => {
          if (!!response) {
            response.map((assessment) => {
              const group = this.groups.find(
                (group) => group.uuid === assessment.groupUUID
              );
              assessment.group = group;
            });
            this.allAssessments = response;
            this.allAssessments.sort((a, b) => {
              if (a.status === b.status) return 0;
              if (a.status === AssessmentStatus.INACTIVE) return -1;
              if (a.status === AssessmentStatus.COMPLETED) return 1;
              if (a.status === AssessmentStatus.SCHEDULED) {
                if (b.status === AssessmentStatus.COMPLETED) return -1;
                return 1;
              }
              if (b.status === AssessmentStatus.INACTIVE) return 1;
              return -1;
            });
            this.allAssessments.map((ele) => {
              if (ele.group?.name) {
                ele.group.name = this.capitalizeFirstLetter(ele.group.name);
              }
            });
            this.loadAssessments();
            this.loaderSvc.removeLoader(loader);
          }
        }).bind(this)
      )
      .subscribe();
  }

  loadAssessments(): void {
    this.p = 1;
    if (this.allAssessments === null) return;
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    if (!this.selectedGroup.value) this.assessments = this.allAssessments;
    else
      this.assessments = this.allAssessments.filter(
        (assessment) => assessment.group?.uuid === this.selectedGroup.value.uuid
      );
    this.loaderSvc.removeLoader(loader);
  }

  capitalizeFirstLetter(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  createAssessment(): void {
    this.modalSvc.show(LeaderAssessmentCreateComponent, {
      class: "modal-md-c modal-dialog-centered",
    });
  }

  viewAssessment(assessment: Assessment): void {
    if (
      assessment.status === AssessmentStatus.INACTIVE ||
      assessment.status === AssessmentStatus.SCHEDULED
    ) {
      localStorage.setItem(
        "createAssessment",
        JSON.stringify({ formValue: assessment, from: "view" })
      );
      this.router.navigate(["/leader/assessment/skills"]);
    } else this.router.navigate(["/leader/assessment/view", assessment.uuid]);
  }

  editAssesment() {
    this.router.navigate(["/leader/assessment/skills"]);
  }
  openModal(template: TemplateRef<any>, assessment: any) {
    this.deleteAss = assessment;
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  deleteAssessment(assessment): void {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.assessmentSvc
      .deleteAssessment(assessment.uuid)
      .pipe(
        map((response: any) => {
          this.loadAssessmentList();
          this.loaderSvc.removeLoader(loader);
        }).bind(this)
      )
      .subscribe();
    this.modalRef?.hide();
  }

  decline(): void {
    this.message = this.page?.declined;
    this.modalRef?.hide();
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
