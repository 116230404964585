<div
  *ngIf="!!assessments.length"
  class="tab-content reportMain"
  id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="physics"
    role="tabpanel"
    aria-labelledby="physics-tab">
    <div class="tabHeadMain px-4">
      <div class="container">
        <div class="tabHead">
          <span routerLink="/member/assessment/external/list" class="cursor-pointer back-arrow">
            <i class="fas fa-arrow-left"></i>
          </span>
           {{pageLanguage?.report.title}}
        </div>
      </div>
    </div>
    <div class="dashContArea px-5">
      <div class="container-fluid px-4 pb-5 pb-5-sm-c">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="overviewMain">
              <div class="assmntTblMain title-main">
                <div class="assmntTblHd border-bottom"><h1>{{appLabels?.filterBy}}</h1></div>

                <div class="assmntTbl reportsFilter bg-white">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 reportsFilterCol">
                      <label>{{pageLanguage?.report.assessmentSelection}}</label>
                      <select [(ngModel)]="selectedAssessment">
                        <option
                          *ngFor="let assessment of assessments"
                          [ngValue]="assessment">
                          {{ assessment.assessmentName }}
                        </option>
                      </select>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 reportsFilterCol">
                      <div class="reportsFilterColBtn btn-row-c">
                        <button
                          class="submitBtn"
                          (click)="onSearch()"
                          type="button"
                          class="btn btn-primary"
                          >
                          {{appLabels?.search}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="reportsTabs">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="domain1"
              role="tabpanel"
              aria-labelledby="domain1-tab">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 mb-3">
                  <div class="boxMain card-white card-white-sm border-radius-md">
                    <div class="boxHead card-title-main">
                      <h5>{{pageLanguage?.report.domainScores}}</h5>
                    </div>
                    <div class="p-0">
                      <div class="strengthMain">
                        <div
                        *ngFor="let data of domainScores"
                        class="strengthRow list-bordered-btm-dotted">
                        <div
                          class="strengthCol w-100 d-flex align-items-center justify-content-between">
                          <div class="list-item">
                            {{ allDomainsAndSkills[data.domainUID.toString()].name }}
                          </div>
                          <div class="strengthCol">
                            <div class="list-img">
                              <img src="{{data.color}}" alt="graph" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div
                    class="chartBtmArea legend-btm col-12">
                    <div class="row w-100">
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-good.svg" alt="graph" /></div>
                        <div>{{appLabels?.good}} &gt;4</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-ok.svg" alt="graph" /></div>
                        <div>{{appLabels?.ok}} &gt;3-4</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-improvement.svg" alt="graph" /></div>
                        <div>{{appLabels?.improvement}} &lt;=3</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-insufficient1.svg" alt="graph" /></div>
                        <div>{{appLabels?.insufficientData}}</div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 mb-3">
                  <div class="boxMain card-white card-white-sm border-radius-md">
                    <div class="boxHead card-title-main">
                      <h5>{{pageLanguage?.report.skillScores}}</h5>
                    </div>
                    <div class="padding0">
                      <div class="strengthMain">
                        <div
                        *ngFor="let data of skillScores"
                        class="strengthRow list-bordered-btm-dotted">
                        <div
                          class="strengthCol w-100 d-flex align-items-center justify-content-between">
                          <div class="list-item">
                            {{ allDomainsAndSkills[data.uid.toString()].name }}
                          </div>
                          <div class="strengthCol">
                            <div class="list-img">
                              <img src="{{data.color}}" alt="graph" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div
                    class="chartBtmArea legend-btm col-12">
                    <div class="row w-100">
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-good.svg" alt="graph" /></div>
                        <div>{{appLabels?.good}} (5)</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-ok.svg" alt="graph" /></div>
                        <div>{{appLabels?.ok}} (4)</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-improvement.svg" alt="graph" /></div>
                        <div>{{appLabels?.improvement}} (1-3)</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-insufficient1.svg" alt="graph" /></div>
                        <div>{{appLabels?.insufficientData}}</div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!assessments.length">
  <div class="emptyAssmntMain">
    <div class="emptyAssmntInner">
      <span><img src="assets/img/empty-search.svg" alt="#" /></span>
      <h5>{{appLabels?.noResultsFound}}</h5>
    </div>
  </div>
</div>
