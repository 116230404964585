<div
  class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center"
  >
  <div class="container px-0 w-sm-100 w-50">
    <div class="positive-brain-list w-auto">
      <h4 class="w-auto text-center mb-0 px-3">
        {{ mainTitle }}
      </h4>
      <span
        class="d-flex justify-content-center small text-center mt-2 sm-center-txt"
        [innerHTML]="subTitle"
      ></span>
      <div class="col-12 d-flex justify-content-center mt-3">
        <div class="col-sm-12 col-lg-6 c-form">
          <form [formGroup]="form">
            <div
              class="form-group"
              *ngFor="let textInputControl of textInputList.controls; let i = index"
              formArrayName="textInputList"
              [ngClass]="{ 'is-invalid': submitted && !textInputList.valid }">
              <input
                type="text"
                class="form-control"
                id="textInput{{ i }}"
                [formControl]="textInputControl"
                [placeholder]="textInputs[i]?.placeholder" />
              <div
                *ngIf="!!textInputControl.errors?.pattern"
                class="invalid-feedback">
                {{ labels.errorNoHTML }}
              </div>
            </div>

            <div
              *ngIf="submitted && validCount < min"
              class="invalid-feedback">
              {{ validationError }}
            </div>

            <div class="form-group" *ngIf="!!disclaimer">
              <div class="form-check">
                <input
                  class="form-check-input"
                  formControlName="disclaimer"
                  type="checkbox"
                  id="disclaimer">
                <label class="form-check-label" for="disclaimer">
                  {{ disclaimer.display }}
                </label>
              </div>
              <div
                *ngIf="submitted && form.controls['disclaimer'].errors?.required"
                class="invalid-feedback">
                {{ disclaimer.error }}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="footer-inner-action">
        <button
          *ngIf="!!next?.onClick"
          type="button"
          (click)="nextOnClick()"
          class="btn btn-primary btn-lg px-4">
          {{ next.display || labels.next }}
        </button>
      </div>
    </div>
  </div>
</div>
