import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { SkillBuilderService } from '@services/member';
import {
  AuthService,
  Domain2Service,
  LanguageLoaderService,
  LanguageService,
  TokenService
} from '@services/public';
import { ExposedPromise } from '@util';
import { catchError, map, throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loaded: boolean = false;
  private _credentialsLoaded: ExposedPromise<void> = new ExposedPromise<void>();
  
  constructor(
    private _authSvc: AuthService,
    private _domainSvc: Domain2Service,
    // Initializes this service.
    public _languageLoaderSvc: LanguageLoaderService,
    private _languageSvc: LanguageService,
    private _skillBuilderSvc: SkillBuilderService,
    private _tokenSvc: TokenService
  ) { }

  ngOnInit() {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          let navPath = notification.data.navigation;
          delete notification.data;
          delete notification.id;
          alert(JSON.stringify(notification.body));
          PushNotifications.removeAllDeliveredNotifications();
          window.location.href = navPath;        
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          window.location.href = notification.notification.data.navigation;
          PushNotifications.removeAllDeliveredNotifications();
        },
      );
    }

    // Load credentials.
    if (!!this._tokenSvc.refreshToken) {
      this._authSvc.refreshTokens().pipe(
        catchError((err) => {
          this._credentialsLoaded.resolve();
          return throwError(() => err);
        }).bind(this),
        map((response: any) => {
          if (this._tokenSvc.login(response))
            this._authSvc.confirm().subscribe();
          this._credentialsLoaded.resolve();
        }).bind(this)
      ).subscribe();
    } else this._credentialsLoaded.resolve();

    // Load services.
    Promise.all([
      this._credentialsLoaded,
      this._domainSvc.loaded,
      this._languageSvc.loadingLabels,
      this._skillBuilderSvc.loaded
    ]).then((() => this.loaded = true).bind(this));
  }
}
