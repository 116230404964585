import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DECISION_PAGE, DecisionAction, DecisionData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'skillBuilders.decision.pages.takenAction';

@Component({
  selector: 'app-member-skill-builder-decision-taken-action',
  templateUrl: './taken-action.component.html',
  styleUrls: ['./taken-action.component.scss']
})
export class MemberSkillBuilderDecisionTakenActionComponent implements OnInit {

  @Input() data: DecisionData;
  @ViewChild('reminder', { static: false }) reminderTemplate: TemplateRef<void>;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }
  selected: string = null;
  submitted: boolean = false;
  choice: string;
  modalRef: BsModalRef;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: '',
    reminder: '',
    no: '',
    yes: '',
    notSure: '',
    ok: '',
    error1: ''
  }

  constructor(
    private _router: Router,
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'title':
              this._languageSvc.template(
                this.page[key], { affect: this.data?.affect }
              ).then(value => this.page[key] = value);
              break;
            case 'no':
              this._languageSvc.template(this.page[key]).then(
                value => {
                  this.page[key] = value;

                  // Get actions.
                  this.options.next([...this.data.actions.map(
                    (value: DecisionAction) => {
                      if (value.action === this.data?.action)
                        return { display: value.action, selected: true };
                      return { display: value.action };
                    }), { display: value }]);
                });
              break;
            default:
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value);
          }
        }
      });

    // Load decision data.
    this.selected = this.data?.action;
    this.choice = this.data?.rightChoice;
  }

  onChange(option: SelectListOption): void {
    this.selected = option.display;
  }

  nextOnClick(option: SelectListOption): void {
    if (option.display === this.page.no) {
      this.modalRef = this._modalSvc.show(this.reminderTemplate, {
        class: "modal-sm modal-dialog-centered",
        backdrop: "static",
        keyboard: true,
      });
      return;
    }
    this.submitted = true;
    if (!this.choice) return;
    const data: DecisionData = Object.assign({}, this.data, {
      action: option.display,
      rightChoice: this.choice,
      page: (this.choice === 'yes' ?
        DECISION_PAGE.RIGHT_CHOICE_YES : DECISION_PAGE.RIGHT_CHOICE_NO)
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, data);
  }

  closeReminder(): void {
    this.modalRef?.hide();
    this._router.navigate(['member/dashboard']);
    this._storageSvc.setTempStorage(TEMP_KEYS.DECISION, null);
  }

}
