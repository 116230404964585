<div class="assStudentMain">
  <div class="tabHeadMain px-4">
    <div class="container d-flex">
      <div class="col-12 col-md-6 tabHead">
        <button (click)="backstep()" class="back-arrow transparent-button">
          <i class="fas fa-arrow-left"></i>
        </button>
        {{pageLanguage?.self.title}} - {{currentSkillIndex + 1}} {{appLabels?.outOf}} {{skills.length}}
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center justify-content-end">
        <app-assesment-instruction-popup [isSelf]="true" [pageLanguage]="pageLanguage" [labels]="appLabels"></app-assesment-instruction-popup>
      </div>
    </div>

  </div>

  <div *ngIf="!!skills.length" class="container-fluid px-4 pb-lg">
    <div class="pageTopCard">
      <h2>{{ skills[currentSkillIndex].name }}</h2>
      <h5>{{ skills[currentSkillIndex].definition }}</h5>
      <p>{{ skills[currentSkillIndex].description }}</p>
      <p>{{ instructions }}</p>
    </div>
    <form [formGroup]="form">
      <div class="cardForm">
        <div *ngFor="let question of skills[currentSkillIndex].questions"
          class="cardFormRow" [ngClass]="{
            'invalid-box':
              form.controls[question].errors && form.controls[question].touched
          }">
          <div class="cardFormQst">{{ question }}</div>
          <div class="cardFormAnswer">
            <div class="cardFormAnsrCol" *ngFor="let option of options">
              <label class="containerLabel">{{ option.display }}
                <input type="radio" [formControlName]="question"
                  [value]="option.value" [name]="question" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      class="d-flex justify-content-center align-items-center footer-inner-action btn-c">
      <button (click)="prev()" [disabled]="currentSkillIndex === 0"
        class="btn btn-md">
        {{appLabels?.previous}}
      </button>
      <button *ngIf="currentSkillIndex < skills.length - 1" (click)="next()"
        class="btn btn-primary">
       {{appLabels?.next}}
      </button>
      <button *ngIf="currentSkillIndex >= skills.length - 1" (click)="finish()"
        class="btn btn-primary">
        {{appLabels?.finish}}
      </button>
    </div>
  </div>
</div>

<div class="emptyAssmntMain" *ngIf="!skills.length">
  <div class="emptyAssmntInner">
    <span><img src="assets/img/empty-search.svg" alt="#" /></span>
    <h5>{{appLabels?.finish}}</h5>
  </div>
</div>
