import { SkillBuilderData } from "@services/member";

export enum BRAIN_PAGE {
  IMPACT = 'IMPACT',
  AFFIRMATIONS = 'AFFIRMATIONS',
  DIARY = 'DIARY',
  DO_TOMORROW = 'DO_TOMORROW'
}

export const BRAIN_PREVIOUS_PAGE = {};

// Track flow.
BRAIN_PREVIOUS_PAGE[BRAIN_PAGE.IMPACT] = null;
BRAIN_PREVIOUS_PAGE[BRAIN_PAGE.AFFIRMATIONS] = null; // Start flow.
BRAIN_PREVIOUS_PAGE[BRAIN_PAGE.DIARY] = BRAIN_PAGE.AFFIRMATIONS;
BRAIN_PREVIOUS_PAGE[BRAIN_PAGE.DO_TOMORROW] = BRAIN_PAGE.DIARY;

export interface BrainData extends SkillBuilderData {
  page?: BRAIN_PAGE;
  affirmation: string;
  diary: string;
  score: number;
  doTomorrow: string;
  logUUID: string;
  completed: boolean;
  impact: number;
}

export interface BrainReportThought {
  thought: string;
  count: number;
  percent: number;
}

export interface BrainReportLog {
  thought: string;
  date: string;
  diary: string;
  score: number;
}

export interface BrainReportPie {
  bigImpact: number;
  littleImpact: number;
  noImpact: number;
}

export interface BrainReportData {
  thoughts: Array<BrainReportThought>;
  logs: Array<BrainReportLog>;
  followThrough?: number;
  pie?: BrainReportPie;
}
