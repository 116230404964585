<div class="credHeader">
    <img src="assets/img/logo.svg" alt="" />
</div>

<div class="tabHeadMain">
  <div class="container">
    <div class="tabHead">
      <span class="back-arrow"><i routerLink="/" class="fas fa-arrow-left"></i></span>{{pageLanguage?.forgotPassword}}
    </div>
  </div>
</div>

<div class="d-flex justify-content-center plain-rounded-card px-4">
  <div class="card card-primary col-sm-12 col-lg-5">
    <div class="card-body">
      <h3 class="title-main">{{pageLanguage?.forgotPassword}}</h3>
      <p class="subHd">{{pageLanguage?.enterEmail}}</p>
      <form [formGroup]="form" (ngSubmit)="forgotPassword()">
        <div class="formInner">
          <div class="flex-column formInnerCol mb-3">
            <label>{{pageLanguage?.email}}</label>
            <div class="input-group">
              <span class="credIcons">
                <input
                  [ngClass]="{
                    'danger-class': f.email.errors && f.email.touched
                  }"
                  type="email"
                  maxlength="320"
                  name="email"
                  formControlName="email"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="{{pageLanguage?.email}}" />
              </span>
              <div
                *ngIf="f.email.errors && f.email.touched"
                class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{pageLanguage?.emailRequired}}</div>
                <div *ngIf="f.email.errors.pattern">{{pageLanguage?.emailInvalid}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-c my-3">
          <button class="btn btn-primary btn-block">{{pageLanguage?.sendEmail}}</button>
        </div>
      </form>

      <div class="login-dont-have-text">
        <span>
          <a routerLink="/login" routerLinkActive="active"
            >{{pageLanguage?.backToLogin}}</a
          ></span
        >
      </div>
    </div>
  </div>
</div>
