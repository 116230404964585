import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { application, config, version } from "environment";
import { TokenService } from "./token.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService, PUBLIC_HEADER_KEY } from "./auth.service";
import { Router } from "@angular/router";
import { LoaderService } from "./loader.service";
import { LanguageService } from "./language.service";
import { LogoutService } from './logout.service';
import {
  Observable,
  ReplaySubject,
  catchError,
  lastValueFrom,
  map,
  tap,
  throwError
} from "rxjs";
import {
  GeneralModalComponent
} from "@pages/member/general-modal/general-modal.component";
import { ActiveHttpRequest, ExposedPromise, NewHttpRequest } from "@util";
import { v4 } from "uuid";
import { STORAGE_KEYS } from "./storage.service";

// Controls non-production debug messages for this module.
const DEBUG = false;

// Links to the app stores.
const APP_LINKS = {
  'jakapa.apple': 'https://apps.apple.com/us/app/jakapa-soft-skills-builder/id6443466313',
  'jakapa.google': 'https://play.google.com/store/apps/details?id=com.jakapa.app'
}
const APP_POPUP = {
  modalInfo: {
    title: '',
    subTitle: 'Please update your app',
    message: 'The feature you were trying to access is not available in your current version of the JAKAPA app. Please update your app at the following link:',
    linkText: 'Go To App Store',
    linkDestination: APP_LINKS[application]
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor, OnDestroy {
  private _runPromise: Promise<void> = null;
  private _pause: ExposedPromise<void> = new ExposedPromise<void>();
  private _newRequests: Array<NewHttpRequest<any>> = [];
  private _activeRequests: Array<ActiveHttpRequest<any>> = [];
  private _popupFired = false;
  private _closing = false;

  private _headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': `application/vnd.${application}+json; version=${version}`
  }

  constructor(
    private _router: Router,
    private _tokenSvc: TokenService,
    private _modalSvc: BsModalService,
    private _toastSvc: ToastrService,
    private _authSvc: AuthService,
    private _loaderSvc: LoaderService,
    private _languageSvc: LanguageService,
    private _logoutSvc: LogoutService
  ) {
    // Set the client ID.
    let clientID = localStorage.getItem(STORAGE_KEYS.CLIENT_ID);
    if (!clientID) {
      clientID = v4();
      localStorage.setItem(STORAGE_KEYS.CLIENT_ID, clientID);
    }
    this._headers['Client-ID'] = clientID;

    // Main function to handle HTTP requests.
    this._runPromise = (async () => {
      while (!this._closing || this._activeRequests.length > 0) {
        // Handle closing cleanup.
        if (this._closing) {
          while (this._activeRequests.length > 0) {
            const activeRequest = this._activeRequests.pop();
            if (!activeRequest.completed) {
              // Error out active requests that have not completed.
              activeRequest.error(
                new Error('HTTP Interceptor is shutting down.')
              ).then(() => activeRequest.subscription.unsubscribe());
            } else {
              // Clean up active requests that are already completed.
              activeRequest.clearLoader();
              activeRequest.subscription.unsubscribe();
            }
          }

          // Skip the rest of the processing.
          continue;
        }

        // Reset pause before processing requests to prevent race conditions.
        this._pause = new ExposedPromise<void>();

        // Loop through the activeRequests array.
        let i = 0;
        while (i < this._activeRequests.length) {
          if (this._activeRequests[i].completed
            && this._activeRequests[i].expires <= (new Date()).valueOf())
          {
            // Clear out active requests that are completed and expired.
            this._activeRequests[i].clearLoader();
            this._activeRequests[i].subscription.unsubscribe();
            this._activeRequests.splice(i, 1);
          } else i++;
        }

        // Loop through the newRequests array.
        while (this._newRequests.length > 0) {
          const newRequest = this._newRequests.pop();
          let activeRequest = this._activeRequests.find(request =>
            this._equalHttpRequests(request.request, newRequest.request));
          if (!activeRequest) {
            activeRequest = new ActiveHttpRequest<any>(newRequest);
            this._activeRequests.push(activeRequest);
            this._initializeActiveRequest(activeRequest);
          }
          activeRequest.subscription.add(
            activeRequest.response.pipe(map((response: HttpEvent<any>) => {
              // Ignore everything except response events.
              if (!(response instanceof HttpResponse)) return response;

              // Create a deep copy of the response body.
              response = response.clone({
                body: JSON.parse(JSON.stringify(response.body))
              });
              return response;
            })).subscribe(newRequest.response));
        }

        // Pause until intercept or ngOnDestroy is called.
        await this._pause;
      }
    })();
  }

  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newRequest: NewHttpRequest<any> = {
      request,
      next,
      response: new ReplaySubject<HttpEvent<any>>()
    }
    this._newRequests.push(newRequest);
    if (!this._closing) this._pause.resolve();
    return newRequest.response.asObservable();
  }

  // Check tokens and handle bad access tokens.
  private async _checkTokens(): Promise<boolean> {
    let valid = true;
    if (this._tokenSvc.accessTokenExpiration <= new Date() &&
      !!this._tokenSvc.refreshTokenExpiration &&
      this._tokenSvc.refreshTokenExpiration <= new Date()) {
      valid = false; // Return false if there are no current tokens.
    } else if (this._tokenSvc.accessTokenExpiration <= new Date()) {
      await lastValueFrom(this._authSvc.refreshTokens().pipe(map(
        (response: any) => { this._tokenSvc.login(response); }).bind(this)))
        .catch(_err => valid = false);
      // Return false if the refresh token failed.
      if (this._tokenSvc.accessTokenExpiration <= new Date()) valid = false;
      if (valid) this._authSvc.confirm().subscribe();
    }

    // Handle token failure.
    if (!valid) {
      this._loaderSvc.clearLoaders();
      this._modalSvc.hide();
      this._logoutSvc.logout();
      this._router.navigate(['/']);
    }

    return valid;
  }

  // Custom equality function for HttpRequests.
  private _equalHttpRequests(a: HttpRequest<any>, b: HttpRequest<any>): boolean {
    if (a.urlWithParams !== b.urlWithParams) return false;
    if (!this._equal(a.body, b.body)) return false;
    return true;
  }

  // Custom equality function for HttpRequestBodys.
  // Assumes no circular references.
  private _equal(a: any, b: any): boolean {
    // Check for variable type.
    if (typeof a !== typeof b) return false;

    // Check if the variable type is undefined.
    if (typeof a === 'undefined') return true;

    // Check if variables are null.
    if (a === null) return b === null;
    else if (b === null) return false;

    // Check if the variable type is primitive.
    if (typeof a !== 'object') return a === b;

    // Check if the variables are arrays.
    if (Array.isArray(a)) {
      if (!Array.isArray(b)) return false;
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; i++)
        if (!this._equal(a[i], b[i])) return false;
      return true;
    } else if(Array.isArray(b)) return false;

    // Check if the object key values are equal.
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (const key in a)
      if (!this._equal(a[key], b[key])) return false
    return true;
  }

  // Handles the active request.
  private async _initializeActiveRequest(
    activeRequest: ActiveHttpRequest<any>
  ): Promise<void> {
    // Set function references.
    activeRequest.checkTokens = this._checkTokens.bind(this);
    activeRequest.send =
      this._sendActiveRequest.bind(this, activeRequest);

    // Add authorization if needed.
    if (!activeRequest.request.headers.has(PUBLIC_HEADER_KEY)) {
      if (await this._checkTokens()) {
        activeRequest.request = activeRequest.request.clone({
          setHeaders: { Authorization: `Bearer ${this._tokenSvc.accessToken}` }
        });
      } else {
        activeRequest.response.error(new Error('Invalid access tokens.'));
        return;
      }
    }

    // Set global headers.
    activeRequest.request = activeRequest.request.clone(
      { setHeaders: this._headers });

    // Set loader and clearLoader function.
    const loader = Symbol();
    this._loaderSvc.addLoader(loader, 'HTTP Request');
    activeRequest.clearLoader =
      (() => { this._loaderSvc.removeLoader(loader); }).bind(this);

    // Send request with operators.
    activeRequest.send();
  }

  private _sendActiveRequest(
    activeRequest: ActiveHttpRequest<any>
  ): void {
    activeRequest.subscription.add(
      activeRequest.nextHandler.handle(activeRequest.request).pipe(
        tap((x) => {
          if (!config.production && DEBUG)
            console.debug({
              id: 'http tap',
              request: activeRequest.request,
              x
            });
        }),
        catchError((err: any) => {
          if (!config.production && DEBUG)
            console.debug({
              id: 'http catchError',
              request: activeRequest.request,
              err
            });

          // Catch HTTP errors.
          // 401 errors are handled by the activeRequest object.
          this._loaderSvc.clearLoaders();
          if (err.status === 403) {
            this._toastSvc.error('Restricted Access');
          } else if (
            err.status === 415 &&
            ['jakapa.apple', 'jakapa.google'].includes(application) &&
            !this._popupFired
          ) {
            this._popupFired = true;
            this._modalSvc.show(GeneralModalComponent,
              {initialState: APP_POPUP, class:'centered-all'});
          } else if (err.status === 500) {
            this._toastSvc.error('Internal Server Error');
          } else if (err.status === 0) {
            this._toastSvc.error('Please Try Again');
          } else if (!!err.error && !!err.error.errors) {
            err.error.errors.forEach(async (error: any) => {
              if (!!error.message)
                this._toastSvc.error(error.message, '', {
                  enableHtml: true,
                  closeButton: true,
                  timeOut: 10000
                });
              else {
                const path: string = `errors.${error.type}`;
                const message: string =
                  (await this._languageSvc.get(
                    [path], { [path]: error.data }))[path];
                this._toastSvc.error(
                  message, '', {
                    enableHtml: true,
                    closeButton: true,
                    timeOut: 10000
                  });
              }
            });
          } else this._toastSvc.error(`Error: ${err.status}`);
          return throwError(() => err);
        }).bind(this),
        map((response: HttpEvent<any>) => {
          // Ignore everything except response events.
          if (!(response instanceof HttpResponse)) return response;
          if (!config.production && DEBUG)
            console.debug({
              id: 'http map',
              request: activeRequest.request,
              response: {
                ...response,
                body: JSON.parse(JSON.stringify(response.body))
              }
            });

          // Catch custom errors.
          if (!!response.body.errors) {
            this._loaderSvc.clearLoaders();
            response.body.errors.forEach(async (error: any) => {
              if (!!error.message)
                this._toastSvc.error(error.message, '', {
                  enableHtml: true,
                  closeButton: true,
                  timeOut: 10000
                });
              else {
                const path: string = `errors.${error.type}`;
                const message: string =
                  (await this._languageSvc.get(
                    [path], { [path]: error.data }))[path];
                this._toastSvc.error(
                  message, '', {
                    enableHtml: true,
                    closeButton: true,
                    timeOut: 10000
                  });
              }
            });
          }
          return response;
        }).bind(this)).subscribe(activeRequest));
  }

  async ngOnDestroy(): Promise<void> {
    this._closing = true;
    this._pause.resolve();
    await this._runPromise;
  }

}
