import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { REFLECTION_PAGE, ReflectionData } from '../common';
import { ReplaySubject, Subscription } from 'rxjs';
import {
  SelectListCustom,
  SelectListNext,
  SelectListOption
} from '@components';
import {
  Domain2Service,
  LanguageService,
  MAIN_KEYS,
  MAIN_KEY_PREFIX,
  Skill2,
  StorageService,
  TEMP_KEYS,
  VOLATILE_KEYS
} from '@services/public';
import { APIErrors, ExposedPromise, isAPIErrors } from '@util';
import { Router } from '@angular/router';
import {
  ReflectionService,
  ReflectionServiceCreateLogResponse
} from '@services/member';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.action';

@Component({
  selector: 'app-member-skill-builder-reflection-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class MemberSkillBuilderReflectionActionComponent
  implements OnInit, OnDestroy
{

  @Input() data: ReflectionData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  customOptions: Array<SelectListOption> = [];
  custom: SelectListCustom = {
    add: this.addCustom.bind(this),
    delete: this.deleteCustom.bind(this)
  }
  next: SelectListNext = {
    display: null,
    onClick: this.nextOnClick.bind(this)
  }
  defaultLoaded: ExposedPromise<void> = new ExposedPromise<void>();
  languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: '',
    submit: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _reflectionSvc: ReflectionService,
    private _router: Router,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        const promises: Array<Promise<any>> = [];
        for (const key in value[PAGE_PATH]) {
          switch (key) {
            case 'subTitle': {
              if (this.data.domainUID === 0) break;
              promises.push(this._languageSvc.template(
                value[PAGE_PATH][key],
                {
                  domain: this._domainSvc.getDomain(
                    this.data.domainUID).name.toLowerCase(),
                  skill: this._domainSvc.getSkill(
                    this.data.skillUID).name.toLowerCase()
                }
              ).then(value => this.page.subTitle = value));
              break;
            }
            case 'subTitleOnboarding': {
              if (this.data.domainUID !== 0) break;
              promises.push(this._languageSvc.template(
                value[PAGE_PATH][key],
                {
                  skill: this._domainSvc.getSkill(
                    this.data.skillUID).name.toLowerCase()
                }
              ).then(value => this.page.subTitle = value));
              break;
            }
            default: {
              promises.push(this._languageSvc.template(
                value[PAGE_PATH][key]
              ).then(value => this.page[key] = value));
            }
          }
        }
        Promise.all(promises).then(() => this.languageLoaded.resolve());
      }
    );

    // Get options list.
    const skill: Skill2 = this._domainSvc.getSkill(this.data.skillUID);
    const path: string =
      `domains2.${skill?.domain.uid}.skills.${skill.uid}.goalActions`;
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = this._languageSvc.objectToOrderedArray(
          value[path]
        );
        this.defaultLoaded.resolve();
      }
    );
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
        this.customOptions = [];
        if (Array.isArray(update.value[MAIN_KEYS.GOAL_ACTIONS])) {
          for (const option of update.value[MAIN_KEYS.GOAL_ACTIONS]) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
        this.customOptions = [];
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.GOAL_ACTIONS) {
        this.customOptions = [];
        if (Array.isArray(update.value)) {
          for (const option of update.value) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
    }));
    let options: Array<string> = this._storageSvc.getMainStorage(
      MAIN_KEYS.GOAL_ACTIONS);
    if (Array.isArray(options)) {
      for (const option of options) {
        if (typeof option !== 'string') continue;
        this.customOptions.push({ display: option, custom: true });
      }
    }

    this._mergeAndSelectOptions();
  }

  private async _mergeAndSelectOptions(): Promise<void> {
    await this.defaultLoaded;
    await this.languageLoaded;
    this.options.next([...this.customOptions, ...this.defaultOptions].map(
      (option: SelectListOption) => {
        if (option.display === this.data.action) {
          option.selected = true;
          if (!option.value?.skillBuilderType) this.next.display = null;
          else this.next.display = this.page.submit;
        }
        return option;
      }));
  }

  addCustom(customOption: string): void {
    if (this.customOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    if (this.defaultOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    this.customOptions.push({ display: customOption, custom: true });
    this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS,
      this.customOptions.map(option => option.display));
  }

  deleteCustom(option: SelectListOption): void {
    this.customOptions = this.customOptions.filter(
      customOption => customOption.display !== option.display);
    if (this.customOptions.length === 0)
      this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS, null);
    else this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS,
        this.customOptions.map(option => option.display));
  }

  onChange(option: SelectListOption): void {
    if (!option.value?.skillBuilderType) this.next.display = null;
    else this.next.display = this.page.submit;
  }

  nextOnClick(option: SelectListOption): void {
    if (!option.value?.skillBuilderType) {
      this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION, {
        ...this.data,
        page: REFLECTION_PAGE.SUMMARY,
        action: option.display,
        skillBuilderType: null
      });
      return;
    }

    this.data.action = option.display;
    this.data.skillBuilderType = option.value.skillBuilderType;
    this._reflectionSvc.createLog(this.data).subscribe(
      (res: ReflectionServiceCreateLogResponse | APIErrors) => {
        if (!isAPIErrors(res)) {
          if (this.data.domainUID === 0)
            this._storageSvc.setTempStorage(
              TEMP_KEYS.IS_REWARDS_ORIENTATION, true);
          this._storageSvc.setVolatileStorage(
            VOLATILE_KEYS.GOAL_PLAN_UUID, res.goalPlanUUID);
          this._router.navigate(['/member/skill-builder/goal']);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
