import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { config } from "environment";

@Injectable({
  providedIn: "root",
})
export class AllChallengesService {

  constructor(
    private http: HttpClient
  ) {}

  getMobileDashboard(): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/challenge/stats?timezone=America/Los_Angeles`
    );
  }

}
