import { Component, Input, OnInit } from '@angular/core';
import { LABELS, LanguageService } from '@services/public';
import { AbstractReportData } from '../common';
import { AbstractService } from '@services/member';
import { APINoData, isAPINoData } from '@util';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.report';

@Component({
  selector: 'app-member-skill-builder-abstract-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class MemberSkillBuilderAbstractReportComponent implements OnInit {

  ICON_CLASSES: { [key: string]: string } = {
    UP: 'double-up',
    DOWN: 'double-down'
  }
  SHOW_MIN: number = 3;

  @Input() noData: () => void;

  display: boolean = false;
  ideas: AbstractReportData = [];
  show: number = 3;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    oneApart: '',
    twoApart: '',
    threeApart: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.VIEW_LESS]: '',
    [LABELS.VIEW_MORE]: ''
  }

  constructor(
    private _abstractSvc: AbstractService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    this._languageSvc.getLabels(this.labels);

    this._abstractSvc.getReport().subscribe(
      (res: AbstractReportData | APINoData) => {
        if (!isAPINoData(res)) {
          this.display = true;
          this.ideas = res;
        } else {
          this.display = false;
          this.noData();
        }
      }
    );
  }

  toggleMore(){
    this.show =
      (this.ideas.length > this.show ? this.ideas.length : this.SHOW_MIN);
  }

}
