<!-- Skill builder header -->
<!-- TODO: Refactor SCSS -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="back()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
      </div>
    </div>
  </div>
</div>

<ng-container [ngSwitch]="data?.page">
  <!-- Skill builder start flow -->
  <app-member-skill-builder-wwyd-dilemma
    *ngSwitchCase="WWYD_PAGE.DILEMMA"
    [data]="data"
  ></app-member-skill-builder-wwyd-dilemma>
  <app-member-skill-builder-wwyd-custom-dilemma
    *ngSwitchCase="WWYD_PAGE.CUSTOM_DILEMMA"
    [data]="data"
  ></app-member-skill-builder-wwyd-custom-dilemma>
  <app-member-skill-builder-wwyd-first-choice
    *ngSwitchCase="WWYD_PAGE.FIRST_CHOICE"
    [data]="data"
  ></app-member-skill-builder-wwyd-first-choice>
  <app-member-skill-builder-wwyd-list-actions
    *ngSwitchCase="WWYD_PAGE.LIST_ACTIONS"
    [data]="data"
  ></app-member-skill-builder-wwyd-list-actions>
  <app-member-skill-builder-wwyd-ethics
    *ngSwitchCase="WWYD_PAGE.ETHICS"
    [data]="data"
  ></app-member-skill-builder-wwyd-ethics>
  <app-member-skill-builder-wwyd-benefits
    *ngSwitchCase="WWYD_PAGE.BENEFITS"
    [data]="data"
  ></app-member-skill-builder-wwyd-benefits>
  <app-member-skill-builder-wwyd-choose-action
    *ngSwitchCase="WWYD_PAGE.CHOOSE_ACTION"
    [data]="data"
  ></app-member-skill-builder-wwyd-choose-action>
  <app-member-skill-builder-wwyd-final-choice
    *ngSwitchCase="WWYD_PAGE.FINAL_CHOICE"
    [data]="data"
  ></app-member-skill-builder-wwyd-final-choice>
</ng-container>
