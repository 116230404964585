<div class="main-container w-100 align-items-center justify-content-center d-flex">
  <div class="container px-0 w-sm-100 w-50">
    <div class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="card card-white card-white-sm">
              <div class="card-body">
                <h5>{{ page.title }}</h5>
                <div class="pt-4">
                  <button
                    type="button"
                    (click)="choice = false"
                    class="btn btn-md ml-3 {{ choice === false ? 'btn-secondary' : 'btn-outline btn-outline-danger' }}">
                    {{ page.no }}
                  </button>
                  <button
                    type="button"
                    (click)="choice = true"
                    class="btn btn-md ml-3 {{ choice === true ? 'btn-secondary' : 'btn-outline btn-outline-danger' }}">
                    {{ page.yes }}
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="submitted && choice === null" class="invalid-feedback">
              {{ page.error1 }}
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100 justify-content-center" *ngIf="choice === true" [formGroup]="form">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
          <div class="card card-white card-white-sm mb-0">
            <div class="card-body">
              <h5>{{ page.ratingTitle }}</h5>
              <div class="">
                <div class="cont">
                  <div class="stars">
                    <ngx-star-rating
                      formControlName="rating"
                      [ngClass]="{ 'is-invalid': submitted && form.controls.rating.errors }"
                      [id]="'rating'">
                    </ngx-star-rating>
                  </div>
                </div>
                <div class="col-12 px-3">
                  <div class="dot-sm"></div>
                  <div class="dotted-line">
                    <i class="fas fa-chevron-right right-arrow"></i>
                  </div>
                  <div class="col-12 rating-label">
                    <div class="lft">{{ page.noImpact }}</div>
                    <div class="rgt">{{ page.bigImpact }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="submitted && form.controls.rating.errors" class="invalid-feedback">
            <div *ngIf="form.controls.rating.errors.required">{{ page.error2 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-inner-action">
    <button
      type="button"
      class="btn btn-primary btn-lg px-4"
      (click)="next()">
      {{ page.next }}
    </button>
  </div>
</div>
