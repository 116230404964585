import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { ChartConfiguration } from "chart.js";

import { Assessment } from "./assessment.service";
import {
  AssessmentScore,
  AssessmentScores,
  AssessmentScoresService,
} from "./assessment-scores.service";
import {
  DomainGrowthService,
  DomainOverallScoresService,
  ImageChartEntry,
  OverallScoresColumn,
  SelfAwarenessService,
  SelfByGroupService,
  SelfVsPeerBySkillService,
  SelfVsPeerByUserService,
  SkillGrowthService,
  SkillOverallScoresService,
  StrengthsAndWeaknessesService,
} from "./report";
import { Domain, LoaderService, LogoutService, Skill, LanguageService } from "@services/public";
import {
  Group,
  GroupService,
  Organization,
} from "@services/member";
import { User, UserService } from "@services/viewer";

const PAGE_PATH = `pages.leader.report`;
const ONE_DAY = 864e5;
const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

export enum ReportPages {
  DASHBOARD,
  DETAILED,
}

export interface DashboardReports {
  domainOverallScores: Observable<Array<OverallScoresColumn>>;
  domainGrowth: Observable<ChartConfiguration>;
  strengthsAndWeaknesses: Observable<Array<ImageChartEntry>>;
  selfAwareness: Observable<Array<ImageChartEntry>>;
  selfByGroup: Observable<ChartConfiguration>;
  selfVsPeerByUser: Observable<ChartConfiguration>;
}

export interface DetailedReports {
  selfVsPeerBySkill: Observable<ChartConfiguration>;
  strengthsAndWeaknesses: Observable<Array<ImageChartEntry>>;
  skillOverallScores: Observable<Array<OverallScoresColumn>>;
  skillGrowth: Observable<ChartConfiguration>;
}

@Injectable({
  providedIn: "root",
})
export class ReportService {
  // Local data storage from API calls.
  private _assessmentScores: AssessmentScores = null;
  private _users: Array<User> = null;
  private _groups: Array<Group> = null;
  private _updated: Date = null;

  // Current page.
  private _page: ReportPages = null;

  // Page filters.
  private _domain: Domain = null;
  private _organization: Organization = null;
  private _group: Group = null;
  private _user: User = null;
  private _timespan: string = "all time";
  private _assessment: Assessment = null;
  private _skillOverallScoresSkill: Skill = null;
  private _growthSkill: Skill = null;
  private _growthDomain: Domain = null;
  private _selfByGroupDomain: Domain = null;
  private _selfVsPeerDomain: Domain = null;

  // Filtered data storage.
  private _filteredScores: AssessmentScores = null;
  private _filteredScoresNoDomain: AssessmentScores = null;
  private _filteredScoresNoDomainUserGroup: AssessmentScores = null;

  // Report subjects.
  private _dashboardReports = {
    domainOverallScores: new ReplaySubject<Array<OverallScoresColumn>>(1),
    domainGrowth: new ReplaySubject<ChartConfiguration>(1),
    strengthsAndWeaknesses: new ReplaySubject<Array<ImageChartEntry>>(1),
    selfAwareness: new ReplaySubject<Array<ImageChartEntry>>(1),
    selfByGroup: new ReplaySubject<ChartConfiguration>(1),
    selfVsPeerByUser: new ReplaySubject<ChartConfiguration>(1),
  };
  private _detailedReports = {
    selfVsPeerBySkill: new ReplaySubject<ChartConfiguration>(1),
    strengthsAndWeaknesses: new ReplaySubject<Array<ImageChartEntry>>(1),
    skillOverallScores: new ReplaySubject<Array<OverallScoresColumn>>(1),
    skillGrowth: new ReplaySubject<ChartConfiguration>(1),
  };
  private _modal: ReplaySubject<ChartConfiguration> =
    new ReplaySubject<ChartConfiguration>(1);
  
  // Page language.
  page: {[key: string]: string} = {
  }

  labels = {
    self:'', 
    peer: '', 
    skillLevel: '', 
    score:'',
    users: ''
  };

  constructor(
    private logoutSvc: LogoutService,
    private assessmentScoresSvc: AssessmentScoresService,
    private usersSvc: UserService,
    private groupSvc: GroupService,
    private loaderSvc: LoaderService,
    private domainOverallScoresSvc: DomainOverallScoresService,
    private domainGrowthSvc: DomainGrowthService,
    private strenthsAndWeaknessesSvc: StrengthsAndWeaknessesService,
    private selfAwarenessSvc: SelfAwarenessService,
    private selfByGroupSvc: SelfByGroupService,
    private selfVsPeerByUserSvc: SelfVsPeerByUserService,
    private selfVsPeerBySkillSvc: SelfVsPeerBySkillService,
    private skillOverallScoresSvc: SkillOverallScoresService,
    private skillGrowthSvc: SkillGrowthService,
    private _languageSvc: LanguageService
  ) {
    // Bind logout service.
    this.logoutSvc.subscribe(this.logout.bind(this));
  }

  private _loadAPIs(): void {
    // Load assessment scores.
    const loader1: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated)
      this.loaderSvc.addLoader(
        loader1,
        "services/leader/report:constructor:assessmentScores"
      );
    this.assessmentScoresSvc.scores.subscribe((next) => {
      this._assessmentScores = next;
      this._loadCharts();
      this._updated = new Date();
      this.loaderSvc.removeLoader(loader1);
    });

    // Load users.
    const loader2: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated)
      this.loaderSvc.addLoader(
        loader2,
        "services/leader/report:constructor:users"
      );
    this.usersSvc.users.subscribe((next) => {
      this._users = next;
      this._languageSvc.get([PAGE_PATH]).then(
        value => {
          if (
            typeof value[PAGE_PATH] !== 'object' ||
            value[PAGE_PATH] === null
          ) return;
        this.page = value[PAGE_PATH];
        this.labels.peer = this.page?.peer;
        this.labels.self = this.page?.self;
        this.labels.skillLevel = this.page?.skillLevel;
        this.labels.score = this.page?.score;
        this._loadCharts();
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
        }
      ); 
      this._updated = new Date();
      this.loaderSvc.removeLoader(loader2);
    });

    // Load groups.
    const loader3: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated)
      this.loaderSvc.addLoader(
        loader3,
        "services/leader/report:constructor:groups"
      );
    this.groupSvc.groups.subscribe((next) => {
      this._groups = next;
      this._loadCharts();
      this._updated = new Date();
      this.loaderSvc.removeLoader(loader3);
    });
  }

  private _loadCharts(): void {
    // Skip until everything is loaded.
    if (this._page === null) return;
    if (!this._assessmentScores) return;
    if (!this._users) return;
    if (!this._groups) return;
    if (!this._domain) return;
    if (!this._organization) return;
    if (this._page === ReportPages.DASHBOARD) {
      if (!this._growthDomain) return;
      if (!this._selfVsPeerDomain) return;
    }
    if (this._page === ReportPages.DETAILED) {
      if (!this._skillOverallScoresSkill) return;
      if (!this._growthSkill) return;
    }

    // Show loader.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader, "services/leader/report:_loadCharts");

    // Initialize filter.
    this._filteredScores = Object.assign({}, this._assessmentScores);

    // Filter scores.
    if (this._page === ReportPages.DASHBOARD)
      this._filterTimespan(this._filteredScores, this._timespan);
    if (this._page === ReportPages.DETAILED)
      this._filterAssessment(this._filteredScores, this._assessment);
    this._filterOrganization(this._filteredScores, this._organization);
    this._filteredScoresNoDomainUserGroup = Object.assign(
      {},
      this._filteredScores
    );
    this._filterGroup(this._filteredScores, this._group);
    this._filterUser(this._filteredScores, this._user);
    this._filteredScoresNoDomain = Object.assign({}, this._filteredScores);
    this._filterDomain(this._filteredScores, this._domain);

    // Load reports.
    if (this._page === ReportPages.DASHBOARD) {
      this._dashboardReports.domainOverallScores.next(
        this.domainOverallScoresSvc.generateReport(
          this._filteredScores,
          this._users,
          this.labels
        )
      );
      this._dashboardReports.domainGrowth.next(
        this.domainGrowthSvc.generateReport({
          self: this._filteredScoresNoDomain.self.filter(
            (score) => this._growthDomain.uid === score.domainUID
          ),
          peer: this._filteredScoresNoDomain.peer.filter(
            (score) => this._growthDomain.uid === score.domainUID
          ),
        }, this.labels)
      );
      this._dashboardReports.strengthsAndWeaknesses.next(
        this.strenthsAndWeaknessesSvc.generateReport(
          this._filteredScores,
          this._domain
        )
      );
      this._dashboardReports.selfAwareness.next(
        this.selfAwarenessSvc.generateReport(this._filteredScores, this._domain)
      );
      this._dashboardReports.selfByGroup.next(null);
      this._dashboardReports.selfVsPeerByUser.next(null);
    }
    if (this._page === ReportPages.DETAILED) {
      this._detailedReports.selfVsPeerBySkill.next(
        this.selfVsPeerBySkillSvc.generateReport(
          this._filteredScores,
          this._domain,
          this.labels
        )
      );
      this._detailedReports.strengthsAndWeaknesses.next(
        this.strenthsAndWeaknessesSvc.generateReport(
          this._filteredScores,
          this._domain
        )
      );
      this._detailedReports.skillOverallScores.next(
        this.skillOverallScoresSvc.generateReport(
          {
            self: this._filteredScores.self.filter(
              (score) => this._skillOverallScoresSkill.uid === score.skillUID
            ),
            peer: this._filteredScores.peer.filter(
              (score) => this._skillOverallScoresSkill.uid === score.skillUID
            ),
          },
          this._users, this.labels
        )
      );
      this._detailedReports.skillGrowth.next(
        this.skillGrowthSvc.generateReport({
          self: this._filteredScores.self.filter(
            (score) => this._growthSkill.uid === score.skillUID
          ),
          peer: this._filteredScores.peer.filter(
            (score) => this._growthSkill.uid === score.skillUID
          ),
        }, this.labels)
      );
    }

    // Clear loader.
    this.loaderSvc.removeLoader(loader);
  }

  private _filterTimespan(scores: AssessmentScores, timespan: string): void {
    scores.self = scores.self.filter((score) =>
      this._scoreWithinTimespan(score, timespan)
    );
    scores.peer = scores.peer.filter((score) =>
      this._scoreWithinTimespan(score, timespan)
    );
  }

  private _filterAssessment(
    scores: AssessmentScores,
    assessment: Assessment
  ): void {
    scores.self =
      !assessment || assessment.type !== "self"
        ? scores.self
        : scores.self.filter(
            (score) => assessment.uuid === score.assessmentUUID
          );
    scores.peer =
      !assessment || assessment.type !== "peer"
        ? scores.peer
        : scores.peer.filter(
            (score) => assessment.uuid === score.assessmentUUID
          );
  }

  private _filterOrganization(
    scores: AssessmentScores,
    organization: Organization
  ): void {
    scores.self = !organization
      ? scores.self
      : scores.self.filter(
          (score) => {
            for (const group of this._groups.filter((group) =>
                !!group.role && organization.uuid === group.organizationUUID))
              if (group.memberUUIDs.includes(score.userUUID)) return true;
            return false;
          }
        );
    scores.peer = !organization
      ? scores.peer
      : scores.peer.filter(
          (score) => organization.uuid === score.organizationUUID
        );
  }

  private _filterGroup(scores: AssessmentScores, group: Group): void {
    scores.self = !group
      ? scores.self
      : scores.self.filter(
        (score) => group.memberUUIDs.includes(score.userUUID));
    scores.peer = !group
      ? scores.peer
      : scores.peer.filter((score) => group.uuid === score.groupUUID);
  }

  private _filterUser(scores: AssessmentScores, user: User): void {
    scores.self = !user
      ? scores.self
      : scores.self.filter((score) => user.uuid === score.userUUID);
    scores.peer = !user
      ? scores.peer
      : scores.peer.filter((score) => user.uuid === score.userUUID);
  }

  private _filterDomain(scores: AssessmentScores, domain: Domain): void {
    scores.self = scores.self.filter((score) => domain.uid === score.domainUID);
    scores.peer = scores.peer.filter((score) => domain.uid === score.domainUID);
  }

  private _scoreWithinTimespan(
    score: AssessmentScore,
    timespan: string
  ): boolean {
    switch (timespan) {
      case "all time":
        return true;
      case "30 days":
        if (
          30 * ONE_DAY + new Date(score.date).valueOf() >
          new Date().valueOf()
        )
          return true;
        return false;
      case "90 days":
        if (
          90 * ONE_DAY + new Date(score.date).valueOf() >
          new Date().valueOf()
        )
          return true;
        return false;
      case "180 days":
        if (
          180 * ONE_DAY + new Date(score.date).valueOf() >
          new Date().valueOf()
        )
          return true;
        return false;
      case "1 year": {
        const date = new Date(score.date);
        date.setFullYear(date.getFullYear() + 1);
        if (date > new Date()) return true;
        return false;
      }
      case "5 years": {
        const date = new Date(score.date);
        date.setFullYear(date.getFullYear() + 5);
        if (date > new Date()) return true;
        return false;
      }
      default:
        return true;
    }
  }

  get dashboardReports(): DashboardReports {
    const reports = {};
    for (const report in this._dashboardReports)
      reports[report] = this._dashboardReports[report].asObservable();
    return <DashboardReports>reports;
  }

  get detailedReports(): DetailedReports {
    const reports = {};
    for (const report in this._detailedReports)
      reports[report] = this._detailedReports[report].asObservable();
    return <DetailedReports>reports;
  }

  get modal(): Observable<ChartConfiguration> {
    return this._modal.asObservable();
  }

  updateDashboardFilters(
    organization: Organization,
    domain: Domain,
    group: Group,
    user: User,
    timespan: string,
    growthDomain: Domain,
    selfByGroupDomain: Domain,
    selfVsPeerDomain: Domain
  ): void {
    this._page = ReportPages.DASHBOARD;
    this._organization = organization;
    this._domain = domain;
    this._group = group;
    this._user = user;
    this._timespan = timespan;
    this._growthDomain = growthDomain;
    this._selfByGroupDomain = selfByGroupDomain;
    this._selfVsPeerDomain = selfVsPeerDomain;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else this._loadCharts();
  }

  updateDomainGrowth(growthDomain: Domain): void {
    this._growthDomain = growthDomain;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else if (!this._filteredScoresNoDomain) this._loadCharts();
    else
      this._dashboardReports.domainGrowth.next(
        this.domainGrowthSvc.generateReport({
          self: this._filteredScoresNoDomain.self.filter(
            (score) => this._growthDomain.uid === score.domainUID
          ),
          peer: this._filteredScoresNoDomain.peer.filter(
            (score) => this._growthDomain.uid === score.domainUID
          ),
        }, this.labels)
      );
  }

  updateSelfByGroup(selfByGroupDomain: Domain): void {
    this._selfByGroupDomain = selfByGroupDomain;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else if (!this._filteredScoresNoDomainUserGroup) this._loadCharts();
    else
      this._dashboardReports.selfByGroup.next(
        this.selfByGroupSvc.generateReport(
          {
            self: this._filteredScoresNoDomainUserGroup.self.filter(
              (score) => this._selfByGroupDomain.uid === score.domainUID
            ),
            peer: [],
          },
          this._groups, this.labels
        )
      );
  }

  updateSelfVsPeerByUser(selfVsPeerDomain: Domain): void {
    this._selfVsPeerDomain = selfVsPeerDomain;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else if (!this._filteredScoresNoDomain) this._loadCharts();
    else
      this._dashboardReports.selfVsPeerByUser.next(
        this.selfVsPeerByUserSvc.generateReport(
          {
            self: this._filteredScoresNoDomain.self.filter(
              (score) => this._selfVsPeerDomain.uid === score.domainUID
            ),
            peer: this._filteredScoresNoDomain.peer.filter(
              (score) => this._selfVsPeerDomain.uid === score.domainUID
            ),
          },
          this._users, this.labels
        )
      );
  }

  getRecentSelfByGroupChartConfiguration(filters: any): ChartConfiguration {
    this._selfByGroupDomain = filters.domainFilter;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else if (!this._filteredScoresNoDomainUserGroup) this._loadCharts();
    else {
      return this.selfByGroupSvc.generateReport(
        {
          self: this._filteredScoresNoDomainUserGroup.self.filter(
            (score) => this._selfByGroupDomain.uid === score.domainUID
          ),
          peer: [],
        },
        this._groups, this.labels);
    }
    return null;
  }

  getSelfVsPeerByUserChartConfiguration(filters: any): ChartConfiguration {
    this._selfVsPeerDomain = filters.domainFilter;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
      this.groupSvc.loadGroups();
    } else if (!this._filteredScoresNoDomain) this._loadCharts();
    else {
      return this.selfVsPeerByUserSvc.generateReport(
        {
          self: this._filteredScoresNoDomain.self.filter(
            (score) => this._selfVsPeerDomain.uid === score.domainUID
          ),
          peer: this._filteredScoresNoDomain.peer.filter(
            (score) => this._selfVsPeerDomain.uid === score.domainUID
          ),
        },
        this._users,this.labels);
    }
    return null;
  }

  updateDetailedFilters(
    organization: Organization,
    domain: Domain,
    group: Group,
    user: User,
    assessment: Assessment,
    skillOverallScoresSkill: Skill,
    growthSkill: Skill
  ): void {
    this._page = ReportPages.DETAILED;
    this._organization = organization;
    this._domain = domain;
    this._group = group;
    this._user = user;
    this._assessment = assessment;
    this._skillOverallScoresSkill = skillOverallScoresSkill;
    this._growthSkill = growthSkill;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
    } else this._loadCharts();
  }

  updateSkillOverallScores(skillOverallScoresSkill: Skill): void {
    this._skillOverallScoresSkill = skillOverallScoresSkill;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
    } else if (!this._filteredScores) this._loadCharts();
    else
      this._detailedReports.skillOverallScores.next(
        this.skillOverallScoresSvc.generateReport(
          {
            self: this._filteredScores.self.filter(
              (score) => this._skillOverallScoresSkill.uid === score.skillUID
            ),
            peer: this._filteredScores.peer.filter(
              (score) => this._skillOverallScoresSkill.uid === score.skillUID
            ),
          },
          this._users, this.labels
        )
      );
  }

  updateSkillGrowth(growthSkill: Skill): void {
    this._growthSkill = growthSkill;
    if (!this._updated) this._loadAPIs();
    else if (this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()) {
      this.assessmentScoresSvc.loadScores();
      this.usersSvc.loadUsers();
    } else if (!this._filteredScores) this._loadCharts();
    else
      this._detailedReports.skillGrowth.next(
        this.skillGrowthSvc.generateReport({
          self: this._filteredScores.self.filter(
            (score) => this._growthSkill.uid === score.skillUID
          ),
          peer: this._filteredScores.peer.filter(
            (score) => this._growthSkill.uid === score.skillUID
          ),
        }, this.labels)
      );
  }

  updateModal(report: string, filters: any): void {}

  private logout(): void {
    this._dashboardReports.domainOverallScores.complete();
    this._dashboardReports.domainOverallScores = new ReplaySubject<
      Array<OverallScoresColumn>
    >(1);
    this._dashboardReports.domainGrowth.complete();
    this._dashboardReports.domainGrowth = new ReplaySubject<ChartConfiguration>(
      1
    );
    this._dashboardReports.strengthsAndWeaknesses.complete();
    this._dashboardReports.strengthsAndWeaknesses = new ReplaySubject<
      Array<ImageChartEntry>
    >(1);
    this._dashboardReports.selfAwareness.complete();
    this._dashboardReports.selfAwareness = new ReplaySubject<
      Array<ImageChartEntry>
    >(1);
    this._dashboardReports.selfByGroup.complete();
    this._dashboardReports.selfByGroup = new ReplaySubject<ChartConfiguration>(
      1
    );
    this._dashboardReports.selfVsPeerByUser.complete();
    this._dashboardReports.selfVsPeerByUser =
      new ReplaySubject<ChartConfiguration>(1);
    this._detailedReports.selfVsPeerBySkill.complete();
    this._detailedReports.selfVsPeerBySkill =
      new ReplaySubject<ChartConfiguration>(1);
    this._detailedReports.strengthsAndWeaknesses.complete();
    this._detailedReports.strengthsAndWeaknesses = new ReplaySubject<
      Array<ImageChartEntry>
    >(1);
    this._detailedReports.skillOverallScores.complete();
    this._detailedReports.skillOverallScores = new ReplaySubject<
      Array<OverallScoresColumn>
    >(1);
    this._detailedReports.skillGrowth.complete();
    this._detailedReports.skillGrowth = new ReplaySubject<ChartConfiguration>(
      1
    );
    this._modal.complete();
    this._modal = new ReplaySubject<ChartConfiguration>(1);
    this._updated = null;
  }
}
