<div class="wrapper">
<div class="member-dashboard-reports main-container">
<div class="mobile-report tab-sm-block">
  <tabset class="nested-tab tab-show">
    <tab heading="{{page.assessments}}">
      <ng-container *ngIf="topSkills?.length">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 top-skils mt-4">
            <div class="title-main">
              <h1 class="mb-3 pl-2">{{page.topSkills}}</h1>
            </div>
            <div class="top-skils-list">
              <div class="list-group" *ngFor="let skill of topSkills">
                <a class="list-group-item" aria-current="true">
                  <span><img [src]="getLogo(skill.uid)" alt="time-management" class="flex-shrink-0" /></span>
                  <div class="content-between">
                    <h6 class="mb-0">{{ skill.name }}</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="dashContArea dashContAreaStudent px-5 mt-2">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex align-items-center">
              <div class="title-main">
                <h1 class="mb-0">{{page.title}}</h1>
              </div>
            </div>

            <div
              class="col-md-12 col-lg-6 filters mt-3 mt-lg-0 filters d-flex align-items-center justify-content-start justify-content-lg-end">
              <div class="row mb-3">
                <div class="col-xs-12 col-sm-6 col-md-4 mb-2 mb-lg-0">
                  <select [(ngModel)]="selectedGroup" (change)="updateFilters()">
                    <option [ngValue]="null">{{labels.allGroups}}</option>
                    <option *ngFor="let group of groups" [ngValue]="group">
                      {{ group.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 mb-2 mb-lg-0">
                  <select [(ngModel)]="selectedDomain" (change)="updateFilters()">
                    <option [ngValue]="null">{{labels.allDomains}}</option>
                    <option *ngFor="let domain of domains" id="mylist" [ngValue]="domain">
                      {{ domain.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 mb-2 mb-lg-0">
                  <select [(ngModel)]="selectedTimespan" (change)="updateFilters()">
                    <option value="all time">{{labels.allTime}}</option>
                    <option value="30 days">30 {{labels.days}}</option>
                    <option value="90 days">90 {{labels.days}}</option>
                    <option value="180 days">180 {{labels.days}}</option>
                    <option value="1 year">1 {{labels.year}}</option>
                    <option value="5 years">5 {{labels.years}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        <div class="col-md-12  col-sm-6">
          <div class="row mb-4">
            <div class="col-xs-12 col-sm-12 col-xl-6 mb-3 mb-xl-0">
              <div class="boxMain card-white card-white-sm border-radius-md">
                <div class="boxHead card-title-main flex-wrap">
                    <h5>{{page.selfVsPeer}}</h5>
                    <span
                      [ngbTooltip]=page.selfVsPeerInfo
                      triggers="click"
                      tooltipClass="my-custom-class"
                      placement="left">
                      <span class="info-circle">
                        <img src="assets/img/info-ico-card.svg" alt="info" />
                      </span>
                    </span>
                    <p class="col-9 mb-0 pl-0">{{page.compareYourSkills}}</p>
                  </div>
                  <div class="chartArea px-0">
                    <div
                      class="tabHead col-sm-12 col-md-12 space-between align-items-center d-flex justify-content-between">
                      <h5 class="mb-0 font-sm"> {{selectedDomain == null? labels.allDomains:selectedDomain.name}}</h5>
                      <a (click)="updateDashboardDomain('')" class="link-purple"
                        *ngIf="selectedDomain == null?'':selectedDomain.name">{{page.showAllDomains}}
                      </a>
                    </div>
                    <div class="chart-wrapper">
                      <canvas #myCanvas width="200" height="200" chart-click="onClick"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-xl-6">
                <div class="boxMain card-white card-white-sm border-radius-md">
                  <div class="boxHead card-title-main flex-wrap">
                    <h5>{{page.strengthsAndWeaknesses}}</h5>
                    <span
                      [ngbTooltip]= page.strengthAndWeaknessesInfo
                      triggers="click"
                      tooltipClass="my-custom-class"
                      placement="left">
                      <span class="info-circle">
                        <img src="assets/img/info-ico-card.svg" alt="info" />
                      </span>
                    </span>
                    <p class="col-9 mb-0 pl-0">{{page.recognizeStrengthsAndWeaknesses}}</p>
                  </div>
                  <div
                      class="tabHead col-sm-12 col-md-12 space-between align-items-center d-flex justify-content-between pt-3">
                      <h5 class="mb-0"> {{selectedDomain == null? labels.allDomains:selectedDomain.name}}</h5>
                      <a (click)="updateDashboardDomain('')" class="link-purple"
                        *ngIf="selectedDomain == null?'':selectedDomain.name">{{page.showAllDomains}}
                      </a>
                    </div>

                  <ng-container *ngIf="!!strengthsAndWeaknesses?.length">
                    <div *ngFor="let data of strengthsAndWeaknesses" class="strengthRow list-bordered-btm-dotted" (click)="updateDashboardDomain(data.name)">
                      <div class="strengthCol w-100 d-flex align-items-center justify-content-between" >
                        <div class="list-item">
                          {{ data.name }}
                        </div>
                        <div class="strengthCol">
                          <div class="list-img">
                            <img src="{{data.url}}" alt="graph" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!strengthsAndWeaknesses?.length">
                    <div class="p-3">{{labels.insufficientData}}</div>
                  </ng-container>
                  <div *ngIf="!!strengthsAndWeaknesses?.length" class="chartBtmArea legend-btm col-12">
                    <div class="row w-100">
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-good.svg" alt="graph" /></div>
                        <div>{{labels.good}} &gt;4</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-ok.svg" alt="graph" /></div>
                        <div>{{labels.ok}} &gt;3-4</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-improvement.svg" alt="graph" /></div>
                        <div>{{labels.improvement}} &lt;=3</div>
                      </div>
                      <div class="chartBtmCol col-3">
                        <div><img src="assets/img/graph-insufficient1.svg" alt="graph" /></div>
                        <div>{{labels.insufficientData}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <ng-container *ngIf="selectedDomain != null">
                <div class="col-xs-12 col-sm-12 col-xl-6 mb-3 mb-xl-0">
                  <div class="boxMain card-white card-white-sm border-radius-md px-0">
                    <div class="boxHead card-title-main flex-wrap">
                      <h5>{{page.selfAwareness}}</h5>
                      <span
                        [ngbTooltip]=page.selfAwarenessInfo
                        triggers="click"
                        tooltipClass="my-custom-class"
                        placement="left">
                        <span class="info-circle">
                          <img src="assets/img/info-ico-card.svg" alt="info" />
                        </span>
                      </span>
                      <p class="col-9 mb-0 pl-0">{{page.seeYourRatings}}</p>
                    </div>
                    <div
                        class="tabHead col-sm-12 col-md-12 space-between align-items-center d-flex justify-content-between pt-3">
                        <h5 class="mb-0"> {{selectedDomain == null? labels.allDomains:selectedDomain.name}}</h5>
                        <a (click)="updateDashboardDomain('')" class="link-purple"
                          *ngIf="selectedDomain == null?'':selectedDomain.name">{{page.showAllDomains}}
                        </a>
                      </div>
                    <ng-container *ngIf="!!selfAwareness?.length">
                      <div class="pt-4 strengthRow list-bordered-btm-dotted">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-6 col-md-4 mt-2" *ngFor="let data of selfAwareness" (click)="updateDashboardDomain(data.name)">
                              <div class="text-center">
                                <img src="{{data.url}}" alt="graph" />
                              </div>
                              <div class="text-center text-gray-lgt">
                                {{ data.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!selfAwareness?.length">
                      <div class="p-3">{{labels.insufficientData}}</div>
                    </ng-container>
                    <div *ngIf="!!selfAwareness?.length" class="chartBtmArea btn-row-chart">
                      <div class="chartBtmCol">
                        <span class="no-entry"></span>
                        {{labels.insufficientData}}
                      </div>
                      <div class="chartBtmCol">
                        <span class="bad"></span>
                        {{page.notAligned}} <br />(> +/-1)
                      </div>
                      <div class="chartBtmCol">
                        <span class="ok"></span>
                       {{page.partialAligned}} <br />(+/-0.25 to +/-1)
                      </div>
                      <div class="chartBtmCol">
                        <span class="good"></span>
                        {{page.aligned}} <br />
                        (&lt;= +/-0.25)
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-xs-12 col-sm-12 col-xl-6">
                <div class="boxMain card-white card-white-sm border-radius-md px-0">
                  <div class="boxHead card-title-main flex-wrap">
                    <h5>{{page.growth}}</h5>
                      <div class="col-auto d-flex mt-2 mt-md-0 mb-2 mb-lg-0 align-items-center px-0">
                      <select [(ngModel)]="selectedGrowthDomain" (change)=" updateGrowthFilters()">
                      <option *ngFor="let domain of domains" [ngValue]="domain">{{domain.name}}</option>
                    </select>
                    <span [ngbTooltip]= "page.growthInfo" triggers="click" tooltipClass="my-custom-class" placement="left" class="pl-2">
                      <span class="info-circle">
                        <img src="assets/img/info-ico-card.svg" alt="info" />
                      </span>
                    </span>

                    </div>
                    <p class="col-9 mb-0 pl-0">{{page.seeYourGrowth}}</p>
                    </div>
                  <div class="{{ !growth ? 'd-none' : '' }}">
                    <div class="chartArea line-chart p-0">
                      <canvas #lineChart></canvas>
                    </div>
                  </div>
                  <ng-container *ngIf="!growth">
                    <div class="p-3">{{labels.insufficientData}}</div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab heading="{{page.skillBuilders}}" id="tab1" class="db-report">
      <app-member-skill-builder-report>
      </app-member-skill-builder-report>
    </tab>
  </tabset>
</div>
</div>
</div>
