import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReflectionData } from '@pages/member/skill-builder/reflection/common';
import { Domain2Service } from '@services/public';
import { APIErrors } from '@util';
import { config } from 'environment';
import { Observable } from 'rxjs';

export interface ReflectionServiceCreateLogResponse {
  goalPlanUUID: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReflectionService {

  constructor(
    private _domainSvc: Domain2Service,
    private _http: HttpClient
  ) { }

  createLog(
    data: ReflectionData
  ): Observable<ReflectionServiceCreateLogResponse | APIErrors> {
    return this._http.post<ReflectionServiceCreateLogResponse | APIErrors>(
      `${config.apiBase}member/challenge/reflection/log/create`,
      {
        strengths: data.strengths,
        weaknesses: data.weaknesses,
        impacts: data.impacts,
        skill: data.skill,
        action: data.action,
        domainUID: (
          data.domainUID === 0 ?
            this._domainSvc.getSkill(data.skillUID).domain.uid :
            data.domainUID),
        skillBuilderType: data.skillBuilderType
      });
  }

}
