import { Component, Input, OnInit } from '@angular/core';
import { LABELS, LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ABSTRACT_PAGE, AbstractData, AbstractReason } from '../common';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.summary';

@Component({
  selector: 'app-member-skill-builder-abstract-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class MemberSkillBuilderAbstractSummaryComponent implements OnInit {

  @Input() data: AbstractData;
  reasons: Array<AbstractReason> = [];

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subtitle: '',
    basedOnRating: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.NEXT]: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    this._languageSvc.getLabels(this.labels);

    // Calculate ratings and order reasons.
    let totalRatings = 75;
    this.reasons.push(...this.data.positiveReasons.map(reason => {
      reason.rating = reason.extreme * reason.big;
      totalRatings += reason.rating;
      return reason;
    }));
    this.reasons.push(...this.data.negativeReasons.map(reason => {
      reason.rating = -1 * reason.extreme * reason.big;
      totalRatings += reason.rating;
      return reason;
    }));
    this.reasons.sort((a, b) => b.rating - a.rating);
    this.data.generatedRating = Math.round(totalRatings / 15);
  }

  nextOnClick(): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, {
      ...this.data,
      page: ABSTRACT_PAGE.COMFORT
    });
  }

}
