import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GroupTagPopupComponent } from "../group-tag-popup/group-tag-popup.component";
import { GroupService, TagService } from "@services/member";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "@services/public";

@Component({
  selector: "app-join-group-tag",
  templateUrl: "./join-group-tag.component.html",
})
export class JoinGroupTagComponent implements OnInit {
  @Input() isGroup: any;
  modalRef?: BsModalRef;
  message?: string;
  groupsData = [];
  tagsData = [];
  memberGroup: any;
  dialogMessage: string;
  memberTag: any;
  leaveRecoed: any;
  accExpandedTag: boolean = false;
  accExpandedGroup: boolean = false;
  pageLanguage: any;
  labels: any;
  path: string = `pages.member.joinGroupTag`;
  constructor(
    public grpSvc: GroupService,
    public tagSvc: TagService,
    private modalSvc: BsModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _languageSvc: LanguageService
  ) {}

  ngOnInit(): void {
    this._languageSvc.get([this.path]).then((value) => {
      this.pageLanguage = value[this.path];
    });
    this._languageSvc.get([`labels`]).then((value) => {
      this.labels = value[`labels`];
    });
    this.grpSvc.groups.subscribe((groups) => {
      this.groupsData = groups;
      this.memberGroup = this.groupsData.filter(
        (element) => !(element.pending === false && element.member === false)
      );
      this.memberGroup.map((group) => {
        if (group.name) {
          group.name = this.capitalizeFirstLetter(group.name);
        }
      });
    });

    //load group
    this.grpSvc.loadGroups();

    this.tagSvc.tags.subscribe((tags) => {
      this.tagsData = tags;
      this.memberTag = this.tagsData.filter(
        (element) => !(element.pending === false && element.member === false)
      );
      this.memberTag.map((tag) => {
        if (tag.name) {
          tag.name = this.capitalizeFirstLetter(tag.name);
        }
      });
    });
    //load tags
    this.tagSvc.loadTags();
  }

  capitalizeFirstLetter(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  expandAccTag() {
    if (this.accExpandedTag) {
      this.accExpandedTag = false;
    } else {
      this.accExpandedTag = true;
    }
  }

  expandAccGroup() {
    if (this.accExpandedGroup) {
      this.accExpandedGroup = false;
    } else {
      this.accExpandedGroup = true;
    }
  }
  openModalListPopUp() {
    let initialState: any = {
      isGroup: this.isGroup,
    };
    this.modalRef = this.modalSvc.show(GroupTagPopupComponent, {
      class: "modal-md modal-dialog-centered",
      backdrop: "static",
      keyboard: true,
      initialState,
    });
  }

  joinGroup() {
    this.isGroup = true;
    this.openModalListPopUp();
  }

  joinTag() {
    this.isGroup = false;
    this.openModalListPopUp();
  }

  leave() {
    if (this.isGroup) {
      this.grpSvc
        .leaveGroup({ uuid: this.leaveRecoed.uuid })
        .subscribe((res) => {
          this.modalRef?.hide();
          this.successDialogBox(this.pageLanguage.groupDeleteSuccess);
          this.grpSvc.loadGroups();
        });
    } else {
      this.tagSvc.leaveTag({ uuid: this.leaveRecoed.uuid }).subscribe((res) => {
        this.modalRef?.hide();
        this.successDialogBox(this.pageLanguage.tagDeleteSuccess);
        this.tagSvc.loadTags();
      });
    }
  }

  successDialogBox(title: any) {
    Swal.fire({
      title: title,
      icon: "success",
      confirmButtonColor: "#456790",
      confirmButtonText: "Ok",
    });
  }

  openModal(template: TemplateRef<any>, event, leaveRecord) {
    this.leaveRecoed = leaveRecord;
    if (event == "group") {
      this.dialogMessage = this.pageLanguage.confirmLeaveGroup;
      this.isGroup = true;
    } else {
      this.dialogMessage = this.pageLanguage.confirmLeaveTag;
    }
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  decline() {
    this.message = this.pageLanguage.declined;
    this.modalRef?.hide();
  }

  navigation() {
    if (this.activatedRoute.snapshot.paramMap.get("fromSetting")) {
      this.router.navigate(["/member/settings"]);
    } else {
      this.router.navigate(["/member/dashboard"]);
    }
  }
}
