import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService, LogoutService } from "@services/public";
import { config } from "environment";
import { firstValueFrom, map, Observable, ReplaySubject } from "rxjs";

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

// Organization metadata
export interface Organization {
  uuid: string;
  name: string;
  active?: boolean;
  addUser?: boolean;
  createGroup?: boolean;
  createTag?: boolean;
}

export const NO_ORGANIZATIONS: Organization = {
  uuid: null,
  name: "No Organizations",
  active: false,
  addUser: false,
  createGroup: false,
  createTag: false,
}

@Injectable({
  providedIn: "root",
})
export class OrganizationService {

  private _organizations: ReplaySubject<Array<Organization>> =
    new ReplaySubject<Array<Organization>>(1);
  private _updated: Date | null = null;

  constructor(
    private http: HttpClient,
    private logoutSvc: LogoutService,
    private loaderSvc: LoaderService
  ) {
    // Bind the logout function to clear data.
    this.logoutSvc.subscribe(this.logout.bind(this));
  }

  // Reloads all organization metadata.
  loadOrganizations(): void {
    const loader: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated)
      this.loaderSvc.addLoader(
        loader,
        "services/member/organization:loadOrganizations"
      );
    this.http
      .get<any>(`${config.apiBase}member/organization/list`)
      .pipe(
        map((response: any) => {
          if (!response.errors) {
            this._organizations.next(response);
            this._updated = new Date();
            this.loaderSvc.removeLoader(loader);
          }
        }).bind(this)
      )
      .subscribe();
  }

  // Gets all organization metadata. Refreshes if data is outdated.
  get organizations(): Observable<Array<Organization>> {
    if (
      !this._updated ||
      this._updated.valueOf() + UPDATE_INTERVAL < new Date().valueOf()
    )
      this.loadOrganizations();
    return this._organizations.asObservable();
  }

  // Gets an organization's name based on its UUID.
  async organizationName(uuid: string): Promise<string> {
    const organizations = await firstValueFrom(this.organizations);
    const organization = organizations.find(
      (organization) => organization.uuid === uuid
    );
    if (typeof organization === "undefined") return "Unknown Organization";
    return organization.name;
  }

  // Clears the organization data upon logout and resets the service.
  private logout(): void {
    this._organizations.complete();
    this._organizations = new ReplaySubject<Array<Organization>>(1);
    this._updated = null;
  }

  getOrganizations(): Observable<any> {
    return this.http.get<any>(`${config.apiBase}leader/organization/list`);
  }

  viewOrganizations(uuid): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}account/organization/view?uuid=${uuid}`
    );
  }

  acceptOrganization(reqBody): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}account/organization/accept`,
      { uuid: reqBody }
    );
  }

  declineOrganization(reqObj): Observable<any> {
    return this.http.delete<any>(
      `${config.apiBase}account/organization/decline`,
      {
        body: { uuid: reqObj },
      }
    );
  }

}
