import { Component, OnInit} from "@angular/core";
import {Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import { Subscription } from "rxjs";

import {
  Assessment,
  AssessmentStatus,
  AssessmentService,
  AssessmentType,
} from "@services/leader";
import { Domain, DomainService, LoaderService, Skill, LanguageService } from "@services/public";
import { LeaderAssessmentCreateComponent } from "@pages/leader/assessment/create/create.component";
import { GroupService } from "@services/member";
import {User, UserService } from "@services/viewer";
import { DatePipe } from "@angular/common";

const PAGE_PATH = `pages.leader.assessment.skills`;

@Component({
  selector: "app-leader-assessment-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.scss"],
})
export class LeaderAssessmentSkillsComponent implements OnInit {
  AssessmentStatus: typeof AssessmentStatus = AssessmentStatus; // Expose this enum to the template.
  AssessmentType: typeof AssessmentType = AssessmentType; // Expose this enum to the template.

  domains: Array<Domain> = [];
  allDomainsAndSkills: any = {};
  isCreate: any = true;

  assessment: Assessment = {
    uuid: null,
    name: "-",
    type: null,
    startDate: "-",
    endDate: "-",
    status: null,
    groupUUID: null,
    group: {
      uuid: null,
      name: "-",
      abbreviation: null,
      timezone: null,
      startDate: null,
      endDate: null,
      memberUUIDs: null,
      users: null,
      organizationUUID: "",
      member: null,
      role: null,
    },
    skills: [],
    subgroups: null,
    users: null,
  };
  groups: any;
  modalRef?: BsModalRef;

  users: Array<User> | null = null;
  user: User | null = null;

  // Page language.
  page: {[key: string]: string} = {
  }

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private domainSvc: DomainService,
    private assessmentSvc: AssessmentService,
    private router: Router,
    private modalSvc: BsModalService,
    private loaderSvc: LoaderService,
    private groupSvc: GroupService,
    private usersSvc: UserService,
    private _languageSvc: LanguageService
  ) {}

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );
    // Load domains.
    this.domainSvc.getDomains().then((response) => {
      this.domains = response;
    });
    this.groupSvc.groups.subscribe((groups) => {
      this.groups = groups;
    });
    this.groupSvc.loadGroups();
    // Load domains and skills.
    this.domainSvc.getAll().then((response) => {
      this.allDomainsAndSkills = response;
    });

    // Load assessment.
    const createExternal = JSON.parse(localStorage.getItem("createAssessment"));
    if (createExternal.from === "view") {
      this.isCreate = false;
      this.assessmentSvc
        .getAssessment(createExternal.formValue.uuid)
        .pipe(
          map((response: any) => {
            if (!!response) {
              response.group = this.groups.find(
                (group) => group.uuid === response.groupUUID
              );
              response.skills = response.skillUIDs;
              this.getAssessment(response);
            }
          }).bind(this)
        )
        .subscribe();
    } else {
      createExternal.formValue.status = "INACTIVE";
      createExternal.formValue.skillUIDs = [];
      createExternal.formValue.subgroups = [];
      if (createExternal.formValue.type === AssessmentType.SELF) {
        if (Array.isArray(createExternal.formValue.selectedUsers)
        && createExternal.formValue.selectedUsers.length > 0) {
        createExternal.formValue.users =
          createExternal.formValue.selectedUsers.map((user) => user.uuid);
        }
      }
      this.getAssessment(createExternal.formValue);
    }
    // Get users.
    this._subscriptions.add(
      this.usersSvc.users.subscribe((users) => {
        this.users = users;        
      })
    );
    this.usersSvc.loadUsers();
        
      
  }

  getAssessment(assessment) {
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    assessment.startDate = new Date(assessment.startDate);
    assessment.endDate = new Date(assessment.endDate);
    this.assessment = assessment;
    this.assessment.skills = assessment.skillUIDs.map(
      (skillUID: number) => this.allDomainsAndSkills[skillUID.toString()]
    );
    this.loaderSvc.removeLoader(loader);
  }

  editAssessment() {
    this.modalRef = this.modalSvc.show(LeaderAssessmentCreateComponent, {
      class: "modal-dialog-centered",
    });
    this.modalRef.content.assessment = this.assessment;
    this.modalRef.content.filteredUsers = this.users.filter((user) =>
      this.assessment.group.memberUUIDs.includes(user.uuid)
    );
    const createAssessment = JSON.parse(
      localStorage.getItem("createAssessment")
    );
    if (this.assessment.type === AssessmentType.SELF)
    {
      if (createAssessment.from !== "view") {
        const usersSelected = this.modalRef?.content?.form.value.selectedUsers;
        if (usersSelected === 'ALL' || usersSelected === null || usersSelected === 'undefined') {
          this.modalRef.content.checkAll.checked = true;
          this.modalRef.content.selectedUsers = this.modalRef.content.filteredUsers
        }
        else if (Array.isArray(usersSelected)) {
          this.modalRef.content.selectedUsers = usersSelected;
          this.modalRef.content.tab1.click();
        }
      }
      else{ 
        if (this.assessment.subgroups?.length > 0) {
          this.modalRef.content.selectedUsers = 
            this.modalRef.content.filteredUsers.filter((user: User) => 
              this.assessment.subgroups.flat().includes(user.uuid));
          this.modalRef.content.tab1.click();
        }
      }
    }
    this.modalRef.onHide.subscribe((_response) => {
      if (createAssessment.from === "view") {
        this.isCreate = false;
        this.assessment.name = createAssessment.formValue.name;
        this.assessment.group = createAssessment.formValue.group;
        this.assessment.startDate = createAssessment.formValue.startDate;
        this.assessment.endDate = createAssessment.formValue.endDate;
        if (this.assessment.type === AssessmentType.SELF) {
          this.assessment.subgroups = Array.isArray(
            this.modalRef.content.selectedUsers)?
            [this.modalRef.content.selectedUsers.map(
              (user: User) => {return user.uuid})]: null;
        }
        this.getAssessment(this.assessment);
      } else {
        createAssessment.formValue.status = "INACTIVE";
        createAssessment.formValue.skillUIDs = [];
        createAssessment.formValue.type = this.assessment.type;
        createAssessment.formValue.subgroups = [];
        createAssessment.formValue.users =  Array.isArray(
          this.modalRef.content.selectedUsers)? 
          this.modalRef.content.selectedUsers.map(
            (user: User) => {return user.uuid}): null;
        this.getAssessment(createAssessment.formValue);
      }
    });
  }

  toggleSkill(skill: Skill): void {
    if (!this.assessment.skills.includes(skill))
      this.assessment.skills.push(skill);
    else
      this.assessment.skills = this.assessment.skills.filter(
        (selected) => selected !== skill
      );
  }

  onSubmit() {
    const reqSelfData = {
      uuid: this.assessment.uuid,
      name: this.assessment.name,
      type: this.assessment.type,
      groupUUID: this.assessment.group.uuid,
      startDate: new DatePipe("en-US").transform(
        this.assessment.startDate,
        "yyyy-MM-dd"
      ),
      endDate: new DatePipe("en-US").transform(
        this.assessment.endDate,
        "yyyy-MM-dd"
      ),
      skillUIDs: this.assessment.skills.map((skill) => skill.uid)
    };
    // Make sure skills are selected.
    if (!this.assessment.skills.length) return;

    // Set users or subgroups for self and leader assessments.
    if (this.assessment.type === AssessmentType.SELF) {
      this.isCreate? reqSelfData["users"] = this.assessment.users:
      reqSelfData["subgroups"] = this.assessment.subgroups;
    }

    // Submit self or leader assessments.
    if (this.assessment.type === AssessmentType.SELF ||
        this.assessment.type === AssessmentType.LEADER) {
      const loader: unique symbol = Symbol();
      this.loaderSvc.addLoader(loader);
      if (this.isCreate) {
        delete reqSelfData.uuid;
        // Create assessment skills and schedule.
        this.assessmentSvc
          .createAssessment(reqSelfData)
          .pipe(
            map((response: any) => {
              if (!!response.success) {
                this.loaderSvc.removeLoader(loader);
                localStorage.removeItem("createAssessment");
                let title = this.page.assessmentCreated;
                this.successMessage(title);
              }
            }).bind(this)
          )
          .subscribe();
      } else {
        this.assessmentUpdate(reqSelfData, loader);
      }
    } else {
      if (this.isCreate)
        localStorage.setItem(
          "createAssessment",
          JSON.stringify({ formValue: this.assessment, from: "create" })
        );
      else
        localStorage.setItem(
          "createAssessment",
          JSON.stringify({ formValue: this.assessment, from: "view" })
        );
      this.router.navigate(["leader/assessment/subgroups"]);
    }
  }

  assessmentUpdate(reqUpdatedObj, loader) {
    // Update assessment skills and schedule self assessments.
    this.assessmentSvc
      .updateAssessment(reqUpdatedObj)
      .pipe(
        map((response: any) => {
          if (!!response.success) {
            this.loaderSvc.removeLoader(loader);
            localStorage.removeItem("createAssessment");
            let title = this.page?.assessmentUpdated;
            this.successMessage(title);
          }
        }).bind(this)
      )
      .subscribe();
  }

  successMessage(title) {
    Swal.fire({
      title: title,
      icon: "success",
      confirmButtonColor: "#456790",
      confirmButtonText: this.page?.ok,
      didDestroy: () => this.router.navigate(["leader/dashboard"]),
    });
  }
}
