<div class="position-relative py-5 px-3">
  <div class="thumbs-img-wrapper"><img src="assets/img/thumbs-up.svg" class="img-fluid img-md-thumb" /></div>
  <button #closebutton type="button" class="close close-top" data-dismiss="modal" (click)="decline()" aria-label="labels.close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="card-title-pop-up mb-0">{{popUpInfo.title}}</div>
  <div *ngIf="popUpInfo.title =='Well Done!'" class="card-text pb-3">{{page.passedCycle}} <strong>{{popUpInfo.level}}</strong> {{page.of}} <br /><strong>{{popUpInfo.domains}}</strong></div>
  <p class="card-text pb-1">{{page.nextWeekCycle}} <strong>{{popUpInfo.nextLevel}}</strong> {{page.of}} <br /><strong>{{popUpInfo.nextDomain}}</strong></p>
  <div class="pt-4 d-flex d-md-none align-items-center justify-content-center">
    <button (click)="decline()" type="button" class="btn btn-md">
      {{labels.close}}
    </button>
  </div>
</div>