import { Component,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  DoCheck
} from '@angular/core';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { CarouselService } from '@services/member/carousel.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})

export class CarouselComponent implements DoCheck {
  // Define the local configuration variable
  @Input() localCarouselConfig: any = {
    _animation: true,
    interval: 0,
    keyboard: true,
    showNavigationIndicators: true,
    showNavigationArrows: true,
    wrap: true,
    pauseOnFocus: true,
    pauseOnHover: false,
    _ngbConfig: true,
    animation: true
  };
  @Input() public slides: any;
  domain?: any;
  type?: any;
  @ViewChild('carousel') carousel: NgbCarousel;
  @ViewChild('carouselWrapper', { static: false }) carouselWrapper: ElementRef;
  carouselShow: boolean = true;
  first_id: string = null;
  last_id: any;
  leftArrow: HTMLElement;
  rightArrow: HTMLElement;
  clickListenerAdded: boolean = false;
  activeID: any;
  animationState: 'collapsed' | 'expanded' = 'collapsed';
  posIni: any;
  move(pos:number) {
    const offset = this.posIni - pos;
    if (offset < -100) this.carousel.prev()

    if (offset > 100) this.carousel.next();
  };
  last_slide: boolean = false;

  constructor(
    private _carouselConfig: NgbCarouselConfig,
    private _carouselSvc: CarouselService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {
    this._carouselConfig = this.localCarouselConfig;
  }

  get isCarouselVisible() {
    return this._carouselSvc.isCarouselVisible;
  }

  set isCarouselVisible(visible){
    this._carouselSvc.isCarouselVisible = visible

  }

  showCarousel() {
    if(this.isCarouselVisible && this.carousel.slides.toArray().length > 0) {
      this.carousel.select(this.carousel.slides.first.id);
    }
    this._carouselSvc.toggleCarousel();
    if (this.isCarouselVisible) {
      this.carouselWrapper.nativeElement.classList.remove('hide');
      const scrollPos = window.scrollY;
      const vpHeight = window.innerHeight;
      if (scrollPos / vpHeight > .3) {
        const carouselTop = scrollPos;
        this._renderer.setStyle(this.carouselWrapper.nativeElement,
          'top', `${carouselTop}px`);
      }
      this.animationState = 'expanded';
    } else {
      setTimeout(()=>{
        this.carouselWrapper.nativeElement.classList.add('hide');
        this.animationState = 'collapsed';
        this.first_id = null;
        this.last_id = null;
      },100);
    }
  }

  nextSlide(
    leftArrow:HTMLElement,
    rightArrow:HTMLElement,
    first_id: string,
    last_id:string
  ): void {
    if (this.carousel.activeId === this.carousel.slides.last.id) {
      rightArrow.classList.add('hide');
      leftArrow.classList.remove('hide');
    }
    if (this.carousel.activeId === first_id) {
      leftArrow.classList.add('hide');
      rightArrow.classList.remove('hide')  ;
    }
    if (this.carousel.activeId != last_id && this.carousel.activeId != first_id) {
      leftArrow.classList.remove('hide');
      rightArrow.classList.remove('hide');
    }
  }

  ngDoCheck(): void {
    if (!!this.isCarouselVisible) {
      if (!!this.carousel) {
        setTimeout(() => {
          if (
            !!this.carousel &&
            !!this.carousel?.slides &&
            !!this._carouselSvc.isCarouselVisible
          ) {
            if(!this.first_id || !this.last_id) {
              this.first_id = this.carousel.slides.first.id;
              this.last_id = this.carousel.slides.last.id;
            }
            // Now, you should be able to access the carousel.slides property safely.
          }
        }, 1000);
      }
      if (!this.leftArrow) {
        this.leftArrow = this._elementRef.nativeElement.querySelector(
          '.carousel-control-prev'
        );
      }
      if (!this.rightArrow) {
        this.rightArrow = this._elementRef.nativeElement.querySelector(
          '.carousel-control-next'
        );
      }

      if(!this.clickListenerAdded) {
        setTimeout(() =>{
          this._renderer.listen(this.leftArrow, 'click', () => {
            this.nextSlide(
              this.leftArrow,
              this.rightArrow,
              this.first_id,
              this.last_id
            );
          });
          this._renderer.listen(this.rightArrow, 'click', () => {
            this.nextSlide(
              this.leftArrow,
              this.rightArrow,
              this.first_id,
              this.last_id
            );
          });
          this.clickListenerAdded = true;
        }, 1000);
      }
      if(this.leftArrow) {
        if (this.carousel.activeId === this.last_id) {
          this.rightArrow.classList.add('hide');
          this.leftArrow.classList.remove('hide');
          this.last_slide = true;
        }else{
          this.last_slide =false;
        }
        if (this.carousel.activeId === this.first_id) {
          this.leftArrow.classList.add('hide');
          this.rightArrow.classList.remove('hide')  ;
        }
        if (
          this.carousel.activeId != this.last_id &&
          this.carousel.activeId != this.first_id
        ) {
          this.leftArrow.classList.remove('hide');
          this.rightArrow.classList.remove('hide');
        }
        if (this.carousel.slides.length <= 1) {
          this.leftArrow.classList.add('hide');
          this.rightArrow.classList.add('hide');
          this.last_slide = true;
        }
      }
    }
  }

}
