<app-skill-builder-select-list
  [mainTitle]="page.title" [options]="options" [next]="next">
</app-skill-builder-select-list>

<ng-template #NoHelpTemplate>
  <div class="modal-body modal-center px-0">
    <div class="modal-header bottom-border-0 px-0 pr-4 pb-0 d-none d-lg-flex">
      <button
        type="button"
        #closebutton
        class="close pb-0"
        data-dismiss="modal"
        (click)="closeOptionModal()">
        &times;
      </button>
    </div>
    <div class="col-12 my-4 px-4">
      <h5 class="title-main text-center">
        {{ page.noHelp }}
      </h5>
    </div>
    <div class="wrapper">
      <div class="main-container positive-brain-group pb-0">
        <div class="positive-brain-list w-auto">
          <div class="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
            <div class="list-box">
              <input
                class="list-group-item-check"
                type="radio"
                name="option.value"
                id="tryDifferent"
                value="tryDifferent" />
              <label class="list-group-item" (click)="tryDifferent()">
                {{ page.tryDifferent }}
              </label>
            </div>
            <div class="list-box">
              <input
                class="list-group-item-check"
                type="radio"
                name="option.value"
                id="trySame"
                value="trySame" />
              <label class="list-group-item" (click)="trySame()">
                {{ page.trySame }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #LittleHelpTemplate>
  <div class="modal-body modal-center">
    <p class="mt-3 text-center success-msg px-5 title-main">
      {{ page.littleHelp }}
    </p>

    <div
      class="pt-4 d-flex align-items-center justify-content-center">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-md btn-bordered-purple">
        {{ page.ok }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #LotOfHelpTemplate>
  <div class="modal-body modal-center">
    <div class="d-flex justify-content-center align-items-center mt-3">
      <div class="success-checkmark">
        <i class="fas fa-check"></i>
      </div>
    </div>
    <p class="mt-3 text-center success-msg px-5 title-main">
      {{ page.lotOfHelp }}
    </p>

    <div
      class="pt-4 d-flex align-items-center justify-content-center">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-md btn-bordered-purple">
        {{ page.ok }}
      </button>
    </div>
  </div>
</ng-template>
