import { Component, OnInit } from '@angular/core';
import { LABELS, LanguageService } from '@services/public';
import { BsModalRef } from 'ngx-bootstrap/modal';

const PAGE_PATH: string = 'pages.member.dashboard.activities.congratulationsPopUp.page';

@Component({
  selector: 'app-congratulations-pop-up',
  templateUrl: './congratulations-pop-up.component.html',
  styleUrls: ['./congratulations-pop-up.component.scss']
})
export class CongratulationsPopUpComponent implements OnInit {
  message?: string;
  popUpInfo:any;

  // Page langauge.
  page: {[key: string]: string} = {
    of: '',
    passedCycle: '',
    pageLanguage: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.CLOSE]: '',
    [LABELS.DECLINED]: ''
  }
  
  constructor(
    private _languageSvc: LanguageService,
    private _modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value
          );
        }
      }
    );
    this._languageSvc.getLabels(this.labels);
  }

  decline() {
    this.message = this.labels.declined;
    this._modalRef.hide();
  }
  
}
