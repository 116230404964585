import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { LanguageService } from '@services/public';
import { GoalCompleted, GoalData } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';
import { GoalService } from '@services/member';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { APIErrors, APISuccess, NO_HTML_PATTERN, isAPISuccess } from '@util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.goal.pages.track';

@Component({
  selector: 'app-member-skill-builder-goal-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class MemberSkillBuilderGoalTrackComponent implements OnInit {

  @Input() data: GoalData;
  @ViewChild('no', { static: false }) noTemplate: TemplateRef<void>;
  @ViewChild('yes', { static: false }) yesTemplate: TemplateRef<void>;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }
  formNo: FormGroup;
  formYes: FormGroup;
  submitted: boolean = false;
  maxChars: number = 400;
  private _modalRef: BsModalRef;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    titleNo: '',
    titleYes: '',
    placeholder: '',
    error1: '',
    error2: '',
    cancel: '',
    submit: ''
  }

  constructor(
    private _goalSvc: GoalService,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Get options list.
    const path: string = 'skillBuilders.goal.completed';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.options.next(Object.values(value[path]).map(
          (option: SelectListOption) => {
            if (option.value === this.data.completed)
              option.selected = true;
            return option;
          }));
        else this.options.next([]);
      });

    // Setup forms.
    this.formNo = new FormGroup({
      answerNo: new FormControl('',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
    });
    this.formYes = new FormGroup({
      answerYes: new FormControl('',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
    });
  }

  nextOnClick(option: SelectListOption): void {
    this.data.completed = option.value;
    this._modalRef = this._modalSvc.show(
      (option.value === GoalCompleted.YES ? this.yesTemplate : this.noTemplate),
      {
        class: "modal-sm modal-dialog-centered",
        backdrop: "static",
        keyboard: true,
      }
    );
  }

  cancelOnClick(): void {
    this.submitted = false;
    this._modalRef?.hide();
  }

  submitOnClick(): void {
    const form: FormGroup =
      (this.data.completed === GoalCompleted.YES ? this.formYes : this.formNo);
    const answer: AbstractControl =
      (this.data.completed === GoalCompleted.YES ?
        form.controls.answerYes :
        form.controls.answerNo);
    answer.setValue((answer.value || '').trim());
    this.submitted = true;
    if (!form.valid) return;
    this.data.help = answer.value;
    this._goalSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this._modalRef?.hide();
          this._router.navigate(['member/dashboard']);
        }
      }
    );
  }

}
