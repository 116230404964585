// Exposes the resolve and reject functions to be triggered externally.
export class ExposedPromise<T> extends Promise<T> {
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;

  constructor(executor?: (
    resolve: (value: T | PromiseLike<T>) => void,
    reject: (reason?: any) => void
  ) => void) {
    let resolvePromise: (value: T | PromiseLike<T>) => void;
    let rejectPromise: (reason?: any) => void;
    executor = executor ?? (() => {});
    super((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
      executor(resolve, reject);
    });
    this.resolve = resolvePromise;
    this.reject = rejectPromise;
  }

  toPromise(): Promise<T> {
    return Promise.resolve(this);
  }
}
