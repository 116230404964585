export const config = {
  production: true,
  apiBase: 'https://qa.jakapa.com/api/v2/',
  clientID: 'e05db121-e191-463d-9a1c-86c16a36c687',
  redirectURI: 'https://qa.jakapa.com/',
  apple: {
    redirectURI: 'https://qa.jakapa.com/member/dashboard',
    serviceID: 'com.jakapa.qa',
  },
  firebaseConfig: {
    apiKey: "AIzaSyBcBbDIGhPRiIe_ZqZ9LOmfRFM4TWxhOFM",
    authDomain: "jakapa-skill-builder-app.firebaseapp.com",
    projectId: "jakapa-skill-builder-app",
    storageBucket: "jakapa-skill-builder-app.appspot.com",
    messagingSenderId: "529874557268",
    appId: "1:529874557268:web:3ba777cb70fe81636fd0c1",
    measurementId: "G-GN4VMBBP35"
  }
};
