import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { WWYDData, WWYD_BENEFICIAL, WWYD_ETHICAL, WWYD_PAGE } from '../common';
import { ReplaySubject } from 'rxjs';
import { SelectListNext, SelectListOption } from '@components';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.chooseAction';
const ETHICAL_SCORES: { [key: string]: number } = {
  [WWYD_ETHICAL.VERY_ETHICAL]: 6,
  [WWYD_ETHICAL.SOMEWHAT_ETHICAL]: 3,
  [WWYD_ETHICAL.SOMEWHAT_UNETHICAL]: -3,
  [WWYD_ETHICAL.VERY_UNETHICAL]: -6
};
const BENEFICIAL_SCORES: { [key: string]: number } = {
  [WWYD_BENEFICIAL.VERY_BENEFICIAL]: 4,
  [WWYD_BENEFICIAL.SOMEWHAT_BENEFICIAL]: 2,
  [WWYD_BENEFICIAL.SOMEWHAT_DETRIMENTAL]: -2,
  [WWYD_BENEFICIAL.VERY_DETRIMENTAL]: -4
};

@Component({
  selector: 'app-member-skill-builder-wwyd-choose-action',
  templateUrl: './choose-action.component.html',
  styleUrls: ['./choose-action.component.scss']
})
export class MemberSkillBuilderWwydChooseActionComponent implements OnInit {

  @Input() data: WWYDData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    // Get options list.
    this.options.next(
      this.data.actions
      .map(action => {
        return {
          display: action.display,
          value: ETHICAL_SCORES[action.ethical] +
            BENEFICIAL_SCORES[action.beneficial],
          selected: (action.display === this.data.chosenAction)
        };
      })
      .sort((a, b) => b.value - a.value)
    );
  }

  nextOnClick(option: SelectListOption): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.WWYD, {
      ...this.data,
      page: WWYD_PAGE.FINAL_CHOICE,
      chosenAction: option.display
    });
  }

}
