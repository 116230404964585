<div class="main-container positive-brain-group">
  <div class="container px-0">
    <div class="positive-brain-list w-auto">
      <div
        class="list-group list-group-checkable d-grid gap-2 border-0 w-auto"
        [formGroup]="form"
      >
        <span
          class="mainTitle w-auto text-center mb-0 px-3"
          [innerHTML]="mainTitle"
        ></span>
        <span class="text-center" [innerHTML]="subTitle"></span>
        <div
          class="list-box"
          *ngFor="let optionControl of optionList.controls; let i = index"
          formArrayName="{{ 'optionList' + id }}"
        >

          <input
            class="list-group-item-check"
            type="radio"
            id="{{ cachedOptions[i].display + id }}"
            [value]="cachedOptions[i]"
            name="{{ 'optionList' + id }}"
            [formControl]="optionList.controls[0]"
            (change)="onChange()"
            *ngIf="max === 1"
          />
          <input
            class="list-group-item-check"
            type="checkbox"
            id="{{ cachedOptions[i].display + id }}"
            [value]="cachedOptions[i]"
            [formControl]="optionControl"
            (change)="onChange()"
            *ngIf="max !== 1"
          />

          <label
            class="list-group-item"
            for="{{ cachedOptions[i].display + id }}"
          >
            {{ cachedOptions[i].display }}
          </label>

          <span
            class="pl-2 d-block"
            type="button"
            ngbTooltip="{{ cachedOptions[i].info?.tooltip || cachedOptions[i].info?.display }}"
            tooltipClass="my-custom-class w-tooltip"
            placement="left"
            triggers="click"
            (click)="displayInfo(cachedOptions[i])"
            *ngIf="cachedOptions[i].info"
          >
            <img src="assets/img/Info-icon.svg"/>
          </span>
          <span
            class="pl-2 d-block"
            type="button"
            tooltipClass="my-custom-class w-tooltip"
            placement="left"
            triggers="click"
            (click)="displayLink(cachedOptions[i])"
            *ngIf="cachedOptions[i].link"
          >
            <img src="assets/img/download.svg"/>
          </span>
          <span
            *ngIf="cachedOptions[i].custom && !!custom?.delete"
            class="d-block"
          >
            <button
              type="button"
              (click)="custom.delete(cachedOptions[i])"
              class="btn trash-btn"
            >
              <img
                src="assets/img/delete.svg"
                alt="delete.svg"
              />
            </button>
          </span>
        </div>

        <button
          *ngIf="!!custom?.add || !!custom?.onClick"
          type="button"
          class="btn btn-outline-primary text-muted py-3 w-100 mt-2 mb-20"
          (click)="(custom.onClick || addCustom.bind(this))()"
        >
          <i class="fa fa-regular fa-plus mr-2 text-primary"></i>
          {{ custom.display || page.addCustom }}
        </button>

        <div
          *ngIf="submitted && selectedCount === 0"
          class="invalid-feedback text-center"
        >
          {{ page.error1 }}
        </div>

        <div
          *ngIf="selectedCount > max"
          class="invalid-feedback text-center"
        >
          {{ page.error2 }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!!next?.onClick" class="main-container positive-brain-group">
  <div class="container px-0">
    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4">
        {{ next.display || labels.next }}
      </button>
    </div>
  </div>
</div>

<ng-template #addCustomTemplate>
  <div class="modal-body text-center modal-confirm">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        {{ custom.display || page.addCustom }}
      </h4>
    </div>
    <div class="col-12 my-4 px-4" [formGroup]="custom.form">
      <textarea
        class="form-control txtarea-bordered"
        id="{{ 'option' + id }}"
        rows="3"
        [placeholder]="labels.typeHere"
        formControlName="{{ 'option' + id }}"
        class="form-control"
        maxlength="140"
        [ngClass]="{ 'is-invalid':
          custom?.form?.controls['option' + id].touched
            && custom?.form?.controls['option' + id].errors
        }"
      >
      </textarea>
      <div
        *ngIf="custom?.form?.controls['option' + id].touched
          && custom?.form?.controls['option' + id].errors?.required"
        class="invalid-feedback"
      >
        {{ page.error3 }}
      </div>
      <div
        *ngIf="custom?.form?.controls['option' + id].touched
          && custom?.form?.controls['option' + id].errors?.pattern"
        class="invalid-feedback"
      >
        {{ labels.errorNoHTML }}
      </div>
    </div>
    <div class="pt-4">
      <button
        (click)="cancelCustom()"
        type="button"
        class="btn btn-md ml-3"
      >
        {{ labels.cancel }}
      </button>
      <button
        (click)="saveCustom()"
        type="button"
        class="btn btn-md btn-primary"
      >
        {{ labels.save }}
      </button>
    </div>
  </div>
</ng-template>
