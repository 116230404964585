import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from 'environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  //delete Group
  getUserList(query?: any): Observable<any> {
    if (query === undefined) {
      query = "";
    }
    return this.http.get<any>(
      `${config.apiBase}researcher/management/user/list${query}`
    );
  }

  deleteUser(useruuid) {
    return this.http.put<any>(
      `${config.apiBase}researcher/management/user/edit`,
      useruuid
    );
  }

  updateUser(reqBody) {
    return this.http.put<any>(
      `${config.apiBase}researcher/management/user/edit`,
      reqBody
    );
  }

  getUserById(query?: any): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}researcher/management/user/view?uuid=${query}`
    );
  }

  unsubscribe(reqBody): Observable<any> {
    return this.http.put<any>(
      `${config.apiBase}researcher/management/user/edit`,
      reqBody
    );
  }

}
