import { SkillBuilderData } from "@services/member";

export enum ADVENTURE_PAGE {
  CHOOSE = 'CHOOSE',
  IMPACT = 'IMPACT'
}

export const ADVENTURE_PREVIOUS_PAGE = {};

// Start flow.
ADVENTURE_PREVIOUS_PAGE[ADVENTURE_PAGE.CHOOSE] = null;

// Track flow.
ADVENTURE_PREVIOUS_PAGE[ADVENTURE_PAGE.IMPACT] = null;

export enum ADVENTURE_IMPACT {
  A_LOT = 'a-lot',
  A_LITTLE = 'a-little',
  NO = 'no'
};

export interface AdventureData extends SkillBuilderData {
  page?: ADVENTURE_PAGE;
  adventure: string;
  logUUID: string;
  impact: ADVENTURE_IMPACT;
};

export interface AdventureReportPie {
  aLot: number;
  aLittle: number;
  no: number;
};

export interface AdventureReportData {
  completed: number;
  pie: AdventureReportPie;
};
