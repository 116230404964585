
<div class="main-container w-100" *ngIf="display">
  <div class="container-fluid pb-5-sm-c pb-5-md-c">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="col-12 px-0">
          <app-skill-builder-calendar
            [events]="calendarEvents"
            [legend]="calendarLegend"
            [title]="page.calendarTitle"
            skillBuilderType="stress"
            class="w-100 mb-3"
          ></app-skill-builder-calendar>
        </div>

        <div class="col-12 px-0 components-skill-builder-calendar">
          <div
            class="card card-rounded d-flex align-items-center"
            [ngClass]="stressLevelColor"
          >
            <div class="card-body pt-3 d-flex align-items-center">
              <div class="col-12 d-flex align-items-center px-0">
                <div class="analysis-txt pl-0 black">
                  {{ page.stressLevel }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-6">
        <div class="col-12 px-0">
          <div class="card card-rounded">
            <div class="card-header card-header-with-ico">
              <div class="col-12">
                <div class="row">
                  <div class="col-10 pl-0">
                    {{ page.destressTitle }}
                  </div>
                  <div
                    class="col-2 d-flex align-items-center justify-content-end"
                  >
                    <div
                      *ngIf="destressList.length > show || show !== SHOW_MIN"
                      class="circle-md d-flex align-items-center justify-content-center"
                      (click)="toggleMore()"
                    >
                      <i
                        class="{{
                          show === SHOW_MIN ? ICON_CLASSES.DOWN : ICON_CLASSES.UP
                        }}"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="col-12 mb-3">
                <ul
                  class="legend d-flex justify-content-center justify-content-md-start dotted-border-btm pb-3"
                >
                  <li>
                    <span class="good circle-sm"></span>
                    {{ page.veryHelpful }}
                  </li>
                  <li>
                    <span class="ok circle-sm"></span>
                    {{ page.somewhatHelpful }}
                  </li>
                  <li>
                    <span class="bad circle-sm"></span>
                    {{ page.notHelpful }}
                  </li>
                </ul>
              </div>
              <ul class="list-bordered-btm list-bordered-btm-dotted px-2">
                <li
                  class="d-flex justify-content-between flex-column pb-4 progress-bar-list"
                  *ngFor="let destressItem of destressList | slice: 0:show; let i = index;"
                >
                  {{ destressItem.destress }}
                  <span>
                    <div class="progress">
                      <div
                        class="good progress-bar"
                        role="progressbar"
                        [ngStyle]="{ 'width': destressItem[2] + '%' }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ destressItem[2] + '%' }}
                      </div>
                      <div
                        class="ok progress-bar"
                        role="progressbar"
                        [ngStyle]="{ 'width': destressItem[1] + '%' }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ destressItem[1] + '%' }}
                      </div>
                      <div
                        class="bad progress-bar"
                        role="progressbar"
                        [ngStyle]="{ 'width': destressItem[0] + '%' }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ destressItem[0] + '%' }}
                      </div>
                    </div>
                  </span>
                </li>
              </ul>
            </div>

            <div
              *ngIf="destressList.length > show || show !== SHOW_MIN"
              class="btn-row py-4 d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                (click)="toggleMore()"
                class="btn btn-small btn-outline btn-outline-purple"
              >
                {{ show === SHOW_MIN ? page.viewMore : page.viewLess }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <app-skill-builder-list
            [title]="page.symptomsTitle"
            [data]="symptomsList"
            class="w-100 mb-3"
          ></app-skill-builder-list>
        </div>
      </div>
    </div>
  </div>
</div>
