<div
  class="main-container w-100 d-flex align-items-center justify-content-center">
  <div class="container px-0 w-sm-100 w-50">
    <h4 class="w-auto text-center mb-0 px-3">
      {{ page.title }}
    </h4><br>

    <div class="card card-white card-white-sm mb-5">
      <div class="card-body">
        <span class="mt-0 text-center tag-card title-main font-weight-normal mb-0" [innerHTML]="page.generatedSummary">
        </span>
        <div class="footer-inner-action">
          <button
            type="button"
            (click)="nextOnClick()"
            class="btn btn-primary btn-lg px-4">
            {{ page.next }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
