<div class="card card-white card-white-sm mt-2 mb-1">
  <div class="card-body px-2">
    <h5>
      {{ title }}
    </h5>
    <div class="d-flex flex-column" [formGroup]="form">
      <ngx-star-rating
        formControlName="{{ 'rating' + id }}"
        id="{{ 'rating' + id }}"
        (change)="change(rating.value)"
      ></ngx-star-rating>
      <div class="col-12 px-3">
        <div class="dot-sm {{ reverseColors ? 'positive' : '' }}"></div>
        <div class="dotted-line">
          <i
            class="fas fa-chevron-right right-arrow {{
              reverseColors ? 'neutral' : ''
            }}"
          ></i>
        </div>
        <div class="col-12 rating-label">
          <div class="lft">{{ left }}</div>
          <div class="rgt">{{ right }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="submitted && rating.errors?.required" class="invalid-feedback">
  {{ errorRequired || page.errorRequired}}
</div>
