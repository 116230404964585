import { Component, Input, OnInit } from '@angular/core';
import { MessageData } from "../common";
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService } from '@services/public';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from '@services/member/skill-builder/message.service';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.message.pages.impact';

@Component({
  selector: 'app-member-skill-builder-message-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class MemberSkillBuilderMessageImpactComponent implements OnInit {

  @Input() data: MessageData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: ''
  };

  constructor(
    private _languageSvc: LanguageService,
    private _messageSvc: MessageService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          if (key === 'title')
            this._languageSvc.template(
              this.page[key], { name: this.data?.name }
            ).then(value => this.page[key] = value);
          else if (key === 'subTitle')
            this._languageSvc.template(
              this.page[key], { message: this.data?.message }
            ).then(value => this.page[key] = value);
          else this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    const path: string = 'skillBuilders.message.impact';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = Object.values(value[path]);

        this.options.next(this.defaultOptions.map(
          (value: SelectListOption) => {
            if (value.value === this.data?.impact)
              return { ...value, selected: true };
            return value;
          }));
      });
  }

  nextOnClick(option: SelectListOption): void {
    const data: MessageData = Object.assign({}, this.data, {
      impact: option.value
    });
    this._messageSvc.updateLog(data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
      }
    );
  }

}
