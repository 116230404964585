export enum AVATARS {
  AVATAR1 = 'avatar1',
  AVATAR2 = 'avatar2',
  AVATAR3 = 'avatar3',
  AVATAR4 = 'avatar4',
  AVATAR5 = 'avatar5',
  AVATAR6 = 'avatar6',
  AVATAR7 = 'avatar7',
  AVATAR8 = 'avatar8',
  AVATAR9 = 'avatar9',
  AVATAR10 = 'avatar10',
  AVATAR11 = 'avatar11',
  AVATAR12 = 'avatar12',
  AVATAR13 = 'avatar13',
  AVATAR14 = 'avatar14',
  AVATAR15 = 'avatar15',
  AVATAR16 = 'avatar16',
  AVATAR17 = 'avatar17',
  AVATAR18 = 'avatar18',
  AVATAR19 = 'avatar19',
  AVATAR20 = 'avatar20',
  AVATAR21 = 'avatar21',
  AVATAR22 = 'avatar22',
  AVATAR23 = 'avatar23',
  AVATAR24 = 'avatar24',
  AVATAR25 = 'avatar25',
  AVATAR26 = 'avatar26',
  AVATAR27 = 'avatar27',
  AVATAR28 = 'avatar28',
  AVATAR29 = 'avatar29',
  AVATAR30 = 'avatar30',
  AVATAR31 = 'avatar31',
  AVATAR32 = 'avatar32',
  AVATAR33 = 'avatar33',
  AVATAR34 = 'avatar34',
  AVATAR35 = 'avatar35',
  AVATAR36 = 'avatar36',
  AVATAR37 = 'avatar37',
  AVATAR38 = 'avatar38',
  AVATAR39 = 'avatar39',
  AVATAR40 = 'avatar40',
  AVATAR41 = 'avatar41',
  AVATAR42 = 'avatar42',
  AVATAR43 = 'avatar43',
  AVATAR44 = 'avatar44',
  AVATAR45 = 'avatar45',
  AVATAR46 = 'avatar46',
  AVATAR47 = 'avatar47',
  AVATAR48 = 'avatar48',
  AVATAR49 = 'avatar49',
  AVATAR50 = 'avatar50',
  AVATAR51 = 'avatar51',
  AVATAR52 = 'avatar52',
  AVATAR53 = 'avatar53',
  AVATAR54 = 'avatar54',
  AVATAR55 = 'avatar55',
  AVATAR56 = 'avatar56',
  AVATAR57 = 'avatar57',
  AVATAR58 = 'avatar58',
  AVATAR59 = 'avatar59',
  AVATAR60 = 'avatar60',
  AVATAR61 = 'avatar61',
  AVATAR62 = 'avatar62',
  AVATAR63 = 'avatar63',
  AVATAR64 = 'avatar64',
  AVATAR65 = 'avatar65',
  AVATAR66 = 'avatar66',
  AVATAR67 = 'avatar67',
  AVATAR68 = 'avatar68',
  AVATAR69 = 'avatar69',
  AVATAR70 = 'avatar70',
  AVATAR71 = 'avatar71',
  AVATAR72 = 'avatar72',
  AVATAR73 = 'avatar73',
  AVATAR74 = 'avatar74',
  AVATAR75 = 'avatar75',
  AVATAR76 = 'avatar76',
  AVATAR77 = 'avatar77',
  AVATAR78 = 'avatar78',
  AVATAR79 = 'avatar79',
  AVATAR80 = 'avatar80',
  AVATAR81 = 'avatar81',
  AVATAR82 = 'avatar82',
  AVATAR83 = 'avatar83',
  AVATAR84 = 'avatar84',
  AVATAR85 = 'avatar85',
  AVATAR86 = 'avatar86',
  AVATAR87 = 'avatar87',
  AVATAR88 = 'avatar88',
  AVATAR89 = 'avatar89',
  AVATAR90 = 'avatar90',
  AVATAR91 = 'avatar91',
  AVATAR92 = 'avatar92',
  AVATAR93 = 'avatar93',
  AVATAR94 = 'avatar94',
  AVATAR95 = 'avatar95',
  AVATAR96 = 'avatar96',
  AVATAR97 = 'avatar97',
  AVATAR98 = 'avatar98',
  AVATAR99 = 'avatar99',
  AVATAR100 = 'avatar100',
  AVATAR101 = 'avatar101',
  AVATAR102 = 'avatar102',
  AVATAR103 = 'avatar103',
  AVATAR104 = 'avatar104',
  AVATAR105 = 'avatar105',
  AVATAR106 = 'avatar106',
  AVATAR107 = 'avatar107',
  AVATAR108 = 'avatar108',
  AVATAR109 = 'avatar109',
  AVATAR110 = 'avatar110',
  AVATAR111 = 'avatar111',
  AVATAR112 = 'avatar112',
  AVATAR113 = 'avatar113',
  AVATAR114 = 'avatar114',
  AVATAR115 = 'avatar115',
  AVATAR116 = 'avatar116',
  AVATAR117 = 'avatar117',
  AVATAR118 = 'avatar118',
  AVATAR119 = 'avatar119',
  AVATAR120 = 'avatar120',
  AVATAR121 = 'avatar121',
  AVATAR122 = 'avatar122',
  AVATAR123 = 'avatar123',
  AVATAR124 = 'avatar124',
  AVATAR125 = 'avatar125',
  AVATAR126 = 'avatar126',
  AVATAR127 = 'avatar127',
  AVATAR128 = 'avatar128',
  AVATAR129 = 'avatar129',
  AVATAR130 = 'avatar130',
  AVATAR131 = 'avatar131',
  AVATAR132 = 'avatar132',
  AVATAR133 = 'avatar133',
  AVATAR134 = 'avatar134',
  AVATAR135 = 'avatar135',
  AVATAR136 = 'avatar136',
  AVATAR137 = 'avatar137',
  AVATAR138 = 'avatar138',
  AVATAR139 = 'avatar139',
  AVATAR140 = 'avatar140'
}

const AVATAR_INDEXS: { [key: string]: number } = {};
for (const avatar of Object.values(AVATARS)) {
  AVATAR_INDEXS[avatar] = +avatar.slice(6) - 1;
}
const NUMBER_OF_COLUMNS = 10;

export function getAvatarCoordinates(
  avatar: AVATARS,
  size: number = 45
): { x: number, y: number } {
  return {
    x: (AVATAR_INDEXS[avatar] % NUMBER_OF_COLUMNS) * size,
    y: Math.floor(AVATAR_INDEXS[avatar] / NUMBER_OF_COLUMNS) * size
  };
}
