<app-skill-builder-select-list
  *ngFor="let action of data.actions"
  [mainTitle]="action.display"
  [options]="getOptions.bind(this, action)"
  [change]="onChange.bind(this, action)"
  [submitted]="submitted"
></app-skill-builder-select-list>
<div class="main-container positive-brain-group">
  <div class="container px-0">
    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4">
        {{ page.next }}
      </button>
    </div>
  </div>
</div>
