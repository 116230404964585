<div class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center pb-5-sm-c pb-5-md-c" [formGroup]="form">
  <div class="container px-0 w-sm-100 w-50">
    <div class="positive-brain-list w-auto">
      <h4 class="w-auto text-center mb-0 px-3">
        {{ page.title }}
      </h4>
      <span
        class="d-flex justify-content-center small text-center mt-2 sm-center-txt"
        >{{ page.subTitle }}.</span>
      <div class="col-12 d-flex justify-content-center mt-3">
        <div class="col-sm-12 col-lg-6 c-form d-flex flex-column align-items-center">
          <input 
            type="text" 
            class="form-control"
            aria-describedby=""
            formControlName="name"
            placeholder="{{ page.placeholder }}"
          />
          <div *ngIf="submitted && form.controls.name.errors" class="invalid-feedback">
            <div *ngIf="form.controls.name.errors.required || form.controls.name.errors.pattern">
              {{ page.error }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer-inner-action">
        <button 
          type="button" 
          (click)="next()" 
          class="btn btn-primary btn-lg px-4">
          {{ page.next }}
        </button>
      </div>
    </div>
  </div>
</div>
