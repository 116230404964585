import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  isCarouselVisible: boolean = false;

  constructor() { }

  toggleCarousel() {
    this.isCarouselVisible = !this.isCarouselVisible;
    if(this.isCarouselVisible){
      this.onClose.emit('not-finished');
    }else{
      this.onClose.emit('finished');
    }
  }
  // When carousel start to close
  onClose?: EventEmitter<unknown>  = new EventEmitter<unknown>();
}
