<div
  class="main-container w-100 d-flex align-items-center justify-content-center pb-lg"
>
  <div class="container px-0 w-sm-100 w-50">
    <div class="row">
      <div class="col-12 heading-1">
        <h4 class="w-auto text-center mb-0 px-3">
          {{page.title}}
        </h4>
        <div class="subtext">
          {{page.subtitle}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-white card-white-sm">
          <div class="card-body w-100 align-items-start text-gray-lgt">
            <ul
              *ngFor="let reason of reasons"
              class="list-bordered-btm list-bordered-btm-dotted w-100"
            >
              <li class="py-2">
                {{ reason.display }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card card-white card-white-sm">
          <div class="card-body w-100 text-gray-lgt">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                {{page.basedOnRating}}
              </div>
              <div>
                <span class="rating-count">{{ data.generatedRating }}</span>
                <span class="rating-count-sm">/10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4"
      >
        {{ labels.next }}
      </button>
    </div>
  </div>
</div>
