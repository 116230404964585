<div class="main-container w-100" *ngIf="display">
  <div class="container-fluid pb-5-sm-c pb-5-md-c">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
        <app-skill-builder-pie-chart
          [title]="page.pieTitle"
          [data]="pieData"
          class="w-100 mb-3"
        ></app-skill-builder-pie-chart>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" >
        <div class="row">
          <div class="card card-rounded">
            <div class="card-body pt-3 d-flex align-items-center">
              <div class="col-12 d-flex align-items-center px-0">
                <div
                  class="count-box d-flex align-items-center justify-content-center"
                >
                  {{ bestAction }}
                </div>
                <div class="analysis-txt">
                  {{ page.bestAction }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card card-rounded">
            <div class="card-body pt-3 d-flex align-items-center">
              <div class="col-12 d-flex align-items-center px-0">
                <div
                  class="count-box org d-flex align-items-center justify-content-center"
                >
                  {{ decisions }}
                </div>
                <div class="analysis-txt">
                  {{ page.decisions }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
