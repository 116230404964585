import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Chart } from 'chart.js/auto';
import { ChartConfiguration } from 'chart.js';
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from '@services/public';

export interface GraphFilter {
  key: string;
  value: any;
  options: Array<any>;
  optionsGenerator?: Function;
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('graph', { read: ElementRef }) graph: ElementRef;
  @ViewChild('graphContainer', { read: ElementRef }) graphContainer: ElementRef;
  title: string;
  filters: Array<GraphFilter>;
  update: Function;
  refresh: Subject<boolean>;
  graphChart: Chart;
  labels: any;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _languageSvc: LanguageService,
    public bsModalRef: BsModalRef) { }

    ngOnInit(): void {
      this._languageSvc.get([`labels`]).then((value) => {
        this.labels = value[`labels`];
      });
    }
  
  ngAfterViewInit(): void {
    this._subscriptions.add(this.refresh.subscribe(
      _next => this.updateFilters()));
    this.updateFilters();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  updateFilters(): void {
    // Compile filters.
    const filters = {};
    for (const filter of this.filters)
      filters[filter.key] = filter.value;

    // Get new configuration.
    const configuration: ChartConfiguration = this.update(filters);
    if (!configuration) return;

    // Destroy old graph.
    if (!!this.graphChart) this.graphChart.destroy();

    // Generate new graph.
    this.graphChart = new Chart(this.graph.nativeElement,
      configuration);
    this.graphContainer.nativeElement.style.height = `${
      Math.max((configuration.data.labels.length + 1) * 30, 420)
    }px`;
  }
}
