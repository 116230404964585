<div class="info-card {{type == 'reading'? 'domain': 'skb'}}">
  <div class="domain-logo {{type == 'reading'? 'domain': 'skb'}}">
    <div class="{{type=='reading'? domain :type}} icon"></div>
  </div>    
  <div class="header {{type == 'reading'? 'domain': 'skb'}}">
    <h2>{{header}}</h2>
  </div>
  <div *ngIf="isHTML(body)" > 
    <div [innerHTML]="body" class="body"></div>   
  </div>
  <div *ngIf="!isHTML(body)" #plainText class="body">
    {{body}}
  </div>   
</div>
