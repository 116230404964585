import { Component, Input, OnInit } from '@angular/core';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ABSTRACT_PAGE, AbstractData } from '../common';
import { TextListNext, TextListTextInput } from '@components';
import { ExposedPromise } from '@util';

const PAGE_PATH: string = 'skillBuilders.abstract.pages.negativeReasons';

@Component({
  selector: 'app-member-skill-builder-abstract-negative-reasons',
  templateUrl: './negative-reasons.component.html',
  styleUrls: ['./negative-reasons.component.scss']
})
export class MemberSkillBuilderAbstractNegativeReasonsComponent
  implements OnInit
{

  @Input() data: AbstractData;
  reasons: Array<TextListTextInput> = [
    { placeholder: '' },
    { placeholder: '' },
    { placeholder: '' }
  ];
  next: TextListNext = { onClick: this.nextOnClick.bind(this) };
  loaded: ExposedPromise<void> = new ExposedPromise<void>();
  private _languageLoaded: ExposedPromise<void> = new ExposedPromise<void>();

  // Page langauge.
  page: {[key: string]: string} = {
    subtitle: '',
    placeholder: '',
    errorMinimum: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        const promises: Array<Promise<void>> = [];
        for (const key in this.page) {
          switch (key) {
            case 'placeholder':
              for (let i: number = 0; i < this.reasons.length; i++) {
                promises.push(this._languageSvc.template(
                  this.page[key],
                  { index: (i + 1).toString() }
                ).then(value => { this.reasons[i].placeholder = value; }));
              }
              break;
            default:
              promises.push(this._languageSvc.template(this.page[key]).then(
                value => { this.page[key] = value }));
          }
        }
        Promise.all(promises).then(_value => this._languageLoaded.resolve());
      }
    );

    await this._languageLoaded;

    // Get negative reasons list.
    if (!!this.data.negativeReasons) {
      for (let i = 0; i < this.data.negativeReasons.length; i++)
        this.reasons[i].value = this.data.negativeReasons[i].display;
    }

    this.loaded.resolve();
  }

  nextOnClick(reasons: Array<string>): void {
    this._storageSvc.setTempStorage(TEMP_KEYS.ABSTRACT, {
      ...this.data,
      page: ABSTRACT_PAGE.POSITIVE_IMPACT,
      negativeReasons: reasons.map((value, index) => {
        if (
          !!this.data.negativeReasons &&
          value === this.data.negativeReasons[index]?.display
        ) {
          return this.data.negativeReasons[index];
        }
        return { display: value };
      })
    });
  }

}
