import { Component, OnInit } from "@angular/core";
import { OrganizationService } from "@services/member";
import { ActivatedRoute, Router } from "@angular/router";
import {LanguageService} from "@services/public";

@Component({
  selector: "app-accept-invitation",
  templateUrl: "./accept-invitation.component.html",
  styleUrls: ["./accept-invitation.component.scss"],
})
export class AcceptInvitationComponent implements OnInit {
  orgUUID: any;
  orgData: any;
  pageLanguage: any;
  path: string = `pages.public.acceptInvitation`;
  constructor(
    private orgSVC: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private _languageSvc: LanguageService
  ) {}

  ngOnInit(): void {
    this._languageSvc.get([this.path]).then((value) => {
      this.pageLanguage = value[this.path];
    });
    this.orgUUID = this.route.snapshot.paramMap.get("code");
    this.orgSVC.viewOrganizations(this.orgUUID).subscribe((res) => {
      this.orgData = res;
    });
  }

  acceptRequest() {
    this.orgSVC.acceptOrganization(this.orgUUID).subscribe((res) => {
      this.router.navigate(["/member/dashboard"]);
    });
  }

  declineRequest() {
    this.orgSVC.declineOrganization(this.orgUUID).subscribe((res) => {
      this.router.navigate(["/login"]);
    });
  }
}
