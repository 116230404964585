import { SkillBuilderData } from "@services/member";

export enum STRESS_PAGE {
  FEEL = 'FEEL',
  LOOK = 'LOOK',
  BRAIN = 'BRAIN',
  DESTRESS = 'DESTRESS',
  FEEL_TRACK = 'FEEL_TRACK',
  IMPACT = 'IMPACT',
  CHANGE_PLAN = 'CHANGE_PLAN'
}

export const STRESS_PREVIOUS_PAGE = {};

// Default start flow.
STRESS_PREVIOUS_PAGE[STRESS_PAGE.FEEL] = null;
STRESS_PREVIOUS_PAGE[STRESS_PAGE.LOOK] = STRESS_PAGE.FEEL;
STRESS_PREVIOUS_PAGE[STRESS_PAGE.BRAIN] = STRESS_PAGE.LOOK;
STRESS_PREVIOUS_PAGE[STRESS_PAGE.DESTRESS] = STRESS_PAGE.BRAIN;

// Default track flow.
STRESS_PREVIOUS_PAGE[STRESS_PAGE.FEEL_TRACK] = null;
STRESS_PREVIOUS_PAGE[STRESS_PAGE.IMPACT] = STRESS_PAGE.FEEL_TRACK;
STRESS_PREVIOUS_PAGE[STRESS_PAGE.CHANGE_PLAN] = null;

// Try different reliever flow.
export const STRESS_PREVIOUS_PAGE_TDR = {};
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.FEEL] = null;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.LOOK] = STRESS_PAGE.FEEL;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.BRAIN] = STRESS_PAGE.LOOK;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.DESTRESS] = null;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.FEEL_TRACK] = null;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.IMPACT] = STRESS_PAGE.FEEL_TRACK;
STRESS_PREVIOUS_PAGE_TDR[STRESS_PAGE.CHANGE_PLAN] = STRESS_PAGE.DESTRESS;

/**
// Start flow.
(start) > feel > look > brain > destress > (createPlan) > (end)

// Track flow.
(start) > feel-track > (choice1)
(1: noStress) > (createLog) > change-plan > (choice2)
(2: wantToChange) > feel > look > brain > (if not path 4) destress > (createPlan) > (end)
(2: noChange) > (if path 4) (createPlan) > (end)
(1: default) > impact > (choice3)
(3: noHelp) > (modal) > (choice4)
(4: tryDifferent) > (createLog) > destress > change-plan > (choice2)
(4: keeyTrying) > (createLog) > (end)
(3: default) > (createLog) > change-plan > (choice2)

// Page directions.
null < feel > look
feel < look > brain
look < brain > (path4 ? (createPlan) > (end) : destress)
(path4 ? null : brain) < destress > (path4 ? change-plan : (createPlan) > (end))
null < feel-track > (noStress ? (createLog) > change-plan : impact)
feel-track < impact > (noHelp ? (tryDifferent ? (createLog) > destress : (createLog) > change-plan) : (createLog) > change-plan)
(path4 ? destress : null) < change-plan > (wantToChange ? feel : (path4 ? (createPlan) > (end) : (end)))
*/

export interface StressData extends SkillBuilderData {
  page?: STRESS_PAGE;
  tryDifferentReliever?: boolean; // Path flag.
  feel?: Array<string>;
  look?: Array<string>;
  brain?: Array<string>;
  destress?: string;
  stress?: number;
  impact?: number;
}

export interface StressReportLog {
  date: string;
  destress: string;
  stress: number;
  impact: number;
}

export interface StressReportData {
  symptoms: Array<string>;
  logs: Array<StressReportLog>;
}
