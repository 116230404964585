import {Component,OnInit} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { GroupService, TagService } from "@services/member";
import { UserService } from "@services/viewer";
import { BsModalRef } from "ngx-bootstrap/modal";
import { LanguageService } from "@services/public";

@Component({
  selector: "app-group-tag-popup",
  templateUrl: "./group-tag-popup.component.html",
})
export class GroupTagPopupComponent implements OnInit {
  formGroup: FormGroup;
  successMessage: string;
  searchText: any;
  masterSelected: boolean;
  checkedList: any = [];
  modalRef?: BsModalRef;
  isGroup: any;
  message?: string;
  dialogTitle: string;
  records: any;
  placeholder: string;
  memberRecords: any;
  //TODO members
  groupsData = [];
  tagsData = [];

  //Page Language
  pageLanguage: any;
  labels: any;
  path: string = `pages.member.groupTagPopUp`

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public viewerSvc: UserService,
    public grpSvc: GroupService,
    public tagSvc: TagService,
    private _languageSvc: LanguageService
  ) {
    this.masterSelected = false;
  }
  ngOnInit() {
    this._languageSvc.get([this.path]).then((value) => {
      this.pageLanguage = value[this.path];
    });
    this._languageSvc.get([`labels`]).then((value) => {
      this.labels = value[`labels`];
    });

    if (this.isGroup) {
      this.dialogTitle = this.pageLanguage.joinGroup;
      this.placeholder = this.pageLanguage.groupPlaceholder;
      this.grpSvc.groups.subscribe((groups) => {
        this.groupsData = groups;
        this.records = this.groupsData.filter(
          (element) => element.pending === false && element.member === false
        );
        this.records.map((group) => {
          if (group.name) {
            group.name = this.capitalizeFirstLetter(group.name);
          }
        });
      });
    } else {
      this.dialogTitle = this.pageLanguage.joinTag;
      this.placeholder = this.pageLanguage.tagPlaceholder;
      this.tagSvc.tags.subscribe((tags) => {
        this.tagsData = tags;
        this.records = this.tagsData.filter(
          (element) => element.pending === false && element.member === false
        );
        this.records.map((tag) => {
          if (tag.name) {
            tag.name = this.capitalizeFirstLetter(tag.name);
          }
        });
      });
    }
    this.formGroup = this.fb.group({
      recordList: [null, Validators.requiredTrue],
    });

    this.memberRecords = this.records;
  }

  capitalizeFirstLetter(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  searchKey(data: string) {
    this.searchText = data;
    this.search();
  }

  search() {
    this.records =
      this.searchText === ""
        ? this.memberRecords
        : this.memberRecords.filter(
            (val) =>
              val.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
          );
  }

  decline() {
    this.bsModalRef.hide();
  }

  isAllSelected() {
    this.masterSelected = this.records.every(function (item: any) {
      return item.checked == true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.records.length; i++) {
      if (this.records[i].checked) this.checkedList.push(this.records[i].uuid);
    }
  }

  onSendRequest() {
    let requredObj: any;
    if (this.isGroup) {
      requredObj = {
        groupUUIDs: this.checkedList,
      };
      this.grpSvc.sendGroupRequest(requredObj).subscribe((res) => {
        this.bsModalRef.hide();
        this.successDialogBox(
         this.pageLanguage.requestSentSuccess
        );
        this.grpSvc.loadGroups();
      });
    } else {
      requredObj = {
        tagUUIDs: this.checkedList,
      };
      this.tagSvc.sendTagRequest(requredObj).subscribe((res) => {
        this.bsModalRef.hide();
        this.successDialogBox(
         this.pageLanguage.requestSentSuccess
        );
        this.tagSvc.loadTags();
      });
    }
  }

  successDialogBox(title: any) {
    Swal.fire({
      title: title,
      icon: "success",
      confirmButtonColor: "#456790",
      confirmButtonText: "Ok",
    });
  }
}
