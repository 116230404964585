<div class="modal-header">
  <h5 class="title">
    {{ page.title }}
  </h5>
  <button
    class="close"
    type="button"
    data-dismiss="modal"
    (click)="cancelOnClick()"
    [attr.aria-label]="labels.close"
  >
    <span aria-hidden="true">
      &times;
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="image-name-avatars">
    <div class="image-name">
      <div
        *ngIf="!!avatarPreview"
        class="avatar-image preview"
        [ngStyle]="getAvatarStyle(avatarPreview, 80)"
        alt="user icon"
      ></div>
      <img
        *ngIf="!avatarPreview"
        class="default-image"
        src="assets/img/2.svg"
        alt="user icon"
      />
      <div class="user-name">{{ userName }}</div>
    </div>
    <div class="avatars-container">
      <div class="avatars">
        <div
          *ngFor="let avatar of this.avatars"
          class="avatar-image {{
            avatar === avatarPreview ? 'selected' : ''
          }}"
          [ngStyle]="getAvatarStyle(avatar)"
          (click)="avatarOnClick(avatar)"
        ></div>
      </div>
    </div>
    <div *ngIf="error" class="invalid-feedback">
      {{ labels.errorSelectOption }}
    </div>
  </div>
  <div class="buttons">
    <button
      class="btn btn-md"
      type="button"
      (click)="cancelOnClick()"
    >
      {{ labels.cancel }}
    </button>
    <button
      class="btn btn-md btn-primary"
      type="button"
      (click)="saveOnClick()"
    >
      {{ labels.save }}
    </button>
  </div>
</div>
