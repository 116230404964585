<!-- Skill builder header -->
<!-- TODO: Refactor SCSS -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="back()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === REFLECTION_PAGE.STRENGTHS">
  <app-member-skill-builder-reflection-strengths [data]="data">
  </app-member-skill-builder-reflection-strengths>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.WEAKNESSES">
  <app-member-skill-builder-reflection-weaknesses [data]="data">
  </app-member-skill-builder-reflection-weaknesses>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.IMPACTS">
  <app-member-skill-builder-reflection-impacts [data]="data">
  </app-member-skill-builder-reflection-impacts>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.SKILL">
  <app-member-skill-builder-reflection-skill [data]="data">
  </app-member-skill-builder-reflection-skill>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.ACTION">
  <app-member-skill-builder-reflection-action [data]="data">
  </app-member-skill-builder-reflection-action>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.CUSTOM_ACTION">
  <app-member-skill-builder-reflection-custom-action [data]="data">
  </app-member-skill-builder-reflection-custom-action>
</ng-container>
<ng-container *ngIf="data?.page === REFLECTION_PAGE.SUMMARY">
  <app-member-skill-builder-reflection-summary [data]="data">
  </app-member-skill-builder-reflection-summary>
</ng-container>
