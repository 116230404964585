<div class="video-container">
  <video #videoPlayer class="videoPlayer" (ended)="handleVideoEnded()" controls poster="{{poster}}" preload="auto">
    <source *ngFor="let video of videos" [src]="video" type="video/{{ getFileExtension(video) }}">
    <track class="track"
    label="English"
    kind="subtitles"
    srclang="en"
    [src]="transcript"
    default />
  </video>
</div>



