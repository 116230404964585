import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject} from 'rxjs';
import { config } from 'environment';

@Injectable({
  providedIn: 'root'
})
export class WeeklyChallengeService {

  constructor(
    private http: HttpClient
  ) { }

  private challengeIDSubject = new BehaviorSubject<string>(null);
  challengeID$ = this.challengeIDSubject.asObservable();

  completeWeeklyChallenge(reqBody): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/challenge/weekly/complete`, reqBody);
  }

  getWeekly(): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/challenge/weekly/view?timezone=America/Chicago`);
  }

  setChallengeID(challengeID: string): void {
    this.challengeIDSubject.next(challengeID);
  }

}
