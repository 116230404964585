import { Component, OnInit, Renderer2, HostBinding, HostListener } from '@angular/core';


@Component({
  selector: 'app-member-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MemberMainComponent implements OnInit {
  @HostBinding('class') class = 'wrapper';
  @HostListener('window:click') onClick() {
    if (this.menuOpened) {
      if (this._opening)
        this._opening = false
      else
        this.toggleMenu();
    }
  }

  public menuOpened = true;
  public _opening = false;
 
  constructor(
    private renderer: Renderer2) { 
    }

  ngOnInit() {
    this.toggleMenu();
  }
  
  toggleMenu() {
    this.menuOpened = !this.menuOpened;
    if (this.menuOpened) {   
      this._opening = true;
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('body'),
        'lock-scroll'
      );
    } else {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.removeClass(
        document.querySelector('body'),
        'lock-scroll'
      );
    }
  }
}
