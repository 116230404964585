import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LABELS, LanguageService } from '@services/public';
import { NO_HTML_PATTERN } from '@util';

export interface TextBoxDisclaimer {
  display: string; // The display text for the disclaimer.
  error?: string; // The error if the disclaimer is not checked.
}

export interface TextBoxNext {
  display?: string; // The display text for the Next button.
  onClick: Function; // The function to call to submit the input.
}

@Component({
  selector: 'app-skill-builder-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class SkillBuilderTextBoxComponent implements OnInit {

  @Input() title: string = '';
  @Input() value: string = '';
  @Input() placeholder: string; // Default is LABELS.TYPE_HERE.
  @Input() errorRequired: string;
  @Input() disclaimer: TextBoxDisclaimer = null;
  @Input() next: TextBoxNext = null;

  form: FormGroup;
  submitted: boolean = false;

  // Page langauge.
  labels: {[key: string]: string} = {
    [LABELS.ERROR_DISCLAIMER]: '',
    [LABELS.ERROR_NO_HTML]: '',
    [LABELS.NEXT]: '',
    [LABELS.TYPE_HERE]: ''
  }

  constructor(
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.getLabels(this.labels);

    // Set defaults.
    this.placeholder = this.placeholder ?? this.labels[LABELS.TYPE_HERE];

    // Setup form.
    this.form = new FormGroup({
      textInput: new FormControl(
        this.value,
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)]
      ),
      disclaimer: new FormControl(!this.disclaimer, Validators.requiredTrue)
    });
  }

  get textInput(): FormControl {
    return <FormControl>this.form.controls.textInput;
  }

  nextOnClick(): void {
    this.textInput.setValue((this.textInput.value || '').trim());
    this.submitted = true;
    if (!this.form.valid) return;
    this.next.onClick(this.textInput.value);
  }

}
