<div class="col-md-12 col-lg-12">
  <div class="row mb-4 mt-3">
    <div class="col-xs-12 col-sm-12 col-md-12 mt-2 px-3">
        <div id="no-more-tables">
  <table class="table table-c">

    <thead #dataTableHeader class="thead-light">
      <tr >
        <th *ngFor="let item of headerData" (click)="item.sort?sort(item.field):'' " [ngClass]="table_header_highlight == item.header ? 'label_background' : ''">
          <div class="icon_th">
            <div> <span>{{ item.header}}</span> </div>
            <div *ngIf="item.sort" class="sort_arrows">
              <div>
                 <span  [ngClass]=" !isDesc ? 'text_label' : ''">
                    <img  [ngClass]="!isDesc ? 'text_label' : ''" src="assets/img/up.svg" />
                </span>
               </div>
                <div>
                    <span  [ngClass]=" isDesc ? 'text_label' : ''">
                        <img [ngClass]="isDesc ? 'text_label' : ''" src="assets/img/down.svg" />
                    </span>
                </div>
            </div>
        </div></th>
      </tr>
    </thead>
    <tbody
      #dataTableBody
      (scroll)="parallelScroll($event)"

    >
      <tr *ngFor="let row of tableData |paginate: { itemsPerPage:10, currentPage: p, totalItems:totalItems }; let i = index;">
        <td *ngFor="let headerItem of headerData" title="{{ row[headerItem.field] }} "attr.data-title="{{headerItem.header}}">
             <div *ngIf="headerItem.field == 'status'">
             <span *ngIf="row[headerItem.field] =='Active'">
              {{ row[headerItem.field] !== null?row[headerItem.field]: '-' }}</span>
              <span *ngIf="row[headerItem.field] !='Active'" style="color: red;">
                {{ row[headerItem.field] !== null?row[headerItem.field]: '-' }}</span></div>
                <div *ngIf="headerItem.field != 'status'">
                  <span *ngIf="headerItem.field != 'action' ">
                    {{ row[headerItem.field] !== null?row[headerItem.field]: '-' }}</span></div>
             <div *ngIf="headerItem.field == 'action'" class="actionColumn" >
             <span *ngIf="headerItem.isArchive && !row.archived" tooltip="Archive" class="cursor-pointer mx-2"><img src="assets/img/archive-icon.svg" alt="archive-icon.svg" (click)="rowClicked(row,'archived')" [class.disabled]="!row.canArchive"/></span>
             <span *ngIf="headerItem.isArchive && row.archived" tooltip="Unarchive" class="cursor-pointer mx-2"><img src="assets/img/unarchive-icon.svg" alt="unarchive-icon.svg" (click)="rowClicked(row,'unarchived')" [class.disabled]="!row.canArchive" /></span>
             <span *ngIf="row.canEdit" tooltip="Edit" class="cursor-pointer mx-2"><img src="assets/img/edit-gray.svg" alt="edit.svg" (click)="rowClicked(row,'edit')" [class.disabled]="!row.canEdit"/></span>
             <span *ngIf="!headerItem.status " tooltip="Delete" class="cursor-pointer mx-2"> <img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="rowClicked(row,'delete')" [class.disabled]="!row.canDelete"/></span>
             <span *ngIf=" headerItem.status " tooltip="Delete" class="cursor-pointer mx-2"> <img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="rowClicked(row,'delete')"/></span>
             <span *ngIf=" headerItem.status && row.status ==='Pending Approval' " tooltip="Approve" class="cursor-pointer mx-2"> <img src="./../../../../assets/img/approve-icon.svg" alt="approve-icon.svg" (click)="rowClicked(row,'approve')" /></span>
            </div>
        </td>
      </tr>
      <ng-container *ngIf="tableData?.length == 0">
        <tr *ngFor="
        let headerItem of headerData
          | paginate: { itemsPerPage: 10, currentPage: p }
      ">
        </tr>
        <td class="text-center pl-0-c" [attr.colspan]="headerData.length">
          {{page?.noRecord}}
        </td>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
      </tr>
    </tfoot>
  </table>

  <div class="w-sm-100 pagination-bottom" >
    <pagination-controls  previousLabel=""
    nextLabel="" class="my-pagination" (pageChange)="handlePageChange($event)" directionLinks="true" >
    </pagination-controls>
  </div>
</div>
</div>
</div>
</div>
