import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService, LogoutService } from '@services/public';
import { config } from 'environment';

const UPDATE_INTERVAL = 300000; // 5 minutes in milliseconds.

export interface AssessmentScores {
  self: Array<AssessmentScore>;
  peer: Array<AssessmentScore>;
  external: Array<AssessmentScore>;
}

export interface AssessmentScore {
  assessmentUUID: string;
  groupUUID: string;
  domainUID: number;
  skillUID: number;
  date: string; // 'YYYY-MM-DD' format.
  score: number;
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentScoresService {
  private _scores: ReplaySubject<AssessmentScores> = new ReplaySubject<AssessmentScores>(1);
  private _updated: Date | null = null;

  constructor(
    private http: HttpClient,
    private logoutSvc: LogoutService,
    private loaderSvc: LoaderService
  ) {
    this.logoutSvc.subscribe(this.logout.bind(this));
  }

  get scores(): Observable<AssessmentScores> {
    if (!this._updated ||
      this._updated.valueOf() + UPDATE_INTERVAL < (new Date()).valueOf())
      this.loadScores();
    return this._scores.asObservable();
  }

  async loadScores(): Promise<void> {
    const loader: unique symbol = Symbol();
    // Only show loader for the initial load.
    if (!this._updated) this.loaderSvc.addLoader(loader,
      'services/user/assessment-scores:loadScores');
    this.http.get<any>(
      `${config.apiBase}member/assessment/scores`).pipe(map(
      (response: any) => {
        if (!response.errors) {
          this._scores.next(response);
          this._updated = new Date();
          this.loaderSvc.removeLoader(loader);
        }
      }).bind(this)).subscribe();
  }

  private logout(): void {
    this._scores.complete();
    this._scores = new ReplaySubject<AssessmentScores>(1);
    this._updated = null;
  }

}
