<div
  *ngIf="display"
  class="main-container w-100 d-flex align-items-center justify-content-center"
>
  <div class="container-fluid pb-5-sm-c pb-5-md-c">
    <div class="col-12 px-0">
      <div class="col-12 d-flex justify-content-center align-items-center px-0">
        <div class="col-sm-12 col-lg-6">
          <div class="col-12 px-0">
            <div class="card card-rounded mt-3">
              <div class="card-body pt-3 d-flex align-items-center">
                <div class="col-12 d-flex align-items-center px-0">
                  <div
                    class="count-box d-flex align-items-center justify-content-center"
                  >
                    {{ totalDilemmas }}
                  </div>
                  <div class="analysis-txt">
                    {{ page.totalDilemmas }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
