<div class="btn-row d-flex">
  <button type="button" class="btn btn-primary" (click)="openModal(template)">
    <span><img src="assets/img/edit-gray.svg" alt="edit" /></span>
  </button>
</div>
<ng-template #template>
  <div class="modal-header">
    <h5 class="mb-0">{{pageLanguage?.title}}</h5>
    <button #closebutton type="button" class="close" data-dismiss="modal" (click)="decline()" [attr.aria-label]="labels?.close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="col-12 my-4 px-4">
      <ul class="list-btm-dotted">
        <li class="pt-0">
          <div class="col-12">
            <div class="row">
              <div class="col-sm-12 col-md-6">{{pageLanguage?.selectID}}</div>
              <div
                class="col-sm-12 col-md-6 btn-row d-flex align-items-center justify-content-end"
                (click)="modalRef?.hide()">
                <app-add-email
                  (addEmailEvent)="
                    addEmailEvent($event)
                  "></app-add-email>
              </div>
            </div>
          </div>
        </li>
        <li *ngFor="let obj of existingEmails; let i = index">
          <div class="col-12 custom-radios px-0 d-flex d-block-sm">
            <div class="col-sm-12 col-md-7 d-flex pl-0">
              <input
                type="radio"
                id="color-{{ i }}"
                name="color-{{ i }}"
                [value]="obj.email"
                [checked]="obj.primary"
                [disabled]="!obj.verified"
                (change)="onChange($event, i)" />
              <label for="color-{{ i }}" class="col-auto">
                <span>
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
              </label>
              <label class="px-2">{{ obj.email }}</label>
            </div>
            <div
            class="col-sm-12 col-md-5 remove-mail d-flex align-items-center justify-content-end">
            <div *ngIf="!obj.verified" class="btn-row px-2">
              <button
                type="button"
                class="btn btn-small btin-icon btn-secondary btn-secondary-sm"
                (click)="emailVerify(obj.email, i)">
                {{pageLanguage?.verify}}
              </button>
            </div>
            <div *ngIf="obj?.newVerified" class="btn-row px-2">
              {{temporaryText}}
            </div>
            <div *ngIf="!obj.verified" class="circle-ico" (click)="onDelete(i)">
              <img src="assets/img/delete-gray.svg" alt="delete" />
            </div>
          </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="pt-4 justify-content-center d-flex">
      <button type="button" class="btn btn-md" (click)="decline()">
        {{labels?.cancel}}
      </button>
      <button type="button" class="btn btn-md btn-primary" [disabled]="disabled" (click)="onSubmit()">
        {{labels?.save}}
      </button>
    </div>
  </div>
</ng-template>