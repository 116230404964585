<div
  class="main-container w-100 d-flex align-items-center justify-content-center pb-lg"
>
  <div class="container px-0 w-sm-100 w-50">
    <div class="row">
      <div class="col-12">
        <div class="card card-white card-white-sm">
          <div class="card-body px-2">
            <p class="subtext py-0">
              {{ data.idea }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-white card-white-sm">
          <div class="card-body px-2">
            <p class="subtext py-0">
              {{page.howRate}}
            </p>
            <div class="row">
              <div
                class="col-6 col-xl-12 circle-rating d-flex justify-content-end justify-content-lg-center align-items-center"
              >
                <rating
                  [(ngModel)]="data.userRating"
                  [max]="10"
                  [customTemplate]="ratingButton"
                ></rating>
                <ng-template #ratingButton let-i="index" let-v="value">
                  <button class="btn btn-{{ (i+1) <= v ? 'info' : 'default' }}">
                    {{ i + 1 }}
                  </button>
                </ng-template>
              </div>
              <div class="col-6 col-xl-12  rating-label-vertical">
              <div class="lft">{{page.left}}</div>
                <div class="mid">{{page.middle}}</div>
                <div class="rgt">{{page.right}}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="submitted && !data.userRating"
          class="invalid-feedback"
        >
          {{page.errorRequired}}
        </div>
      </div>
    </div>
    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4"
      >
        {{ labels.next }}
      </button>
    </div>
  </div>
</div>
