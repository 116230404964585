import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { LoaderService, AuthService, LanguageService } from '@services/public';

const PATH = `pages.public.verify`;

@Component({
  selector: 'app-public-email-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class PublicEmailVerifyComponent implements OnInit {

  verified: boolean = false;
  pageLanguage: any;

  constructor(
    private authSvc: AuthService,
    private route: ActivatedRoute,
    private loaderSvc: LoaderService,
    private _languageSvc: LanguageService) { }

  ngOnInit(): void {
    this._languageSvc.get([PATH]).then((value) => {
      this.pageLanguage = value[PATH];
    });
    // Submit email verification code.
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.authSvc.verifyEmail(this.route.snapshot.paramMap.get('code')).pipe(map(
      (response: any) => {
        if (response.success) this.verified = true;
        this.loaderSvc.removeLoader(loader);
      }).bind(this)).subscribe();
  }
}
