import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _loaders: Array<symbol> = [];
  _loaders2: Array<{ loader: symbol; name: string }> = [];

  constructor() {}

  addLoader(loader: symbol, name?: string): void {
    this.isLoading.next(true);
    this._loaders.push(loader);
    if (!!name) this._loaders2.push({ loader, name });

    // console.debug(`Active loaders: ${this._loaders2.map(value => value.name)}`);
  }

  removeLoader(loader: symbol): void {
    this._loaders = this._loaders.filter((value) => value !== loader);
    this._loaders2 = this._loaders2.filter((value) => value.loader !== loader);
    if (this._loaders.length === 0) this.isLoading.next(false);
    // console.debug(`Active loaders: ${this._loaders2.map(value => value.name)}`);
    // if (!this._loaders.length && !this._loaders2.length)
    //   console.debug(`All loaders removed.`);
    // else console.debug(`${this._loaders.length} unnamed loaders and ${this._loaders2.length} named loaders active.`);
  }

  clearLoaders(): void {
    this._loaders = [];
    this.isLoading.next(false);
  }
}
