import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TEMP_KEYS, StorageService, TEMP_KEY_PREFIX, LogoutService, LanguageService } from "@services/public";
import { Subscription, map } from "rxjs";
import { application } from "environment";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  modalRef?: BsModalRef;
  message?: string;
  @ViewChild("template") template: TemplateRef<any>;
  isGhost: boolean;
  application: boolean;
  pageLanguage: any;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private modalSvc: BsModalService,
    private logoutSvc: LogoutService,
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
    ) {}

  ngOnInit(): void {
    this._languageSvc.get([`pages.public.settings`]).then(
      (value)=>this.pageLanguage=value[`pages.public.settings`]);
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR) {
        this.isGhost = false;
      }
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.IS_GHOST)
        this.isGhost = update.value;
    }));
    this.isGhost = !!this._storageSvc.getTempStorage(TEMP_KEYS.IS_GHOST);
    // @ts-ignore
    this.application = application === 'jakapa.website';
  }

  logout(): void {
    this.logoutSvc.logout();
    this.router.navigate(["/"]);
  }

  openModal(template: any) {
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-md modal-dialog-centered",
      backdrop: "static",
      keyboard: true,
    });
  }
  resources() {
    window.open("https://jakapa.com/resources");
  }

  decline(): void {
    this.message = this.pageLanguage?.declined;
    this.modalRef?.hide();
  }

}
