import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Group } from "@services/member";
import { Skill } from "@services/public";
import { config } from "environment";

export interface Assessment {
  uuid: string;
  name: string;
  groupUUID: string;
  type: AssessmentType;
  startDate: string | Date;
  endDate: string | Date;
  status: AssessmentStatus;
  group: Group;
  skills: Array<Skill>;
  subgroups: Array<Array<any>>;
  users: Array<any>;
}

export enum AssessmentType {
  SELF = "self",
  PEER = "peer",
  LEADER = "leader",
}

export enum AssessmentStatus {
  INACTIVE = "INACTIVE",
  SCHEDULED = "SCHEDULED",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

@Injectable({
  providedIn: "root",
})
export class AssessmentService {

  constructor(
    private http: HttpClient
  ) { }

  getAssessment(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}leader/assessment/view?uuid=${encodeURIComponent(
        uuid
      )}`
    );
  }

  getAssessmentResults(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${
        config.apiBase
      }leader/assessment/results?uuid=${encodeURIComponent(uuid)}`
    );
  }

  getAssessments(): Observable<any> {
    return this.http.get<any>(`${config.apiBase}leader/assessment/list`);
  }

  createAssessment(assessment: any): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}leader/assessment/create`,
      assessment
    );
  }

  updateAssessment(assessment: any): Observable<any> {
    return this.http.put<any>(
      `${config.apiBase}leader/assessment/edit`,
      assessment
    );
  }

  updateAssessmentSkills(
    uuid: string,
    skillUIDs: Array<number>
  ): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}leader/assessment/skills`,
      { uuid, skillUIDs }
    );
  }

  updateAssessmentSubgroups(
    uuid: string,
    subgroups: Array<Array<string>>
  ): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}leader/assessment/subgroups`,
      { uuid, subgroups }
    );
  }

  releaseAssessmentResults(
    assessmentUUID: string,
    userUUIDs: Array<string>
  ): Observable<any> {
    return this.http.put<any>(
      `${config.apiBase}leader/assessment/release`,
      { assessmentUUID, userUUIDs }
    );
  }

  deleteAssessment(uuid): Observable<any> {
    return this.http.delete<any>(
      `${config.apiBase}leader/assessment/delete`,
      {
        body: { uuid: uuid },
      }
    );
  }

}
