export interface APISuccess {
  success: boolean;
}

export function isAPISuccess(
  value: unknown
): value is APISuccess {
  if (typeof value !== 'object' || value === null) return false;
  return !!(<APISuccess>value).success;
}

export interface APIError {
  type: string;
  data?: any;
}

export interface APIErrors {
  errors: Array<APIError>;
}

export function isAPIErrors(
  value: unknown
): value is APIErrors {
  if (typeof value !== 'object' || value === null) return false;
  return !!(<APIErrors>value).errors && !(<APISuccess>value).success;
}

export interface APINoData {
  noData: boolean;
}

export function isAPINoData(
  value: unknown
): value is APINoData {
  if (typeof value !== 'object' || value === null) return false;
  return !!(<APINoData>value).noData;
}
