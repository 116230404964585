import { SkillBuilderData } from "@services/member";

export enum REFLECTION_PAGE {
  STRENGTHS = 'STRENGTHS',
  WEAKNESSES = 'WEAKNESSES',
  IMPACTS = 'IMPACTS',
  SKILL = 'SKILL',
  ACTION = 'ACTION',
  CUSTOM_ACTION = 'CUSTOM_ACTION',
  SUMMARY = 'SUMMARY'
}

export const REFLECTION_PREVIOUS_PAGE = {};

// Start flow.
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.STRENGTHS] = null;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.WEAKNESSES] =
  REFLECTION_PAGE.STRENGTHS;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.IMPACTS] = REFLECTION_PAGE.WEAKNESSES;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.SKILL] = REFLECTION_PAGE.IMPACTS;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.ACTION] = REFLECTION_PAGE.SKILL;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.CUSTOM_ACTION] = REFLECTION_PAGE.SKILL;
REFLECTION_PREVIOUS_PAGE[REFLECTION_PAGE.SUMMARY] = REFLECTION_PAGE.ACTION;

export interface ReflectionData extends SkillBuilderData {
  page?: REFLECTION_PAGE;
  domainUID?: number;
  onboardingSkillUIDs?: Array<number>;
  strengths?: Array<number>;
  weaknesses?: Array<number>;
  impacts?: Array<string>;
  skillUID?: number;
  skill?: string;
  action?: string;
  skillBuilderType?: string;
}
