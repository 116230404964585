<div class="tab-content reportMain" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="physics"
    role="tabpanel"
    aria-labelledby="physics-tab">
    <div class="tabHeadMain">
      <div class="container">
        <div class="tabHead">
          <span routerLink="/leader/dashboard" class="back-arrow"><i class="fas fa-arrow-left"></i>
          </span>
          {{page?.report}}
        </div>
      </div>
    </div>

    <div class="dashContArea px-5d">
      <div class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="overviewMain">
                <div class="assmntTblMain mb-0">
                  <div class="assmntTbl reportsFilter bg-white">
                    <div class="row align-items-center">
                      <div class="col-md-12 col-lg-1"> {{page?.filterBy}}</div>
                      <div class="col-md-12 col-lg-8">
                        <div class="row">
                          <div class="col-xs-12 col-sm-6 col-lg-3 d-flex flex-column mb-3 mb-lg-0">
                            <label>{{page?.orgSelect}}</label>
                            <select [(ngModel)]="selectedOrganization" (change)="onOrganizationChange()">
                              <option *ngFor="let organization of organizations" [value]="organization">{{organization.name}}</option>
                            </select>
                          </div>
                        <div class="col-xs-12 col-sm-6 col-lg-3 d-flex flex-column mb-3 mb-lg-0">
                          <label>{{page?.groupSelect}}</label>
                          <select
                            [(ngModel)]="selectedGroup"
                            (change)="onGroupChange()">
                            <option [ngValue]="null">{{page?.allGroups}}</option>
                            <option *ngFor="let group of groups" [ngValue]="group">
                              {{ group.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3 d-flex flex-column mb-3 mb-lg-0">
                          <label>{{page?.userSelection}}</label>
                          <select [(ngModel)]="selectedUser">
                            <option [ngValue]="null">{{page?.allUsers}}</option>
                            <option *ngFor="let user of users" [ngValue]="user">
                              {{ user.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-lg-3 d-flex flex-column mb-3 mb-lg-0">
                          <label>{{page?.assessmentSelect}}</label>
                          <select [(ngModel)]="selectedAssessment">
                            <option [ngValue]="null">{{page?.allAssessments}}</option>
                            <option
                              *ngFor="let assessment of groupAssessments"
                              [ngValue]="assessment">
                              {{ assessment.name }}
                            </option>
                          </select>
                        </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-3 pt-4 btn-c d-flex align-items-center justify-content-end">
                        <button class="btn btn-primary" id="auto_trigger" type="button" (click)="onSearch()">
                          {{page?.search}}
                        </button>
                        <button *ngIf="domains.length" class="btn btn-md" type="button" (click)="allClear()">
                          {{page?.clear}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-c tab-container">
            <div class="row d-flex">
              <ul *ngIf="!!selfVsPeer" class="nav nav-tabs leader-report-tabs" id="myTab" role="tablist">
                <div class="container">
                  <li *ngFor="let domain of domains" class="nav-item">

                    <a class="nav-link {{
                  this.selectedDomain === domain ? 'active' : ''
                }}" id="domain1-tab" data-toggle="tab" href="#domain1" role="tab" (click)="onDomainChange(domain)"
                      aria-controls="physics" aria-selected="true">{{ domain.name }}</a>
                  </li>
                </div>
              </ul>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="row mb-4">
                  <div class="col-xs-12 col-sm-12 col-md-8 mb-3 mb-xl-0">
                    <div class="boxMain card-white card-white-sm border-radius-md">
                      <div class="boxHead card-title-main">
                        <h5>{{ selfVsPeerTitle }}</h5>
                        <div class="d-flex justify-content-end align-items-center">
                          <span [ngbTooltip]="
                         page?.recentSelfTip
                        " triggers="click" tooltipClass="my-custom-class" placement="left">
                        <span class="info-circle">
                          <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
                        </span>
                          </span>
                        </div>
                      </div>
                      <div class="chartArea {{!selfVsPeer ? 'd-none': ''}} ">
                        <canvas #myCanvas></canvas>
                      </div>
                      <ng-container *ngIf="!selfVsPeer">
                        <div class="p-3">{{page?.insufficientData}}</div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4">
                    <div class="boxMain card-white card-white-sm border-radius-md">
                      <div class="boxHead card-title-main">
                        <h5>{{page?.strengthAndWeaknesses}}</h5>
                        <span [ngbTooltip]="
                            page?.strengthWeaknessTip
                          " triggers="click" tooltipClass="my-custom-class" placement="left">
                          <span class="info-circle">
                            <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
                          </span>
                        </span>
                      </div>
                    
                        <div class="chartArea {{!strengthsAndWeaknesses ? 'd-none': ''}} ">
                          <div class=" strengthMain">
                            <ng-container *ngIf="strengthsAndWeaknesses">
                              <div *ngFor="let skill of strengthsAndWeaknesses"
                                class="strengthRow list-bordered-btm-dotted">
                                <div class="strengthCol w-100 d-flex align-items-center justify-content-between">
                                  <div class="list-item">
                                    {{ skill.name }}
                                  </div>
                                  <div class="strengthCol">
                                    <div class="list-img">
                                      <img src="assets/img/{{skill.image}}" alt="graph" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            
                          </div>
                          <div *ngIf="!!strengthsAndWeaknesses?.length" class="chartBtmArea legend-btm col-12 d-inline-block">
                            <div class="row">
                              <div class="chartBtmCol col-3">
                                <img src="assets/img/graph-good.svg" alt="graph" />
                                {{page?.good}} &gt;4
                              </div>
                              <div class="chartBtmCol col-3">
                                <img src="assets/img/graph-ok.svg" alt="graph" />
                               {{page?.ok}} &gt;3-4
                              </div>
                              <div class="chartBtmCol col-3">
                                <img src="assets/img/graph-insufficient.svg" alt="graph" />
                               {{page?.improvement}} &lt;=3
                              </div>
                              <div class="chartBtmCol col-3">
                                <div><img src="assets/img/graph-insufficient1.svg" alt="graph" /></div>
                                <div>{{page?.insufficientData}}</div>
                              </div>
                            </div>
                          </div>
                         
                        </div>
                        <ng-container *ngIf="!strengthsAndWeaknesses?.length">
                          <div class="p-3">{{page?.insufficientData}}</div>
                        </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-xs-12 col-sm-12 col-md-8">
                    <div class="boxMain card-white card-white-sm border-radius-md">
                      <div class="boxHead card-title-main">
                        <h5>{{page?.overallSkillScore}}</h5>
                        <div class="d-flex justify-content-end align-items-center">
                          <div>
                            <select class="select-w" [(ngModel)]="selectedSkillOverallScoresSkill"
                            (change)="onSkillOverallScoresSkillChange()">
                            <option
                            *ngFor="let skill of selectedDomain?.skills"
                            [ngValue]="skill">
                            {{ skill.name }}
                          </option>
                            </select>
                        </div>
                          <span [ngbTooltip]="
                          page?.scoreDistroTip
                        " triggers="click" tooltipClass="my-custom-class" placement="left" class="pl-2">
                        <span class="info-circle">
                          <img src="assets/img/info-ico-card.svg" alt="info" />
                        </span>
                      </span>
                        </div>
                      </div>
                      <div *ngIf="skillOverallScores">
                        <div class="pt-4 d-flex scroll-area">
                          <div class="col-12">
                            <div class="row list-box">
                              <div class="col-12 col-md-3 d-flex align-items-stretch"
                                *ngFor="let column of skillOverallScores;let isLast = last">
                                <div class="card" [ngClass]="{'arrow-list': !isLast}"> 
                                  <div class="step-counter"
                                    [ngClass]="{'bg-org': column.name === '1-2',
                                      'bg-blue': column.name === '2-3',
                                      'bg-purple': column.name === '3-4',
                                      'bg-green' : column.name === '4-5' }">{{column.name}} </div>
                                  <ul>
                                    <li *ngFor="let user of column.users">{{user}}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!skillOverallScores?.length">
                        <div class="p-3">{{page?.insufficientData}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-xs-12 col-sm-12 col-md-8">
                    <div class="boxMain card-white card-white-sm border-radius-md">
                      <div class="boxHead card-title-main">
                        <h5>{{page?.growth}}</h5>
                      
                        <div class="d-flex justify-content-end align-items-center">
                          <div>
                            <select class="select-w" [(ngModel)]="selectedGrowthSkill"
                            (change)="onGrowthSkillChange()">
                            <option
                            *ngFor="let skill of selectedDomain?.skills"
                            [ngValue]="skill">
                            {{ skill.name }}
                          </option>
                            </select>
                        </div>
                          <span [ngbTooltip]="
                          page?.growthTip
                        " triggers="click" tooltipClass="my-custom-class" placement="left" class="pl-2">
                        <span class="info-circle">
                          <img src="assets/img/info-ico-card.svg" alt="{{page?.info}}" />
                        </span>
                      </span>
                        </div>
                      </div>
                      <div class="{{ !growth ? 'd-none' : '' }}">
                        <div class="chartArea line-chart p-0">
                          <canvas #growthChart></canvas>
                        </div>
                      </div>
                      <ng-container *ngIf="!growth">
                        <div class="p-3">{{page?.insufficientData}}</div>
                      </ng-container>
                    
                    </div>
                  </div>
                    
                    </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
