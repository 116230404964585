<div class="member-dashboard-rewards-metrics">
  <div class="header">
    <div class="title">
      {{ page.title }}
    </div>
    <div class="header-right">
      <div class="header-form" [formGroup]="form">
        <input
          class="input-date"
          id="date"
          formControlName="date"
          type="text"
          [placeholder]="labels.selectDate"
          bsDatepicker
          [daysDisabled]="[0,2,3,4,5,6,7]"
          [bsConfig]="{ showWeekNumbers: false }"
          (ngModelChange)="dateOnSelect($event)"
          placement="bottom"
          readonly
        />
      </div>
    </div>
  </div>
  <div class="metrics">
    <div *ngFor="let metric of metrics" [ngClass]="metric.class">
      <div class="flag">
        <img src="assets/img/flag.svg" alt="flag image">
      </div>
      <div class="date-name">
        <div class="date">
          {{ metric.startDate | date:'MM/dd/YY' }}
          {{ labels.to }}
          {{ metric.endDate | date:'MM/dd/YY' }}
        </div>
        <div class="name">
          ({{ metric.domain }})
        </div>
      </div>
      <div class="score-extra-credit">
        <div class="score-container">
          <div class="score">
            <span class="score-bold">{{
              metric.score
            }}</span><span class="score-out-of">/100</span>
          </div>
          <div class="score-text" [innerHTML]="page.score"></div>
        </div>
        <div class="extra-credit-container">
          <div>
            <img class="crown" src="assets/img/crown.png" alt="crown image">
            <span>{{ metric.extraCredit }}</span>
          </div>
          <div class="extra-credit-text" [innerHTML]="page.extraCredit"></div>
        </div>
      </div>
    </div>
    <div *ngIf="metrics.length === 0">
      <span>
        {{ page.noMetrics }}
      </span>
    </div>
  </div>
</div>
