import { Component, Input, OnInit } from '@angular/core';
import { Domain2Service, LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.dashboard.rewards.journey.page';

export interface JourneyDomain {
  domainUID: number;
  level: number;
}

@Component({
  selector: 'app-member-dashboard-rewards-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class MemberDashboardRewardsJourneyComponent implements OnInit {

  @Input() domains: Array<JourneyDomain>;

  // Page langauge.
  page: {[key: string]: string} = {
    title: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'title':
              this._languageSvc.template(
                this.page[key],
                { name: new Date().getFullYear().toString() }
              ).then(value => { this.page[key] = value; });
              break;
            default:
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value
              );
          }
        }
      }
    );
  }

  getIcons(level: number): Array<string> {
    return this.domains.filter(
      domain => domain.level === level || (level === 5 && domain.level > 5)
    ).map(domain => this._domainSvc.getDomain(domain.domainUID).logo);
  }

}
