import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from 'environment';
import { APIErrors } from '@util';

export interface Notification {
  title: string;
  posted: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public _notifications: Array<Notification> | null = null;

  constructor(
    private _http: HttpClient
  ) { }

  loadNotifications():  Observable<any | APIErrors> {
    return this._http.get<any | APIErrors>(
      `${config.apiBase}member/notification/list`
    );
  }

}
