import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JOURNAL_PAGE, JournalData } from '../common';
import { NO_HTML_PATTERN } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.journal.pages.highlight';

@Component({
  selector: 'app-member-skill-builder-journal-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss']
})
export class MemberSkillBuilderJournalHighlightComponent implements OnInit {

  @Input() data: JournalData;
  form: FormGroup;
  submitted: boolean = false;
  maxChars: number = 400;

  // Page language.
  page: { [key: string]: string } = {
    title: '',
    subTitle: '',
    placeholder: '',
    next: '',
    error: '',
    checkboxLabel: '',
    checkboxError: ''
  };

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Setup form.
    this.form = new FormGroup({
      highlight: new FormControl(this.data?.highlight || '',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)]),
      disclaimer: new FormControl(false, Validators.requiredTrue)
    });
  }
  
  nextOnClick(): void {
    this.form.controls.highlight.setValue(
      (this.form.controls.highlight.value || '').trim());
    this.submitted = true;
    if (!this.form.valid) return;
    const data: JournalData = Object.assign({}, this.data, {
      page: JOURNAL_PAGE.FREQUENCY,
      highlight: this.form.controls.highlight.value
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, data);
  }

}
