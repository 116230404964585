import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'skillBuilders.components.starRating';

@Component({
  selector: 'app-skill-builder-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class SkillBuilderStarRatingComponent implements OnInit {

  private static _ID: number = 1;

  @Input() title: string = '';
  @Input() value: number;
  @Input() left: string = '';
  @Input() right: string = '';
  @Input() errorRequired: string; // Default is page.errorRequired.
  @Input() change: Function = () => {};
  @Input() submitted: boolean = false; // Controllable via the parent.
  @Input() reverseColors: boolean = false;

  form: FormGroup;
  id: number = 0;

  // Page langauge.
  page: {[key: string]: string} = {
    errorRequired: ''
  }

  constructor(
    private _languageSvc: LanguageService
  ) {
    this.id = SkillBuilderStarRatingComponent._ID++;
  }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    // Setup form.
    this.form = new FormGroup({
      [`rating${this.id}`]: new FormControl(this.value, [Validators.required])
    });
  }

  get rating(): FormControl {
    return <FormControl>this.form.controls[`rating${this.id}`];
  }

}
