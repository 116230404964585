<div
  class="main-container w-100 d-flex align-items-center justify-content-center positive-brain-group"
>
  <div class="container px-0 w-sm-100 w-50">
    <h4 class="w-auto text-center mb-0 px-3">
      {{ page.title }}
    </h4>
    <div class="subtext">
      {{ page.subtitle }}
    </div>

    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4"
      >
        {{ labels.next }}
      </button>
    </div>
  </div>
</div>
