import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrainData } from '../common';
import { APIErrors, APISuccess, NO_HTML_PATTERN, isAPISuccess } from '@util';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { BrainService } from '@services/member/skill-builder/brain.service';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.brain.pages.doTomorrow';

@Component({
  selector: 'app-member-skill-builder-brain-do-tomorrow',
  templateUrl: './do-tomorrow.component.html',
  styleUrls: ['./do-tomorrow.component.scss']
})
export class MemberSkillBuilderBrainDoTomorrowComponent implements OnInit {

  @Input() data: BrainData;
  form: FormGroup;
  submitted: boolean = false;
  maxChars: number = 400;

  // Page language.
  page: { [key: string]: string } = {
    title: '',
    placeholder: '',
    next: '',
    error: ''
  };

  constructor(
    private _languageSvc: LanguageService,
    private _brainSvc: BrainService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Setup form.
    this.form = new FormGroup({
      doTomorrow: new FormControl(this.data?.doTomorrow || '',
        [Validators.required, Validators.pattern(NO_HTML_PATTERN)])
    });
  }

  nextOnClick(): void {
    this.form.controls.doTomorrow.setValue(
      (this.form.controls.doTomorrow.value || '').trim());
    this.submitted = true;
    if (!this.form.valid) return;

    this.data.doTomorrow = this.form.controls.doTomorrow.value;
    this._brainSvc.createLog(this.data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) this._router.navigate(['member/dashboard']);
      });
  }

}
