<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-12 col-lg-6 space-between">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="backstep()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{title}}</span>
        <button type="button" (click)="closeTab()" class="close d-none d-blcok-sm" [attr.aria-label]="labels?.close">
          <div aria-hidden="true" class="icon-md">&times;</div>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="main-container w-100 d-flex align-items-center justify-content-center">
  <div class="container px-0 w-sm-100 w-50">
    <div class="mt-3 mb-5 w-100 d-flex align-items-center justify-content-center flex-column">
      <div class="col-sm-12 col-md-12 col-lg-10 col-xl-8">
        <div class="row w-100 justify-content-center">
          <div class="col-sm-12">
            <h5 class="w-auto text-center mb-4 px-3 font-weight-bold">
              {{description}}
            </h5>
            <div class="card card-white-sm mb-3 card-no-space"
              *ngFor="let question of questions let i = index" >
              <div class="cards-body">
                <p class="text-center para ft-bold" >{{question.display}}</p>
                <div *ngIf="question.type ==='list'">
                  <div *ngFor="let option of question.answers let innerIndex = index" >
                <div class="form-check1 mb-3 px-3" >
                  <label class="form-check-label pl-0 d-flex align-items-center" >
                    <input *ngIf="question.input == 'radio'" class="form-check-input"
                      [name]="i"
                      type="radio"
                      [(ngModel)]="question.selected"
                      [id]="i + innerIndex.toString()" [value]="innerIndex"
                      />
                      <input *ngIf="question.input == 'checkbox'" class="form-check-input"
                      [name]="innerIndex"
                      type="checkbox"
                      [(ngModel)]="option.selected"
                      [id]="i + innerIndex.toString()" [value]="innerIndex"
                      />
                    <span class="letter">{{letterOption[innerIndex]}}</span>
                    <div class="d-flex pl-2">
                      <div class="options pr-2"><span>{{option.display}}</span> </div>
                    </div>
                  </label>
                </div>
                </div>
              </div>
                <div  class="row justify-content-center" >
                  <div class="col-12" *ngIf="question.type ==='star'">
                    <div class="">
                      <div class="cont d-flex justify-content-center">
                        <div class="stars">
                          <ngx-star-rating [(ngModel)]="question.selected" [id]="i"></ngx-star-rating>
                        </div>
                      </div>
                      <div class="col-12 px-3">
                        <div class="dot-sm"></div>
                        <div class="dotted-line">
                          <i class="fas fa-chevron-right right-arrow"></i>
                        </div>
                        <div class="col-12 rating-label">
                          <div class="lft">{{question?.answers[0]?.display | titlecase}}</div>
                          <div class="rgt">{{question?.answers[4]?.display | titlecase}} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="submitted" class="invalid-feedback">
             {{pageLanguage?.answerAll}}
            </div>
          </div>
        </div>

      <div class="footer-inner-action">
        <button type="button" class="btn btn-primary btn-lg px-4" (click)="GetSelectedOptions()">
          {{labels?.submit}}
        </button>
      </div>
    </div>
  </div>