import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { JournalData } from "../common";
import { SelectListNext, SelectListOption } from '@components';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { ReplaySubject } from 'rxjs';
import { JournalService } from '@services/member';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.journal.pages.accuracy';

@Component({
  selector: 'app-member-skill-builder-journal-accuracy',
  templateUrl: './accuracy.component.html',
  styleUrls: ['./accuracy.component.scss']
})
export class MemberSkillBuilderJournalAccuracyComponent implements OnInit {

  @Input() data: JournalData;
  @ViewChild('AccurateTemplate', { static: false }) AccurateTemplate: TemplateRef<void>;
  @ViewChild('MissingDetailsTemplate', { static: false }) MissingDetailsTemplate: TemplateRef<void>;
  @ViewChild('NotAccurateTemplate', { static: false }) NotAccurateTemplate: TemplateRef<void>;
  modalRef: BsModalRef;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  next: SelectListNext = {
    onClick: this.nextOnClick.bind(this)
  }

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    accurate: '',
    missingDetails: '',
    notAccurate: '',
    ok: ''
  };

  constructor(
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService,
    private _journalSvc: JournalService,
    private _modalSvc: BsModalService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    const path: string = 'skillBuilders.journal.accuracy';
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = Object.values(value[path]);

        this.options.next(this.defaultOptions.map(
          (value: SelectListOption) => {
            if (value.value === this.data?.accuracy)
              return { ...value, selected: true };
            return value;
          }));
      });
  }

  nextOnClick(option: SelectListOption): void {
    const data: JournalData = Object.assign({}, this.data, {
      accuracy: option.value
    });
    this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, data);
    this._journalSvc.createLog(data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          switch(option.value) {
            case 'accurate':
              this.modalRef = this._modalSvc.show(this.AccurateTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true
              });
              break;
            case 'missing-details':
              this.modalRef = this._modalSvc.show(this.MissingDetailsTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true
              });
              break;
            case 'not-accurate':
              this.modalRef = this._modalSvc.show(this.NotAccurateTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true
              });
              break;
            default:
              this._router.navigate(['member/dashboard']);
              this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, null);
              break;
          }
        }
      }
    );
  }

  closeModal() {
    this.modalRef?.hide();
    this._router.navigate(['member/dashboard']);
    this._storageSvc.setTempStorage(TEMP_KEYS.JOURNAL, null);
  }

}
