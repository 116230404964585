<div class="main-container w-100 d-flex align-items-center justify-content-center">
  <div class="container px-0 w-sm-100 w-50 heading-1">
    <h4 class="w-auto text-center mb-0 px-3">
      {{ page.title }}
    </h4>
    <div class="pb-3 w-100 d-flex align-items-center justify-content-center flex-column">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7">
        <div class="row justify-content-center">
          <div class="col-12 mb-3 c-form">
            <form [formGroup]="form">
              <textarea
                class="form-control txtarea"
                id="userSummary"
                rows="3"
                placeholder="{{ page.placeholder }}"
                formControlName="userSummary"
                [maxlength]="maxChars"
                [ngClass]="{ 'is-invalid': submitted && form.controls.userSummary.errors }">
              </textarea>
              <div *ngIf="submitted && form.controls.userSummary.errors" class="invalid-feedback">
                <div *ngIf="form.controls.userSummary.errors.required || form.controls.userSummary.errors.pattern">
                  {{ page.error }}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-inner-action">
      <button
        type="button"
        (click)="nextOnClick()"
        class="btn btn-primary btn-lg px-4">
        {{ page.next }}
      </button>
    </div>
  </div>
</div>
