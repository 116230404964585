<!-- Skill builder header -->
<div class="tabHeadMain innertabHeadMain innertabHeadMain-sm">
  <div class="container px-0">
    <div class="col-12 d-flex">
      <div class="tabHead col-sm-12 col-md-6 space-between align-items-start">
        <span class="cursor-pointer txt-md">
          <i class="fas fa-arrow-left" (click)="back()"></i>
        </span>
        <span class="text-center-sm pl-2 txt-md">{{ page.title }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Skill builder start flow -->
<ng-container *ngIf="data?.page === MESSAGE_PAGE.NAME">
  <app-member-skill-builder-message-name [data]="data">
  </app-member-skill-builder-message-name>
</ng-container>
<ng-container *ngIf="data?.page === MESSAGE_PAGE.VALUES">
  <app-member-skill-builder-message-values [data]="data">
  </app-member-skill-builder-message-values>
</ng-container>
<ng-container *ngIf="data?.page === MESSAGE_PAGE.POSITIVE_MESSAGE">
  <app-member-skill-builder-message-positive-message [data]="data">
  </app-member-skill-builder-message-positive-message>
</ng-container>

<!-- Skill builder track flow -->
<ng-container *ngIf="data?.page === MESSAGE_PAGE.DELIVERED_MESSAGE">
  <app-member-skill-builder-message-delivered-message [data]="data">
  </app-member-skill-builder-message-delivered-message>
</ng-container>
<ng-container *ngIf="data?.page === MESSAGE_PAGE.IMPACT">
  <app-member-skill-builder-message-impact [data]="data">
  </app-member-skill-builder-message-impact>
</ng-container>
