<div
  class="main-container positive-brain-group pt-3 w-100 d-flex justify-content-center">
  <div class="container-fluid px-0 w-sm-100 w-50 pb-5-sm-c pb-5-md-c">
    <div class="positive-brain-list w-auto">
      <div class="col-12 d-flex justify-content-center mt-3">
        <div class="col-sm-12 col-md-6 c-form">
          <form [formGroup]="form">
            <div
              *ngFor="let action of actions.controls; let i = index"
              formArrayName="actions">
              <div class="heading-md text-center">
                {{ data?.actions[i]?.action }}
              </div>
              <div [formGroup]="action">
                <div>
                  <span
                    class="d-flex justify-content-center small text-center mt-2 sm-center-txt mb-3"
                    >
                    {{ page.positiveOutcome }}
                  </span>

                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="positiveOutcome{{ i }}"
                      [placeholder]="page.placeholder"
                      formControlName="positiveOutcome" />
                    <div
                      *ngIf="submitted &&
                        !!action.controls.positiveOutcome.errors?.required"
                      class="invalid-feedback">
                      {{ page.error1 }}
                    </div>
                    <div
                      *ngIf="submitted
                        && !!action.controls.positiveOutcome.errors?.pattern"
                      class="invalid-feedback">
                      {{ page.error2 }}
                    </div>
                  </div>
                </div>
                <div>
                  <span
                    class="d-flex justify-content-center small text-center mt-2 sm-center-txt mb-3"
                    >
                    {{ page.negativeOutcome }}
                  </span>

                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="negativeOutcome{{ i }}"
                      [placeholder]="page.placeholder"
                      formControlName="negativeOutcome" />
                    <div
                      *ngIf="submitted &&
                        !!action.controls.negativeOutcome.errors?.required"
                      class="invalid-feedback">
                      {{ page.error1 }}
                    </div>
                    <div
                      *ngIf="submitted
                        && !!action.controls.negativeOutcome.errors?.pattern"
                      class="invalid-feedback">
                      {{ page.error2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="footer-inner-action">
        <button
          type="button"
          (click)="nextOnClick()"
          class="btn btn-primary btn-lg px-4">
          {{ page.next }}
        </button>
      </div>
    </div>
  </div>
</div>
