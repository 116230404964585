<div class="assessmentListMain">
  <div class="tabHeadMain px-5">
    <div class="container">
      <div class="tabHead">
        <span routerLink="/leader/dashboard" class="back-arrow"><i class="fas fa-arrow-left"></i>
        </span>
       {{page?.assessments}}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-2 col-md-2 lftPanalMain">
        <div class="lftPanal">
          <ul>
            <li (click)="selectedGroup.next(null)" class="lftPnlheading">
              {{page?.allGroups}}
            </li>
            <ng-container *ngFor="let group of groups">
              <li (click)="selectedGroup.next(group)">
                <a class="{{selectedGroup.value === group ? 'active' : ''}}">{{group.name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      
      <div class="col-xs-12 col-sm-10 col-md-10 rytPanalMain pb-lg">
        <div class="rytPanal">
          <div class="rytHeadMain btn-c">
            <h3>{{page?.assessmentList}}</h3>
            <button class="btn btn-primary text-white add-class mt-3 mt-md-0" (click)="createAssessment()">
              <span>{{page?.createNewAssessment}}</span>
            </button>
          </div>
          <div id="no-more-tables" >
            <table class="table table-c">
              <thead class="thead-light">
                <tr>
                  <th>{{page?.assessmentName}}</th>
                  <th>{{page?.assessmentType}}</th>
                  <th>{{page?.groupName}}</th>
                  <th>{{page?.starDate}}</th>
                  <th>{{page?.endDate}}</th>
                  <th>{{page?.status}}</th>
                  <th>{{page?.actions}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="assessments.length > 0">
                  <tr *ngFor="
                  let assessment of assessments
                    | paginate: { itemsPerPage: 10, currentPage: p }
                " class="cursor-pointer">
                    <td data-title="{{page?.assessmentName}}">{{assessment.name}}</td>
                    <td data-title=" {{page?.assessmentType}}" class="text-capitalize">{{assessment.type}}</td>
                    <td data-title="{{page?.groupName}}">{{assessment.group?.name}}</td>
                    <td data-title="{{page?.starDate}}">{{assessment.startDate}}</td>
                    <td data-title="{{page?.endDate}}">{{assessment.endDate}}</td>
                    <td data-title="{{page?.status}}" *ngIf="assessment.status == 'ACTIVE'">
                      <span><img src="assets/img/pending_ass.svg" class="img-status" /></span><span
                      class="pl-2">{{page?.active}}</span>
                    </td>
                    <td data-title="Status" *ngIf="assessment.status == 'SCHEDULED'">
                      <span><img src="assets/img/pro.svg" class="img-status" /></span><span
                        class="pl-2">{{page?.inactive}}</span>
                    </td>
                    <td class="sucess-ico" data-title="Status" *ngIf="assessment.status == 'COMPLETED'">
                      <span><img src="assets/img/success.svg" class="img-status" /></span><span
                      class="pl-2">{{page?.completed}}</span>
                    </td>
                    <td data-title="Action">
                      <div class="d-flex">
                      <span class="actionColumn" *ngIf="assessment.status == 'COMPLETED' || assessment.status == 'ACTIVE'"><img src="./../../../../assets/img/view-report-icon.svg" alt="view.svg"   (click)="viewAssessment(assessment)"/></span>
                      <span class="actionColumn" *ngIf="assessment.status == 'SCHEDULED'"><img src="assets/img/update-icon.svg" alt="update-icon.svg" (click)="viewAssessment(assessment)"/></span>
                      <span class="actionColumn" *ngIf="assessment.status == 'SCHEDULED'"><img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="deleteAssessment(assessment)"/></span>
                      <span  class="actionColumn" *ngIf="assessment.status == 'COMPLETED' || assessment.status == 'ACTIVE'"><img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="openModal(template, assessment)"/></span>  
                    </div>
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngIf="assessments.length === 0">
                  <tr *ngFor="
                  let assessment of assessments
                    | paginate: { itemsPerPage: 5, currentPage: '1' }
                ">
                  </tr>
                  <td class="text-center pl-0-c" [attr.colspan]="6">
                    {{page?.noAssessmentsFound}
                  </td>
                </ng-container>
              </tbody>
            </table>
            <div style="float: right" class="w-sm-100">
              <pagination-controls  previousLabel=""
              nextLabel="" class="my-pagination" (pageChange)="p = $event" directionLinks="true">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center modal-confirm">
    <div class="modal-body">
      <div class="msg-wrapper">
        <div>
          <img src="assets/img/new_delete.svg" alt="new_delete.svg" />
        </div>
        <p class="mt-3 text-center delete-msg">
         {{page?.deleteMsg}}
        </p>
      </div>
    </div>
    <div class="pt-4 border-top">
      <button
        (click)="decline()"
        type="button"
        class="btn btn-md ml-3">
       {{page?.cancel}}
      </button>
      <button
      (click)="deleteAssessment(deleteAss)"
        type="button"
        class="btn btn-md btn-danger">
        {{page?.delete}}
      </button>
    </div>
  </div>
</ng-template>