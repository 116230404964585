<div class="modal-header external">
  <h5 class="modal-title" id="exampleModalLongTitle">{{pageLanguage?.modalTitle}}</h5>
  <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body external">
  <form [formGroup]="form" (ngSubmit)="onSave()">
    <div class="row">
      <div class="form-group col-sm-12 col-md-4">
        <label for="assessmentName">{{appLabels?.assessmentName}}</label>
        <input id="assessmentName" formControlName="assessmentName" maxlength="40" type="text"
          placeholder="{{appLabels?.firstName}}" class="form-control"
          [ngClass]="{'danger-class': f.assessmentName.errors && f.assessmentName.touched}" />
        <div *ngIf="f.assessmentName.errors && f.assessmentName.touched" class="invalid-feedback">
          <div *ngIf="f.assessmentName.errors.required || f.assessmentName.errors?.['whitespace']"> {{appLabels?.assessmentNameRequired}}</div>
          <div *ngIf="!f.assessmentName.errors.required && ( f.assessmentName.errors.maxlength?.actualLength > 40)">
            {{appLabels?.assessmentName + appLabels?.nameLength}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="firstName">{{appLabels?.firstName}}</label>
        <input id="firstName" (keypress)="keyPressOnlyAlphabets($event)" formControlName="firstName" maxlength="40"
          type="text" placeholder="{{appLabels?.firstName}}" class="form-control"
          [ngClass]="{'danger-class': f.firstName.errors && f.firstName.touched}" />
        <div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required || f.firstName.errors?.['whitespace']"> {{appLabels?.firstNameRequired}}</div>
          <div *ngIf="!f.firstName.errors.required && ( f.firstName.errors.maxlength?.actualLength > 40)">
           {{appLabels?.firstName + appLabels?.nameLength}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="lastName">{{appLabels?.lastName}}</label>
        <input id="lastName" (keypress)="keyPressOnlyAlphabets($event)" formControlName="lastName" maxlength="40"
          type="text" placeholder="{{appLabels?.lastName}}" [ngClass]="{'danger-class': f.lastName.errors && f.lastName.touched}"
          class="form-control" />
        <div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required || f.lastName.errors?.['whitespace']"> {{appLabels?.lastNameRequired}}</div>
          <div *ngIf="!f.lastName.errors.required && ( f.lastName.errors.maxlength?.actualLength > 40)">
            {{appLabels?.lastName + appLabels?.nameLength}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-4">
        <label for="email">{{appLabels?.email}}</label>
        <input id="email" type="text" maxlength="320" formControlName="email" name="email"
          [ngClass]="{'danger-class': f.email.errors && f.email.touched}" placeholder="{{appLabels?.email}}" class="form-control" />
        <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
          <div *ngIf="f.email.errors.required || f.email.errors?.['whitespace']"> {{appLabels?.emailRequired}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="relationship">{{pageLanguage?.relationship}}</label>
        <input id="relationship" type="text" maxlength="40" formControlName="relationship" name="relationship"
          placeholder="{{pageLanguage?.relationship}}" class="form-control"
          [ngClass]="{'danger-class': f.relationship.errors && f.relationship.touched}" />
        <div *ngIf="f.relationship.errors && f.relationship.touched" class="invalid-feedback">
          <div *ngIf="f.relationship.errors.required || f.relationship.errors?.['whitespace']">{{pageLanguage?.relationshipRequired}}</div>
          <div *ngIf="!f.relationship.errors.required && ( f.relationship.errors.maxlength?.actualLength > 40)">
           {{ pageLanguage?.relationship + appLabels?.nameLength}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center modal-footer bottomBtnsModal footer-inner-action">
      <button class="btn btn-primary ml-3">{{appLabels?.save}}</button>
      <button type="button" class="cancel mr-0" data-dismiss="modal" aria-label="Close"
        (click)="closeModal()">{{appLabels?.cancel}}</button>
    </div>
  </form>
</div>
