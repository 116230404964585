<div
  *ngIf="display"
  class="main-container w-100 d-flex align-items-center justify-content-center pb-lg"
>
  <div class="container px-0 w-sm-100 w-50">
    <div class="row">
      <div class="col-12">
        <div class="card card-white card-white-sm heading-1">
          <div class="card-header card-header-with-ico">
            <h4>
              {{ page.title }}
            </h4>
            <div class="col-2 d-flex align-items-center justify-content-end">
              <div
                *ngIf="ideas.length > show || show !== SHOW_MIN"
                class="circle-md d-flex align-items-center justify-content-center"
                (click)="toggleMore()"
              >
                <i
                  class="{{
                    show === SHOW_MIN ? ICON_CLASSES.DOWN : ICON_CLASSES.UP
                  }}"
                ></i>
              </div>
            </div>
          </div>
          <div class="card-body w-100 align-items-start text-gray-lgt pt-0">
            <ul class="list-bordered-btm list-bordered-btm-dotted w-100" >
              <li class="py-2">
                <ul class="legend justify-content-start pl-0 pt-0">
                  <li class="no-border">
                    <span class="good circle-sm"></span>
                    {{ page.oneApart }}
                  </li>
                  <li class="no-border">
                    <span class="ok circle-sm"></span>
                    {{ page.twoApart }}
                  </li>
                  <li class="no-border">
                    <span class="bad circle-sm"></span>
                    {{ page.threeApart}}
                  </li>
                </ul>
              </li>
            </ul>
            <ul
              *ngFor="let idea of ideas | slice: 0:show"
              class="list-bordered-btm list-bordered-btm-dotted w-100"
            >
              <li class="py-2 d-flex">
                <span
                  class="left-border-list {{
                    idea.ratingDifference < 2 ? 'good' :
                    (idea.ratingDifference === 2 ? 'ok' : 'bad')
                  }}"
                ></span>
                {{ idea.idea }}
              </li>
            </ul>
          </div>
          <div
            *ngIf="ideas.length > show || show !== SHOW_MIN"
            class="btn-row py-4 d-flex align-items-center justify-content-center"
          >
            <button
              type="button"
              (click)="toggleMore()"
              class="btn btn-small btn-outline btn-outline-purple"
            >
              {{ show === SHOW_MIN ? labels.viewMore : labels.viewLess }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
