 <div
  class="col-md-12 d-flex align-items-center filters">
  <div class="row mb-3 w-100">
    <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
      <select  [(ngModel)]="selectedOrg" (change)="onSelectOrganization($event.target.value)">
        <option *ngFor="let organization of organizations" [value]="organization.uuid">{{organization.name}}</option>
      </select>
    </div>
    <span class="divider-rgt pl-2 mr-1 d-none d-md-flex"></span>

          <div class="col-sm-4 col-md-2 mb-2 mb-lg-0">
            <select class="select-w" [(ngModel)]="selectedGroup" (change)="loadAssessments()">
              <option *ngFor="let group of groups" [ngValue]="group">
                <a class="{{  selectedGroup === group ? 'active' : ''  }}">{{ group.name }}</a>
              </option>
            </select>
          </div>
  </div>
</div>
          <div class="col-12">
            <div class="row align-items-center border-btm-wht pb-3 pt-3 pt-lg-0">
            <div class="col-sm-12 col-md-6 mb-3 mb-md-0">
            <div class="title-main">
            <h1 class="mb-0">{{page?.assessmentList}}</h1>
            </div>
            </div>
            <div class="col-sm-12 col-md-6 d-flex justify-content-start justify-content-md-end">
            <button type="button" (click)="createAssessment()" class="btn btn-secondary btn-sm">
                     {{page?.createNew}}
            </button>
            </div>
            </div>
  <div class="col-md-12 col-lg-12">
    <div class="row mb-4 mt-4">
      <div class="col-md-12 col-lg-12 col-xl-9 mb-4 mb-xl-0 px-2 px-lg-3">
        <div class="boxMain card-white card-white-sm border-radius-md">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-lg-6 mb-3 mb-lg-0">
              <div class="row">
                <div class="col-md-6 col-lg-6 d-flex align-items-center">
                  <p class="text-widget mb-0">
                    <span class="count-lg font-weight-bold">{{ assessments.length }}</span>
                    -
                    <span class="txt-md">{{page?.totalAssess}}</span>

                  </p>
                </div>
                <div class="col-md-6 col-lg-6 d-flex align-items-center">
                  <span class="txt-md font-weight-bold">{{
                    finalDate
                    }}</span>
                  <span class="txt-sm pl-2">{{page?.sinceLast}}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-3 mb-lg-0">
              <div class="row">
                <div class="col-md-4 mb-3 mb-lg-0">
                  <div class="text-widget d-flex">
                    <div style="padding: 5px">
                      <img src="assets/img/comp.svg" />
                    </div>
                    <div class="d-flex flex-column">
                      <span class="txt-md">{{assessmentsCompleted?.length}}</span>
                      <span class="txt-sm">{{page?.completed}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-lg-0">
                  <div class="text-widget d-flex">
                    <div style="padding: 5px">
                      <img src="assets/img/pending_01.svg" />
                    </div>
                    <div class="d-flex flex-column">
                      <span class="txt-md">{{
                        assessmentsActive?.length
                        }}</span>
                      <span class="txt-sm">{{page?.inProgress}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-lg-0">
                  <!-- <h2>Column 4</h2>-->
                  <div class="text-widget d-flex">
                    <div style="padding: 5px">
                      <img src="assets/img/pro.svg" />
                    </div>
                    <div class="d-flex flex-column">
                      <span class="txt-md">{{
                        assessmentsPending?.length
                        }}</span>
                      <span class="txt-sm">{{page?.pending}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-3">
        <div
          class="boxMain card-white card-white-sm border-radius-md d-flex align-items-center justify-content-center">
          <div class="col-12">
            <div class="row">
              <div class="col-12 mb-3 mb-sm-0 mb-xl-0 d-flex align-items-center">
                <select class="select-w" [(ngModel)]="selectedGroup" (change)="loadAssessments()">
                  <option *ngFor="let group of groups" [ngValue]="group">
                    <a class="{{
                        selectedGroup === group ? 'active' : ''
                      }}">{{ group.name }}</a>
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-12">
      <div class="row mb-4 mt-4">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <div class="boxMain card-white card-white-sm border-radius-md">
            <div id="no-more-tables">
              <table class="table table-c">
                <thead class="thead-light">
                  <tr>
                    <th>{{page?.assessName}}</th>
                    <th>{{page?.assessType}}</th>
                    <th>{{page?.groupName}}</th>
                    <th [ngClass]="table_header_highlight == 'startdate' ? 'label_background' : ''"
                      (click)="sortNumberColumn('startdate')" style="cursor: pointer">
                      <div class="icon_th">
                        <div>
                          <span>
                           {{page?.startDate}}
                          </span>
                        </div>

                        <div class="sort_arrows">
                          <div>
                            <span
                              [ngClass]="table_header_highlight == 'startdate' && !ascNumberSort ? 'text_label' : ''">
                              <img
                                [ngClass]="table_header_highlight == 'startdate' && !ascNumberSort ? 'text_label' : ''"
                                src="assets/img/up.svg" />
                            </span>
                          </div>

                          <div>
                            <span
                              [ngClass]="table_header_highlight == 'startdate' && ascNumberSort ? 'text_label' : ''">
                              <img
                                [ngClass]="table_header_highlight == 'startdate' && ascNumberSort ? 'text_label' : ''"
                                src="assets/img/down.svg" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th [ngClass]="table_header_highlight == 'enddate' ? 'label_background' : ''"
                      (click)="sortNumberColumn('enddate')" style="cursor: pointer">
                      <div class="icon_th">

                        <div class="icon_th">
                          <div>
                            <span>
                             {{page?.endDate}}
                            </span>
                          </div>

                          <div class="sort_arrows">
                            <div>
                              <span
                                [ngClass]="table_header_highlight == 'enddate' && endDate ? 'text_label' : ''">
                                <img
                                  [ngClass]="table_header_highlight == 'enddate' && endDate ? 'text_label' : ''"
                                  src="assets/img/up.svg" />
                              </span>
                            </div>

                            <div>
                              <span
                                [ngClass]="table_header_highlight == 'enddate' && !endDate ? 'text_label' : ''">
                                <img
                                  [ngClass]="table_header_highlight == 'enddate' && !endDate ? 'text_label' : ''"
                                  src="assets/img/down.svg" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th [ngClass]="table_header_highlight == 'status' ? 'label_background' : ''"
                      (click)="sortNumberColumn('status')" style="cursor: pointer">

                      <div class="icon_th">
                        <div>
                          <span>
                            {{page?.status}}
                          </span>
                        </div>

                        <div class="sort_arrows">
                          <div>
                            <span [ngClass]="table_header_highlight == 'status' && status ? 'text_label' : ''">
                              <img [ngClass]="table_header_highlight == 'status' && status ? 'text_label' : ''"
                                src="assets/img/up.svg" />
                            </span>
                          </div>
                          <div>
                            <span [ngClass]="table_header_highlight == 'status' && !status ? 'text_label' : ''">
                              <img src="assets/img/down.svg" />
                            </span>

                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="icon_th">
                        <div>
                          <span>
                            {{page?.actions}}
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="assessments.length > 0">
                    <tr *ngFor="
                        let assessment of assessments
                          | paginate: { itemsPerPage: 5, currentPage: p }
                      ">
                      <td data-title="{{page?.assessName}}">
                        {{ assessment.name }}
                      </td>
                      <td data-title="{{page?.assessType}}" class="text-capitalize">
                        {{ assessment.type }}
                      </td>
                      <td data-title="{{page?.groupName}}">
                        {{ assessment.group?.name }}
                      </td>
                      <td data-title="{{page?.startdate}}">
                        {{ assessment.startDate }}
                      </td>
                      <td data-title="{{page?.endDate}}">
                        {{ assessment.endDate }}
                      </td>
                      <td data-title="End Date" *ngIf="assessment.status == 'COMPLETED'">
                        <span><img src="assets/img/success.svg" class="img-status" /></span><span class="pl-2">{{
                          page?.completed | titlecase }}</span>
                      </td>
                      <td data-title="Status" *ngIf="assessment.status == 'ACTIVE'">
                        <span><img src="assets/img/pending_ass.svg" class="img-status" /></span><span
                          class="pl-2">{{page?.inProgress}}</span>
                      </td>
                      <td data-title="Status" *ngIf="assessment.status == 'SCHEDULED'">
                        <span><img src="assets/img/pro.svg" class="img-status" /></span><span
                          class="pl-2">{{page?.pending}}</span>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="actionColumn" *ngIf="assessment.status == 'COMPLETED' || assessment.status == 'ACTIVE'"><img src="./../../../../assets/img/view-report-icon.svg" alt="view.svg"   (click)="viewAssessment(assessment)"/></span>
                          <span class="actionColumn" *ngIf="assessment.status == 'SCHEDULED'"><img src="assets/img/update-icon.svg" alt="update-icon.svg" (click)="viewAssessment(assessment)"/></span>
                          <span class="actionColumn" *ngIf="assessment.status == 'SCHEDULED'"><img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="deleteAssessment(assessment)"/></span>
                          <span  class="actionColumn" *ngIf="assessment.status == 'COMPLETED' || assessment.status == 'ACTIVE'"><img src="./../../../../assets/img/delete-gray.svg" alt="delete.svg" (click)="openModal(template, assessment)"/></span>  
                        </div>
                      </td>

                    </tr>
                  </ng-container>
                  <ng-container *ngIf="assessments.length === 0">
                    <tr *ngFor="
                    let assessment of assessments
                      | paginate: { itemsPerPage: 5, currentPage: '1' }
                  ">
                    </tr>
                    <td class="text-center pl-0-c" [attr.colspan]="8">
                     {{page?.noFound}}
                    </td>
                  </ng-container>
                </tbody>
              </table>
              <div style="float: right" class="w-sm-100">
                <pagination-controls  previousLabel=""
                nextLabel="" class="my-pagination" (pageChange)="p = $event" directionLinks="true">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #template>
    <div class="modal-body text-center modal-confirm">
      <div class="modal-body">
        <div class="msg-wrapper">
          <div>
            <img src="assets/img/new_delete.svg" alt="new_delete.svg" />
          </div>
          <p class="mt-3 text-center delete-msg">
           {{page?.deleteWarning}}
          </p>
        </div>
      </div>
      <div class="pt-4 border-top">
        <button
          (click)="decline()"
          type="button"
          class="btn btn-md ml-3">
          {{page?.cancel}}
        </button>
        <button
        (click)="deleteAssessment(deleteAss)"
          type="button"
          class="btn btn-md btn-danger">
          {{page?.delete}}
        </button>
      </div>
    </div>
  </ng-template>