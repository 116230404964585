import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  REFLECTION_PAGE,
  REFLECTION_PREVIOUS_PAGE,
  ReflectionData
} from './common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SKILL_BUILDER_TYPES } from '@services/member';
import {
  Domain2Service,
  LanguageService,
  StorageService,
  TEMP_KEYS,
  TEMP_KEY_PREFIX,
  VOLATILE_KEYS
} from '@services/public';

const PAGE_PATH: string = 'skillBuilders.reflection.pages.main';

@Component({
  selector: 'app-member-skill-builder-reflection',
  templateUrl: './reflection.component.html',
  styleUrls: ['./reflection.component.scss']
})
export class MemberSkillBuilderReflectionComponent
  implements OnInit, OnDestroy
{

  REFLECTION_PAGE: typeof REFLECTION_PAGE = REFLECTION_PAGE;
  data: ReflectionData;
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    title: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _router: Router,
    private _storageSvc: StorageService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      });

    // Load domain and skill data to be used in subpages.
    await this._domainSvc.loaded;

    // Get reflection data.
    this.data = this._storageSvc.getTempStorage(TEMP_KEYS.REFLECTION);
    const domainUID: number =
      this._storageSvc.getVolatileStorage(VOLATILE_KEYS.DOMAIN_UID, true);
    if (!this.data) {
      // Redirect to the dashboard if domain UID is not set.
      if (typeof domainUID !== 'number') {
        this._router.navigate(['member/dashboard']);
        return;
      }

      // Build reflection data.
      const data: ReflectionData = {
        type: SKILL_BUILDER_TYPES.REFLECTION,
        start: true,
        page: REFLECTION_PAGE.STRENGTHS,
        domainUID: domainUID
      }

      if (data.domainUID === 0) {
        // Get onboarding skills.
        const path: string = 'skillBuilders.reflection.onboardingSkillUIDs';
        this._languageSvc.get([path]).then(value => {
          if (typeof value[path] !== 'object' || value[path] === null) return;
          data.onboardingSkillUIDs = Object.values(value[path]);
          this.data = data;
          this._setStorageSubscription();
        });
        return;
      } else this.data = data;
    }
    this._setStorageSubscription();
  }

  private _setStorageSubscription() {
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
        this.data = update.value[TEMP_KEYS.REFLECTION];
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR)
        this.data = null;
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.REFLECTION)
        this.data = update.value;
      if (!this.data && !this._router.getCurrentNavigation())
        this._router.navigate(['member/dashboard']);
    }));
  }

  back() {
    let page: REFLECTION_PAGE;
    if (this.data.page === REFLECTION_PAGE.SUMMARY && !this.data.skillUID)
      page = REFLECTION_PAGE.CUSTOM_ACTION;
    else page = REFLECTION_PREVIOUS_PAGE[this.data.page];
    if (!page) {
      this._router.navigate(['member/dashboard']);
      return;
    }
    this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION,
      { ...this.data, page });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._storageSvc.setTempStorage(TEMP_KEYS.REFLECTION, null);
  }

}
