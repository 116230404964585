import { Component, Input, OnInit } from '@angular/core';
import { LABELS, LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.dashboard.rewards.challengeBadges.page';

export interface ChallengeBadge {
  name: string;
  gradient: string;
  level: number;
  icon: string;
}

@Component({
  selector: 'app-member-dashboard-rewards-challenge-badges',
  templateUrl: './challenge-badges.component.html',
  styleUrls: ['./challenge-badges.component.scss']
})
export class MemberDashboardRewardsChallengeBadgesComponent implements OnInit {

  @Input() challengeBadges: Array<ChallengeBadge> = [];

  domainIcon: any = {};

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    legend: '',
    previousLevel: '',
    currentLevel: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.LEVEL]: ''
  }

  constructor(
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value
          );
        }
      }
    );
    this._languageSvc.getLabels(this.labels);
  }

}
