import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService, LanguageService} from '@services/public';
import { application } from 'environment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-account-modal',
  // @ts-ignore
  templateUrl: (application === 'jakapa.website') ?
    './account-modal.component.website.html' :
    './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss']
})
export class AccountModalComponent implements OnInit {

  isWeb: boolean = false;
  pageLanguage: any;
  labels: any;

  constructor(
    private router: Router, 
    private logoutSvc: LogoutService,
    private modalSvc: BsModalService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    if(application === 'jakapa.website')
    {
      this.isWeb = true;
    }
    const path = `pages.account.accountModal.website`
    this._languageSvc.get([path]).then((value) => {
      this.pageLanguage = value[path];
    });
    this._languageSvc.get([`labels`]).then((value) => {
      this.labels = value[`labels`];
    })
  }

  logout(): void {
    this.logoutSvc.logout();
    this.modalSvc.hide();
    this.router.navigate(["/"]);
  }

}
