import { Component, Input, OnInit } from '@angular/core';
import { WwydService } from '@services/member';
import { LanguageService } from '@services/public';
import { WWYDReportData } from '../common';
import { APINoData, isAPINoData } from '@util';

const PAGE_PATH: string = 'skillBuilders.wwyd.pages.report';

@Component({
  selector: 'app-member-skill-builder-wwyd-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class MemberSkillBuilderWwydReportComponent implements OnInit {

  @Input() noData: () => void;
  display: boolean = false;
  totalDilemmas: number;

  // Page langauge.
  page: {[key: string]: string} = {
    totalDilemmas: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _wwydSvc: WwydService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    this._wwydSvc.getReport().subscribe(
      (res: WWYDReportData | APINoData) => {
        if (!isAPINoData(res)) {
          this.display = true;
          this.totalDilemmas = res.totalDilemmas;
        } else {
          this.display = false;
          this.noData();
        }
      }
    );
  }

}
