import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  TemplateRef,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

import {User, UserService } from "@services/viewer";
import { GroupService, Group } from "@services/member";
import { LoaderService, LanguageService } from "@services/public";

const PAGE_PATH = `pages.leader.assessment.create`;

@Component({
  selector: "app-leader-assessment-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class LeaderAssessmentCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public set assessment(assessment: any) {
    if (assessment) {
      this.form.patchValue(assessment);
      this.f.type.disable();
      if (this.f.type.value === "peer")
        this.groups = this.groups.filter(
          (group) => group.memberUUIDs.length > 1
        );
      const group = this.groups.find(
        (group) => group.uuid === assessment.group.uuid
      );
      if (!!group) this.f.group.patchValue(group);
    }
  }

  form: FormGroup;
  groups: Array<Group> = [];
  users: Array<User> | null = null;
  user: User | null = null;

  selectedGroup: Group | null = null;
  filteredUsers: Array<User> = [];

  checkAll: HTMLInputElement
  previousTypeValue: any
  tab1: HTMLElement;
  tab2: HTMLElement;
  selectedUsers: any;
  checkboxes: NodeListOf<HTMLInputElement>;

  private _subscriptions: Subscription = new Subscription();
  minStartDate: Date;
  maxEndDate: Date;
  modalRef: BsModalRef;
   // Page language.
   page: {[key: string]: string} = {
   }

  constructor(
    private groupSvc: GroupService,
    private router: Router,
    private modalSvc: BsModalService,
    private loaderSvc: LoaderService,
    private usersSvc: UserService,
    private elRef: ElementRef,
    private _languageSvc: LanguageService
  ) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-dialog-centered",
    });
  }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page)
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value);
      }
    );

    this.form = new FormGroup(
      {
        uuid: new FormControl(null),
        searchUser: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        type: new FormControl("self"),
        group: new FormControl(null, [Validators.required]),
        startDate: new FormControl(null, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        selectedUsers: new FormControl(null)
      },
      this.dateValidator
    );

    // Set checkAll and previousTypeValue.
    this.checkAll = this.elRef.nativeElement
    .querySelector("#checkAll");
    this.previousTypeValue = this.f.type.value;

    // Get groups.
    this._subscriptions.add(
      this.groupSvc.groups.subscribe((next) => {
        if (!this.f.uuid.value || this.f.type.value !== "peer")
          this.groups = next.filter(
            (group) => !!group.memberUUIDs && group.memberUUIDs.length > 0
          );
        else
          this.groups = next.filter(
            (group) => !!group.memberUUIDs && group.memberUUIDs.length > 1
          );
        const group = this.groups.find(
          (group) => group.uuid === this.form.value.group?.uuid
        );
        if (!group) this.f.group.patchValue(null);
        else this.f.group.patchValue(group);
      })
    );
    this.groupSvc.loadGroups();

    // Get users.
    this._subscriptions.add(
      this.usersSvc.users.subscribe((users) => {
        this.users = users;        
      })
    );
    this.usersSvc.loadUsers();
  }

  get f(): any {
    return this.form.controls;
  }

  selectAllUsers(): void {
    const checks = this.elRef.nativeElement.querySelectorAll(
      "#checkboxes input[type=checkbox]");
    const checked = Array.from(checks).filter(
      (check: HTMLInputElement) => {
        check.checked = this.checkAll.checked;
          return check.checked;
      });
    if (checked.length > 0) {
      this.selectedUsers = 'ALL';
    } else {
      this.selectedUsers = null;
    }
  }

  selectIndividual(event: any): void { 
    const check = event.target;
    const selectedUsers = Array.isArray(this.selectedUsers)
      ? this.selectedUsers
      : this.selectedUsers === 'ALL'
      ? this.filteredUsers
      : [];
    const user = this.users.filter(
      (user) =>
      this.form.value.group.memberUUIDs.includes(user.uuid)
      ).find((user) => user.email === check.value);

    if (check.checked) {
      this.selectedUsers = [...selectedUsers, user];
    } 
    else {
      this.selectedUsers = selectedUsers.filter((u) => u.email !== check.value);
    }
    const groupMembers = this.users.filter(
      (user) => this.form.value.group.memberUUIDs.includes(user.uuid));
    this.checkAll.checked = this.selectedUsers.length === groupMembers.length;
  }

  // check is user is selected
  isSelected(email: string): boolean {
    return this.selectedUsers === 'ALL' ||
      (Array.isArray(this.selectedUsers)) &&
        this.selectedUsers.some(user => user.email === email)
  }

  //User Search
  searchUser(event: any): void {
    const value = event.target.value;
    if (this.form.value.group) {
      if (!value) {
        this.filteredUsers = this.users.filter((user) =>
          this.form.value.group.memberUUIDs.includes(user.uuid));
      }
      else {
        this.filteredUsers = this.users.filter((user) =>
          this.form.value.group.memberUUIDs.includes(user.uuid))
            .filter((user: User) => user.name.toLowerCase().includes(
              value.toLowerCase()) ||
              user.email.toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }

  private dateValidator(form: FormGroup): any {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (
      new Date(form.value.startDate) < currentDate) {
      return {startDate: true};
    }
    if (
      !form.value.startDate ||
      !form.value.endDate ||
      new Date(form.value.startDate) > new Date(form.value.endDate) 
    ) {
      return { dateConflict: true };
    }
    return null;
  }

  public onGroupChange(): void {
    if (!this.form.value.group) return;
    const group = this.form.value.group;
    this.filteredUsers = this.users.filter((user) =>
      group.memberUUIDs.includes(user.uuid)
    );
    this.selectedUsers = null;
    this.checkAll.checked = false;
    const checks = this.elRef.nativeElement.querySelectorAll(
      "#groupMembers input[type=checkbox]");
    checks.forEach((check) => {
      check.checked = false;
    });    
    if (group.memberUUIDs.length === 1) this.f.type.patchValue("self");
    if (
      !!this.form.value.startDate &&
      new Date(this.form.value.startDate) < new Date(group.startDate)
    )
      this.f.startDate.patchValue(null);
    if (
      !!this.form.value.startDate &&
      new Date(this.form.value.startDate) > new Date(group.endDate)
    )
      this.f.startDate.patchValue(null);
    if (
      !!this.form.value.endDate &&
      !!group.endDate &&
      new Date(this.form.value.endDate) < new Date(group.startDate)
    )
      this.f.endDate.patchValue(null);
    if (
      !!this.form.value.endDate &&
      !!group.endDate &&
      new Date(this.form.value.endDate) > new Date(group.endDate)
    )
      this.f.endDate.patchValue(null);
    this.minStartDate = new Date(group.startDate);
    if (!group.endDate) delete this.maxEndDate;
    else this.maxEndDate = new Date(group.endDate);
  }

  public onSubmit(): void {
    // Validate form.
    this.f.selectedUsers.patchValue(this.selectedUsers);
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    if (!this.form.value.uuid) {
      localStorage.setItem(
        "createAssessment",
        JSON.stringify({ formValue: this.form.value, from: "create" })
      );
      this.loaderSvc.removeLoader(loader);
      this.closeModal();
      this.router.navigate(["/leader/assessment/skills"]);
    } else {
      // Update assessment.
      localStorage.setItem(
        "createAssessment",
        JSON.stringify({ formValue: this.form.value, from: "view" })
      );   
      this.closeModal();
      this.loaderSvc.removeLoader(loader);
      this.router.navigate(["/leader/assessment/skills"]);
    }
  }

  closeModal() {
    this.modalSvc.hide();
  }

  ngAfterViewInit(): void {
    // Set tabs.
    this.tab1 = this.elRef.nativeElement.querySelector("#tab1-link");
    this.tab2 = this.elRef.nativeElement.querySelector("#tab2-link");

    // Set Form Rules.
    this._subscriptions.add(
      this.form.valueChanges.subscribe((_next) => {
        if (Array.isArray(this.selectedUsers)
          && this.selectedUsers?.length ===
          this.form.value.group?.memberUUIDs?.length) {
          this.selectedUsers = "ALL";
        }
        // Leader Feedback is only for groups.
        if (this.f.type.value !== this.previousTypeValue) {
          this.previousTypeValue = this.f.type.value;
          if (this.f.type.value === "leader" || this.f.type.value === "peer") {
            this.tab1.classList.add("disabled");
            if (this.tab1.classList.contains("active")) {
              this.tab2.click();
            }
            this.checkAll.checked = false;
            this.selectedUsers =null;
          } 
          if (this.f.type.value === "self"){
            this.tab1.classList.remove("disabled");
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
